import notificationTypes from "./notificationTypes";

const initialState = {
  open: false,
  message: "",
  type: "success",
  allNotifications: [],
  archivedNotifications: [],
  nonArchivedNotifications: [],
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationTypes.SHOW_SUCCESS:
      return {
        open: true,
        message: action.payload.message,
      };
    case notificationTypes.SHOW_ERROR:
      return {
        open: true,
        message: action.payload.message,
        type: "error"
      };
    case notificationTypes.CLOSE_NOTIFICATION:
      return {
        open: false,
        message: "",
        type: "success",
      };
    case notificationTypes.GET_ALL:
      return {
        allNotifications: action.payload,
      };
    case notificationTypes.GET_ARCHIVED:
      return {
        archivedNotifications: action.payload,
      };
    case notificationTypes.GET_NON_ARCHIVED:
      return {
        nonArchivedNotifications: action.payload,
      };

    default:
      return state;
  }
};

export default notificationReducer;
