import React from "react";
import { MoreVert } from "@mui/icons-material";
import {
  Paper,
  Stack,
  Box,
  Typography,
  Grid,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import SiteCardRow from "./SiteCardRow";
import { CustomButton } from "../../../components";
import { SERVER } from "../../../utils/server";

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

function SiteCard({
  data,
  onDelete,
  onEdit,
  onDetail,
  onCommand,
  isAdmin,
  onCommandList,
}) {

  return (
    <Grid item xs={12} md={6} lg={6}>
      <Paper
        sx={{
          width: "100%",
          border: "1px solid #161B38",
          borderRadius: "10px",
          //   m: 2,
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            padding: 0.5,
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            cursor: "pointer",
          }}
          onClick={onDetail}
        >
          <img
            src={
              data.imageUrl
                ? `${SERVER}download?fileName=${data.imageUrl}`
                : "/site.png"
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/site.png";
            }}
            alt="site"
            style={{ width: "100%", height: 160, objectFit: 'cover' }}
          />
        </Box>
        <Box p={2} pt={0}>
        <Typography
          fontSize={14}
          fontWeight={700}
          color="#161B38"
          my={1}
          mt={2}
          sx={{ cursor: "pointer" }}
          onClick={onDetail}
        >
          {data.name}
        </Typography>
        <SiteCardRow title="Client :" value={data?.client?.raisonSociale} />
        <SiteCardRow title="Superficie :" value={`${data.superficy} m²`} />
        <SiteCardRow title="Type :" value={data?.siteType?.name} />
        <SiteCardRow
          title="Adresse :"
          value={`${data.address?.address} ${data.address?.city}`}
        />
        <SiteCardRow
          title="Opérationnels :"
          value={data.operationelAffectes?.length || 0}
        />
        <Stack
          direction="row"
          sx={{ alignItems: "center", justifyContent: "space-between", my: 2 }}
        >
          {!isAdmin ? (
            <CustomButton
              title="Commander"
              onClick={onCommand}
            />
          ) : (
            <div />
          )}

          <Select
            MenuProps={MenuProps}
            variant="standard"
            disableUnderline
            sx={{ "& .MuiSelect-icon": { transform: "none" } }}
            IconComponent={(props) => (
              <MoreVert
                fontSize="large"
                {...props}
                style={{ color: "#161B38" }}
              />
            )}
          >
            <MenuItem
              style={{
                background: "white",
                color: "#161928",
                fontSize: 14,
                fontWeight: 700,
              }}
              onClick={onDetail}
            >
              Description du site
            </MenuItem>
            <Divider sx={{ mx: 2 }} />
            <MenuItem
              style={{
                background: "white",
                color: "#161928",
                fontSize: 14,
                fontWeight: 700,
              }}
              onClick={onEdit}
            >
              Modifier le site
            </MenuItem>
            <Divider sx={{ mx: 2 }} />
            <MenuItem
              style={{
                background: "white",
                color: "#161928",
                fontSize: 14,
                fontWeight: 700,
              }}
              onClick={onCommandList}
            >
              Voir les commandes
            </MenuItem>
            <Divider sx={{ mx: 2 }} />
            <MenuItem
              style={{
                background: "white",
                color: "#CB1728",
                fontSize: 14,
                fontWeight: 700,
              }}
              onClick={onDelete}
            >
              Supprimer le site
            </MenuItem>
          </Select>
        </Stack>
        </Box>
      </Paper>
    </Grid>
  );
}

SiteCard.propTypes = {};

export default SiteCard;
