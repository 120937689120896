import axios from "axios";
import { getErrorMessage } from "../../utils/errorCodeMessage";
import { SERVER } from "../../utils/server";
import notificationTypes from "../notifications/notificationTypes";

export const signin = (user, callback,onError) => async (dispatch) => {
  try {
    const results = await axios.post(`${SERVER}auth/signin`, user);
 
    if (results.data.isSuccess) {
      const value = results.data.data.agent || results.data.data.admin || results.data.data.client
      localStorage.setItem("accessToken", results.data.data.accessToken);
      localStorage.setItem("agent", JSON.stringify(value));
      callback?.(value.authAccountType);
    } else {
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: results.data.message },
      });
      onError?.();
    }
  } catch (error) {
    if (error.response) {
      // console.log(error.response.data);
      // console.log(error.response.status);
      if (error.response.status === 400) {
        onError?.();
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: {
            message: getErrorMessage(error.response.data?.message),
          },
        });
      }
      return;
    }
    dispatch({
      type: notificationTypes.SHOW_ERROR,
      payload: { message: "Une erreur est survenue" },
    });
  }
};

export const signout = (callback) => async () => {
  try {
    localStorage.clear();
    callback?.();
  } catch (error) {
    console.error("Signout error", error);
  }
};

export const sendOtp = (data, callback,onError) => async (dispatch) => {
  try {
    const results = await axios.post(`${SERVER}auth/request-otp-reset-pass`, data);
 
    if (results.data.isSuccess) {
      localStorage.setItem("emailToReset", data.email);
      callback?.();
    } else {
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: results.data.message },
      });
      onError?.();
    }
  } catch (error) {
    onError?.();
    dispatch({
      type: notificationTypes.SHOW_ERROR,
      payload: { message: "Une erreur est survenue" },
    });
    console.error("Send Otp error", error);
  }
};

export const verifyOtp = (data, callback,onError) => async (dispatch) => {
  try {
    const results = await axios.post(`${SERVER}auth/verify-otp`, data);
 
    if (results.data.isSuccess) {
      localStorage.removeItem("emailToReset");
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: "Mot de passe réinitialiser avec succès" },
      });
      callback?.();
    } else {
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: results.data.message },
      });
      onError?.();
    }
  } catch (error) {
    onError?.();
    dispatch({
      type: notificationTypes.SHOW_ERROR,
      payload: { message: "Une erreur est survenue" },
    });
    console.error("Send Otp error", error);
  }
};