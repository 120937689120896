import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import FormModalTitle from "../../../components/FormModalTitle";
import { SERVER } from "../../../utils/server";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, md: 650, lg: 700 },
  bgcolor: "background.paper",
  borderRadius: 2,
};

function DevisPreviewModal({ filename, open, onClose }) {
 
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FormModalTitle
          title={`Aperçu du dévis`}
          closeModal={onClose}
        />
        <Box p={4} pt={3} style={{ maxHeight: "85vh", overflow: "auto" }}>
          <div>
            <iframe
              src={`${SERVER}download?fileName=${filename}`}
              title="PDF Document"
              width="100%"
              height="500px"
            />
          </div>
        </Box>
      </Box>
    </Modal>
  );
}

DevisPreviewModal.propTypes = {};

export default DevisPreviewModal;
