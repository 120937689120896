import axios from "axios";
import { SERVER } from "../../utils/server";
import devisTypes from "./devisTypes";
import notificationTypes from "../notifications/notificationTypes";
import { getErrorMessage } from "../../utils/errorCodeMessage";

export const createDevis =
  (devis, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}devis`, devis);
      if (results.data.isSuccess) {
        dispatch({
          type: devisTypes.CREATE_DEVIS,
          payload: results.data.data,
        });
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Dévis envoyé avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: { message: results.data.message },
        });
        onError?.();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          onError?.();
          dispatch({
            type: notificationTypes.SHOW_ERROR,
            payload: {
              message: getErrorMessage(error.response.data?.data[0]?.param),
            },
          });
        }
        return;
      }
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Devis creation error", error);
    }
  };

export const updateDevis =
  (devis, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.put(`${SERVER}devis`, devis);
      if (results.data.isSuccess) {
        dispatch({
          type: devisTypes.UPDATE_DEVIS,
          payload: results.data.data,
        });
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Dévis modifié avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: { message: results.data.message },
        });
        onError?.();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          onError?.();
          dispatch({
            type: notificationTypes.SHOW_ERROR,
            payload: {
              message: getErrorMessage(error.response.data?.data[0]?.param),
            },
          });
        }
        return;
      }
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Devis update error", error);
    }
  };  

  export const createBonCommande =
  (devis, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}bonDeCommand`, devis);
      if (results.data.isSuccess) {
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Bon de commande envoyé avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: { message: results.data.message },
        });
        onError?.();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          onError?.();
          dispatch({
            type: notificationTypes.SHOW_ERROR,
            payload: {
              message: getErrorMessage(error.response.data?.data[0]?.param),
            },
          });
        }
        return;
      }
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Bon de commande creation error", error);
    }
  };  

export const signDevis =
  (id, devis, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}devis/${id}/signOrReject`, devis);
      if (results.data.isSuccess) {
        dispatch({
          type: devisTypes.CREATE_DEVIS,
          payload: results.data.data,
        });
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Dévis signé avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: { message: results.data.message },
        });
        onError?.();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          onError?.();
          dispatch({
            type: notificationTypes.SHOW_ERROR,
            payload: {
              message: getErrorMessage(error.response.data?.data[0]?.param),
            },
          });
        }
        return;
      }
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Devis creation error", error);
    }
  };

  export const sendAmendement =
  (amendement, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}amendement`, amendement);
      if (results.data.isSuccess) {
    
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Amendement envoyé avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: { message: results.data.message },
        });
        onError?.();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          onError?.();
          dispatch({
            type: notificationTypes.SHOW_ERROR,
            payload: {
              message: getErrorMessage(error.response.data?.data[0]?.param),
            },
          });
        }
        return;
      }
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Amendement creation error", error);
    }
  };  

  export const updateAmendement =
  (amendement, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.put(`${SERVER}amendement`, amendement);
      if (results.data.isSuccess) {
    
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Amendement modifié avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: { message: results.data.message },
        });
        onError?.();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          onError?.();
          dispatch({
            type: notificationTypes.SHOW_ERROR,
            payload: {
              message: getErrorMessage(error.response.data?.data[0]?.param),
            },
          });
        }
        return;
      }
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Amendement update error", error);
    }
  };

export const listCommandDevis = async (commandId) => {
  try {
    const results = await axios.get(`${SERVER}devis/command/${commandId}`);
    return results.data.data;
  } catch (error) {
    console.error("Devis command list error", error);
  }
};

export const listBonDeCommand = async (commandId) => {
  try {
    const results = await axios.get(`${SERVER}bonDeCommand/command/${commandId}`);
    return results.data.data;
  } catch (error) {
    console.error("Bon de command list error", error);
  }
};

export const listAmendementDevis = async (devisId) => {
  try {
    const results = await axios.get(`${SERVER}amendement/devis/${devisId}`);
    return results.data.data;
  } catch (error) {
    console.error("Devis amendement list error", error);
  }
};

export const listDevis = () => async (dispatch) => {
  try {
    const results = await axios.get(`${SERVER}devis`);
    dispatch({ type: devisTypes.LIST_DEVIS, payload: results.data.data });
  } catch (error) {
    console.error("Devis list error", error);
  }
};

export const deleteDevis = (id) => async (dispatch) => {
   await axios.delete(`${SERVER}devis/${id}`);

  dispatch({ type: devisTypes.REMOVE_DEVIS, payload: id });
};
