import axios from "axios";
import { SERVER } from "../../utils/server";
import agentTypes from "./agentTypes";
import notificationTypes from "../notifications/notificationTypes";
import { CODES } from "../../utils/errorCodes";
import { getErrorMessage } from "../../utils/errorCodeMessage";

export const createAgent =
  (agent, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}securityAgent`, agent);
      if (results.data.isSuccess) {
        // dispatch({
        //   type: agentTypes.CREATE_AGENT,
        //   payload: results.data.data,
        // });
        // dispatch({
        //   type: notificationTypes.SHOW_SUCCESS,
        //   payload: { message: "Enregistré avec succès" },
        // });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: { message: results.data.message },
        });
        onError?.();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          onError?.();
          dispatch({
            type: notificationTypes.SHOW_ERROR,
            payload: {
              message: getErrorMessage(error.response.data?.message),
            },
          });
        }
        return;
      }
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Agent creation error", error);
    }
  };

export const activateAgent =
  (agent, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.post(
        `${SERVER}securityAgent/activate`,
        agent
      );
      if (results.data.isSuccess) {
        localStorage.setItem("accessToken", results.data.data.accessToken);
        localStorage.setItem("agent", JSON.stringify(results.data.data.agent));
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: { message: results.data.message },
        });
        onError?.();
      }
    } catch (error) {
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Agent activation error", error);
    }
  };

export const listAgents = () => async (dispatch) => {
  try {
    const results = await axios.get(`${SERVER}securityAgents`);
    dispatch({ type: agentTypes.LIST_AGENTS, payload: results.data.data });
  } catch (error) {
    console.error("Agent list error", error);
  }
};

export const deleteAgent = (id) => async (dispatch) => {
   await axios.delete(`${SERVER}securityAgent/${id}`);

  dispatch({ type: agentTypes.REMOVE_AGENT, payload: id });
};

export const updateAgent =
  (agent, callback, toastMessage = "Modifié avec succès", onError,isProfile) =>
  async (dispatch) => {
    try {
      const results = await axios.put(`${SERVER}securityAgent`, agent);
      dispatch({
        type: agentTypes.UPDATE_AGENT,
        payload: results.data.data,
      });
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: toastMessage },
      });
      if(isProfile){
        localStorage.setItem("agent",JSON.stringify(results.data.data))
      }
      callback?.();
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          onError?.();
          dispatch({
            type: notificationTypes.SHOW_ERROR,
            payload: {
              message: getErrorMessage(error.response.data?.data[0]?.param),
            },
          });
        }
        return;
      }
      console.log("update agent error", error);
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
    }
  };

  export const resetAgentPassword = (agent, callback,onError) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}securityAgent/resetPassword`, agent);
      if (results.data.isSuccess) {
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Modifié avec succès" },
        });
        callback?.();
      } else {
        const { errorCode } = results.data;
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: { message: CODES[errorCode] },
        });
        onError?.();
      }
    } catch (error) {
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Admin creation error", error);
    }
  };