import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";
import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import { CustomButton } from "../components";

const BottomBar = ({
  title,
  subTitle,
  subTitle2,
  step,
  prevStep,
  nextStep,
}) => (
  <Box
    sx={{
      height: "30%",
      width: "80%",
      margin: 10,
      background: "#FFFFFF",
      opacity: 0.5,
      borderRadius: "5px",
      border: "2px solid #161928",
      backdropFilter: "blur(15px)",
      position: "absolute",
      bottom: 0,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
    }}
  >
    <div>
      <Typography
        fontWeight="600"
        fontSize={25}
        color="black"
        textAlign={"center"}
      >
        {title}
      </Typography>

      <Typography
        fontWeight="400"
        fontSize={14}
        color="black"
        sx={{margin: 3}}
        textAlign={"center"}
        my={2}
      >
        {subTitle}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        mt={3}
      >
        <CustomButton
          rootStyle={{
            backgroundColor: "transparent",
            justifyContent: "flex-start",
            padding: 0,
          }}
          leftComponent={
            <ChevronLeftRounded
              sx={{
                border: "1px solid #000",
                borderRadius: 100,
                fontSize: 35,
              }}
              htmlColor="#000"
            />
          }
          onClick={prevStep}
        />
        <CustomButton
          rootStyle={{
            backgroundColor: "transparent",
            justifyContent: "flex-start",
            padding: 0,
          }}
          rightComponent={
            <ChevronRightRounded
              sx={{
                border: "1px solid #000",
                borderRadius: 100,
                fontSize: 35,
              }}
              htmlColor="#000"
            />
          }
          onClick={nextStep}
        />
      </Box>
    </div>
  </Box>
);

const AuthLayout = ({
  image = "signin",
  leftComponent,
  onSubmit,
  showBottomBar = true,
}) => {
  const [step, setStep] = React.useState(1);
  const [bgImage, setBgImage] = React.useState(image);

  const nextStep = (value, image) => {
    setStep(value);
    setBgImage(image);
  };

  const prevStep = (value, image) => {
    setStep(value);
    setBgImage(image);
  };

  return (
    <Box sx={{ backgroundColor: "white",maxHeight: "100vh" }} pt={0} mt={0}>
      <Grid
        container
        rowSpacing={1}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          style={{
            justifyContent: "center",
            display: "flex",
            // alignItems: "center",
            maxHeight: "100vh",
            overflow: 'auto'
          }}
        >
          <form onSubmit={onSubmit}>{leftComponent}</form>
        </Grid>

        <Grid
          item
          lg={6}
          md={6}
          sx={{
            justifyContent: "right",
            display: { xs: "none", md: "flex", lg: "flex" },
          }}
        >
          <Box
            sx={{
              position: "relative",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              width: '100%'
            }}
          >
            <img
              src={`/${bgImage}.webp`}
              alt={"item.title"}
              loading="lazy"
              style={{ width: "100%", height: "100vh", objectFit: "cover"}}
            />
            {showBottomBar && step === 1 && (
              <BottomBar
                step={step}
                title={`Agent de sécurité`}
                subTitle="Animi laborum aut praesentium. Architecto fugiat reprehenderit qui. Doloremque vel harum voluptatem consequatur ipsa."
                subTitle2="sommelière - Yaoundé (CMR)"
                // prevStep={() => prevStep(1, "signup1")}
                // nextStep={() => nextStep(2, "signup2")}
              />
            )}

            {/* {showBottomBar && step === 2 && (
              <BottomBar
                step={step}
                title={`"Je contrôle facilement la comptabilité de ma quincaillerie malgré la distance"`}
                subTitle="Gérémy"
                subTitle2="Chef d'entreprise - Douala (CMR)"
                prevStep={() => prevStep(1, "signup1")}
                nextStep={() => nextStep(3, "signup3")}
              />
            )}
            {showBottomBar && step === 3 && (
              <BottomBar
                step={step}
                title={`"Mon stock, mes achats et mes factures sont regroupés dans KBM."`}
                subTitle="Dannick"
                subTitle2="Gérant - Douala (CMR)"
                prevStep={() => prevStep(2, "signup2")}
                nextStep={() => nextStep(3, "signup3")}
              />
            )} */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AuthLayout;
