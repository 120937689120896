import React, { useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
  OutlinedInput,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import {
  AppRegistration,
  Lock,
  Mail,
  Phone,
  WhatsApp,
} from "@mui/icons-material";
import SigninTextInput from "../auth/components/SigninTextInput";
import FormModalTitle from "../../components/FormModalTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { clientSubYup } from "../../utils/yupValidation";
import { useDispatch, useSelector } from "react-redux";
import {
  createOperationnel,
  updateOperationnel,
} from "../../redux/operationnels/operationnelActions";
import SigninPhoneInput from "../auth/components/SigninPhoneInput";
import CustomLoader from "../../components/CustomLoader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, md: 700, lg: 700 },
  bgcolor: "background.paper",
  borderRadius: 2,
};

function OperationnelFormModal({ toggleModal, item, open }) {
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");
  const clients = useSelector((state) => Object.values(state.clients));

  const [loading, setLoading] = useState(false);
  const [limitedAccess, setLimitedAccess] = useState(true);

  const dispatch = useDispatch();
  const callback = () => {
    toggleModal();
    setLoading(false);
    resetForm();
  };

  const onError = () => {
    setLoading(false);
  };

  const toggleAccess = () => {
    setLimitedAccess(!limitedAccess);
  };

  const initialValues = {
    firstname: "",
    id: "",
    lastname: "",
    phoneNumber: "",
    whatSApp: "",
    email: "",
    password: "",
    address: "",
    postalCode: "",
    city: "",
    country: "France",
    confirmPassword: "",
    clientId: ["admin"].includes(agent?.authAccountType) ? "" : "" + agent?.id,
  };

  const { values, handleSubmit, setValues, errors, resetForm, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: Yup.object().shape(clientSubYup),
      onSubmit(values) {
        setLoading(true);
        const toSave = {
          ...values,
        };

        if (limitedAccess) {
          toSave.rules = ["OPERATIONNEL"];
        } else {
          toSave.rules = ["OPERATIONNEL", "CLIENT_ADMIN"];
        }

        if (values.id !== "") {
          let user = { ...item, ...toSave };
          delete user.email;
          dispatch(
            updateOperationnel({ operationnel: user, callback, onError })
          );
        } else {
          const user = {
            ...toSave,
            isActive: true,
            id: undefined,
          };
          dispatch(
            createOperationnel({ operationnel: user, callback, onError })
          );
        }
      },
    });

  useEffect(() => {
    if (item) {
      setValues(item);
      setFieldValue("clientId", "" + item.client?.id);
    }
  }, [item, setFieldValue, setValues]);

  const disabled =
    !values.email?.trim() ||
    !values.phoneNumber?.trim() ||
    !values.password?.trim() ||
    !values.firstname?.trim() ||
    !values.lastname?.trim() ||
    !values.clientId?.trim() ||
    (!item && values.password !== values.confirmPassword);

  return (
    <Modal
      open={open}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit}>
        <Box sx={style}>
          <FormModalTitle
            title={`${values.id ? "Modifier" : "Ajouter"} un opérateur`}
            closeModal={toggleModal}
            disabled={disabled}
          />
          <Box
            px={{ xs: 2, md: 4, lg: 4 }}
            pt={0}
            style={{ maxHeight: "85vh", overflow: "auto" }}
          >
            <FormControl sx={{ mt: 2 }}>
              <FormLabel
                sx={{
                  fontSize: {
                    xs: 12,
                    md: 14,
                  },
                  fontWeight: 600,
                  mb: 1,
                  color: "black",
                }}
              >
                Accès à toutes les commandes et sites du client
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  control={<Radio />}
                  label="Accès limité"
                  checked={limitedAccess}
                  onChange={toggleAccess}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Accès illimité"
                  checked={!limitedAccess}
                  onChange={toggleAccess}
                />
              </RadioGroup>
            </FormControl>
            <Stack
              direction={{ xs: "column", md: "row", lg: "row" }}
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <SigninTextInput
                title={"Nom*"}
                value={values.lastname}
                id="lastname"
                name="lastname"
                onChange={(value) => setFieldValue("lastname", value)}
                errorMessage={errors.lastname}
              />
              <SigninTextInput
                title={"Prénom*"}
                value={values.firstname}
                id="firstname"
                name="firstname"
                onChange={(value) => setFieldValue("firstname", value)}
                errorMessage={errors.firstname}
                icon={<AppRegistration />}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row", lg: "row" }}
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <SigninPhoneInput
                title={"Téléphone*"}
                value={values.phoneNumber}
                id="phoneNumber"
                name="phoneNumber"
                onChange={(value) => setFieldValue("phoneNumber", value)}
                errorMessage={errors.phoneNumber}
                icon={<Phone />}
              />
              <SigninTextInput
                title={"WhatsApp"}
                value={values.whatSApp}
                id="whatSApp"
                name="whatSApp"
                onChange={(value) => setFieldValue("whatSApp", value)}
                errorMessage={errors.whatSApp}
                icon={<WhatsApp />}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row", lg: "row" }}
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <SigninTextInput
                title={"Email*"}
                value={values.email}
                id="email"
                name="email"
                onChange={(value) => setFieldValue("email", value)}
                errorMessage={errors.email}
                icon={<Mail />}
              />
              {["admin"].includes(agent?.authAccountType) ? (
                <FormControl sx={{ mb: 3, mt: 2 }} fullWidth>
                  <Typography
                    color="black"
                    sx={{
                      fontSize: {
                        xs: 12,
                        md: 14,
                      },
                      fontWeight: 600,
                      mb: 1,
                    }}
                  >
                    Client*
                  </Typography>
                  <Select
                    variant="standard"
                    value={values.clientId}
                    onChange={({ target }) =>
                      setFieldValue("clientId", "" + target.value)
                    }
                    input={<OutlinedInput />}
                    style={{
                      backgroundColor: "#fff",
                    }}
                    // MenuProps={MenuProps}
                  >
                    {clients.map((client, index) => (
                      <MenuItem key={index} value={client.id}>
                        {client.raisonSociale}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
            </Stack>

            {!values.id ? (
              <Stack
                direction={{ xs: "column", md: "row", lg: "row" }}
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Mot de passe*"}
                  icon={<Lock />}
                  value={values.password}
                  id="password"
                  name="password"
                  type="password"
                  onChange={(value) => setFieldValue("password", value)}
                  errorMessage={errors.password}
                />
                <SigninTextInput
                  title={"Confirmer Mot de passe*"}
                  icon={<Lock />}
                  value={values.confirmPassword}
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  onChange={(value) => setFieldValue("confirmPassword", value)}
                  errorMessage={errors.confirmPassword}
                />
              </Stack>
            ) : null}
          </Box>
        </Box>
        <CustomLoader open={loading} />
      </form>
    </Modal>
  );
}

export default OperationnelFormModal;
