import axios from "axios";
import { SERVER } from "../../utils/server";
import adminTypes from "./adminTypes";
import notificationTypes from "../notifications/notificationTypes";
import { CODES } from "../../utils/errorCodes";

export const createAdmin = (admin, callback) => async (dispatch, getState) => {
  try {
    const results = await axios.post(`${SERVER}admin`, admin);
    dispatch({
      type: adminTypes.CREATE_ADMIN,
      payload: results.data.data,
    });
    dispatch({
      type: notificationTypes.SHOW_SUCCESS,
      payload: {message: "Enregistré avec succès"},
    });
    callback?.();
  } catch (error) {
    dispatch({
      type: notificationTypes.SHOW_ERROR,
      payload: { message: "Une erreur est survenue" },
    });
    console.error("Admin creation error", error);
  }
};

export const listAdmins = () => async (dispatch) => {
  try {
    const results = await axios.get(`${SERVER}admins`);
    dispatch({ type: adminTypes.LIST_ADMINS, payload: results.data.data });
  } catch (error) {
    console.error("Admin list error", error);
  }
};

export const deleteAdmin = (id) => async (dispatch) => {
   await axios.delete(`${SERVER}admin/${id}`);

  dispatch({ type: adminTypes.REMOVE_ADMIN, payload: id });
};

export const updateAdmin = (admin,callback,toastMessage="Modifié avec succès",onError,isProfile) => async (dispatch) => {
  try {
    const results = await axios.put(`${SERVER}admin`, admin);
    dispatch({
      type: adminTypes.UPDATE_ADMIN,
      payload: results.data.data,
    });
    dispatch({
      type: notificationTypes.SHOW_SUCCESS,
      payload: {message: toastMessage},
    });
    if(isProfile){
      localStorage.setItem("agent",JSON.stringify(results.data.data))
    }
    callback?.();
  } catch (error) {
    console.log("update admin error", error);
    onError?.();
    dispatch({
      type: notificationTypes.SHOW_ERROR,
      payload: { message: "Une erreur est survenue" },
    });
  }
};

export const resetAdminPassword = (admin, callback,onError) => async (dispatch, getState) => {
  try {
    const results = await axios.post(`${SERVER}admin/resetPassword`, admin);
    if (results.data.isSuccess) {
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: "Modifié avec succès" },
      });
      callback?.();
    } else {
      const { errorCode } = results.data;
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: CODES[errorCode] },
      });
      onError?.();
    }
  } catch (error) {
    dispatch({
      type: notificationTypes.SHOW_ERROR,
      payload: { message: "Une erreur est survenue" },
    });
    console.error("Admin creation error", error);
  }
};