import { MoreVert } from "@mui/icons-material";
import {
  Divider,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import EmptyList from "../../../components/EmptyList";

const MenuProps = {
  PaperProps: {
    style: {
      width: 150,
    },
  },
};

function ServiceHistoryTable({ histories, menuComponent, tableFooter, toggleEdit, openDelete }) {
  return histories?.length ? (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: "600" }}>
              #
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: "600" }}>
              Type de service
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Durée de l’expérience (en heures)
            </TableCell>
            {menuComponent ? (
              <TableCell align="center" sx={{ fontWeight: "600" }}></TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {histories?.map((admin, index) => (
            <TableRow
              key={admin.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="left">
                {index + 1}
              </TableCell>
              <TableCell component="th" scope="row" align="left">
                {`${admin.name}`}
              </TableCell>
              <TableCell align="center">{admin.experienceHours}</TableCell>

              {menuComponent ? (
                <TableCell align="center">
                  <Select
                    MenuProps={MenuProps}
                    variant="standard"
                    disableUnderline
                    sx={{ "& .MuiSelect-icon": { transform: "none" } }}
                    IconComponent={(props) => (
                      <MoreVert
                        fontSize="large"
                        {...props}
                        style={{ color: "#161B38" }}
                      />
                    )}
                  >
                    <MenuItem
                      style={{
                        background: "white",
                        color: "#161928",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                      onClick={() => toggleEdit(admin)}
                    >
                      Modifier
                    </MenuItem>
                    <Divider sx={{ mx: 2 }} />

                    {!admin.isDeleted && (
                      <MenuItem
                        style={{
                          background: "white",
                          color: "#CB1728",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                        onClick={() => openDelete(admin)}
                      >
                        Supprimer
                      </MenuItem>
                    )}
                  </Select>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
        {tableFooter}
      </Table>
      {!histories?.length ? (
        <Typography my={4} textAlign="center" color={"#9b9999"}>
          Aucun élément trouvé
        </Typography>
      ) : null}
    </TableContainer>
  ) : (
    <EmptyList />
  );
}

export default ServiceHistoryTable;
