import React from "react";
import { Typography } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { COLORS } from "../../../utils";

const SigninPhoneInput = ({
  title,
  type = "text",
  onChange,
  errorMessage,
  showError,
  disabled,
  value,
}) => {
  return (
    <div
      style={{
        marginTop: 15,
        width: "100%",
        position: "relative",
        height: 100,
      }}
    >
      <Typography
        color="black"
        sx={{
          fontSize: {
            xs: 12,
            md: 14,
          },
          fontWeight: 600,
          mb: 1,
        }}
      >
        {title}
      </Typography>
      <PhoneInput
        country={"fr"}
        // disableCountryCode
        value={value}
        countryCodeEditable={false}
        disableDropdown
        containerStyle={{ height: 52 }}
        inputStyle={{
          width: "100%",
          height: "100%",
        }}
        onChange={(phone) => onChange(phone)}
      />
      {errorMessage !== "" ? (
        <small style={{ color: COLORS.red, fontSize: 10 }}>
          {errorMessage}
        </small>
      ) : null}
    </div>
  );
};

export default SigninPhoneInput;
