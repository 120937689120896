import { Alert, Container, Snackbar } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { MainLayout } from "./layouts";
import notificationTypes from "./redux/notifications/notificationTypes";
import routes from "./Router/routes";
import AgentDetail from "./views/agents/AgentDetail";
import ConfirmAccount from "./views/auth/ConfirmAccount";
import ForgotPassword from "./views/auth/ForgotPassword";
import NewPassword from "./views/auth/NewPassword";
import Signin from "./views/auth/Signin";
import Signup from "./views/auth/Signup";
import AgentProposed from "./views/commands/AgentProposed";
import AgentProposition from "./views/commands/AgentProposition";
import CommandBonDetail from "./views/commands/CommandBonDetail";
import CommandDetail from "./views/commands/CommandDetail";
import NewCommand from "./views/commands/NewCommand";
import Settings from "./views/settings/Settings";

function App() {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);

  const handleClose = () => {
    dispatch({
      type: notificationTypes.CLOSE_NOTIFICATION,
    });
  };

  return (
    <Container
      style={{
        padding: 0,
        margin: "unset",
        maxWidth: "100%",
      }}
    >
      <>
        <Routes>
          <Route path={"/home/"} element={<MainLayout />}>
            {routes.map((route, index) => (
              <Route
                path={route.path}
                element={route.component}
                exact={route.exact}
                key={index}
              />
            ))}

            <Route path={"settings"} element={<Settings />} />
            <Route path={"command/:id"} element={<CommandDetail />} />
            <Route path={"command-bon-detail"} element={<CommandBonDetail />} />
            <Route path={"agent-proposition"} element={<AgentProposition />} />
            <Route path={"agent-proposed"} element={<AgentProposed />} />
            <Route path={"agent-detail"} element={<AgentDetail />} />
            <Route path={"command-new"} element={<NewCommand />} />
            {/* <Route path={"my-commands-list"} element={<CommandList />} /> */}
          </Route>
          <Route path={"/"} element={<Signin />} />
          <Route path={"/signin"} element={<Signin />} />
          <Route path={"/signup"} element={<Signup />} />
          <Route path={"/forgot-password"} element={<ForgotPassword />} />
          <Route path={"/new-password"} element={<NewPassword />} />
          <Route path={"/confirm-account"} element={<ConfirmAccount />} />
        </Routes>
      </>
      <Snackbar
        open={notifications.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={notifications.type} sx={{ width: "100%" }}>
          {notifications.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default App;
