import React from "react";
import {
  Paper,
  Stack,
  Typography,
} from "@mui/material";


function CommandCard({status,color,data}) {

  return (
    <Paper sx={{ my: 2, display: "flex", height: 100}}>
    <div style={{width:6, backgroundColor: color,height:"100%", marginRight: 5, borderTopRightRadius: 5,borderBottomRightRadius: 5}} />
    <Stack justifyContent="space-evenly" direction="column" sx={{height:"100%", width: "100%", px: 1}}>
    <Stack
      direction="row"
      spacing={2}
      sx={{ alignItems: "center",  justifyContent: "space-between"  }}
    >
      <Typography fontSize={14}>Commande n° {data.commandNumber}</Typography>
      <Typography fontSize={14} fontWeight={700}>
        du {new Date(data.createdAt).toLocaleDateString("fr")}
      </Typography>
    </Stack>
    <Typography fontSize={14} fontWeight={700}>
      {data.siteIntervension?.name}
    </Typography>
    <Stack
      direction="row"
      spacing={2}
      sx={{ alignItems: "center", justifyContent: "space-between" }}
    >
      <Typography fontSize={14}>
        Statut de la commande :
      </Typography>
      <Typography fontSize={14} fontWeight={700}>
        {status}
      </Typography>
    </Stack>
    </Stack>
  </Paper>
  );
}

CommandCard.propTypes = {};

export default CommandCard;
