import * as Yup from "yup";

export const clientYup = {
  raisonSociale: Yup.string().required("Champs requis"),
  email: Yup.string().required("Champs requis"),
  phoneNumber: Yup.string().required("Champs requis"),
  city: Yup.string().required("Champs requis"),
  country: Yup.string().required("Champs requis"),
  postalCode: Yup.string().required("Champs requis"),
  siret: Yup.string().required("Champs requis"),
  password: Yup.string().min(6).required("Champs requis"),
};

export const clientSubYup = {
  firstname: Yup.string().required("Champs requis"),
  lastname: Yup.string().required("Champs requis"),
  email: Yup.string().required("Champs requis"),
  // password: Yup.string().min(6).required("Champs requis"),
};

export const adminYup = {
  firstname: Yup.string().required("Champs requis"),
  lastname: Yup.string().required("Champs requis"),
  phoneNumber: Yup.string().required("Champs requis"),
  email: Yup.string().required("Champs requis"),
  password: Yup.string().min(6).required("Champs requis"),
};

export const agentYup = {
  firstname: Yup.string().required("Champs requis"),
  lastname: Yup.string().required("Champs requis"),
  phoneNumber: Yup.string().required("Champs requis"),
  email: Yup.string().required("Champs requis"),
  password: Yup.string().min(6).required("Champs requis"),
  city: Yup.string().required("Champs requis"),
  country: Yup.string().required("Champs requis"),
  postalCode: Yup.string().required("Champs requis"),
  nationalId: Yup.string().required("Champs requis"),
  confirmPassword: Yup.string().min(6).required("Champs requis"),
};

export const siteYup = {
  name: Yup.string().required("Champs requis"),
  address: Yup.string().required("Champs requis"),
  postalCode: Yup.string().required("Champs requis"),
  city: Yup.string().required("Champs requis"),
  siteTypeId: Yup.string().required("Champs requis"),
};

export const passwordYup = {
  oldPassword:  Yup.string().min(6).required("Champs requis"),
  newPassword:  Yup.string().min(6).required("Champs requis"),
  confirmPassword:  Yup.string().min(6).required("Champs requis"),
};
