import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const SigninHeader = ({ title, subTitle, centerTitle }) => {
  return (
    <>
    <Typography sx={{textAlign: {xs: "center",md: "left"}}}>
    <Link to="/">
        <img
          src={"/logo_full.png"}
          alt={"logo_full"}
          style={{ width: 120, height: 120, objectFit: "contain" }}
        />
      </Link>
    </Typography>
      <Typography
        color="primary"
        sx={{
          fontSize: {
            xs: 30,
            md: 48,
          },
          fontWeight: 600,
          // textAlign: centerTitle ? "center":"unset"
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: 13,
            md: 15,
          },
          mb: 4,
        }}
        color="black2"
      >
        {subTitle}
      </Typography>
    </>
  );
};

export default SigninHeader;
