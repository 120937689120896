import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { CustomButton, CustomConfirm, TablePaginationActions } from "../../components";
import SigninTextInput from "../auth/components/SigninTextInput";
import FormModalTitle from "../../components/FormModalTitle";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import EmptyList from "../../components/EmptyList";
import { createServiceType } from "../../redux/serviceType/serviceTypeActions";

const MenuProps = {
  PaperProps: {
    style: {
      width: 150,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, md: 600, lg: 600 },
  bgcolor: "background.paper",
  borderRadius: 2,
};

function ServiceType() {
  const serviceTypes = useSelector((state) => Object.values(state.serviceTypes));
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [filter, setFilter] = useState("list");
  const [confirmModalTitle, setConfirmModalTitle] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const toggleModal = () => {
    setOpen(!open);
  };
  const toggleDeleteModal = (item) => {
    if (item) {
      setSelectedItem(item);
    }
    setDeleteModal(!deleteModal);
  };

  const toggleEdit = (item) => {
    // setId(item.id);
    // setName(item.name);
    // setDescription(item.description);
    // setOpen(true);
  };

    const onError = () => {
    setLoading(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openDelete = (item) => {
    // setSelectedItem(item);
    // setConfirmModalTitle("Voulez-vous vraiment supprimer cet Agent?");
    // setDeleteModal(true);
    // setIsDelete(true);
  };

  const deleteConfirm = () => {
    if (isDelete) {
      onDelete();
    }
  };

  const onDelete = () => {
    let user = { ...selectedItem, isDeleted: true };
    // dispatch(
    //   updateServiceType(user, setDeleteModal(!deleteModal), "Supprimé avec succès")
    // );
  };

  const onCloseModal = () => {
    setName("");
    setDescription("");
    toggleModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const user = {
      name,
      description,
    };
    dispatch(createServiceType(user, callback,onError));
  };

  const dispatch = useDispatch();
  const callback = () => {
    setOpen(false);
    setLoading(false);
    setName("");
    setDescription("");
  };

  return (
    <Box
      style={{
        minHeight: "100vh",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          borderRadius: 3,
          // height: "600px",
          backgroundColor: "#fff",
          padding: 2,
          marginTop: 3,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{
            mb: 1,
          }}
        >
          <Typography fontWeight="600" color="#161B38" fontSize={28}>
            Liste des types de services
          </Typography>

          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            {/* <FormControl>
              <InputLabel id="filter-select">Filtrer</InputLabel>
              <Select
                labelId="filter-select"
                id="filter-select"
                size="small"
                label="Filtrer"
                sx={{ minWidth: 250 }}
                onChange={({ target }) => setFilter(target.value)}
                displayEmpty
                renderValue={
                  filter !== ""
                    ? undefined
                    : () => (
                        <Typography color="#c2c2c2">
                          Sélectionner un élément
                        </Typography>
                      )
                }
                IconComponent={(props) => (
                  <KeyboardArrowDown
                    {...props}
                    style={{
                      fontSize: "30px",
                    }}
                  />
                )}
                value={filter}
              >
                <MenuItem value="list" style={{ background: "white" }}>
                  Liste des Types de services
                </MenuItem>
              </Select>
            </FormControl> */}

            <CustomButton
              title={"Ajouter"}
              titleStyle={{
                fontSize: "13px",
                textTransform: "none",
                fontWeight: "600",
              }}
              rootStyle={{
                backgroundColor: "#161B38",
                height: 42,
              }}
              onClick={toggleModal}
            />
          </Stack>
        </Stack>

        {serviceTypes.length ? (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    #
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    Nom
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "600" }}>
                    Description
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "600" }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceTypes
                  .filter((admin) =>
                    filter === "suspended"
                      ? admin.isSuspended
                      : filter === "deleted"
                      ? admin.isDeleted
                      : !admin.isSuspended && !admin.isDeleted
                  )
                  .map((admin, index) => (
                    <TableRow
                      key={admin.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="left">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        {`${admin.name}`}
                      </TableCell>
                      <TableCell align="center">{admin.description}</TableCell>

                      <TableCell align="center">
                        <Select
                          MenuProps={MenuProps}
                          variant="standard"
                          disableUnderline
                          sx={{ "& .MuiSelect-icon": { transform: "none" } }}
                          IconComponent={(props) => (
                            <MoreVert
                              fontSize="large"
                              {...props}
                              style={{ color: "#161B38" }}
                            />
                          )}
                        >
                          <MenuItem
                            style={{
                              background: "white",
                              color: "#161928",
                              fontSize: 14,
                              fontWeight: 700,
                            }}
                            onClick={() => toggleEdit(admin)}
                          >
                            Modifier
                          </MenuItem>
                          <Divider sx={{ mx: 2 }} />

                          {!admin.isDeleted && (
                            <MenuItem
                              style={{
                                background: "white",
                                color: "#CB1728",
                                fontSize: 14,
                                fontWeight: 700,
                              }}
                              onClick={() => openDelete(admin)}
                            >
                              Supprimer
                            </MenuItem>
                          )}
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      25,
                      50,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    labelRowsPerPage="Rangées par page"
                    count={serviceTypes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rangées par page",
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
            {!serviceTypes.filter((admin) =>
              filter === "suspended"
                ? admin.isSuspended
                : filter === "deleted"
                ? admin.isDeleted
                : !admin.isSuspended && !admin.isDeleted
            ).length ? (
              <Typography my={4} textAlign="center" color={"#9b9999"}>
                Aucun élément trouvé
              </Typography>
            ) : null}
          </TableContainer>
        ) : (
          <EmptyList />
        )}
      </Paper>

      <Modal
        open={open}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <FormModalTitle
              title={`${id ? "Modifier" : "Ajouter"} un type de service`}
              closeModal={toggleModal}
              isLoading={loading}
            />
            <Box p={4} pt={0} style={{ maxHeight: "85vh", overflow: "auto" }}>
              <Stack
                direction={{ xs: "column", md: "row", lg: "row" }}
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Nom"}
                  value={name}
                  id="name"
                  name="name"
                  onChange={(value) => setName(value)}
                  required
                  // errorMessage={errors.name}
                />
                <SigninTextInput
                  title={"Description (facultatif)"}
                  value={description}
                  id="description"
                  name="description"
                  onChange={(value) => setDescription(value)}
                  // errorMessage={errors.description}
                  // icon={<LockSVG />}
                />
              </Stack>
            </Box>
          </Box>
        </form>
      </Modal>
      <CustomConfirm
        visible={deleteModal}
        onClose={toggleDeleteModal}
        title={confirmModalTitle}
        onConfirm={deleteConfirm}
      />
    </Box>
  );
}

ServiceType.propTypes = {};

export default ServiceType;
