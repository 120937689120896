import React, { useEffect, useState } from "react";
import Fuse from "fuse.js";
import {
  Avatar,
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Add,
  CalendarMonth,
  FilterAlt,
  KeyboardArrowDown,
  Search,
  Sort,
} from "@mui/icons-material";
import { DateRangePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { fr } from "date-fns/locale";

import { EmptySVG, FileSVG, SearchSVG } from "../../utils/svgImages";
import CommandRow from "./components/CommandRow";
import { listCommands } from "../../redux/commands/commandActions";
import { COLORS } from "../../utils";
import { CustomButton } from "../../components";
import usePagination from "../../hooks/usePagination";
import { isBrowser, MobileView } from "react-device-detect";
import { saveCommandPDF } from "../../utils/pdfUtil";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CommandList() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState("desc");

  const agent = JSON.parse(localStorage.getItem("agent") || "{}");
  const commands = useSelector((store) =>
    Object.values(store.commands)
      .filter((val) => (state ? val?.siteIntervension?.id === state : val))
      .sort((a, b) =>
        orderBy === "desc"
          ? b.createdAt > a.createdAt
            ? 1
            : -1
          : a.createdAt < b.createdAt
          ? -1
          : 1
      )
  );

  const PER_PAGE = 10;

  const count = Math.ceil(commands.length / PER_PAGE);
  const _DATA = usePagination(commands, PER_PAGE);

  const [filter, setFilter] = useState("list");
  const [search, setSearch] = useState("");
  
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [searchResults, setSearchResults] = useState(_DATA.currentData());

  const options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["siteIntervension.name", "operationalCreator.firstname", "operationalCreator.lastname", "client.raisonSociale", "commandNumber"],
  };

  const fuse = new Fuse(commands, options);
console.log("@@@@",searchResults)
  const onTopBtnClick = (command) => {
    navigate("/home/command/" + command?.id);
  };

  const handleSearch = (event) => {
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setSearchResults(_DATA.currentData());
      return;
    }

    const results = fuse.search(value);
    const items = results.map((result) => result.item);
    setSearchResults(items);
  };
  
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const resetFilter = () => {
    setFilter("list");
    setSearch("");
    setOrderBy("desc");
    setStartDate(null);
    setEndDate(null);
    // setSelectDate({
    //   startDate: null,
    //   endDate: null,
    // });
  };

  const onPrint = async (command) => {
    const results = [];
    let commandLineTab = command?.comandLines;

    commandLineTab?.sort((a, b) => b.serviceDay.day - a.serviceDay.day);
    while (commandLineTab.length !== 0) {
      let dayOfWeek = commandLineTab[0].serviceDay.day;
      let group = commandLineTab.filter(
        (item) => item.serviceDay.day === dayOfWeek
      );
      results.push({
        title: dayOfWeek,
        data: group,
        date: group[0]?.date,
      });
      commandLineTab = commandLineTab.slice(group.length);
    }

    await saveCommandPDF(command, [...results]);
  };

  const displayInit = () => {
    if (
      filter !== "list" ||
      search !== "" ||
      orderBy !== "desc" ||
      startDate ||
      endDate
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onBottomBtnClick = async (command) => {
    if (agent?.authAccountType === "admin") {
      await onPrint(command);
    } else {
      navigate("/home/command-new", { state: { command } });
    }
  };

  return (
    <Box
      p={4}
      px={{ xs: 2, md: 4, lg: 4 }}
      style={{
        backgroundColor: "#f4f3f7",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: { xs: "block", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
        mt={4}
        mb={2}
      >
        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
          <Avatar sx={{ bgcolor: "#161B38", width: 48, height: 48 }}>
            <FileSVG />
          </Avatar>
          {!state ? (
            <Typography color="primary" fontSize={20} fontWeight={600}>
              {agent?.authAccountType === "admin" ? "Liste des" : "Mes"}{" "}
              commandes
            </Typography>
          ) : (
            <Typography color="primary" fontSize={20} fontWeight={600}>
              Commandes de: {commands[0]?.siteIntervension?.name}
            </Typography>
          )}
        </Stack>

        <MobileView>
          <Stack direction="row" justifyContent={"center"} mt={2} spacing={3}>
            <IconButton
              onClick={() => setShowCalendar(!showCalendar)}
              style={{
                backgroundColor: showCalendar ? COLORS.primary : "transparent",
              }}
            >
              <CalendarMonth
                htmlColor={showCalendar ? COLORS.white : COLORS.primary}
              />
            </IconButton>
            <IconButton
              onClick={() => setShowSearch(!showSearch)}
              style={{
                backgroundColor: showSearch ? COLORS.primary : "transparent",
              }}
            >
              <Search htmlColor={showSearch ? COLORS.white : COLORS.primary} />
            </IconButton>
            <IconButton
              onClick={() => setShowFilter(!showFilter)}
              style={{
                backgroundColor: showFilter ? COLORS.primary : "transparent",
              }}
            >
              <FilterAlt
                htmlColor={showFilter ? COLORS.white : COLORS.primary}
              />
            </IconButton>
            <IconButton
              onClick={() => setShowSort(!showSort)}
              style={{
                backgroundColor: showSort ? COLORS.primary : "transparent",
              }}
            >
              <Sort htmlColor={showSort ? COLORS.white : COLORS.primary} />
            </IconButton>
          </Stack>
        </MobileView>

        <Stack
          direction={{ xs: "column", md: "row", lg: "row" }}
          alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
          spacing={3}
        >
          {isBrowser || showFilter ? (
            <FormControl
              sx={{ mt: { xs: displayInit() ? 0 : 3, md: 0, lg: 0 } }}
            >
              <InputLabel id="filter-select">Filtrer</InputLabel>
              <Select
                labelId="filter-select"
                id="filter-select"
                size="small"
                label="Filtrer"
                style={{
                  width: isBrowser ? 250 : "100%",
                  backgroundColor: "white",
                }}
                onChange={({ target }) => setFilter(target.value)}
                displayEmpty
                renderValue={
                  filter !== ""
                    ? undefined
                    : () => (
                        <Typography color="#c2c2c2">
                          Sélectionner un élément
                        </Typography>
                      )
                }
                IconComponent={(props) => (
                  <KeyboardArrowDown
                    {...props}
                    style={{
                      fontSize: "30px",
                    }}
                  />
                )}
                value={filter}
              >
                <MenuItem value="list" style={{ background: "white" }}>
                  {["admin", "superadmin"].includes(agent?.authAccountType)
                    ? "Tous les bons de commande"
                    : "Tous les Commandes"}
                </MenuItem>

                <MenuItem value="NEW" style={{ background: "white" }}>
                  {["admin", "superadmin"].includes(agent?.authAccountType)
                    ? "Nouveaux bons de commande"
                    : "Nouvelle commandes"}
                </MenuItem>
                {!["admin", "superadmin"].includes(agent?.authAccountType) ? (
                  <MenuItem value="PROCESSING" style={{ background: "white" }}>
                    Commandes En Traitement
                  </MenuItem>
                ) : null}
                {/* <MenuItem value="PROCESSED" style={{ background: "white" }}>
                  {["admin", "superadmin"].includes(agent?.authAccountType)
                    ? "Bons de commande acceptés"
                    : "Commandes Accepté"}
                </MenuItem>
                <MenuItem value="REJECTED" style={{ background: "white" }}>
                  {["admin", "superadmin"].includes(agent?.authAccountType)
                    ? "Bons de commande Refusé"
                    : "Commandes Refusé"}
                </MenuItem> */}
                <MenuItem value="VALIDATED" style={{ background: "white" }}>
                  {["admin", "superadmin"].includes(agent?.authAccountType)
                    ? "Bons de commande Validés"
                    : "Commandes Validés"}
                </MenuItem>
                <MenuItem value="CANCELED" style={{ background: "white" }}>
                  {["admin", "superadmin"].includes(agent?.authAccountType)
                    ? "Bons de commande Annulés"
                    : "Commandes Annulés"}
                </MenuItem>
                {/* <MenuItem value="IN_EXCECUTION" style={{ background: "white" }}>
                Commandes en exécution
              </MenuItem>
              <MenuItem value="FINALYZED" style={{ background: "white" }}>
                Commandes finalisées
              </MenuItem> */}
              </Select>
            </FormControl>
          ) : null}

          {isBrowser || showSearch ? (
            <TextField
              id="input-with-icon-textfield"
              type="search"
              size="small"
              color="secondary"
              placeholder="Rechercher"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchSVG />
                  </InputAdornment>
                ),
                sx: { backgroundColor: "white" },
              }}
              variant="outlined"
              style={{
                borderRadius: "2%",
                width: isBrowser ? 250 : "100%",
              }}
              sx={{ mt: { xs: displayInit() ? 0 : 3, md: 0, lg: 0 } }}
              // value={search}
              onChange={handleSearch}
              // onChange={({ target }) => setSearch(target.value)}
            />
          ) : null}
        </Stack>
      </Box>

      <Stack
        direction={{ xs: "column", md: "row", lg: "row" }}
        spacing={2}
        mb={!displayInit() && !isBrowser ? 0 : 3}
      >
        {isBrowser || showCalendar ? (
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={(date) =>
              // setSelectDate({ ...selectDate, startDate: date })
              setStartDate(date)
            }
            onEndDateChange={(date) =>
              // setSelectDate({ ...selectDate, endDate: date })
              setEndDate(date)
            }
            minimumLength={1}
            format="dd MMM yyyy"
            locale={fr}
          >
            {({ startDateInputProps, endDateInputProps, focus }) =>
              isBrowser ? (
                <Stack
                  direction={"row"}
                  style={{
                    border: "1px solid #c2c2c2",
                    padding: 10,
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                  }}
                >
                  <CalendarMonth />
                  <TextField
                    id="start"
                    size="small"
                    variant="standard"
                    sx={{
                      "& .MuiInput-root": {
                        "&:before, :after, :hover:not(.Mui-disabled):before": {
                          borderBottom: 0,
                        },
                      },
                      ml: 1,
                      width: "100px",
                    }}
                    {...startDateInputProps}
                  />
                  <Typography>---</Typography>
                  <TextField
                    id="end"
                    size="small"
                    variant="standard"
                    sx={{
                      "& .MuiInput-root": {
                        "&:before, :after, :hover:not(.Mui-disabled):before": {
                          borderBottom: 0,
                        },
                      },
                      width: "100px",
                    }}
                    {...endDateInputProps}
                  />
                </Stack>
              ) : (
                <Stack
                  direction={"row"}
                  style={{
                    border: "1px solid #c2c2c2",
                    padding: 10,
                    borderRadius: "5px",
                    backgroundColor: "white",
                  }}
                >
                  <CalendarMonth />
                  <input
                    style={{ border: "none", width: "40%" }}
                    {...startDateInputProps}
                    placeholder="Start date"
                  />
                  <Typography>---</Typography>
                  <input
                    style={{ border: "none", width: "45%", textAlign: "right" }}
                    {...endDateInputProps}
                    placeholder="End date"
                  />
                </Stack>
              )
            }
          </DateRangePicker>
        ) : null}

        {isBrowser || showSort ? (
          <Select
            variant="standard"
            size="small"
            value={orderBy}
            onChange={({ target }) => setOrderBy(target.value)}
            input={<OutlinedInput />}
            style={{
              backgroundColor: "#fff",
            }}
            MenuProps={MenuProps}
          >
            <MenuItem value={"desc"}>Du plus récent au plus ancien</MenuItem>
            <MenuItem value={"asc"}>Du plus ancien au plus récent</MenuItem>
          </Select>
        ) : null}
        {displayInit() ? (
          <CustomButton title="Réinitialiser le filtre" onClick={resetFilter} />
        ) : null}
      </Stack>

      {searchResults
        .filter((val) =>
             startDate
            ? new Date(val.startDate) >= new Date(startDate) &&
              new Date(val.endDate) <= new Date(endDate)
            : filter !== "list"
            ? val.state === filter
            : val
        )
        .map((command, index) => (
          <CommandRow
            key={index}
            elevation={0}
            data={command}
            rootStyle={{ my: 2, mt: { xs: 3, md: 2, lg: 2 } }}
            bottomBtnTitle={
              agent?.authAccountType === "admin" ? "Imprimer" : "Cloner"
            }
            onTopBtnClick={() => onTopBtnClick(command)}
            onBottomBtnClick={() => onBottomBtnClick(command)}
          />
        ))}

      {!searchResults
        .filter((val) =>
            startDate
            ? new Date(val.startDate) >= new Date(startDate) &&
            new Date(val.endDate) <= new Date(endDate)
            : filter !== "list"
            ? val.state === filter
            : val
        ).length ? (
        <Typography textAlign="center" color={"#c2c2c2"} mt={10}>
          Aucun élément trouvé
        </Typography>
      ) : null}

      {!searchResults.length ? (
        <Stack alignItems={"center"} justifyContent="center" mt={10}>
          <Typography fontSize={20} fontWeight="600" color={COLORS.primary}>
            Vous n’avez pas encore de nouvelle commande
          </Typography>
          <EmptySVG />
        </Stack>
      ) : null}

      <Stack direction="row" justifyContent="center" alignItems="center" mt={1}>
        <Pagination
          count={count}
          size="medium"
          page={page}
          shape="rounded"
          color="primary"
          onChange={handleChange}
        />
      </Stack>
      <MobileView>
        {agent?.authAccountType === "client" ? (
          <CustomButton
            title={"Nouvelle commande"}
            onClick={() => navigate("/home/command-new")}
            titleStyle={{
              fontSize: "13px",
              textTransform: "none",
              fontWeight: "600",
              marginRight: 1,
            }}
            rootStyle={{
              backgroundColor: "#161B38",
              height: 42,
              marginLeft: 4,
              position: "fixed",
              right: 25,
              bottom: 25,
            }}
            rightComponent={<Add />}
          />
        ) : null}
      </MobileView>
    </Box>
  );
}

CommandList.propTypes = {};

export default CommandList;
