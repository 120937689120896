import React, { useState } from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import {
  CustomButton,
  CustomConfirm,
  CustomReasonReject,
} from "../../components";
import { COLORS } from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FileSVG } from "../../utils/svgImages";
import CommandDetailCard from "./components/CommandDetailCard";
import { agentApprobation } from "../../redux/commandLine/commandLineActions";
import { Print } from "@mui/icons-material";
import { saveCommandPDF } from "../../utils/pdfUtil";
import CommandDetailSite from "./components/CommandDetailSite";

function CommandBonDetail() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const agent = JSON.parse(localStorage.getItem("agent") || "{}");

  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [rejectDetailModal, setRejectDetailModal] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState("");
  const [modalSource, setModalSource] = useState("");
  // const commandStore = useSelector((store) => store.commandLines[state]);
  const commandStore = JSON.parse(state);
  const command = commandStore.responseLine?.CommandLine;
  const site = command?.command?.siteIntervension;

  const callback = () => {
    setLoading(false);
    navigate(-1);
  };

  const onError = () => {
    setLoading(false);
  };

  const onPrint = async () => {
    const toSave = {
      commandNumber: commandStore.numBonCommandAgent,
      createdAt: command.createdAt,
      state: command.command?.state,
      siteIntervension: command.command?.siteIntervension,
      // comandLines: [
      //   {
      //     serviceDays: command.serviceDays,
      //     AgentType: command.AgentType,
      //     numberOfAgent: command.numberOfAgent,
      //     serviceTypes: command.serviceTypes,
      //     startDate: command.startDate,
      //     endDate: command.endDate,
      //     comment: command.comment,
      //   },
      // ],
    };

    const results = [];
    let commandLineTab = commandStore?.comandLines;

    commandLineTab?.sort((a, b) => b.serviceDay.day - a.serviceDay.day);
    while (commandLineTab.length !== 0) {
      let dayOfWeek = commandLineTab[0].serviceDay.day;
      let group = commandLineTab.filter((item) => item.serviceDay.day === dayOfWeek);
      results.push({
        title: dayOfWeek,
        data: group,
        date: group[0]?.date,
      });
      commandLineTab = commandLineTab.slice(group.length);
    }

    await saveCommandPDF(commandStore,  [...results]);
    // await saveCommandPDF(toSave);
  };

  const onModalConfirm = () => {
    switch (modalSource) {
      case "ACCEPTED":
        onConfirm("ACCEPTED");
        break;
      case "REJECTED":
        onConfirm("REJECTED");
        break;

      default:
        break;
    }
    onCloseModal();
  };

  const onModalOpen = (source) => {
    setModalSource(source);
    switch (source) {
      case "ACCEPTED":
        setConfirmModalTitle("Etes-vous sûr de vouloir VALIDER ce bon ?");
        setConfirmModal(true);
        break;
      case "REJECTED":
        setConfirmModalTitle("Etes-vous sûr de vouloir REJETER ce bon ?");
        setRejectDetailModal(true);
        break;
      default:
        break;
    }
  };

  const onCloseModal = () => {
    setConfirmModal(false);
    setRejectDetailModal(false);
    setConfirmModalTitle("");
    setModalSource("");
  };

  const onConfirm = (status, rejectedReason) => {
    setLoading(true);
    dispatch(
      agentApprobation(
        {
          responseLineCommandToagentId:
            commandStore.responseLineCommandToagentId,
          agentId: agent?.id,
          status,
          rejectedReason,
        },
        callback,
        onError
      )
    );
  };

  return (
    <Box
      p={4}
      px={{ xs: 2, md: 4, lg: 4 }}
      style={{
        backgroundColor: "#f4f3f7",
        minHeight: "100vh",
      }}
    >
      <div>
        <Stack
          direction={{ xs: "column", md: "row", lg: "row" }}
          spacing={2}
          justifyContent={"space-between"}
          my={3}
        >
          <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            <Avatar sx={{ bgcolor: "#161B38", width: 48, height: 48 }}>
              <FileSVG />
            </Avatar>
            <Typography color="primary" fontSize={20} fontWeight={600}>
              Bon de commande N° {commandStore?.numBonCommandAgent}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <CustomButton
              title={"Imprimer"}
              leftComponent={<Print />}
              onClick={onPrint}
              titleStyle={{ marginLeft: 1 }}
              rootStyle={{ padding: 1.5 }}
            />
            {commandStore.statusApprobationAgent !== "SENT" ? (
              <CustomButton
                title={
                  commandStore.statusApprobationAgent === "ACCEPTED"
                    ? "Commande Accepté"
                    : "Commande Rejeté"
                }
                rootStyle={{
                  padding: 1.5,
                  backgroundColor:
                    commandStore.statusApprobationAgent === "ACCEPTED"
                      ? COLORS.green
                      : COLORS.red,
                }}
              />
            ) : null}
          </Stack>
        </Stack>

        <CommandDetailSite data={commandStore} />

        <CommandDetailCard command={command || {}} isBon />

        {loading && (
          <CustomButton
            isLoading={true}
            rootStyle={{
              padding: 1.5,
              width: "100%",
              mt: 2,
              backgroundColor: "#c2c2c280",
            }}
          />
        )}
        {!loading && (
          <Stack
            direction={{ xs: "column", md: "row", lg: "row" }}
            alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
            justifyContent={"space-between"}
            spacing={2}
            mt={2}
          >
            <CustomButton
              title="Sortir"
              onClick={() => navigate(-1)}
              titleStyle={{ color: COLORS.primary }}
              rootStyle={{
                padding: 1.5,
                backgroundColor: "transparent",
                border: `1px solid ${COLORS.primary}`,
                width: 150,
              }}
            />
            {commandStore.statusApprobationAgent === "SENT" ? (
              <Stack direction="row" alignItems="center" spacing={2}>
                <CustomButton
                  title="Accepter"
                  onClick={() => onModalOpen("ACCEPTED")}
                  rootStyle={{
                    padding: 1.5,
                    backgroundColor: COLORS.green,
                    width: 150,
                  }}
                />
                <CustomButton
                  title="Refuser"
                  onClick={() => onModalOpen("REJECTED")}
                  rootStyle={{
                    padding: 1.5,
                    width: 150,
                    backgroundColor: COLORS.red,
                  }}
                />
              </Stack>
            ) : null}
          </Stack>
        )}
      </div>
      <CustomConfirm
        showIcon={false}
        visible={confirmModal}
        title={confirmModalTitle}
        onConfirm={onModalConfirm}
        onClose={onCloseModal}
      />
      {rejectDetailModal && <CustomReasonReject
        visible={rejectDetailModal}
        title={confirmModalTitle}
        onConfirm={(reason) => onConfirm("REJECTED", reason)}
        onClose={onCloseModal}
      />}
    </Box>
  );
}

CommandBonDetail.propTypes = {};

export default CommandBonDetail;
