import axios from "axios";
import { SERVER } from "../../utils/server";
import operationnelTypes from "./operationnelTypes";
import notificationTypes from "../notifications/notificationTypes";
import { CODES } from "../../utils/errorCodes";
import { getErrorMessage } from "../../utils/errorCodeMessage";

export const createOperationnel =
  ({operationnel, callback, onError}) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}clientSubAccount`, operationnel);
      dispatch({
        type: operationnelTypes.CREATE_OPERATIONNEL,
        payload: results.data.data,
      });
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: "Enregistré avec succès" },
      });
      callback?.();
    } catch (error) {
      if (error.response) {
        // console.log(error.response);
        if (error.response.status === 400) {
          onError?.();
          dispatch({
            type: notificationTypes.SHOW_ERROR,
            payload: {
              message: getErrorMessage(error.response.data?.message),
            },
          });
        }
        return;
      }
      onError?.();
        dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Operationnel creation error", error);
    }
  };

export const listOperationnels = () => async (dispatch) => {
  try {
    const results = await axios.get(`${SERVER}clientSubAccounts`);
    dispatch({ type: operationnelTypes.LIST_OPERATIONNELS, payload: results.data.data });
  } catch (error) {
    console.error("Operationnel list error", error);
  }
};

export const deleteOperationnel = (id) => async (dispatch) => {
   await axios.delete(`${SERVER}clientSubAccount/${id}`);

  dispatch({ type: operationnelTypes.REMOVE_OPERATIONNEL, payload: id });
};

export const updateOperationnel = ({operationnel, callback, toastMessage="Modifié avec succès",onError,isProfile}) => async (dispatch) => {
  try {
    const results = await axios.put(`${SERVER}clientSubAccount`, operationnel);
    dispatch({
      type: operationnelTypes.UPDATE_OPERATIONNEL,
      payload: results.data.data,
    });
    dispatch({
      type: notificationTypes.SHOW_SUCCESS,
      payload: {
        message: toastMessage,
      },
    });
    if(isProfile){
      localStorage.setItem("agent",JSON.stringify(results.data.data))
    }
    callback?.();
  } catch (error) {
    console.log("update operationnel error", error);
    onError?.();
    dispatch({
      type: notificationTypes.SHOW_ERROR,
      payload: { message: "Une erreur est survenue" },
    });
  }
};

export const resetOperationnelPassword = (operationnel, callback,onError) => async (dispatch, getState) => {
  try {
    const results = await axios.post(`${SERVER}clientSubAccount/resetPassword`, operationnel);
    if (results.data.isSuccess) {
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: "Modifié avec succès" },
      });
      callback?.();
    } else {
      const { errorCode } = results.data;
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: CODES[errorCode] },
      });
      onError?.();
    }
  } catch (error) {
    dispatch({
      type: notificationTypes.SHOW_ERROR,
      payload: { message: "Une erreur est survenue" },
    });
    console.error("Admin creation error", error);
  }
};