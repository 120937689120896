import commandLineTypes from "./commandLineTypes";


const initialState = {
  data: []
};

const commandLineReducer = (state = initialState, action) => {
  let nextState;

  switch (action.type) {

      case commandLineTypes.LIST_BON_COMMAND:
          nextState = {
              ...state,
              data: action.payload,
              loading: false,
          };
          return nextState || state;

      default:
          return state;
  }
};

export default commandLineReducer;
