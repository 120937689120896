import sitesTypes from "./sitesTypes";

const siteReducer = (state = {}, action) => {
  const payload = action.payload;
  if (action.type === sitesTypes.CREATE_SITE) {
    return { [payload.id]: { ...payload }, ...state };
  }
  if (action.type === sitesTypes.REMOVE_SITE) {
    const localState = { ...state };
    delete localState[payload];
    return { ...localState };
  }
  if (action.type === sitesTypes.UPDATE_SITE) {
    return { ...state, [payload.id]: { ...payload } };
  }
  if (action.type === sitesTypes.LIST_SITES) {
    let tempData = {};
    for (let obj of payload) {
      tempData[obj.id] = { ...obj };
    }
    return { ...tempData };
  }
  return state;
};

export default siteReducer;
