export const CODES = {
  39001: "Identifiants de connexion incorrects",
  39002: "UNE ERREUR EST SURVENUE",
  39003: "MAUVAISE REQUÊTE CONTACTER L'ADMIN",
  39004: "DEMANDE ACCEPTÉE",
  39005: "COMPTE NON CONFIRMÉ",
  39006: "L'UTILISATEUR N'EXISTE PAS",
  39007: "L'UTILISATEUR EXISTE DÉJÀ",
  39008: "L'AGENT N'EXISTE PAS",
  39009: " RESPONSE_LINE_COMMAND_LINE_tTO_AGENT_NOT_EXIST",
  39010: "LE CLIENT SPÉCIFIÉ N'EST PAS PROPRIÉTAIRE DES COMMANDES",
  39011: "L'AGENT SPÉCIFIÉ N'EST PAS LE PROPRIÉTAIRE DES COMMANDES.",
  39012: "COMMANDE DÉJÀ VALIDÉE OU ANNULÉE",
  39013: "AGENT NON ASSOCIÉ À LA LIGNE DE RÉPONSE SPÉCIFIÉE",
  39014: "LA LIGNE DE COMMANDE SPÉCIFIÉE N'EST PAS LIÉE À LA LIGNE DE RÉPONSE",
  39015: "L'IDENTIFIANT DU CLIENT SPÉCIFIÉ N'EST PAS LIÉ À LA LIGNE DE RÉPONSE",
  39016: "VALIDATED_PROCEEDED_AGENT_BEFORE",
  39017: "LA COMMANDE SPÉCIFIÉE N'EXISTE PAS.",
  39018: "CONFIRMER TOUTES LES LIGNES DE RÉPONSE AVANT",
  39019:
    "LE NOMBRE D'AGENTS SÉLECTIONNÉS NE CORRESPOND PAS AU NOMBRE D'AGENTS REQUIS.",
  39020:
    "ASSURER-VOUS QUE TOUS LES LIGNES DE COMMANDE ONT UNE RÉPONSE ET QUE TOUTES LES RÉPONSES ONT ÉTÉ CONFIRMÉES.",
};
