import { Button, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../utils";

export default function CustomButton({
  leftComponent,
  title,
  rightComponent,
  rootStyle,
  titleStyle,
  onClick,
  type = "button",
  isLoading,
  disabled
}) {
  return (
    <Button
      variant="raised"
      type={type}
      sx={{
        backgroundColor: disabled ? "#c2c2c250" : COLORS.primary,
        color: COLORS.white,
        paddingLeft: 2,
        paddingRight: 2,
        alignItems: "center",
        ":hover": {
          // opacity: 0.9
          bgcolor: "#c2c2c299",
          color: '#000'
          // bgcolor: rootStyle?.backgroundColor + "99" || COLORS.primary + "99",
        },
        ...rootStyle,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {leftComponent}
      {title && !isLoading ? (
        <Typography textTransform={"none"} sx={{ ...titleStyle }}>
          {title}
        </Typography>
      ) : null}
      {rightComponent}
      {isLoading ? <CircularProgress size={20} /> : null}
    </Button>
  );
}
