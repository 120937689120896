export function getErrorMessage(error) {
    switch (error) {
        case "incorrect credentials":
            return "Email ou Mot de passe incorrect";
        case "clientId":
            return "Vueillez ajouter un CLIENT";
        case "operationelAffectes":
            return "Vueillez ajouter un Opérationnels";
        case "Email already used by another account, please change email":
            return "Email déjà utilisé par un autre compte, veuillez changer d'email";
    
        default:
            return "Une erreur est survenue"
    }
}