import React, { useState } from "react";
import { Box } from "@mui/material";
import { CustomButton } from "../../components";
import SigninTextInput from "../auth/components/SigninTextInput";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { passwordYup } from "../../utils/yupValidation";
import { resetAdminPassword } from "../../redux/admins/adminActions";
import notificationTypes from "../../redux/notifications/notificationTypes";
import { resetClientOperatorPassword, resetClientPassword } from "../../redux/clients/clientActions";
import { resetAgentPassword } from "../../redux/agents/agentActions";
import { Lock } from "@mui/icons-material";
import CustomLoader from "../../components/CustomLoader";

function Password() {
  const [loading, setLoading] = useState(false);
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");

  const onError = () => {
    setLoading(false);
  };

  const dispatch = useDispatch();
  const callback = () => {
    resetForm();
    setLoading(false);
  };

  const { values, handleSubmit, setValues, errors, setFieldValue, resetForm } =
    useFormik({
      initialValues: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      validationSchema: Yup.object().shape(passwordYup),
      onSubmit(values) {
        if (values.newPassword !== values.confirmPassword) {
          dispatch({
            type: notificationTypes.SHOW_ERROR,
            payload: { message: "Les mot de passe ne correspondent pas" },
          });
        } else {
          setLoading(true);
          let admin = {
            id: agent?.id,
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          };
          switch (agent?.authAccountType) {
            case "admin":
              dispatch(resetAdminPassword(admin, callback, onError));
              break;
            case "superadmin":
              dispatch(resetAdminPassword(admin, callback, onError));
              break;
            case "client":
              dispatch(resetClientPassword(admin, callback, onError));
              break;
            case "sousCompteClient":
              dispatch(resetClientOperatorPassword(admin, callback, onError));
              break;
            case "agent":
              dispatch(resetAgentPassword(admin, callback, onError));
              break;

            default:
              break;
          }
        }
      },
    });

  return (
    <Box
      style={{
        height: "70vh",
      }}
    >
      <form onSubmit={handleSubmit}>
        <Box
          p={4}
          mt={5}
          style={{ overflow: "auto" }}
          sx={{
            width: { xs: "100%", md: 700, lg: 700 },
          }}
        >
          <SigninTextInput
            title={"Mot de passe Actuel"}
            icon={<Lock />}
            value={values.oldPassword}
            id="oldPassword"
            name="oldPassword"
            type="password"
            onChange={(value) => setFieldValue("oldPassword", value)}
            errorMessage={errors.oldPassword}
          />

          <SigninTextInput
            title={"Nouveau Mot de passe"}
            icon={<Lock />}
            value={values.newPassword}
            id="newPassword"
            name="newPassword"
            type="password"
            onChange={(value) => setFieldValue("newPassword", value)}
            errorMessage={errors.newPassword}
          />

          <SigninTextInput
            title={"Confirmer Nouveau Mot de passe"}
            icon={<Lock />}
            value={values.confirmPassword}
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            onChange={(value) => setFieldValue("confirmPassword", value)}
            errorMessage={errors.confirmPassword}
          />

          <CustomButton
            title={"Changer le mot de passe"}
            titleStyle={{
              fontSize: "13px",
              textTransform: "none",
              fontWeight: "600",
            }}
            rootStyle={{
              height: 52,
              idth: { xs: "100%", md: "30%", lg: "30%" },
              my: 4,
            }}
            type="submit"
          />
          <CustomLoader open={loading} />
          <div style={{ width: "100%" }} />
        </Box>
      </form>
    </Box>
  );
}

export default Password;
