import axios from "axios";
import { SERVER } from "../../utils/server";
import serviceTypes from "./serviceTypes";
import notificationTypes from "../notifications/notificationTypes";

export const createServiceType =
  (agent, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}serviceType`, agent);
      if (results.data.isSuccess) {
        dispatch({
          type: serviceTypes.CREATE_SERVICE_TYPE,
          payload: results.data.data,
        });
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Enregistré avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: { message: results.data.data.message },
        });
        onError?.();
      }
    } catch (error) {
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Service Type creation error", error);
    }
  };

export const listServiceTypes = () => async (dispatch) => {
  try {
    const results = await axios.get(`${SERVER}serviceTypes`);
    dispatch({ type: serviceTypes.LIST_SERVICE_TYPES, payload: results.data.data });
  } catch (error) {
    console.error("Service Type list error", error);
  }
};

export const deleteServiceType = (id) => async (dispatch) => {
  await axios.delete(`${SERVER}serviceType/${id}`);

  dispatch({ type: serviceTypes.REMOVE_SERVICE_TYPE, payload: id });
};

export const updateAgent =
  (agent, callback, toastMessage = "Modifié avec succès", onError) =>
  async (dispatch) => {
    try {
      const results = await axios.put(`${SERVER}serviceType`, agent);
      dispatch({
        type: serviceTypes.UPDATE_SERVICE_TYPE,
        payload: results.data.data,
      });
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: toastMessage },
      });

      callback?.();
    } catch (error) {
      console.log("update service type error", error);
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
    }
  };
