import React from "react";
import {
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { COLORS } from "../../../utils";
import { UserSVG } from "../../../utils/svgImages";
import { KeyboardArrowDown } from "@mui/icons-material";

const SigninDropdown = ({
  title,
  type = "text",
  onChange,
  errorMessage,
  showError,
  disabled,
  icon = <UserSVG />,
  value,
  data,
}) => {
  return (
    <div
      style={{
        marginTop: 15,
        width: "100%",
        position: "relative",
        height: 100,
      }}
    >
      <Typography
        color="black"
        sx={{
          fontSize: {
            xs: 12,
            md: 14,
          },
          fontWeight: 600,
          mb: 1,
        }}
      >
        {title}
      </Typography>
      {/* <TextField
        placeholder={title}
        type={type}
        // size="small"
        color="secondary"
        className="inputField"
        variant="outlined"
        style={{
          width: "100%",
          backgroundColor: "#fff",
        }}
        InputProps={{
          style: { borderRadius: 4 },
          sx: { fontSize: { xs: 12, md: 14 } },
          startAdornment: (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
        }}
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        // onBlur={onEndEditing}
      /> */}
      <Select
        labelId="filter-select"
        id="filter-select"
        startAdornment={<InputAdornment position="start">{icon}</InputAdornment>}
        sx={{ width: "100%" }}
        defaultValue={value}
        disabled={disabled}
        onChange={({ target }) => onChange(target.value)}
        // displayEmpty
        // renderValue={
        //   value !== ""
        //     ? undefined
        //     : () => (
        //         <Typography color="#c2c2c2">{title}</Typography>
        //       )
        // }
        IconComponent={(props) => (
          <KeyboardArrowDown
            {...props}
            style={{
              fontSize: "30px",
            }}
          />
        )}
        value={value}
      >
       {data.map(val=> <MenuItem
          value={val}
          key={val}
          style={{ background: "white" }}
        >
          {val}
        </MenuItem>)}
      </Select>
      {errorMessage !== "" ? (
        <small style={{ color: COLORS.red, fontSize: 10 }}>
          {errorMessage}
        </small>
      ) : null}
    </div>
  );
};

export default SigninDropdown;
