import authTypes from "./authTypes";

const initialState = {
  open: false,
  message: "",
  type: "success",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.SIGNIN:
      return {
        open: true,
        message: action.payload.message,
      };
    case authTypes.SIGNOUT:
      return {
        open: false,
        message: "",
        type: "success",
      };

    default:
      return state;
  }
};

export default authReducer;
