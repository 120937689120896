import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { COLORS } from "../utils";

export default function CustomLoader({
  open,
  handleClose,
  title,
  subTitle,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Stack
        style={{
          width: 400,
          minHeight: 150,
          alignItems: "center",
          justifyContent: "center",
        }}
        p={3}
      >
        <CircularProgress />
        {title ? <Typography color={COLORS.primary} mt={3} textAlign="center">
          {title}
        </Typography> : null}
        {subTitle ? (
          <Typography color={COLORS.primary} mt={3} textAlign="center">
            {subTitle}
          </Typography>
        ) : null}
      </Stack>
    </Dialog>
  );
}
