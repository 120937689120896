import React from "react";
import { Stack } from "@mui/material";
import { CustomButton } from "../../../components";

function CommandStepperCard({ title, onClick, active }) {
  return (
    <Stack sx={{ width: "222px", alignItems: "center" }}>
      <img
        src="/new_command.png"
        alt="command"
        style={{
          width: "214px",
          height: "142px",
          border: "5px solid #64677A",
          borderRadius: 5,
        }}
      />

      <CustomButton
        title={title}
        titleStyle={{
          fontSize: "13px",
          textTransform: "none",
          fontWeight: "600",
          color: active? "#fff": "#57585A"
        }}
        rootStyle={{
          backgroundColor: active ? "#161B38" : "#B9BABC",
          height: 42,
          width: "100%",
          marginTop: 3,
        }}
        onClick={onClick}
      />
    </Stack>
  );
}

CommandStepperCard.propTypes = {};

export default CommandStepperCard;
