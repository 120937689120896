import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Fingerprint,
  KeyboardArrowDown,
  LocationOn,
  Mail,
  MoreVert,
  Phone,
  Public,
  QrCode,
} from "@mui/icons-material";
import {
  CustomButton,
  CustomConfirm,
  TablePaginationActions,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { updateAgent } from "../../redux/agents/agentActions";
import EmptyList from "../../components/EmptyList";
import { useFormik } from "formik";
import FormModalTitle from "../../components/FormModalTitle";
import SigninTextInput from "../auth/components/SigninTextInput";
import SigninDropdown from "../auth/components/SigninDropdown";
import { COUNTRIES } from "../../utils/countries";
import AgentDetailModal from "./components/AgentDetailModal";
import SigninPhoneInput from "../auth/components/SigninPhoneInput";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../utils";
import { isBrowser, isMobile } from "react-device-detect";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";

const MenuProps = {
  PaperProps: {
    style: {
      width: 150,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, md: 700, lg: 700 },
  bgcolor: "background.paper",
  borderRadius: 2,
};

function Agents() {
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");
  const agents = useSelector((state) =>
    Object.values(state.agents).filter((val) => !val.isDeleted)
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [confirmModalTitle, setConfirmModalTitle] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [filter, setFilter] = useState("list");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [address, setAddress] = useState(null);

  const navigate = useNavigate();

  const toggleModal = () => setOpen(!open);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onDelete = () => {
    let agent = { ...selectedItem, categoriesId: [], isDeleted: true };
    delete agent.email;
    dispatch(
      updateAgent(agent, setDeleteModal(!deleteModal), "Supprimé avec succès")
    );
  };

  const onSuspend = () => {
    let agent = {
      ...selectedItem,
      categoriesId: [],
      isSuspended: !selectedItem.isSuspended,
    };
    delete agent.email;
    dispatch(
      updateAgent(agent, setDeleteModal(!deleteModal), `Modifié avec succès`)
    );
  };

  const onValidate = (admin) => {
    let agent = {
      ...admin,
      categoriesId: [],
      isActive: true,
      isVisible: true,
    };
    delete agent.email;
    dispatch(updateAgent(agent, setDeleteModal(false), "Confirmé avec succès"));
  };

  const toggleEdit = (item) => {
    setValues(item);
    const { adressCompleted } = item;
    setFieldValue("complementAddress", adressCompleted.complementAddress);
    setFieldValue("address", adressCompleted.address);
    setFieldValue("postalCode", adressCompleted.postalCode);
    setFieldValue("city", adressCompleted.city);
    setFieldValue("country", adressCompleted.country);
    setOpen(true);
  };

  const callback = () => {
    setOpen(false);
    setLoading(false);
    resetForm();
  };

  const openSuspend = (item) => {
    setSelectedItem(item);
    setConfirmModalTitle(
      `Voulez-vous vraiment ${
        item.isSuspended ? "retablir" : "suspendre"
      } cet Agent?`
    );
    setDeleteModal(true);
    setIsDelete(false);
  };

  const openDelete = (item) => {
    setSelectedItem(item);
    setConfirmModalTitle("Voulez-vous vraiment supprimer cet Agent?");
    setDeleteModal(true);
    setIsDelete(true);
  };

  const openConfirm = (item) => {
    setSelectedItem(item);
    setConfirmModalTitle("Voulez-vous vraiment confirmer cet Agent?");
    // setDeleteModal(true);
    // setIsDelete(true);
  };

  const toggleDetail = (item) => {
    navigate("/home/agent-detail", { state: JSON.stringify(item) });
    // setSelectedItem(item);
    // setDetailModal(true);
  };

  const deleteConfirm = () => {
    if (isDelete) {
      onDelete();
    } else {
      onSuspend(true);
    }
  };

  const closeDetailModal = () => {
    setSelectedItem({});
    setDetailModal(false);
  };

  const { values, handleSubmit, setValues, errors, setFieldValue, resetForm } =
    useFormik({
      initialValues: {
        firstname: "",
        lastname: "",
        id: "",
        phoneNumber: "",
        complementAddress: "",
        email: "",
        password: "",
        address: "",
        postalCode: "",
        city: "",
        country: "France",
        nationalId: "",
        // registrationNumber: "",
      },
      // validationSchema: Yup.object().shape(adminYup),
      onSubmit(values) {
        setLoading(true);
        let agent = { ...values, categoriesId: [] };
        delete agent.email;
        dispatch(updateAgent(agent, callback));
      },
    });

    const onAddressChange=(val)=>{
      setAddress(val);
      setFieldValue("address", val.label);
      setFieldValue("complementAddress", val.value.reference);
      geocodeByPlaceId(val.value.reference)
      .then(results => setFieldValue("city", results[0]?.address_components.filter(val=> val.types.includes("locality"))?.[0]?.long_name))
      .catch(error => console.error(error));
    }

  return (
    <Box
      p={4}
      style={{
        backgroundColor: "#f4f3f7",
        minHeight: "100vh",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          borderRadius: 3,
          // height: "600px",
          backgroundColor: "#fff",
          padding: 2,
          marginTop: 3,
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row", lg: "row" }}
          alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
          justifyContent="space-between"
          spacing={2}
          sx={{
            mb: 1,
          }}
        >
          <Typography fontWeight="600" color="#161B38" fontSize={28}>
            Liste des agents
          </Typography>
          {["superadmin", "admin"].includes(agent?.authAccountType) ? (
            <FormControl>
              <InputLabel id="filter-select">Filtrer</InputLabel>
              <Select
                labelId="filter-select"
                id="filter-select"
                size="small"
                label="Filtrer"
                sx={{ minWidth: 250 }}
                onChange={({ target }) => setFilter(target.value)}
                displayEmpty
                renderValue={
                  filter !== ""
                    ? undefined
                    : () => (
                        <Typography color="#c2c2c2">
                          Sélectionner un élément
                        </Typography>
                      )
                }
                IconComponent={(props) => (
                  <KeyboardArrowDown
                    {...props}
                    style={{
                      fontSize: "30px",
                    }}
                  />
                )}
                value={filter}
              >
                <MenuItem value="list" style={{ background: "white" }}>
                  Liste des Agents
                </MenuItem>
                <MenuItem value="suspended" style={{ background: "white" }}>
                  Agents Suspendu
                </MenuItem>
                {/* <MenuItem value="deleted" style={{ background: "white" }}>
                  Agents Supprimé
                </MenuItem> */}
              </Select>
            </FormControl>
          ) : null}
        </Stack>

        {agents.length ? (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {isMobile ? (
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "600" }}
                    ></TableCell>
                  ) : null}
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    #
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    Nom
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "600" }}>
                    Ville
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "600" }}>
                    Téléphone
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "600" }}>
                    Email
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  {isBrowser ? (
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "600" }}
                    ></TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {agents
                  .filter((admin) =>
                    filter === "suspended"
                      ? admin.isSuspended
                      : filter === "deleted"
                      ? admin.isDeleted
                      : !admin.isSuspended && !admin.isDeleted
                  )
                  .map((admin, index) => (
                    <TableRow
                      key={admin.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {isMobile ? (
                        <TableCell align="center">
                          <Select
                            MenuProps={MenuProps}
                            variant="standard"
                            disableUnderline
                            sx={{ "& .MuiSelect-icon": { transform: "none" } }}
                            IconComponent={(props) => (
                              <MoreVert
                                fontSize="large"
                                {...props}
                                style={{ color: "#161B38" }}
                              />
                            )}
                          >
                            <MenuItem
                              style={{
                                background: "white",
                                color: "#161928",
                                fontSize: 14,
                                fontWeight: 700,
                              }}
                              onClick={() => toggleDetail(admin)}
                            >
                              Détails
                            </MenuItem>
                            <Divider sx={{ mx: 2 }} />
                            <MenuItem
                              style={{
                                background: "white",
                                color: "#161928",
                                fontSize: 14,
                                fontWeight: 700,
                              }}
                              onClick={() => toggleEdit(admin)}
                            >
                              Modifier
                            </MenuItem>
                            <Divider sx={{ mx: 2 }} />
                              <MenuItem
                                style={{
                                  background: "white",
                                  color: !admin.isSuspended
                                    ? COLORS.red
                                    : COLORS.green,
                                  fontSize: 14,
                                  fontWeight: 700,
                                }}
                                onClick={() => openSuspend(admin)}
                              >
                                {!admin.isSuspended ? "Suspendre" : "Retablir"}
                              </MenuItem>
                              <Divider sx={{ mx: 2 }} />
                            {filter === "suspended" &&
                            ["superadmin"].includes(agent?.authAccountType) ? (
                                <MenuItem
                                  style={{
                                    background: "white",
                                    color: COLORS.red,
                                    fontSize: 14,
                                    fontWeight: 700,
                                  }}
                                  onClick={() => openDelete(admin)}
                                >
                                  {"Supprimer"}
                                </MenuItem>
                            ) : null}
                          </Select>
                        </TableCell>
                      ) : null}
                      <TableCell component="th" scope="row" align="left">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        {`${admin.firstname} ${admin.lastname}`}
                      </TableCell>
                      <TableCell align="center">
                        {admin.adressCompleted?.city}
                      </TableCell>
                      <TableCell align="center">{admin.phoneNumber}</TableCell>
                      <TableCell align="center">{admin.email}</TableCell>
                      <TableCell align="center">
                        {!admin.isActive ? (
                          <CustomButton
                            title={"Confirmer"}
                            titleStyle={{
                              fontSize: "13px",
                              textTransform: "none",
                              fontWeight: "600",
                            }}
                            rootStyle={{
                              backgroundColor: "#127D07",
                              height: 38,
                            }}
                            onClick={() => onValidate(admin)}
                          />
                        ) : null}
                      </TableCell>
                      {isBrowser ? (
                        <TableCell align="center">
                          <Select
                            MenuProps={MenuProps}
                            variant="standard"
                            disableUnderline
                            sx={{ "& .MuiSelect-icon": { transform: "none" } }}
                            IconComponent={(props) => (
                              <MoreVert
                                fontSize="large"
                                {...props}
                                style={{ color: "#161B38" }}
                              />
                            )}
                          >
                            <MenuItem
                              style={{
                                background: "white",
                                color: "#161928",
                                fontSize: 14,
                                fontWeight: 700,
                              }}
                              onClick={() => toggleDetail(admin)}
                            >
                              Détails
                            </MenuItem>
                            <Divider sx={{ mx: 2 }} />
                            <MenuItem
                              style={{
                                background: "white",
                                color: "#161928",
                                fontSize: 14,
                                fontWeight: 700,
                              }}
                              onClick={() => toggleEdit(admin)}
                            >
                              Modifier
                            </MenuItem>
                            <Divider sx={{ mx: 2 }} />
                              <MenuItem
                                style={{
                                  background: "white",
                                  color: !admin.isSuspended
                                    ? COLORS.red
                                    : COLORS.green,
                                  fontSize: 14,
                                  fontWeight: 700,
                                }}
                                onClick={() => openSuspend(admin)}
                              >
                                {!admin.isSuspended ? "Suspendre" : "Retablir"}
                              </MenuItem>
                              <Divider sx={{ mx: 2 }} />
                            {filter === "suspended" &&
                            ["superadmin"].includes(agent?.authAccountType) ? (
                                <MenuItem
                                  style={{
                                    background: "white",
                                    color: COLORS.red,
                                    fontSize: 14,
                                    fontWeight: 700,
                                  }}
                                  onClick={() => openDelete(admin)}
                                >
                                  {"Supprimer"}
                                </MenuItem>
                            ) : null}
                          </Select>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      25,
                      50,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    labelRowsPerPage="Rangées par page"
                    count={agents.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rangées par page",
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
            {!agents.filter((admin) =>
              filter === "suspended"
                ? admin.isSuspended
                : filter === "deleted"
                ? admin.isDeleted
                : !admin.isSuspended && !admin.isDeleted
            ).length ? (
              <Typography my={4} textAlign="center" color={"#9b9999"}>
                Aucun élément trouvé
              </Typography>
            ) : null}
          </TableContainer>
        ) : (
          <EmptyList />
        )}
      </Paper>
      <Modal
        open={open}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <FormModalTitle
              title={`${values.id ? "Modifier" : "Ajouter"} un agent`}
              closeModal={toggleModal}
              isLoading={loading}
            />
            <Box p={4} pt={0} style={{ maxHeight: "85vh", overflow: "auto" }}>
              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Nom"}
                  value={values.lastname}
                  id="lastname"
                  name="lastname"
                  onChange={(value) => setFieldValue("lastname", value)}
                  errorMessage={errors.lastname}
                />
                <SigninTextInput
                  title={"Prénom"}
                  value={values.firstname}
                  id="firstname"
                  name="firstname"
                  onChange={(value) => setFieldValue("firstname", value)}
                  // icon={<LockSVG />}
                  errorMessage={errors.firstname}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninPhoneInput
                  title={"Téléphone"}
                  value={values.phoneNumber}
                  id="phoneNumber"
                  name="phoneNumber"
                  onChange={(value) => setFieldValue("phoneNumber", value)}
                  icon={<Phone />}
                  errorMessage={errors.phoneNumber}
                />
                <SigninTextInput
                  title={"Email"}
                  value={values.email}
                  disabled
                  id="email"
                  name="email"
                  type="email"
                  onChange={(value) => setFieldValue("email", value)}
                  icon={<Mail />}
                  errorMessage={errors.email}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Nº Pièce d’identité"}
                  value={values.nationalId}
                  id="nationalId"
                  name="nationalId"
                  onChange={(value) => setFieldValue("nationalId", value)}
                  icon={<Fingerprint />}
                  errorMessage={errors.nationalId}
                />
                {/* <SigninTextInput
                  title={"Adresse"}
                  value={values.address}
                  id="address"
                  name="address"
                  icon={<LocationOn />}
                  onChange={(value) => setFieldValue("address", value)}
                  errorMessage={errors.address}
                /> */}
                 <Box width="100%">
              <Typography
                color="black"
                sx={{
                  fontSize: {
                    xs: 12,
                    md: 14,
                  },
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                Adresse *
              </Typography>
              <GooglePlacesAutocomplete
                selectProps={{
                  value: address,
                  onChange: onAddressChange,
                  placeholder: "Adresse",
                  styles: {
                    container: (provided) => ({
                      ...provided,
                      width: "100%",
                      height: "45px",
                      marginBottom: "15px",
                    }),
                    input: (provided) => ({
                      ...provided,
                      width: "100%",
                      height: "45px",
                    }),
                    option: (provided) => ({
                      ...provided,
                    }),
                  },
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                  country: ['fr'],
                  }
                }}
              />
            </Box>
              </Stack>
              {/* <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Complement Adresse"}
                  value={values.complementAddress}
                  id="complementAddress"
                  name="complementAddress"
                  icon={<LocationOn />}
                  onChange={(value) =>
                    setFieldValue("complementAddress", value)
                  }
                  errorMessage={errors.complementAddress}
                />
                <SigninTextInput
                  title={"Code Postal"}
                  value={values.postalCode}
                  id="postalCode"
                  name="postalCode"
                  onChange={(value) => setFieldValue("postalCode", value)}
                  icon={<QrCode />}
                  errorMessage={errors.postalCode}
                />
              </Stack> */}
              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninDropdown
                  title={"Pays"}
                  icon={<Public />}
                  value={values.country}
                  id="country"
                  name="country"
                  data={COUNTRIES}
                  disabled
                  onChange={(value) => setFieldValue("country", value)}
                  errorMessage={errors.country}
                />
                <SigninTextInput
                  title={"Ville"}
                  icon={<Public />}
                  value={values.city}
                  id="city"
                  name="city"
                  onChange={(value) => setFieldValue("city", value)}
                  errorMessage={errors.city}
                />
              </Stack>
            </Box>
          </Box>
        </form>
      </Modal>
      <CustomConfirm
        visible={deleteModal}
        title={confirmModalTitle}
        onConfirm={deleteConfirm}
        onClose={toggleDeleteModal}
      />
      <AgentDetailModal
        data={selectedItem}
        open={detailModal}
        onClose={closeDetailModal}
      />
    </Box>
  );
}

Agents.propTypes = {};

export default Agents;
