import React, { useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { CustomButton, CustomConfirm } from "../../components";
import { useDispatch } from "react-redux";
import {
  updateOperationnel,
} from "../../redux/operationnels/operationnelActions";
import AgentDetailModal from "../agents/components/AgentDetailModal";
import OperationnelTable from "./OperationnelTable";
import OperationnelFormModal from "./OperationnelFormModal";


function Operationnels() {

  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [detailModal, setDetailModal] = useState(false);
  const [filter, setFilter] = useState("list");
  const [confirmModalTitle, setConfirmModalTitle] = useState("");
  const [isDelete, setIsDelete] = useState(false);

  const toggleModal = () => {
    setSelectedItem(null);
    setOpen(!open);
  };

  const toggleDeleteModal = (item) => {
    if (item) {
      setSelectedItem(item);
    }
    setDeleteModal(!deleteModal);
  };

  const toggleDetail = (item) => {
    setSelectedItem(item);
    setDetailModal(true);
  };

  const toggleEdit = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const onDelete = () => {
    let user = { ...selectedItem, isDeleted: true };
    delete user.email;
    dispatch(
      updateOperationnel({
        client: user,
        callback: setDeleteModal(!deleteModal),
        toastMessage: "Supprimé avec succès",
      })
    );
  };

  const onSuspend = () => {
    let user = { ...selectedItem, isSuspended: !selectedItem.isSuspended };
    delete user.email;
    dispatch(
      updateOperationnel({
        client: user,
        callback: setDeleteModal(!deleteModal),
        toastMessage: "Suspendu avec succès",
      })
    );
  };

  const dispatch = useDispatch();

  const openSuspend = (item) => {
    setSelectedItem(item);
    setConfirmModalTitle(
      `Voulez-vous vraiment ${
        item.isSuspended ? "retablir" : "suspendre"
      } ce Operationnel?`
    );
    setDeleteModal(true);
    setIsDelete(false);
  };

  const openDelete = (item) => {
    setSelectedItem(item);
    setConfirmModalTitle("Voulez-vous vraiment supprimer ce Operationnel?");
    setDeleteModal(true);
    setIsDelete(true);
  };

  const deleteConfirm = () => {
    if (isDelete) {
      onDelete();
    } else {
      onSuspend();
    }
  };

  const closeDetailModal = () => {
    setSelectedItem(null);
    setDetailModal(false);
  };

  return (
    <Box
      p={4}
      style={{
        backgroundColor: "#f4f3f7",
        minHeight: "100vh",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          borderRadius: 3,
          backgroundColor: "#fff",
          padding: 2,
          marginTop: 3,
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row", lg: "row" }}
          alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
          justifyContent="space-between"
          spacing={2}
          sx={{
            mb: 1,
          }}
        >
          <Typography fontWeight="600" color="#161B38" fontSize={28}>
            Liste des opérateurs
          </Typography>

          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <CustomButton
              title={"Ajouter"}
              titleStyle={{
                fontSize: "13px",
                textTransform: "none",
                fontWeight: "600",
              }}
              rootStyle={{
                backgroundColor: "#161B38",
                height: 42,
              }}
              onClick={toggleModal}
            />
          </Stack>
        </Stack>

        <OperationnelTable
          toggleEdit={toggleEdit}
          openSuspend={openSuspend}
          openDelete={openDelete}
          toggleDetail={toggleDetail}
          filter={filter}
        />
      </Paper>

      {open && (
        <OperationnelFormModal
          toggleModal={toggleModal}
          item={selectedItem}
          open={open}
        />
      )}

      {deleteModal && (
        <CustomConfirm
          visible={deleteModal}
          title={confirmModalTitle}
          onConfirm={deleteConfirm}
          onClose={toggleDeleteModal}
        />
      )}
      {detailModal && (
        <AgentDetailModal
          data={selectedItem}
          open={detailModal}
          onClose={closeDetailModal}
        />
      )}
    </Box>
  );
}

export default Operationnels;
