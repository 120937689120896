import React from "react";
import { Box, Paper, Stack } from "@mui/material";
import { CustomButton, CustomKeyValue } from "../../../components";
import { COLORS } from "../../../utils";
import { getStatus } from "../../../utils/commandUtils";
import {
  BrowserView,
  isBrowser,
  isMobile,
  MobileView,
} from "react-device-detect";

function CommandRow({
  rootStyle,
  data,
  topBtnTitle = "Détails",
  bottomBtnTitle = "Cloner",
  onTopBtnClick,
  onBottomBtnClick,
  ...rest
}) {
  const ActionButtons = () => (
    <Stack
      spacing={2}
      direction={{ xs: "row", md: "column", lg: "column" }}
      alignItems="center"
      justifyContent={"center"}
    >
      {onTopBtnClick ? (
        <CustomButton
          title={topBtnTitle}
          onClick={onTopBtnClick}
          titleStyle={{ fontSize: 12 }}
          rootStyle={{ minWidth: 150 }}
        />
      ) : null}
      {onBottomBtnClick ? (
        <CustomButton
          title={bottomBtnTitle}
          titleStyle={{ fontSize: 12, color: COLORS.primary }}
          rootStyle={{
            backgroundColor: "white",
            border: "1.5px solid #161B38",
            minWidth: 150,
          }}
          onClick={onBottomBtnClick}
        />
      ) : (
        <CustomButton
          titleStyle={{ fontSize: 12, color: "transparent" }}
          rootStyle={{
            backgroundColor: "transparent",
            height: 45,
          }}
          disabled
        />
      )}
    </Stack>
  );

  const PaperContainer = ({ children, color }) => (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: COLORS.primary + "20",
        p: 2,
        borderLeft: isBrowser ? "7px solid " + color : "",
        borderTop: isMobile ? "7px solid " + color : "",
        ...rootStyle,
      }}
      {...rest}
    >
      {children}
    </Paper>
  );

  return (
    <>
      <BrowserView>
        <PaperContainer color={getStatus(data.state).color}>
          <Stack spacing={2}>
            <CustomKeyValue
              title={"Date"}
              value={`${new Date(data.createdAt).toLocaleDateString("fr")}`}
              // value={`${
              //   commandLength < 10 ? String(0).repeat(10 - commandLength) : ""
              // }${data.commandNumber}`}
              titleStyle={{ fontWeight: 600, color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                fontSize: 14,
              }}
            />
            <CustomKeyValue
              title={"Numéro de commande"}
              value={`${data.commandNumber || "N/a"}`}
              // value={`${
              //   commandLength < 10 ? String(0).repeat(10 - commandLength) : ""
              // }${data.commandNumber}`}
              titleStyle={{ fontWeight: 600, color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                fontSize: 14,
              }}
            />
            {/* <CustomKeyValue
              title={"Client"}
              value={data?.client?.raisonSociale}
              titleStyle={{ fontWeight: 600, color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                fontSize: 14,
              }}
            /> */}
          </Stack>
          <Stack spacing={2}>
            <CustomKeyValue
              title={"Adresse"}
              value={`${data.siteIntervension?.address?.address}, ${data.siteIntervension?.address?.city}`}
              titleStyle={{ fontWeight: 600, color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                fontSize: 14,
              }}
            />
            <CustomKeyValue
              title={"Ligne(s) de besoin"}
              value={data?.comandLines?.length}
              titleStyle={{ fontWeight: 600, color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                fontSize: 14,
              }}
            />
            {/* <CustomKeyValue
              title={"Type(s) de service"}
              value={data?.comandLines[0]?.serviceTypes
                ?.slice(0, 3)
                ?.map((val) => val.name)
                ?.toString()}
              titleStyle={{ fontWeight: 600, color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                fontSize: 14,
              }}
            /> */}
          </Stack>
          <Stack spacing={2}>
            <CustomKeyValue
              title={"Quantité"}
              value={`${data?.comandLines.reduce(
                (a, b) => a + b.numberOfAgent,
                0
              )} Agents`}
              titleStyle={{ fontWeight: 600, color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                fontSize: 14,
              }}
            />
            <CustomKeyValue
              title={"Type(s) d’agent"}
              value={data?.comandLines[0]?.AgentType?.name}
              titleStyle={{ fontWeight: 600, color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                fontSize: 14,
              }}
            />
          </Stack>
          <Stack spacing={2}>
            <CustomKeyValue
              title={"Statut"}
              value={getStatus(data.state).name}
              titleStyle={{ fontWeight: 600, color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                fontSize: 14,
              }}
            />
            <CustomKeyValue
              title={"Période"}
              value={`${new Date(
                data.startDate
              ).toLocaleDateString("fr")} -- ${new Date(
                data.endDate
              ).toLocaleDateString("fr")}`}
              titleStyle={{ fontWeight: 600, color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                fontSize: 14,
              }}
            />
          </Stack>
          <ActionButtons />
        </PaperContainer>
      </BrowserView>

      <MobileView>
        <PaperContainer color={getStatus(data.state).color}>
          <Box sx={{ width: "100%" }}>
            <Stack direction="row" mb={3} justifyContent={"space-between"}>
              <Stack spacing={2}>
                <CustomKeyValue
                  title={"Numéro de commande"}
                  value={`${data.commandNumber || "N/a"}`}
                  // value={`${
                  //   commandLength < 10 ? String(0).repeat(10 - commandLength) : ""
                  // }${data.commandNumber}`}
                  titleStyle={{ fontWeight: 600, color: "#57585A" }}
                  valueStyle={{
                    fontWeight: 700,
                    color: COLORS.primary,
                    fontSize: 14,
                  }}
                />
                <CustomKeyValue
                  title={"Client"}
                  value={data?.client?.raisonSociale}
                  titleStyle={{ fontWeight: 600, color: "#57585A" }}
                  valueStyle={{
                    fontWeight: 700,
                    color: COLORS.primary,
                    fontSize: 14,
                  }}
                />
              </Stack>
              <Stack spacing={2}>
                <CustomKeyValue
                  title={"Statut"}
                  value={getStatus(data.state).name}
                  titleStyle={{ fontWeight: 600, color: "#57585A" }}
                  valueStyle={{
                    fontWeight: 700,
                    color: COLORS.primary,
                    fontSize: 14,
                  }}
                />
                <CustomKeyValue
                  title={"Quantité"}
                  value={`${data?.comandLines.reduce(
                    (a, b) => a + b.numberOfAgent,
                    0
                  )} Agents`}
                  titleStyle={{ fontWeight: 600, color: "#57585A" }}
                  valueStyle={{
                    fontWeight: 700,
                    color: COLORS.primary,
                    fontSize: 14,
                  }}
                />
              </Stack>
            </Stack>
            <ActionButtons />
          </Box>
        </PaperContainer>
      </MobileView>
    </>
  );
}

CommandRow.propTypes = {};

export default CommandRow;
