import axios from "axios";
import { SERVER } from "../../utils/server";
import notificationTypes from "./notificationTypes";

export const archiveNotification =
  (notif, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}archiveNotification`, notif);
      if (results.data.isSuccess) {
        dispatch(listNotifications());
       
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: { message: results.data.data.message },
        });
        onError?.();
      }
    } catch (error) {
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("archive notification error", error);
    }
  };

export const setNotificationRead =
  (notif, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}setNotificationRead`, notif);
      if (results.data.isSuccess) {
        dispatch(listNotifications());
       
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: { message: results.data.data.message },
        });
        onError?.();
      }
    } catch (error) {
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("archive notification error", error);
    }
  };

export const listNotifications = (userType,userID) => async (dispatch) => {
  try {
    const results = await axios.get(`${SERVER}getAllNotifications?concerned=${userType}&ownerEntityId=${userID}`);
    dispatch({ type: notificationTypes.GET_ALL, payload: results.data.data });
  } catch (error) {
    console.error("list Notifications error", error);
  }
};

export const listArchivedNotifications = (userType,userID) => async (dispatch) => {
  try {
    const results = await axios.get(`${SERVER}getArchivedNotifications?concerned=${userType}&ownerEntityId=${userID}`);
    dispatch({ type: notificationTypes.GET_ALL, payload: results.data.data });
  } catch (error) {
    console.error("list Archived Notifications error", error);
  }
};

export const listNonArchivedNotifications = (userID) => async (dispatch) => {
  try {
    const results = await axios.get(`${SERVER}getNonArchivedNotifications?ownerEntityId=${userID}`);
    dispatch({ type: notificationTypes.GET_ALL, payload: results.data.data });
  } catch (error) {
    console.error("list Non Archived Notifications error", error);
  }
};