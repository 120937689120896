import { FileCopy, MoreVert } from "@mui/icons-material";
import {
  Divider,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { CustomButton } from "../../../components";
import EmptyList from "../../../components/EmptyList";

const MenuProps = {
  PaperProps: {
    style: {
      width: 150,
    },
  },
};

function AgentProfileTable({
  agentProfile,
  menuComponent,
  toggleDetail,
  tableFooter,
  toggleEdit,
  openDelete,
  onOpenJust,
}) {
  return agentProfile?.length ? (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: "600" }}>
              #
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: "600" }}>
              Qualification
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "600" }}>
              Description
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "600" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agentProfile?.map((admin, index) => (
            <TableRow
              key={admin.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="left">
                {index + 1}
              </TableCell>
              <TableCell component="th" scope="row" align="left">
                {admin.qualification?.name}
              </TableCell>
              <TableCell align="center">{admin.comment}</TableCell>

              <TableCell align="center">
                {menuComponent ? (
                  <Select
                    MenuProps={MenuProps}
                    variant="standard"
                    disableUnderline
                    sx={{ "& .MuiSelect-icon": { transform: "none" } }}
                    IconComponent={(props) => (
                      <MoreVert
                        fontSize="large"
                        {...props}
                        style={{ color: "#161B38" }}
                      />
                    )}
                  >
                    <MenuItem
                      style={{
                        background: "white",
                        color: "#161928",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                      onClick={() => toggleDetail(admin)}
                    >
                      Détails
                    </MenuItem>
                    <Divider sx={{ mx: 2 }} />
                    <MenuItem
                      style={{
                        background: "white",
                        color: "#161928",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                      onClick={() => toggleEdit(admin)}
                    >
                      Modifier
                    </MenuItem>
                    <Divider sx={{ mx: 2 }} />

                    {!admin.isDeleted && (
                      <MenuItem
                        style={{
                          background: "white",
                          color: "#CB1728",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                        onClick={() => openDelete(admin)}
                      >
                        Supprimer
                      </MenuItem>
                    )}
                  </Select>
                ) : onOpenJust ? (
                  <CustomButton
                    leftComponent={<FileCopy fontSize="small" />}
                    onClick={() => onOpenJust(admin)}
                  />
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {tableFooter}
      </Table>
      {!agentProfile.length ? (
        <Typography my={4} textAlign="center" color={"#9b9999"}>
          Aucun élément trouvé
        </Typography>
      ) : null}
    </TableContainer>
  ) : (
    <EmptyList />
  );
}

export default AgentProfileTable;
