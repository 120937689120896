import axios from "axios";
import notificationTypes from "../redux/notifications/notificationTypes";
import { SERVER } from "./server";

export const fileUploader = async (file,dispatch) => {
  try {
    let formData = new FormData();
    formData.append("file", file);
    const result = await axios.post(`${SERVER}upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
console.log(result?.data)
    if (result?.data?.isSuccess) {
      return result?.data?.data?.filename;
    } else {
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: result?.data?.message },
      });
      return undefined;
    }
    
  } catch (error) {
    return undefined;
  }
};
