import React from "react";
import { Box, Grid, Modal } from "@mui/material";
import { CustomKeyValue, DetailModalTitle } from "../../../components";
import { COLORS } from "../../../utils";
import { SERVER } from "../../../utils/server";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, md: 650, lg: 650 },
  bgcolor: "background.paper",
  borderRadius: 2,
  p: 3,
};

function SiteDetailModal({ data, open, onClose }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <DetailModalTitle title={`Description du site`} onClose={onClose} />

        <Box
          sx={{
            // backgroundColor: "#D9DBDD1A",
            width: "100%",
            height: "100%",
            borderRadius: 2,
            // p: 3,
            my: 4,
          }}
        >
        
            <img
              src={
                data.imageUrl
                  ? `${SERVER}download?fileName=${data.imageUrl}`
                  : "/site.png"
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/site.png";
              }}
              alt="site"
              style={{ width: "100%", height: 200, objectFit: "contain", marginBottom: 15 }}
            />

          <Grid
            container
            spacing={{ xs: 2, md: 5 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Nom"}
                value={data.name}
                titleStyle={{ fontWeight: 600, color: "#57585A" }}
                valueStyle={{ fontWeight: 700, color: COLORS.primary }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Type"}
                value={data.siteType?.name}
                titleStyle={{ fontWeight: 600, color: "#57585A" }}
                valueStyle={{ fontWeight: 700, color: COLORS.primary }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Adresse"}
                value={data.address?.address}
                titleStyle={{ fontWeight: 600, color: "#57585A" }}
                valueStyle={{ fontWeight: 700, color: COLORS.primary }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Code postal"}
                value={data.address?.postalCode}
                titleStyle={{ fontWeight: 600, color: "#57585A" }}
                valueStyle={{ fontWeight: 700, color: COLORS.primary }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Pays"}
                value={data.address?.country}
                titleStyle={{ fontWeight: 600, color: "#57585A" }}
                valueStyle={{ fontWeight: 700, color: COLORS.primary }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Ville"}
                value={data.address?.city}
                titleStyle={{ fontWeight: 600, color: "#57585A" }}
                valueStyle={{ fontWeight: 700, color: COLORS.primary }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Localisation"}
                value={`${data.gpsLocation?.long} / ${data.gpsLocation?.lat}`}
                titleStyle={{ fontWeight: 600, color: "#57585A" }}
                valueStyle={{ fontWeight: 700, color: COLORS.primary }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Client"}
                value={data.client?.raisonSociale}
                titleStyle={{ fontWeight: 600, color: "#57585A" }}
                valueStyle={{ fontWeight: 700, color: COLORS.primary }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Superficie"}
                value={`${data.superficy} m²`}
                titleStyle={{ fontWeight: 600, color: "#57585A" }}
                valueStyle={{ fontWeight: 700, color: COLORS.primary }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Opérationnels"}
                value={data.operationelAffectes?.length || 0}
                titleStyle={{ fontWeight: 600, color: "#57585A" }}
                valueStyle={{ fontWeight: 700, color: COLORS.primary }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}

SiteDetailModal.propTypes = {};

export default SiteDetailModal;
