import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import FormModalTitle from "../../../components/FormModalTitle";
import { CustomDropzone } from "../../../components";
import { fileUploader } from "../../../utils/uploadUtils";
import CustomLoader from "../../../components/CustomLoader";
import {
  createBonCommande,
} from "../../../redux/devis/devisActions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, md: 650, lg: 700 },
  bgcolor: "background.paper",
  borderRadius: 2,
};

function CommandBonModal({ commandId, open, onClose }) {
  const dispatch = useDispatch();

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({ multiple: false });

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState("");

  const handleClose = () => {
    onClose();
  };

  const callback = () => {
    setLoading(false);
    handleClose();
    window.location.reload(false);
  };

  const onError = () => {
    setLoading(false);
  };

  const handleChange = (value) => {
    setDetails(value);
  };

  async function handleSubmit() {
    try {
      setLoading(true);

      const fileUrl = await fileUploader(acceptedFiles[0]);
      const toSave = {
        details,
        commandId,
        attachments: [
          {
            category: "BON_DE_COMMANDE",
            name: "bon de commande",
            url: fileUrl,
          },
        ],
      };
      dispatch(createBonCommande(toSave, callback, onError));
    } catch (error) {
      setLoading(false);
    }
  }

  const disabled = !details.trim() || !acceptedFiles?.length;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FormModalTitle
          title={`Envoyer un bon de commande`}
          closeModal={handleClose}
          disabled={disabled}
          onConfirm={handleSubmit}
        />
        <Box p={4} pt={3} style={{ maxHeight: "85vh", overflow: "auto" }}>

          <Box>
            <Typography
              color="black"
              sx={{
                fontSize: {
                  xs: 12,
                  md: 14,
                },
                fontWeight: 600,
                mb: 1,
              }}
            >
              Ajouter un détail
            </Typography>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              value={details}
              onChange={({ target }) => handleChange(target.value)}
              style={{
                width: "100%",
                borderRadius: 8,
                borderColor: "#bcbbbe",
                padding: 15,
              }}
            />
          </Box>

            <CustomDropzone
              title="le bon de commande"
              showAvatar={false}
              acceptedFiles={acceptedFiles}
              getRootProps={getRootProps({ className: "dropzone" })}
              getInputProps={getInputProps()}
            />
        </Box>
        <CustomLoader open={loading} />
      </Box>
    </Modal>
  );
}

CommandBonModal.propTypes = {};

export default CommandBonModal;
