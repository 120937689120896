import React from "react";
import { Box, Typography } from "@mui/material";

function EmptyList() {
  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        m: 10,
      }}
    >
      <div>
        <img
          src="/paper.png"
          style={{ width: 150, height: 150 }}
          alt="no data"
        />
        <Typography textAlign="center" fontWeight="600" mt={3}>
          Liste vide
        </Typography>
      </div>
    </Box>
  );
}

export default EmptyList;
