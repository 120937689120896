import historyTypes from "./historyTypes";

const historyReducer = (state = {}, action) => {
  const payload = action.payload;
  if (action.type === historyTypes.CREATE_HISTORY) {
    return { [payload.id]: { ...payload }, ...state };
  }
  if (action.type === historyTypes.REMOVE_HISTORY) {
    const localState = { ...state };
    delete localState[payload];
    return { ...localState };
  }
  if (action.type === historyTypes.UPDATE_HISTORY) {
    return { ...state, [payload.id]: { ...payload } };
  }
  if (action.type === historyTypes.LIST_HISTORIES) {
    let tempData = {};
    for (let obj of payload) {
      tempData[obj.id] = { ...obj };
    }
    return { ...tempData };
  }
  return state;
};

export default historyReducer;
