const notificationTypes = {
  CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
  SHOW_SUCCESS: "SHOW_SUCCESS",
  SHOW_ERROR: "SHOW_ERROR",
  GET_ALL: "GET_ALL",
  GET_ARCHIVED: "GET_ARCHIVED",
  GET_NON_ARCHIVED: "GET_NON_ARCHIVED",
};

export default notificationTypes;
