import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { COLORS } from "../../../utils";

const SigninButton = ({ title, type = "submit", onClick, isLoading }) => {
  return (
    <Button
      type={type}
      style={{
        width: "100%",
        color: "white",
        borderRadius: "0.1",
        backgroundColor: isLoading ? "#c2c2c250" : COLORS.primary,
        marginTop: "2em",
        textTransform: "none",
        padding: "10px",
        marginBottom: 15,
      }}
      onClick={onClick}
      disabled={isLoading}
    >
      {!isLoading ? title : <CircularProgress size={20} />}
    </Button>
  );
};

export default SigninButton;
