import React, { useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  CustomButton,
  CustomConfirm,
  CustomDropzone,
  TablePaginationActions,
} from "../../components";
import SigninTextInput from "../auth/components/SigninTextInput";
import FormModalTitle from "../../components/FormModalTitle";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { ChatSVG } from "../../utils/svgImages";
import {
  createAgentProfile,
  listAgentProfiles,
  updateAgentProfile,
} from "../../redux/agentProfile/agentProfileActions";
import { fileUploader } from "../../utils/uploadUtils";
import AgentProfileDetailModal from "./AgentProfileDetailModal";
import AgentProfileTable from "./components/AgentProfileTable";
import { SERVER } from "../../utils/server";
import { PictureAsPdf } from "@mui/icons-material";

const MenuProps = {
  PaperProps: {
    style: {
      width: 150,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, md: 650, lg: 650 },
  bgcolor: "background.paper",
  borderRadius: 2,
};

function AgentProfile() {
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");

  const agentTypes = useSelector((state) => Object.values(state.agentTypes).filter(admin=>!admin.isDeleted));
  const agentProfile = useSelector((state) =>
    Object.values(state.agentProfile)
  );
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [confirmModalTitle, setConfirmModalTitle] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [id, setId] = useState("");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [qualification, setQualification] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editFile, setEditFile] = useState(null);

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({
      multiple: false,
      accept: {
        "image/jpeg": [],
        "image/png": [],
        "image/jpg": [],
        "application/pdf": [],
      },
    });

  const toggleModal = () => {
    setOpen(!open);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleDeleteModal = (item) => {
    if (item) {
      setSelectedItem(item);
    }
    setDeleteModal(!deleteModal);
  };
  const closeDetailModal = () => {
    setSelectedItem({});
    setDetailModal(false);
  };

  const toggleEdit = (item) => {
    setId(item.id);
    setQualification(item.qualification.id);
    setComment(item.comment);
    setEditFile(item.justificatifUrl);
    setOpen(true);
  };

  const toggleDetail = (item) => {
    setSelectedItem(item);
    setDetailModal(true);
  };

  const openDelete = (item) => {
    setSelectedItem(item);
    setConfirmModalTitle("Voulez-vous vraiment supprimer ce profil?");
    setDeleteModal(true);
    setIsDelete(true);
  };

  const deleteConfirm = () => {
    if (isDelete) {
      onDelete();
    }
  };

  const deleteCallback = () => {
    setDeleteModal(!deleteModal);
    dispatch(listAgentProfiles(agent?.client?.id || agent.id));
  };

  const onDelete = () => {
    let user = { ...selectedItem, isDeleted: true };
    dispatch(updateAgentProfile(user, deleteCallback, "Supprimé avec succès"));
  };

  const onCloseModal = () => {
    setId("");
    setQualification("");
    setComment("");
    setEditFile(null);
    toggleModal();
  };

  const onError = () => {
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const justificatifUrl = acceptedFiles?.length
        ? await fileUploader(acceptedFiles[0], dispatch)
        : editFile;

      let user = {
        comment,
        qualification,
        justificatifUrl,
      };

      if (id) {
        dispatch(updateAgentProfile({...user,id}, callback, onError));
      } else {
        user = {
          ...user,
          agentId: agent?.id,
        };
        dispatch(createAgentProfile(user, callback, onError));
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const dispatch = useDispatch();
  const callback = () => {
    setLoading(false);
    onCloseModal();
  };

  useEffect(() => {
    dispatch(listAgentProfiles(agent?.id));
  }, [agent?.id, dispatch]);

  return (
    <Box
      style={{
        minHeight: "100vh",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          borderRadius: 3,
          // height: "600px",
          backgroundColor: "#fff",
          padding: 2,
          marginTop: 3,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{
            mb: 1,
          }}
        >
          <Typography fontWeight="600" color="#161B38" fontSize={28}>
            Profil d'agent
          </Typography>

          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <CustomButton
              title={"Ajouter"}
              titleStyle={{
                fontSize: "13px",
                textTransform: "none",
                fontWeight: "600",
              }}
              rootStyle={{
                backgroundColor: "#161B38",
                height: 42,
              }}
              onClick={toggleModal}
            />
          </Stack>
        </Stack>

        <AgentProfileTable
          agentProfile={agentProfile}
          menuComponent
          toggleEdit={(val) => toggleEdit(val)}
          openDelete={(val) => openDelete(val)}
          toggleDetail={(val) => toggleDetail(val)}
          tableFooter={
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    10,
                    25,
                    50,
                    100,
                    { label: "All", value: -1 },
                  ]}
                  labelRowsPerPage="Rangées par page"
                  count={agentProfile.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rangées par page",
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          }
        />
      </Paper>

      <Modal
        open={open}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <FormModalTitle
              title={`${id ? "Modifier" : "Ajouter"} un profil d'agent`}
              closeModal={onCloseModal}
              isLoading={loading}
            />
            <Box p={4} pt={0} style={{ maxHeight: "85vh", overflow: "auto" }}>
              <Stack
                direction={{ xs: "column", md: "row", lg: "row" }}
                spacing={2}
                mt={3}
                sx={{
                  alignItems: "center",
                  // justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box sx={{ width: { xs: "100%", md: "50%", lg: "50%" } }}>
                  <Typography
                    color="black"
                    sx={{
                      fontSize: {
                        xs: 12,
                        md: 14,
                      },
                      fontWeight: 600,
                      mb: 1,
                    }}
                  >
                    Qualification
                  </Typography>
                  <Select
                    variant="standard"
                    // size="small"
                    value={qualification}
                    onChange={({ target }) => setQualification(target.value)}
                    input={<OutlinedInput />}
                    style={{
                      backgroundColor: "#fff",
                      width: "100%",
                    }}
                    MenuProps={MenuProps}
                  >
                    {agentTypes.map((agent) => (
                      <MenuItem key={agent.id} value={agent.id}>
                        {agent.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box sx={{ width: { xs: "100%", md: "50%", lg: "50%" } }}>
                  <SigninTextInput
                    title={"Commentaire"}
                    value={comment}
                    id="comment"
                    name="comment"
                    onChange={(value) => setComment(value)}
                    // errorMessage={errors.description}
                    icon={<ChatSVG />}
                    rootStyle={{ width: "100%" }}
                  />
                </Box>
              </Stack>
              {editFile ? (
                <>
                  {editFile?.substr(editFile?.length - 3) !== "pdf" ? (
                    <img
                      src={`${SERVER}download?fileName=${editFile}`}
                      alt="justificatif"
                      style={{ height: 200, width: "100%", borderRadius: 5 }}
                    />
                  ) : (
                    <Stack alignItems={'center'}><PictureAsPdf style={{width: 100,height:100}} /></Stack>
                    // <iframe
                    //   title="Pièce justificative"
                    //   src={`${SERVER}download?fileName=${editFile}`}
                    //   style={{ height: 200, width: "100%", borderRadius: 5 }}
                    // />
                  )}
                </>
              ) : null}
              <CustomDropzone
                title="pièce justificative"
                showAvatar={false}
                acceptedFiles={acceptedFiles}
                getRootProps={getRootProps({ className: "dropzone" })}
                getInputProps={getInputProps()}
              />
            </Box>
          </Box>
        </form>
      </Modal>
      <CustomConfirm
        visible={deleteModal}
        onClose={toggleDeleteModal}
        title={confirmModalTitle}
        onConfirm={deleteConfirm}
      />
      <AgentProfileDetailModal
        data={selectedItem}
        open={detailModal}
        onClose={closeDetailModal}
      />
    </Box>
  );
}

AgentProfile.propTypes = {};

export default AgentProfile;
