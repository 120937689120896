import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";
import { COLORS } from "../../../utils";
import { differentBetweenDate } from "../../../utils/dateUtils";

function CommandRecapTable({ data, agentTypes, rootStyle, isDetail }) {
  return (
    <TableContainer sx={{ ...rootStyle }}>
      <Table aria-label="simple table">
        <TableBody>
          {data?.map((command, index) => (
            <Box
              key={index}
              sx={{
                border: "1px solid #c2c2c2",
                width: 800,
                backgroundColor: "white",
              }}
            >
              <TableRow
                sx={{
                  backgroundColor: COLORS.primary,
                }}
              >
                <TableCell
                  align="center"
                  sx={{ width: "33%", color: "white", fontWeight: 600 }}
                >{`${command.title}: ${new Date(
                  command.date
                ).toLocaleDateString("fr")}`}</TableCell>
                <TableCell align="center" sx={{ width: "33%" }}></TableCell>
                <TableCell align="center" sx={{ width: "33%" }}></TableCell>
              </TableRow>
              {command.data?.map((val, inx) => (
                <TableRow key={inx} sx={{ border: "1px solid #c2c2c2" }}>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #c2c2c2" }}
                  >{`${val.numberOfAgent} ${
                    agentTypes[val?.agentTypeId]?.name ||
                    val.AgentType?.name ||
                    "--"
                  }`}</TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #c2c2c2", width: 400 }}
                  >
                    {(!isDetail ? !val.startTime || !val.endTime : false)
                      ? "--"
                      : `${new Date(
                          val.startTime || val?.serviceDay?.startTime
                        )?.getHours()}h:${new Date(
                          val.startTime || val?.serviceDay?.startTime
                        )?.getMinutes()} - ${new Date(
                          val.endTime || val?.serviceDay?.endTime
                        )?.getHours()}h:${new Date(
                          val.endTime || val?.serviceDay?.endTime
                        )?.getMinutes()}`}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #c2c2c2", width: 400 }}
                  >
                    {(!isDetail ? !val.startTime || !val.endTime : false)
                      ? "--"
                      : `${
                          Math.abs(differentBetweenDate(
                            new Date(
                              val.startTime || val?.serviceDay?.startTime
                            ),
                            new Date(val.endTime || val?.serviceDay?.endTime),
                            "hours"
                          ) * val.numberOfAgent)
                        } Heures`}
                  </TableCell>
                </TableRow>
              ))}
            </Box>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CommandRecapTable;
