import React, { useState } from "react";
import { Typography, Card, CardContent } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { COLORS } from "../../utils";
import SigninTextInput from "./components/SigninTextInput";
import { AuthLayout } from "../../layouts";
import SigninHeader from "./components/SigninHeader";
import SigninButton from "./components/SigninButton";
import { activateAgent } from "../../redux/agents/agentActions";
import { QrCode } from "@mui/icons-material";

const ConfirmAccount = () => {
  let navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const zipConfirmEmail = localStorage.getItem("zipConfirmEmail");

  const [errorUsername, setErrorUsername] = useState(false);

  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const callback = () => {
    navigate("/home/bon-commands");
  };
  const onError = () => setIsLoading(false);

  const login = async (event) => {
    event.preventDefault();
    try {
      if (username.trim() && username.trim().split(" ").length === 1) {
        setIsLoading(true);
        setError(null);
        dispatch(
          activateAgent(
            { code: username, email: zipConfirmEmail },
            callback,
            onError
          )
        );
      } else {
        if (!username.trim()) {
          setErrorUsername(true);
        }

        if (username.trim().split(" ").length > 1) {
          setError({ message: "remove white space" });
        }
      }
    } catch (error) {
      console.error("sign in error", error);
      setError(error);
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout
      onSubmit={login}
      leftComponent={
        <Card
          sx={{
            maxWidth: 430,
            boxShadow: "none",
          }}
        >
          <CardContent>
            <SigninHeader
              title={"Confirmation du compte"}
              subTitle={`Entrez le code que vous avez reçu via ${zipConfirmEmail}`}
            />

            <div
              component="form"
              style={{
                width: "100%",
              }}
            >
              <SigninTextInput
                title={"Code"}
                onChange={(value) => setUsername(value)}
                showError={errorUsername}
                icon={<QrCode />}
                type="number"
                // errorMessage={"Code requis"}
              />

              <SigninButton
                title={"Confirmer"}
                onClick={login}
                isLoading={isLoading}
              />

              {error ? (
                <Typography
                  style={{
                    color: COLORS.red,
                    marginTop: 10,
                  }}
                  align={"center"}
                >
                  {error.name === "InvalidParameterException"
                    ? "Remove whitespace"
                    : error.message}
                </Typography>
              ) : null}
            </div>
          </CardContent>
        </Card>
      }
    />
  );
};

export default ConfirmAccount;
