import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Accessibility,
  KeyboardArrowDown,
  Lock,
  Mail,
  MoreVert,
  Phone,
} from "@mui/icons-material";
import {
  CustomButton,
  CustomConfirm,
  TablePaginationActions,
} from "../../components";
import SigninTextInput from "../auth/components/SigninTextInput";
import { LockSVG } from "../../utils/svgImages";
import FormModalTitle from "../../components/FormModalTitle";
import { adminYup } from "../../utils/yupValidation";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  createAdmin,
  listAdmins,
  updateAdmin,
} from "../../redux/admins/adminActions";
import EmptyList from "../../components/EmptyList";
import AgentDetailModal from "../agents/components/AgentDetailModal";
import SigninPhoneInput from "../auth/components/SigninPhoneInput";
import { COLORS } from "../../utils";
import { isBrowser, isMobile } from "react-device-detect";

const MenuProps = {
  PaperProps: {
    style: {
      width: 150,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: 2,
};

function Admins() {
  const admins = useSelector((state) => Object.values(state.admins));
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [filter, setFilter] = useState("list");
  const [confirmModalTitle, setConfirmModalTitle] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const toggleModal = () => {
    resetForm();
    setOpen(!open);
  };
  const toggleDeleteModal = (item) => {
    if (item) {
      setSelectedItem(item);
    }
    setDeleteModal(!deleteModal);
  };

  const toggleEdit = (item) => {
    setValues(item);
    const { adressCompleted } = item;
    setFieldValue("complementAddress", adressCompleted.complementAddress);
    setFieldValue("address", adressCompleted.address);
    setFieldValue("postalCode", adressCompleted.postalCode);
    setFieldValue("city", adressCompleted.city);
    setFieldValue("country", adressCompleted.country);
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openSuspend = (item) => {
    setSelectedItem(item);
    setConfirmModalTitle("Voulez-vous vraiment suspendre cet Agent?");
    setDeleteModal(true);
    setIsDelete(false);
  };

  const openDelete = (item) => {
    setSelectedItem(item);
    setConfirmModalTitle("Voulez-vous vraiment supprimer cet Agent?");
    setDeleteModal(true);
    setIsDelete(true);
  };

  const deleteConfirm = () => {
    if (isDelete) {
      onDelete();
    } else {
      onSuspend();
    }
  };

  const toggleDetail = (item) => {
    setSelectedItem(item);
    setDetailModal(true);
  };

  const onDelete = () => {
    let user = { ...selectedItem, isDeleted: true };
    delete user.email;
    dispatch(
      updateAdmin(user, setDeleteModal(!deleteModal), "Supprimé avec succès")
    );
  };

  const onSuspend = () => {
    let user = { ...selectedItem, isSuspended: true };
    delete user.email;
    dispatch(
      updateAdmin(user, setDeleteModal(!deleteModal), "Suspendu avec succès")
    );
  };

  const dispatch = useDispatch();
  const callback = () => {
    setLoading(false);
    setOpen(false);
    resetForm();
  };

  const closeDetailModal = () => {
    setSelectedItem({});
    setDetailModal(false);
  };

  const { values, handleSubmit, setValues, errors, setFieldValue, resetForm } =
    useFormik({
      initialValues: {
        firstname: "",
        lastname: "",
        id: "",
        phoneNumber: "",
        complementAddress: "",
        email: "",
        password: "",
        address: "",
        postalCode: "",
        city: "",
        country: "France",
        confirmPassword: "",
        fonction: "",
      },
      validationSchema: Yup.object().shape(adminYup),
      onSubmit(values) {
        setLoading(true);
        if (values.id !== "") {
          let user = { ...values };
          delete user.email;
          dispatch(updateAdmin(user, callback));
        } else {
          const user = {
            ...values,
            isActivated: "false",
            isActive: true,
            superAdmin: false,
          };
          dispatch(createAdmin(user, callback));
        }
      },
    });

  useEffect(() => {
    dispatch(listAdmins());
  }, [dispatch]);

  return (
    <Box
      p={4}
      style={{
        backgroundColor: "#f4f3f7",
        minHeight: "100vh",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          borderRadius: 3,
          // height: "600px",
          backgroundColor: "#fff",
          padding: 2,
          marginTop: 3,
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row", lg: "row" }}
          alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
          justifyContent="space-between"
          spacing={2}
          sx={{
            mb: 1,
          }}
        >
          <Typography fontWeight="600" color="#161B38" fontSize={28}>
            Liste des admins
          </Typography>

          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <FormControl>
              <InputLabel id="filter-select">Filtrer</InputLabel>
              <Select
                labelId="filter-select"
                id="filter-select"
                size="small"
                label="Filtrer"
                sx={{ width: { xs: 200, md: 250, lg: 250 } }}
                onChange={({ target }) => setFilter(target.value)}
                displayEmpty
                renderValue={
                  filter !== ""
                    ? undefined
                    : () => (
                        <Typography color="#c2c2c2">
                          Sélectionner un élément
                        </Typography>
                      )
                }
                IconComponent={(props) => (
                  <KeyboardArrowDown
                    {...props}
                    style={{
                      fontSize: "30px",
                    }}
                  />
                )}
                value={filter}
              >
                <MenuItem value="list" style={{ background: "white" }}>
                  Liste des Admins
                </MenuItem>
                <MenuItem value="suspended" style={{ background: "white" }}>
                  Admins Suspendu
                </MenuItem>
                {/* <MenuItem value="deleted" style={{ background: "white" }}>
                  Admins Supprimé
                </MenuItem> */}
              </Select>
            </FormControl>

            <CustomButton
              title={"Ajouter"}
              titleStyle={{
                fontSize: "13px",
                textTransform: "none",
                fontWeight: "600",
              }}
              rootStyle={{
                backgroundColor: "#161B38",
                height: 42,
              }}
              onClick={toggleModal}
            />
          </Stack>
        </Stack>

        {admins.length ? (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {isMobile ? (
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "600" }}
                    ></TableCell>
                  ) : null}
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    #
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "600" }}>
                    Nom
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "600" }}>
                    Téléphone
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "600" }}>
                    Email
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "600" }}>
                    Fonction
                  </TableCell>
                  {isBrowser ? (
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "600" }}
                    ></TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {admins
                  .filter((admin) =>
                    filter === "suspended"
                      ? admin.isSuspended
                      : filter === "deleted"
                      ? admin.isDeleted
                      : !admin.isSuspended && !admin.isDeleted
                  )
                  .map((admin, index) => (
                    <TableRow
                      key={admin.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {isMobile ? (
                        <TableCell align="center">
                          <Select
                            MenuProps={MenuProps}
                            variant="standard"
                            disableUnderline
                            sx={{ "& .MuiSelect-icon": { transform: "none" } }}
                            IconComponent={(props) => (
                              <MoreVert
                                fontSize="large"
                                {...props}
                                style={{ color: "#161B38" }}
                              />
                            )}
                          >
                            <MenuItem
                              style={{
                                background: "white",
                                color: "#161928",
                                fontSize: 14,
                                fontWeight: 700,
                              }}
                              onClick={() => toggleDetail(admin)}
                            >
                              Détails
                            </MenuItem>
                            <Divider sx={{ mx: 2 }} />
                            <MenuItem
                              style={{
                                background: "white",
                                color: "#161928",
                                fontSize: 14,
                                fontWeight: 700,
                              }}
                              onClick={() => toggleEdit(admin)}
                            >
                              Modifier
                            </MenuItem>
                            <Divider sx={{ mx: 2 }} />
                              <MenuItem
                                style={{
                                  background: "white",
                                  color: !admin.isSuspended
                                    ? COLORS.red
                                    : COLORS.green,
                                  fontSize: 14,
                                  fontWeight: 700,
                                }}
                                onClick={() => openSuspend(admin)}
                              >
                                {!admin.isSuspended ? "Suspendre" : "Retablir"}
                              </MenuItem>
                              <Divider sx={{ mx: 2 }} />
                            {filter === "suspended" ? (
                                <MenuItem
                                  style={{
                                    background: "white",
                                    color: COLORS.red,
                                    fontSize: 14,
                                    fontWeight: 700,
                                  }}
                                  onClick={() => openDelete(admin)}
                                >
                                  {"Supprimer"}
                                </MenuItem>
                            ) : null}
                          </Select>
                        </TableCell>
                      ) : null}
                      <TableCell component="th" scope="row" align="left">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        {`${admin.firstname} ${admin.lastname}`}
                      </TableCell>
                      <TableCell align="center">{admin.phoneNumber}</TableCell>
                      <TableCell align="center">{admin.email}</TableCell>
                      <TableCell align="center">{admin.fonction}</TableCell>
                      {isBrowser ? (
                        <TableCell align="center">
                          <Select
                            MenuProps={MenuProps}
                            variant="standard"
                            disableUnderline
                            sx={{ "& .MuiSelect-icon": { transform: "none" } }}
                            IconComponent={(props) => (
                              <MoreVert
                                fontSize="large"
                                {...props}
                                style={{ color: "#161B38" }}
                              />
                            )}
                          >
                            <MenuItem
                              style={{
                                background: "white",
                                color: "#161928",
                                fontSize: 14,
                                fontWeight: 700,
                              }}
                              onClick={() => toggleDetail(admin)}
                            >
                              Détails
                            </MenuItem>
                            <Divider sx={{ mx: 2 }} />
                            <MenuItem
                              style={{
                                background: "white",
                                color: "#161928",
                                fontSize: 14,
                                fontWeight: 700,
                              }}
                              onClick={() => toggleEdit(admin)}
                            >
                              Modifier
                            </MenuItem>
                            <Divider sx={{ mx: 2 }} />
                              <MenuItem
                                style={{
                                  background: "white",
                                  color: !admin.isSuspended
                                    ? COLORS.red
                                    : COLORS.green,
                                  fontSize: 14,
                                  fontWeight: 700,
                                }}
                                onClick={() => openSuspend(admin)}
                              >
                                {!admin.isSuspended ? "Suspendre" : "Retablir"}
                              </MenuItem>
                              <Divider sx={{ mx: 2 }} />
                            {filter === "suspended" ? (
                                <MenuItem
                                  style={{
                                    background: "white",
                                    color: COLORS.red,
                                    fontSize: 14,
                                    fontWeight: 700,
                                  }}
                                  onClick={() => openDelete(admin)}
                                >
                                  {"Supprimer"}
                                </MenuItem>
                            ) : null}
                          </Select>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      25,
                      50,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    labelRowsPerPage="Rangées par page"
                    count={admins.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rangées par page",
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
            {!admins.filter((admin) =>
              filter === "suspended"
                ? admin.isSuspended
                : filter === "deleted"
                ? admin.isDeleted
                : !admin.isSuspended && !admin.isDeleted
            ).length ? (
              <Typography my={4} textAlign="center" color={"#9b9999"}>
                Aucun élément trouvé
              </Typography>
            ) : null}
          </TableContainer>
        ) : (
          <EmptyList />
        )}
      </Paper>

      <Modal
        open={open}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <FormModalTitle
              title={`${values.id ? "Modifier" : "Ajouter"} un admin`}
              closeModal={toggleModal}
              isLoading={loading}
            />
            <Box p={4} pt={0} style={{ maxHeight: "85vh", overflow: "auto" }}>
              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Nom"}
                  value={values.lastname}
                  id="lastname"
                  name="lastname"
                  onChange={(value) => setFieldValue("lastname", value)}
                  errorMessage={errors.lastname}
                />
                <SigninTextInput
                  title={"Prénom"}
                  value={values.firstname}
                  id="firstname"
                  name="firstname"
                  onChange={(value) => setFieldValue("firstname", value)}
                  errorMessage={errors.firstname}
                  // icon={<LockSVG />}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninPhoneInput
                  title={"Téléphone"}
                  value={values.phoneNumber}
                  id="phoneNumber"
                  name="phoneNumber"
                  onChange={(value) => setFieldValue("phoneNumber", value)}
                  errorMessage={errors.phoneNumber}
                  icon={<Phone />}
                />
                <SigninTextInput
                  title={"Email"}
                  value={values.email}
                  id="email"
                  name="email"
                  onChange={(value) => setFieldValue("email", value)}
                  errorMessage={errors.email}
                  icon={<Mail />}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Fonction"}
                  value={values.fonction}
                  id="fonction"
                  name="fonction"
                  onChange={(value) => setFieldValue("fonction", value)}
                  errorMessage={errors.fonction}
                  icon={<Accessibility />}
                />
              </Stack>
              {/* <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Code Postal"}
                  value={values.postalCode}
                  id="postalCode"
                  name="postalCode"
                  onChange={(value) => setFieldValue("postalCode", value)}
                  errorMessage={errors.postalCode}
                  // icon={<LockSVG />}
                />
                <SigninDropdown
                  title={"Pays"}
                  icon={<GlobeSVG />}
                  value={values.country}
                  id="country"
                  name="country"
                  data={COUNTRIES}
                  onChange={(value) => setFieldValue("country", value)}
                  errorMessage={errors.country}
                />
              </Stack> */}
              {/* <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Ville"}
                  icon={<GlobeSVG />}
                  value={values.city}
                  id="city"
                  name="city"
                  onChange={(value) => setFieldValue("city", value)}
                  errorMessage={errors.city}
                />
              </Stack> */}
              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                {!values.id ? (
                  <>
                    <SigninTextInput
                      title={"Mot de passe"}
                      icon={<Lock />}
                      value={values.password}
                      id="password"
                      name="password"
                      type="password"
                      onChange={(value) => setFieldValue("password", value)}
                      errorMessage={errors.password}
                    />
                    <SigninTextInput
                      title={"Confirmer Mot de passe"}
                      icon={<Lock />}
                      value={values.confirmPassword}
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      onChange={(value) =>
                        setFieldValue("confirmPassword", value)
                      }
                      errorMessage={errors.confirmPassword}
                    />
                  </>
                ) : null}
              </Stack>
            </Box>
          </Box>
        </form>
      </Modal>
      <CustomConfirm
        visible={deleteModal}
        onClose={toggleDeleteModal}
        title={confirmModalTitle}
        onConfirm={deleteConfirm}
      />
      <AgentDetailModal
        data={selectedItem}
        open={detailModal}
        onClose={closeDetailModal}
      />
    </Box>
  );
}

Admins.propTypes = {};

export default Admins;
