import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import SiteCardRow from "../../sites/components/SiteCardRow";

function ExistingSiteCard({ data, onClick, active }) {

  return (
    <div
      style={{
        margin: 10,
        height: 180,
        backgroundColor: active ? "#161B38" : "#fff",
        borderRadius: "10px",
        cursor: "pointer",
        width: 300
      }}
      onClick={onClick}
    >
      <Stack
        justifyContent="space-evenly"
        direction="column"
        sx={{ height: "100%", width: "100%", px: 1 }}
      >
        <SiteCardRow title="Nom:" value={data.name} rootStyle={{ my: 0 }} active={active} />
        <SiteCardRow title="Type:" value={data.siteType?.name} rootStyle={{ my: 0 }} active={active} />
        <SiteCardRow
          title="Adresse:"
          value={data.address?.address || '--'}
          rootStyle={{ my: 0 }}
          active={active}
        />
        <SiteCardRow
          title="Code postal:"
          value={data.address?.postalCode || '--'}
          rootStyle={{ my: 0 }}
          active={active}
        />
        <SiteCardRow
          title="Ville:"
          value={data.address?.city || '--'}
          rootStyle={{ my: 0 }}
          active={active}
        />
        <SiteCardRow
          title="Opérationnels:"
          value={data.operationelAffectes?.length || '--'}
          rootStyle={{ my: 0 }}
          active={active}
        />
      </Stack>
    </div>
  );
}

ExistingSiteCard.propTypes = {};

export default ExistingSiteCard;
