import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import FormModalTitle from "../../../components/FormModalTitle";
import { CustomDropzone } from "../../../components";
import { fileUploader } from "../../../utils/uploadUtils";
import CustomLoader from "../../../components/CustomLoader";
import {
  createDevis,
  sendAmendement,
  signDevis,
  updateAmendement,
  updateDevis,
} from "../../../redux/devis/devisActions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, md: 650, lg: 700 },
  bgcolor: "background.paper",
  borderRadius: 2,
};

function CommandDevisModal({ commandId, devis, open, onClose, isEditDevis }) {
  const dispatch = useDispatch();
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({ multiple: false });

  const [loading, setLoading] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [details, setDetails] = useState(isEditDevis ? devis.details : "");

  const isClient = ["client", "sousCompteClient"].includes(
    agent?.authAccountType
  );
  console.log("@@@", devis);
  const handleClose = () => {
    onClose();
  };

  const callback = () => {
    setLoading(false);
    handleClose();
    window.location.reload(false);
  };

  const onError = () => {
    setLoading(false);
  };

  const handleChange = (value) => {
    setDetails(value);
  };

  async function handleSubmit() {
    try {
      setLoading(true);
      const fileUrl = await fileUploader(acceptedFiles[0]);

      if (!isClient) {
        const toSave = {
          details,
          commandId,
          attachments: [
            {
              category: "DEVIS",
              name: "dévis",
              url: fileUrl,
            },
          ],
        };
        // console.log(toSave);
        if (isEditDevis) {
          const updatedDevis = {
            ...devis,
            details,
            attachments: [
              {
                ...devis?.attachments?.[0],
                url: fileUrl ? fileUrl : devis?.attachments?.[0]?.url,
              },
            ],
          };
          dispatch(updateDevis(updatedDevis, callback, onError));
        } else {
          dispatch(createDevis(toSave, callback, onError));
        }
      } else {
        if (isValidate) {
          const toSave = {
            status: "SIGNED",
            commandId,
            signedDevisUrl: fileUrl ? fileUrl : devis?.attachments?.[0]?.url,
          };
          dispatch(signDevis(isEditDevis ? devis.devisId : devis.id, toSave, callback, onError));
        } else {
          const toSave = {
            devisId: devis.id,
            message: details,
            attachments: fileUrl
              ? [
                  {
                    category: "AMENDEMENT",
                    name: "amendement",
                    url: fileUrl,
                  },
                ]
              : undefined,
          };
          if (isEditDevis) {
            const updatedDevis = {
              ...devis,
              message: details,
              attachments: [
                {
                  ...devis?.attachments?.[0],
                  url: fileUrl ? fileUrl : devis?.attachments?.[0]?.url,
                },
              ],
            };
            dispatch(updateAmendement(updatedDevis, callback, onError));
          } else {
            dispatch(sendAmendement(toSave, callback, onError));
          }
        }
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const toggleValidate = () => {
    setIsValidate(!isValidate);
  };

  const disabled =
    isClient && !isValidate
      ? !details.trim()
      : isEditDevis
      ? !details.trim()
      : !acceptedFiles?.length;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FormModalTitle
          title={`${
            isEditDevis ? "Modifier" : !isClient ? "Ajouter" : "Répondre à"
          } un dévis`}
          closeModal={handleClose}
          disabled={disabled}
          onConfirm={handleSubmit}
        />
        <Box p={4} pt={3} style={{ maxHeight: "85vh", overflow: "auto" }}>
          {!devis ? null : isClient ? (
            <FormControl sx={{ mb: isValidate ? 0 : 3 }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="valider"
                  control={<Radio />}
                  label="Valider"
                  checked={isValidate}
                  onChange={toggleValidate}
                />
                <FormControlLabel
                  value="amender"
                  control={<Radio />}
                  label="Amender"
                  checked={!isValidate}
                  onChange={toggleValidate}
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          <Box>
            <Typography
              color="black"
              sx={{
                fontSize: {
                  xs: 12,
                  md: 14,
                },
                fontWeight: 600,
                mb: 1,
              }}
            >
              Ajouter un commentaire
            </Typography>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              value={details}
              onChange={({ target }) => handleChange(target.value)}
              style={{
                width: "100%",
                borderRadius: 8,
                borderColor: "#bcbbbe",
                padding: 15,
              }}
            />
          </Box>

          <CustomDropzone
            title={!isValidate ? "le fichier" : "le dévis signé"}
            showAvatar={false}
            acceptedFiles={acceptedFiles}
            getRootProps={getRootProps({ className: "dropzone" })}
            getInputProps={getInputProps()}
          />
        </Box>
        <CustomLoader open={loading} />
      </Box>
    </Modal>
  );
}

CommandDevisModal.propTypes = {};

export default CommandDevisModal;
