import axios from "axios";
import { SERVER } from "../../utils/server";
import siteTypes from "./siteTypes";
import notificationTypes from "../notifications/notificationTypes";

export const createSiteType =
  (agent, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}siteType`, agent);
      if (results.data.isSuccess) {
        dispatch({
          type: siteTypes.CREATE_SITE_TYPE,
          payload: results.data.data,
        });
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Enregistré avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: { message: results.data.data.message },
        });
        onError?.();
      }
    } catch (error) {
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Site Type creation error", error);
    }
  };

export const listSiteTypes = () => async (dispatch) => {
  try {
    const results = await axios.get(`${SERVER}siteTypes`);
    dispatch({ type: siteTypes.LIST_SITE_TYPES, payload: results.data.data.filter(admin=>!admin.isDeleted) });
  } catch (error) {
    console.error("Site Type list error", error);
  }
};

export const deleteSiteType = (id) => async (dispatch) => {
  await axios.delete(`${SERVER}siteType/${id}`);

  dispatch({ type: siteTypes.REMOVE_SITE_TYPE, payload: id });
};

export const updateSiteType =
  (agent, callback, toastMessage = "Modifié avec succès", onError) =>
  async (dispatch) => {
    try {
      const results = await axios.put(`${SERVER}siteType`, agent);
      dispatch({
        type: siteTypes.UPDATE_SITE_TYPE,
        payload: results.data.data,
      });
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: toastMessage },
      });

      callback?.();
    } catch (error) {
      console.log("update site type error", error);
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
    }
  };
