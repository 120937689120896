import agentTypes from "./agentTypeTypes";

const agentTypeReducer = (state = {}, action) => {
  const payload = action.payload;
  if (action.type === agentTypes.CREATE_AGENT_TYPE) {
    return { [payload.id]: { ...payload }, ...state };
  }
  if (action.type === agentTypes.REMOVE_AGENT_TYPE) {
    const localState = { ...state };
    delete localState[payload];
    return { ...localState };
  }
  if (action.type === agentTypes.UPDATE_AGENT_TYPE) {
    return { ...state, [payload.id]: { ...payload } };
  }
  if (action.type === agentTypes.LIST_AGENT_TYPES) {
    let tempData = {};
    for (let obj of payload) {
      tempData[obj.id] = { ...obj };
    }
    return { ...tempData };
  }
  return state;
};

export default agentTypeReducer;
