import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { CustomButton, CustomKeyValue } from "../../../components";
import { COLORS } from "../../../utils";
import { EditNote, FileDownload, SendOutlined } from "@mui/icons-material";
import { SERVER } from "../../../utils/server";
import { getStatus } from "../../../utils/commandUtils";

function CommandDevisCard({
  devis,
  onEdit,
  onBonCommand,
  command,
  index,
  bonCommand,
}) {
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");

  const downloadDevis = (filename) => {
    const link = document.createElement("a");
    link.href = `${SERVER}download?fileName=${filename}`;
    link.download = "devis.pdf";
    link.click();
  };

  const amendements = devis?.amendements;
  const isClient = ["client", "sousCompteClient"].includes(
    agent?.authAccountType
  );

  const showRepondre =
    ["NEW", "PENDING"].includes(devis.status) &&
    ["client", "admin", "sousCompteClient", "superadmin"].includes(
      agent?.authAccountType
    ) &&
    !["VALIDATED"].includes(command.state);
  const showBonCommande =
    ["SIGNED"].includes(devis.status) && isClient && !bonCommand;
  const showSignedBon =
    ["SIGNED"].includes(devis.status) &&
    devis.attachments.filter((val) => val.category === "DEVIS_SIGNER").length;

  return (
    <Box mb={3}>
      <Typography
        fontSize={20}
        fontWeight="700"
        color={COLORS.primary}
        mb={4}
        sx={{ borderBottom: "1px solid #c2c2c2", width: "80px" }}
      >
        Dévis: {index}
      </Typography>

      <Box
        sx={{
          backgroundColor: "white",
          p: 3,
          borderRadius: 2,
          width: "65%",
        }}
      >
        <Stack direction={"row"} spacing={6} mb={2}>
          <CustomKeyValue
            title={"Date de création:"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
            }}
            rootStyle={{ width: 150 }}
            value={new Date(devis.createdAt).toLocaleDateString("fr-FR")}
          />
          <CustomKeyValue
            title={"Statut:"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
            }}
            value={getStatus(devis.status).name}
          />
        </Stack>
        <Stack direction={"row"} spacing={6} mb={2}>
          {devis?.version > 1 ? (
            <CustomKeyValue
              title={"Derniėre mise à jour:"}
              titleStyle={{
                color: "#57585A",
                fontSize: 13,
              }}
              valueStyle={{
                color: "#161718",
                fontWeight: "700",
                fontSize: 16,
              }}
              value={`${new Date(devis.updateAt).toLocaleDateString(
                "fr-FR"
              )} à ${new Date(devis.updateAt).toLocaleTimeString("fr-FR")}`}
            />
          ) : null}
          <CustomKeyValue
            title={"Commentaire:"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
            }}
            value={devis.details}
          />
        </Stack>
        <Stack direction={"row"} spacing={5} alignItems="flex-end">
          <CustomButton
            title="Télecharger le dévis"
            onClick={() => downloadDevis(devis.attachments[0]?.url)}
            rootStyle={{
              padding: 1.5,
              marginTop: 4,
            }}
            leftComponent={<FileDownload />}
          />
          {showSignedBon ? (
            <CustomButton
              title="Télecharger le dévis signé"
              onClick={() => downloadDevis(devis.attachments?.filter(val=>val.category==="DEVIS_SIGNER")[0]?.url)}
              rootStyle={{
                padding: 1.5,
                marginTop: 4,
              }}
              leftComponent={<FileDownload />}
            />
          ) : null}
          {bonCommand ? (
            <CustomButton
              title="Télecharger le bon"
              onClick={() => downloadDevis(bonCommand.attachments[0]?.url)}
              rootStyle={{
                padding: 1.5,
                marginTop: 4,
              }}
              leftComponent={<FileDownload />}
            />
          ) : null}
          {showRepondre && isClient ? (
            <CustomButton
              title={"Répondre"}
              onClick={() => onEdit(devis)}
              rootStyle={{
                padding: 1.5,
                marginTop: 4,
                backgroundColor: "#25634c",
              }}
              leftComponent={<EditNote />}
            />
          ) : null}
          {showRepondre && !isClient ? (
            <CustomButton
              title={"Modifier"}
              onClick={() => onEdit(devis, true)}
              rootStyle={{
                padding: 1.5,
                marginTop: 4,
                backgroundColor: "#ff7d00",
              }}
              leftComponent={<EditNote />}
            />
          ) : null}
          {showBonCommande ? (
            <CustomButton
              title="Bon de Commande"
              onClick={onBonCommand}
              rootStyle={{
                padding: 1.5,
                marginTop: 4,
                backgroundColor: "#0778fa",
              }}
              leftComponent={<SendOutlined />}
            />
          ) : null}
        </Stack>
        <Divider sx={{ my: 3 }} />
        {amendements?.map((amendement, index, array) => (
          <Box mt={3}>
            <Stack direction={"row"} spacing={6} mb={2}>
              <CustomKeyValue
                title={"Date de création:"}
                titleStyle={{
                  color: "#57585A",
                  fontSize: 13,
                }}
                valueStyle={{
                  color: "#161718",
                  fontWeight: "700",
                  fontSize: 16,
                }}
                rootStyle={{ width: 150 }}
                value={new Date(amendement.createdAt).toLocaleDateString(
                  "fr-FR"
                )}
              />
              <CustomKeyValue
                title={"Commentaire:"}
                titleStyle={{
                  color: "#57585A",
                  fontSize: 13,
                }}
                valueStyle={{
                  color: "#161718",
                  fontWeight: "700",
                  fontSize: 16,
                }}
                value={amendement.message}
              />
            </Stack>

            <Stack direction={"row"} spacing={5} alignItems="flex-end">
              {amendement.attachments[0]?.url ? (
                <CustomButton
                  title="Télecharger l'amendement"
                  onClick={() => downloadDevis(amendement.attachments[0]?.url)}
                  rootStyle={{
                    padding: 1.5,
                    marginTop: 2,
                  }}
                  leftComponent={<FileDownload />}
                />
              ) : null}
              {/* {showRepondre && isClient ? (
                <CustomButton
                  title={"Modifier"}
                  onClick={() => onEdit({...amendement, details: amendement.message, devisId: devis.id}, true)}
                  rootStyle={{
                    padding: 1.5,
                    marginTop: 2,
                    backgroundColor: "#ff7d00",
                  }}
                  leftComponent={<EditNote />}
                />
              ) : null} */}
            </Stack>
            {index !== array.length - 1 ? <Divider /> : null}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

CommandDevisCard.propTypes = {};

export default CommandDevisCard;
