import COLORS from "./colors";

export const getStatus = (status) => {
  switch (status) {
    case "NEW":
      return {
        name: "Nouveau",
        color: "#4CC9F0",
      };
    case "PROCESSING":
    case "PENDING":
      return {
        name: "En Traitement",
        color: "#F9B038",
      };
    case "PROCESSED":
      return {
        name: "Traité",
        color: "#FFD60A",
      };
    case "REJECTED":
      return {
        name: "Rejeté",
        color: "#B0B2B8",
      };
    case "CANCELED":
      return {
        name: "Annulé",
        color: "#D90429",
      };
    case "VALIDATED":
      return {
        name: "Validé",
        color: "#16DB65",
      };
    case "IN_EXCECUTION":
      return {
        name: "En exécution",
        color: "#F9B038",
      };
    case "FINALYZED":
      return {
        name: "Finalisé",
        color: "#16DB65",
      };
    case "SENT":
      return {
        name: "Nouveau",
        color: "#4CC9F0",
      };
    case "ACCEPTED":
      return {
        name: "Accepté",
        color: "#4CC9F0",
      };
    case "SIGNED":
      return {
        name: "Signé",
        color: "#4CC9F0",
      };

    default:
      return {
        name: "",
        color: "transparent",
      };
  }
};
