import React, { useEffect, useState } from "react";
import { Box, Modal, Stack, Typography } from "@mui/material";
import {
  AppRegistration,
  Lock,
  Mail,
  Phone,
  Public,
  WhatsApp,
} from "@mui/icons-material";
import SigninTextInput from "../../auth/components/SigninTextInput";
import FormModalTitle from "../../../components/FormModalTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { clientYup } from "../../../utils/yupValidation";
import { useDispatch } from "react-redux";
import { createClient, updateClient } from "../../../redux/clients/clientActions";
import SigninDropdown from "../../auth/components/SigninDropdown";
import { COUNTRIES } from "../../../utils/countries";
import SigninPhoneInput from "../../auth/components/SigninPhoneInput";
import CustomLoader from "../../../components/CustomLoader";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, md: 700, lg: 700 },
  bgcolor: "background.paper",
  borderRadius: 2,
};

function ClientFormModal({ open, item, toggleModal }) {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(null);

  const dispatch = useDispatch();
  const callback = () => {
    toggleModal();
  };

  const onError = () => {
    setLoading(false);
  };

  const { values, handleSubmit, errors, setValues, setFieldValue } = useFormik({
    initialValues: {
      raisonSociale: "",
      id: "",
      siret: "",
      phoneNumber: "",
      whatSApp: "",
      email: "",
      password: "",
      address: "",
      postalCode: "0000",
      city: "",
      country: "France",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape(clientYup),
    onSubmit(values) {
      setLoading(true);
      if (values.id !== "") {
        let user = { ...values };
        delete user.email;
        dispatch(updateClient({ client: user, callback, onError }));
      } else {
        const user = {
          ...values,
          isActive: true,
          id: undefined,
        };
        dispatch(createClient({ client: user, callback, onError }));
      }
    },
  });

  const onAddressChange=(val)=>{
    setAddress(val);
    setFieldValue("address", val.label);
    setFieldValue("complementAddress", val.value.reference);
    geocodeByPlaceId(val.value.reference)
    .then(results => setFieldValue("city", results[0]?.address_components.filter(val=> val.types.includes("locality"))?.[0]?.long_name))
    .catch(error => console.error(error));
  }

  useEffect(() => {
    if (item) {
      setValues(item);
      const { adressCompleted } = item;
      setFieldValue("complementAddress", adressCompleted.complementAddress);
      setFieldValue("address", adressCompleted.address);
      setFieldValue("postalCode", adressCompleted.postalCode);
      setFieldValue("city", adressCompleted.city);
      setFieldValue("country", adressCompleted.country);
    }
  }, [item, setFieldValue, setValues]);

  const disabled =
    !values.email.trim() ||
    !values.phoneNumber.trim() ||
    !values.password.trim() ||
    !values.city.trim() ||
    !values.country.trim() ||
    !values.raisonSociale.trim() ||
    !values.siret.trim() ||
    values.password !== values.confirmPassword;

  return (
    <Modal
      open={open}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit}>
        <Box sx={style}>
          <FormModalTitle
            title={`${values.id ? "Modifier" : "Ajouter"} un client`}
            closeModal={toggleModal}
            disabled={disabled}
          />
          <Box
            px={{ xs: 2, md: 4, lg: 4 }}
            pt={0}
            style={{ maxHeight: "85vh", overflow: "auto" }}
          >
            <Stack
              direction={{ xs: "column", md: "row", lg: "row" }}
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <SigninTextInput
                title={"Raison sociale*"}
                value={values.raisonSociale}
                id="raisonSociale"
                name="raisonSociale"
                onChange={(value) => setFieldValue("raisonSociale", value)}
                errorMessage={errors.raisonSociale}
              />
              <SigninTextInput
                title={"SIRET*"}
                value={values.siret}
                id="siret"
                name="siret"
                onChange={(value) => setFieldValue("siret", value)}
                errorMessage={errors.siret}
                icon={<AppRegistration />}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row", lg: "row" }}
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <SigninPhoneInput
                title={"Téléphone*"}
                value={values.phoneNumber}
                id="phoneNumber"
                name="phoneNumber"
                onChange={(value) => setFieldValue("phoneNumber", value)}
                errorMessage={errors.phoneNumber}
                icon={<Phone />}
              />
              <SigninTextInput
                title={"WhatsApp"}
                value={values.whatSApp}
                id="whatSApp"
                name="whatSApp"
                onChange={(value) => setFieldValue("whatSApp", value)}
                errorMessage={errors.whatSApp}
                icon={<WhatsApp />}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row", lg: "row" }}
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <SigninTextInput
                title={"Email*"}
                value={values.email}
                id="email"
                name="email"
                onChange={(value) => setFieldValue("email", value)}
                errorMessage={errors.email}
                icon={<Mail />}
              />
              {/* <SigninTextInput
                title={"Adresse"}
                value={values.address}
                id="address"
                name="address"
                onChange={(value) => setFieldValue("address", value)}
                errorMessage={errors.address}
                icon={<LocationOn />}
              /> */}
              <Box width="100%">
              <Typography
                color="black"
                sx={{
                  fontSize: {
                    xs: 12,
                    md: 14,
                  },
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                Adresse *
              </Typography>
              <GooglePlacesAutocomplete
                selectProps={{
                  value: address,
                  onChange: onAddressChange,
                  placeholder: "Adresse",
                  styles: {
                    container: (provided) => ({
                      ...provided,
                      width: "100%",
                      height: "45px",
                      marginBottom: "15px",
                    }),
                    input: (provided) => ({
                      ...provided,
                      width: "100%",
                      height: "45px",
                    }),
                    option: (provided) => ({
                      ...provided,
                    }),
                  },
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                  country: ['fr'],
                  }
                }}
              />
            </Box>
            </Stack>
            {/* <Stack
              direction={{ xs: "column", md: "row", lg: "row" }}
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <SigninTextInput
                title={"Complement Adresse"}
                value={values.complementAddress}
                id="complementAddress"
                name="complementAddress"
                onChange={(value) => setFieldValue("complementAddress", value)}
                icon={<LocationOn />}
                errorMessage={errors.complementAddress}
              />
              <SigninTextInput
                title={"Code Postal*"}
                value={values.postalCode}
                id="postalCode"
                name="postalCode"
                onChange={(value) => setFieldValue("postalCode", value)}
                errorMessage={errors.postalCode}
                icon={<QrCode />}
              />
            </Stack> */}

            <Stack
              direction={{ xs: "column", md: "row", lg: "row" }}
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <SigninDropdown
                title={"Pays*"}
                icon={<Public />}
                value={values.country}
                id="country"
                name="country"
                data={COUNTRIES}
                disabled
                onChange={(value) => setFieldValue("country", value)}
                errorMessage={errors.country}
              />
              <SigninTextInput
                title={"Ville*"}
                icon={<Public />}
                value={values.city}
                id="city"
                name="city"
                onChange={(value) => setFieldValue("city", value)}
                errorMessage={errors.city}
              />
            </Stack>

            {!values.id ? (
              <Stack
                direction={{ xs: "column", md: "row", lg: "row" }}
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Mot de passe*"}
                  icon={<Lock />}
                  value={values.password}
                  id="password"
                  name="password"
                  type="password"
                  onChange={(value) => setFieldValue("password", value)}
                  errorMessage={errors.password}
                />
                <SigninTextInput
                  title={"Confirmer Mot de passe"}
                  icon={<Lock />}
                  value={values.confirmPassword}
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  onChange={(value) => setFieldValue("confirmPassword", value)}
                  errorMessage={errors.confirmPassword}
                />
              </Stack>
            ) : null}
          </Box>
        </Box>
        <CustomLoader open={loading} />
      </form>
    </Modal>
  );
}

ClientFormModal.propTypes = {};

export default ClientFormModal;
