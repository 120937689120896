import React from "react";
import { Box, Grid, Modal } from "@mui/material";
import { CustomKeyValue, DetailModalTitle } from "../../../components";
import { COLORS } from "../../../utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, md: 650, lg: 650 },
  bgcolor: "background.paper",
  borderRadius: 2,
  p: 3,
};

function AgentDetailModal({ data, open, onClose }) {
  let adressCompleted;
  if (data.adressCompleted) {
    adressCompleted = data.adressCompleted;
  } else {
    adressCompleted = data.address;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <DetailModalTitle
          title={`Détails de l'utilisateur`}
          onClose={onClose}
        />

        <Box
          sx={{
            backgroundColor: "#D9DBDD1A",
            width: "100%",
            height: "100%",
            borderRadius: 2,
            p: 3,
            mb: 4,
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 5 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {data.lastname ? (
              <>
                <Grid item xs={2} sm={4} md={4}>
                  <CustomKeyValue
                    title={"Nom"}
                    value={data.lastname}
                    titleStyle={{ color: "#57585A" }}
                    valueStyle={{
                      fontWeight: 700,
                      color: COLORS.primary,
                      wordWrap: "break-word",
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <CustomKeyValue
                    title={"Prénom"}
                    value={data.firstname}
                    titleStyle={{ color: "#57585A" }}
                    valueStyle={{
                      fontWeight: 700,
                      color: COLORS.primary,
                      wordWrap: "break-word",
                    }}
                  />
                </Grid>
              </>
            ) : null}
            {data.raisonSociale ? (
              <>
                <Grid item xs={2} sm={4} md={4}>
                  <CustomKeyValue
                    title={"Raison sociale"}
                    value={data.raisonSociale}
                    titleStyle={{ color: "#57585A" }}
                    valueStyle={{
                      fontWeight: 700,
                      color: COLORS.primary,
                      wordWrap: "break-word",
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <CustomKeyValue
                    title={"SIRET"}
                    value={data.siret}
                    titleStyle={{ color: "#57585A" }}
                    valueStyle={{
                      fontWeight: 700,
                      color: COLORS.primary,
                      wordWrap: "break-word",
                    }}
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Téléphone"}
                value={data.phoneNumber}
                titleStyle={{ color: "#57585A" }}
                valueStyle={{
                  fontWeight: 700,
                  color: COLORS.primary,
                  wordWrap: "break-word",
                }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Email"}
                value={`${data.email}`}
                titleStyle={{ color: "#57585A" }}
                valueStyle={{
                  fontWeight: 700,
                  color: COLORS.primary,
                  wordWrap: "break-word",
                }}
              />
            </Grid>
            {data.nationalId ? (
              <Grid item xs={2} sm={4} md={4}>
                <CustomKeyValue
                  title={"Nº Pièce d’identité"}
                  value={data.nationalId}
                  titleStyle={{ color: "#57585A" }}
                  valueStyle={{
                    fontWeight: 700,
                    color: COLORS.primary,
                    wordWrap: "break-word",
                  }}
                />
              </Grid>
            ) : null}
            {data.whatSApp ? (
              <Grid item xs={2} sm={4} md={4}>
                <CustomKeyValue
                  title={"Whatsapp"}
                  value={data.whatSApp}
                  titleStyle={{ color: "#57585A" }}
                  valueStyle={{
                    fontWeight: 700,
                    color: COLORS.primary,
                    wordWrap: "break-word",
                  }}
                />
              </Grid>
            ) : null}
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Adresse"}
                value={data.adressCompleted?.address || "--"}
                titleStyle={{ color: "#57585A" }}
                valueStyle={{
                  fontWeight: 700,
                  color: COLORS.primary,
                  wordWrap: "break-word",
                }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Complement Adresse"}
                value={data.adressCompleted?.complementAddress || "--"}
                titleStyle={{ color: "#57585A" }}
                valueStyle={{
                  fontWeight: 700,
                  color: COLORS.primary,
                  wordWrap: "break-word",
                }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Code Postal"}
                value={data.adressCompleted?.postalCode || "--"}
                titleStyle={{ color: "#57585A" }}
                valueStyle={{
                  fontWeight: 700,
                  color: COLORS.primary,
                  wordWrap: "break-word",
                }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Pays"}
                value={data.adressCompleted?.country || "--"}
                titleStyle={{ color: "#57585A" }}
                valueStyle={{
                  fontWeight: 700,
                  color: COLORS.primary,
                  wordWrap: "break-word",
                }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Ville"}
                value={data.adressCompleted?.city || "--"}
                titleStyle={{ color: "#57585A" }}
                valueStyle={{
                  fontWeight: 700,
                  color: COLORS.primary,
                  wordWrap: "break-word",
                }}
              />
            </Grid>
            {data.client?.raisonSociale ? <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Client"}
                value={data.client?.raisonSociale}
                titleStyle={{ color: "#57585A" }}
                valueStyle={{
                  fontWeight: 700,
                  color: COLORS.primary,
                  wordWrap: "break-word",
                }}
              />
            </Grid>: null}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}

AgentDetailModal.propTypes = {};

export default AgentDetailModal;
