import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  Badge,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { Outlet, useNavigate } from "react-router-dom";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { makeStyles, styled } from "@mui/styles";
import { Add, Close, ExitToApp } from "@mui/icons-material";

import routes from "../Router/routes";

import SidebarMenu from "../components/SidebarMenu";
import { CustomButton, CustomConfirm } from "../components";
import { LOGO, MenuSVG, NotificationSVG, SettingSVG } from "../utils/svgImages";
import PrivateRoute from "../Router/PrivateRoute";
import Settings from "../views/settings/Settings";
import NotificationCard from "../components/NotificationCard";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  archiveNotification,
  listNotifications,
  setNotificationRead,
} from "../redux/notifications/notificationActions";
import SiteFormModal from "../views/sites/components/SiteFormModal";
import { listAgents } from "../redux/agents/agentActions";
import { listAgentTypes } from "../redux/agentType/agentTypeActions";
import { listServiceTypes } from "../redux/serviceType/serviceTypeActions";
import { listSites } from "../redux/sites/sitesActions";
import { listClients } from "../redux/clients/clientActions";
import { listSiteTypes } from "../redux/siteType/siteTypeActions";
import { listOperationnels } from "../redux/operationnels/operationnelActions";
import {
  listCommands,
  listOperatorCommands,
} from "../redux/commands/commandActions";
import notificationTypes from "../redux/notifications/notificationTypes";

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
  },
  drawer: {
    // [theme.breakpoints.up("sm")]: {
    //   width: drawerWidth,
    // flexShrink: 0
    // },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: "#fff",
    boxShadow: "none",
    height: 100,
    justifyContent: "center",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    maxWidth: "100%",
    // margin: theme.spacing(0, 3),
    overflow: "hidden",
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MainLayout = (props) => {
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");
  const currentUrl = window.location.pathname;

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(!isMobile);
  const [confirmModal, setConfirmModal] = useState(false);
  const [notifyVisible, setNotifyVisible] = useState(false);
  const [currentNotifTab, setCurrentNotifTab] = useState("all");
  const [openModal, setOpenModal] = useState(false);

  const notifications = useSelector((state) => state.notifications);
  const filteredNotifications = notifications?.allNotifications
    ?.filter((notif) =>
      currentNotifTab === "notread"
        ? !notif.itIsAlreadyRead && !notif.itIsArchive
        : currentNotifTab === "archived"
        ? notif.itIsArchive
        : notif
    )
    .sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1));
  const notificationsBadge = notifications?.allNotifications?.filter(
    (notif) => !notif.itIsAlreadyRead
  );

  const confirmTitle = "Voulez-vous vraiment vous déconnecter? ";

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const toggleNotification = () => {
    setNotifyVisible(!notifyVisible);
  };

  const callback = () => {
    getNotifs();
    dispatch({
      type: notificationTypes.SHOW_SUCCESS,
      payload: { message: "Opération réussi" },
    });
  };

  const getNotifs = useCallback(() => {
    dispatch(
      listNotifications(String(agent?.authAccountType).toUpperCase(), agent?.id)
    );
  }, [dispatch, agent?.id, agent?.authAccountType]);

  const getCommands = useCallback(() => {
    if (agent?.authAccountType === "sousCompteClient" && !agent.rules?.includes("CLIENT_ADMIN")) {
      dispatch(listOperatorCommands());
    } else {
      dispatch(
        listCommands(
          ["superadmin", "admin"].includes(agent?.authAccountType)
            ? null
            : agent.rules?.includes("CLIENT_ADMIN")
            ? agent?.client?.id
            : agent.id
        )
      );
    }
  }, [
    agent?.authAccountType,
    agent?.client?.id,
    agent.id,
    agent.rules,
    dispatch,
  ]);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const archiveNotif = (ownerEntityId, notificationId) => {
    dispatch(
      archiveNotification(
        {
          ownerEntityId: ownerEntityId,
          notificationId: notificationId,
        },
        callback
      )
    );
  };

  const setReadNotif = (ownerEntityId, notificationId) => {
    dispatch(
      setNotificationRead(
        {
          ownerEntityId: ownerEntityId,
          notificationId: notificationId,
        },
        callback
      )
    );
  };

  const routeToShow = () => {
    switch (agent?.authAccountType) {
      case "superadmin":
        return [
          "home",
          "commands",
          "sites",
          "admins",
          "clients",
          "agents",
          "agent-types",
          "service-types",
        ];
      case "admin":
        return [
          "home",
          "commands-admin",
          "sites",
          "clients",
          "agents",
          "agent-types",
          "service-types",
          "operationnels",
        ];
      case "agent":
        return ["boncommands"];
      case "client":
        return ["home", "commands", "sites", "operationnels"];
      case "sousCompteClient":
        return ["home", "commands", "sites"];

      default:
        break;
    }
  };

  const signout = () => {
    navigate("/");
    localStorage.clear();
  };

  const drawer = (
    <Stack
      sx={{
        height: "100%",
        overflow: "hidden",
        backgroundColor: "activeBlue",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          height: 100,
          mx: 3,
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #D9DBDD",
        }}
      >
        <div>
          <img
            src={"/logo_white.png"}
            alt="Avatar"
            style={{
              width: 216,
              height: 51,
            }}
          />
        </div>
      </Box>
      <Stack
        style={{
          height: "55%",
          overflow: "auto",
          alignItems: "center",
          // display: "flex",
        }}
        justifyContent={
          routes?.filter((route) => routeToShow()?.includes(route.key))
            ?.length < 4
            ? "center"
            : "flex-start"
        }
      >
        {routes
          ?.filter((route) => routeToShow()?.includes(route.key))
          ?.map((route, index) => (
            <SidebarMenu
              item={{
                ...route,
                name:
                  agent?.authAccountType === "admin" && route.key === "commands"
                    ? "Commandes"
                    : agent?.authAccountType === "admin" &&
                      route.key === "sites"
                    ? "Sites"
                    : route.name,
              }}
              key={index}
              closeDrawer={handleDrawerToggle}
            />
          ))}
      </Stack>
      <Box
        sx={{
          // height: "35%",
          overflow: "auto",
          py: 4,
          alignItems: "center",
          display: "flex",
        }}
      >
        <div style={{ width: "100%" }}>
          <Divider sx={{ backgroundColor: "#D9DBDD", mx: 6, ml: 4, mb: 2 }} />
          <SidebarMenu
            item={{
              name: "Paramètres",
              icon: <SettingSVG style={{ width: 25, height: 25 }} />,
              path: "/home/settings",
              key: "settings",
              component: (
                <PrivateRoute name="settings">
                  <Settings />
                </PrivateRoute>
              ),
            }}
            dividerBottom={false}
            closeDrawer={handleDrawerToggle}
          />
          {/* <Typography sx={{ color: "#D9DBDD" }}>Profil</Typography>

        <Button
          sx={{ textTransform: "none", textAlign: "left" }}
          onClick={() => navigate("/home/profile")}
        >
          <CardHeader
            avatar={
              <Avatar
                style={{
                  width: 40,
                  height: 40,
                }}
              />
            }
            sx={{ padding: 0, mb: 4, mt: 2 }}
            title={
              agent?.firstname
                ? `${agent?.firstname} ${agent?.lastname}`
                : agent?.raisonSociale
            }
            subheader={agent?.adressCompleted?.city}
            titleTypographyProps={{ sx: { color: "white", fontWeight: 600 } }}
            subheaderTypographyProps={{
              sx: { color: "white", fontWeight: 400, fontSize: 12 },
            }}
          />
        </Button> */}

          <CustomButton
            title={"Déconnexion"}
            onClick={() => setConfirmModal(true)}
            leftComponent={<ExitToApp sx={{ mr: 1 }} htmlColor="#161928" />}
            titleStyle={{ color: "#161928" }}
            rootStyle={{
              backgroundColor: "#64677A",
              marginLeft: 4,
              marginTop: 2,
            }}
          />
        </div>
      </Box>
    </Stack>
  );

  useEffect(() => {
    getNotifs();
  }, [getNotifs]);

  useEffect(() => {
    getCommands();
  }, [getCommands]);

  useEffect(() => {
    batch(() => {
      dispatch(listAgents());
      dispatch(listAgentTypes());
      dispatch(listServiceTypes());
      dispatch(listSites());
      dispatch(listClients());
      dispatch(listSiteTypes());
      dispatch(listOperationnels());
    });
  }, [dispatch]);

  return (
    <div>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" open={open} className={classes.appBar}>
          <Toolbar
            sx={{
              display: {
                xs: "none",
                md: "flex",
                lg: "flex",
              },
              justifyContent: "space-between",
            }}
            variant="dense"
          >
            {/* <IconButton onClick={handleDrawerToggle}
              style={{backgroundColor: "white", position: "absolute", left: -10}}
            >
              <ChevronLeft />
            </IconButton> */}
            <Typography color={"#363839"} fontSize={28} fontWeight={"600"}>
              Bienvenue, {agent?.firstname || agent?.raisonSociale}
            </Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Badge
                badgeContent={notificationsBadge?.length}
                max={9}
                sx={{
                  ".MuiBadge-badge": {
                    backgroundColor: "#cb1827",
                  },
                }}
              >
                <IconButton sx={{ padding: 0 }} onClick={toggleNotification}>
                  <NotificationSVG />
                </IconButton>
              </Badge>

              {["client", "admin", "sousCompteClient"].includes(
                agent?.authAccountType
              ) ? (
                <CustomButton
                  title={
                    currentUrl === "/home/my-sites"
                      ? "Nouveau site"
                      : "Nouvelle commande"
                  }
                  onClick={() =>
                    currentUrl === "/home/my-sites"
                      ? toggleModal()
                      : navigate("/home/command-new")
                  }
                  titleStyle={{
                    fontSize: "13px",
                    textTransform: "none",
                    fontWeight: "600",
                    marginRight: 1,
                  }}
                  rootStyle={{
                    backgroundColor: "#161B38",
                    height: 42,
                    marginLeft: 4,
                  }}
                  rightComponent={<Add />}
                />
              ) : null}
            </div>
          </Toolbar>

          <Toolbar
            sx={{
              display: {
                xs: "flex",
                md: "none",
                lg: "none",
              },
              justifyContent: "space-between",
            }}
            variant="dense"
          >
            <IconButton
              color="inherit"
              edge="start"
              onClick={toggleNotification}
            >
              <NotificationSVG
                style={{
                  width: 30,
                }}
              />
            </IconButton>

            <IconButton onClick={() => navigate("/")}>
              <LOGO
                style={{
                  width: 40,
                }}
              />
            </IconButton>

            <IconButton color="inherit" edge="end" onClick={handleDrawerToggle}>
              <MenuSVG
                style={{
                  width: 30,
                }}
              />
            </IconButton>
          </Toolbar>
        </AppBar>

        <MobileView>
          <nav className={classes.drawer} style={{ width: 0 }}>
            <Drawer
              sx={{
                width: open ? drawerWidth : 0,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: open ? drawerWidth : 0,
                  boxSizing: "border-box",
                },
              }}
              variant="persistent"
              anchor="left"
              open={open}
            >
              {drawer}
            </Drawer>
          </nav>
        </MobileView>
        <BrowserView>
          <nav className={classes.drawer}>
            <Drawer
              sx={{
                width: open ? drawerWidth : 0,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: open ? drawerWidth : 0,
                  boxSizing: "border-box",
                },
              }}
              variant="persistent"
              anchor="left"
              open={open}
            >
              {drawer}
            </Drawer>
          </nav>
        </BrowserView>

        {/* <nav className={classes.drawer}>
          <Hidden xsDown implementation="css">
            <Drawer
              sx={{
                width: open ? drawerWidth : 0,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: open ? drawerWidth : 0,
                  boxSizing: "border-box",
                },
              }}
              variant="persistent"
              anchor="left"
              open={open}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav> */}

        <main className={classes.content}>
          <div className={classes.toolbar} />

          <Outlet />
          <CustomConfirm
            showIcon={false}
            visible={confirmModal}
            onClose={() => setConfirmModal(false)}
            onConfirm={signout}
            title={confirmTitle}
          />
        </main>
      </div>

      <Drawer
        anchor={"right"}
        open={notifyVisible}
        onClose={toggleNotification}
        sx={{
          "& .MuiDrawer-paper": {
            width: {
              xs: notifyVisible ? "100%" : 0,
              md: notifyVisible ? 500 : 0,
              lg: notifyVisible ? 500 : 0,
            },
            backgroundColor: "activeBlue",
            height: "100%",
            overflow: "hidden",
          },
        }}
      >
        <Box>
          <Stack direction={"row"} alignItems="center" spacing={5} m={3}>
            <IconButton
              sx={{ backgroundColor: "white", "&:hover": {
                backgroundColor: "#f2f2f2",
              } }}
              onClick={toggleNotification}
            >
              <Close />
            </IconButton>
            <Typography color="white" fontSize={28} fontWeight="600">
              Notifications
            </Typography>
          </Stack>

          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"center"}
            spacing={5}
            sx={{ borderBottom: "1px solid #fff" }}
          >
            <CustomButton
              title={"Tout"}
              rootStyle={{
                borderBottom: `${
                  currentNotifTab === "all" ? "3px" : "0px"
                } solid #fff`,
                borderRadius: 0,
                backgroundColor: "transparent",
              }}
              onClick={() => setCurrentNotifTab("all")}
            />
            <CustomButton
              title={"Non lu"}
              rootStyle={{
                borderBottom: `${
                  currentNotifTab === "notread" ? "3px" : "0px"
                } solid #fff`,
                borderRadius: 0,
                backgroundColor: "transparent",
              }}
              onClick={() => setCurrentNotifTab("notread")}
            />
            <CustomButton
              title={"Archivé"}
              rootStyle={{
                borderBottom: `${
                  currentNotifTab === "archived" ? "3px" : "0px"
                } solid #fff`,
                borderRadius: 0,
                backgroundColor: "transparent",
              }}
              onClick={() => setCurrentNotifTab("archived")}
            />
          </Stack>
          <Box sx={{ overflow: "auto", height: "85vh" }}>
            {filteredNotifications?.length ? (
              filteredNotifications.map((notif) => (
                <NotificationCard
                  key={notif.id}
                  data={notif}
                  onRead={setReadNotif}
                  onArchive={archiveNotif}
                />
              ))
            ) : (
              <Typography color="#f2f2f2" textAlign="center" mt={5}>
                Aucune nouvelle notification
              </Typography>
            )}
          </Box>
        </Box>
      </Drawer>
      {openModal && <SiteFormModal open={openModal} onClose={toggleModal} />}
    </div>
  );
};

MainLayout.propTypes = {
  container: PropTypes.object,
};

export default MainLayout;
