
const DAYS_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// return date from timestamp
export const fromTimestampToDate = (timestamp) => {
  if (Math.abs(timestamp)) {
    return new Date(timestamp);
  }
};

export const sub = (date, days = 0) => {
  if (date?.valueOf()) {
    const local = new Date();
    const result = local.setDate(date.getDate() - days);
    return new Date(result);
  }
  return null;
};

// compare two date objects
export const isToDay = (date, localDate) => {
  if (
    localDate?.getFullYear() === date?.getFullYear() &&
    localDate?.getMonth() === date?.getMonth() &&
    localDate?.getDate() === date?.getDate()
  ) {
    return true;
  } else {
    return false;
  }
};

export const compare = (a, b = 10) => {
  return a < b ? 0 : "";
};

// return a string that represents the date
export const fromTimeToString = (timestamp, format) => {
  if (!timestamp) {
    return;
  }
  let date = fromTimestampToDate(timestamp);
  let hour;
  let suffix;
  if (format === "hh:mm:ss W") {
    return `${compare(date?.getHours())}${date?.getHours()}h :${compare(
      date?.getMinutes()
    )}${date?.getMinutes()}m :${compare(
      date?.getSeconds()
    )}${date?.getSeconds()}s`;
  } else if (format === "hh:mm:ss") {
    return `${compare(date?.getHours())}${date?.getHours()}:${compare(
      date?.getMinutes()
    )}${date?.getMinutes()}`;
  } else {
    if (date?.getHours() > 12) {
      hour = date?.getHours() % 12;
      suffix = "pm";
    } else {
      suffix = "am";
      hour = date?.getHours();
    }
    return `${compare(date?.getHours())}${hour}:${compare(
      date?.getMinutes()
    )}${date?.getMinutes()} ${suffix}`;
  }
};

export const fromDateToString = (timestamp, format) => {
  if (!timestamp || isNaN(Number(timestamp))) {
    return;
  }
  const date = fromTimestampToDate(timestamp);
  if (format === "dd/MM/yyyy") {
    return `${compare(date?.getDate())}${date?.getDate()}/${compare(
      date?.getMonth() + 1
    )}${date?.getMonth() + 1}/${date?.getFullYear()}`;
  } else if (format === "dd.MM.yyyy") {
    return `${compare(date?.getDate())}${date?.getDate()}.${compare(
      date?.getMonth() + 1
    )}${date?.getMonth() + 1}.${date?.getFullYear()}`;
  } else if (format === "NOD NOM dd yyyy") {
    return `${DAYS_OF_WEEK[date?.getDay()]?.slice(0, 3)} ${MONTHS[
      date?.getMonth()
    ]?.slice(0, 3)} ${date?.getDate()} ${date?.getFullYear()}`;
  } else if (format === "NOM dd yyyy") {
    return `${MONTHS[date?.getMonth()]?.slice(
      0,
      3
    )} ${date?.getDate()} ${date?.getFullYear()}`;
  } else if (format === "dd MM yyyy") {
    return `${compare(date?.getDate())}${date?.getDate()} ${compare(
      date?.getMonth() + 1
    )}${MONTHS[date?.getMonth()]} ${date?.getFullYear()}`;
  } else if (format === "dd MM") {
    return `${compare(date?.getDate())}${date?.getDate()} ${MONTHS[
      date?.getMonth()
    ]?.slice(0, 3)}`;
  } else if (format === "MM dd") {
    return `${MONTHS[date?.getMonth()]?.slice(0, 3)} ${date?.getDate()}`;
  }
  return `${date?.getFullYear()} / ${compare(date?.getMonth() + 1)}${
    date?.getMonth() + 1
  } / ${compare(date?.getDate())}${date?.getDate()}`;
};

// return formatted date like with some description
export const showFormat = (timestamp) => {
  if (timestamp) {
    let toDay = new Date();
    const { years, days, months, hours, minutes } = timeCounter(
      timestamp,
      toDay.valueOf()
    );

    let message = "";

    if (years) {
      message = years + " Year(s)";
    }
    if (months) {
      message = years
        ? message + ` and ${months} Month(s)`
        : `${months} Month(s)`;
    }

    if (days) {
      message = months ? message + ` and ${days} Day(s)` : `${days} Days`;
    }

    if (hours) {
      if (!(years || months)) {
        message = days ? message + ` ${hours} Hour(s)` : hours + " hour(s)";
      }
    }

    if (minutes) {
      if (!(years || months || days)) {
        message = hours ? message + ` ${minutes} Min` : `${minutes} Min`;
      }
    }

    return message ? `${message} ago` : "now";
  }
};

export const getMessage = (date, localDate) => {
  if (isToDay(date, localDate)) {
    return "today";
  } else {
    localDate?.setDate(localDate?.getDate() - 1);
    if (isToDay(date, localDate)) {
      return "yesterday";
    } else {
      let retDate = MONTHS[date?.getMonth()] + " " + date?.getDate();
      if (date?.getDate() % 10 === 1) {
        return retDate + "st";
      } else if (date?.getDate() % 10 === 2) {
        return retDate + "nd";
      } else if (date?.getDate() % 10 === 3) {
        return retDate + "rd";
      } else {
        return retDate + "th";
      }
    }
  }
};

export const formatName = (name) => {
  let formatted = name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase().concat(word?.slice(1)));
  return formatted.join(" ");
};

export const timeCounter = (startStamp, newStamp) => {
  let diff = Math.round((newStamp - startStamp) / 1000);
  let years = Math.floor(diff / (365 * 24 * 60 * 60));
  diff = diff - years * 365 * 24 * 60 * 60;
  let months = Math.floor(diff / (30 * 24 * 60 * 60));
  diff = diff - months * 30 * 24 * 60 * 60;
  let d = Math.floor(diff / (24 * 60 * 60));
  diff = diff - d * 24 * 60 * 60;
  let h = Math.floor(diff / (60 * 60));
  diff = diff - h * 60 * 60;
  let m = Math.floor(diff / 60);
  diff = diff - m * 60;
  let s = diff;

  return {
    years,
    months,
    days: d,
    hours: h,
    minutes: m,
    seconds: s,
  };
};

export const timestampWithoutHour = (timestamp) => {
  const date = new Date(timestamp);
  let newTimestamp = new Date(
    date?.getFullYear(),
    date?.getMonth(),
    date?.getDate()
  );
  return newTimestamp.valueOf();
};

export const getCurrentMonth = () => {
  return MONTHS[new Date().getMonth()];
};

export const getDateDifference = (
  endDate,
  startDate = Date.now(),
  output = "days"
) => {
  const diffInMs = new Date(endDate) - new Date(startDate);
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  if (output !== "days") {
    return diffInDays / 30;
  }
  return diffInDays;
};

export const addDaystoDate = (days, startDate = Date.now()) => {
  return days * (1000 * 60 * 60 * 24) + new Date(startDate).valueOf();
};

export const shouldDisplayDispatch = (daysLeft) => {
  if (daysLeft) {
    return true;
  }

  const count = Number(daysLeft);

  if (count <= 5) {
    return true;
  }

  switch (count) {
    case 14:
      return true;
    case 12:
      return true;
    case 10:
      return true;
    case 7:
      return true;
    default:
      return false;
  }
};

export const getDaysOfWeekBetweenDates = (
  sDate = "2021-07-18",
  eDate = "2021-07-20"
) => {
  const startDate = new Date(sDate);
  const endDate = new Date(eDate);

  endDate.setDate(endDate.getDate() + 1);

  const differenceInMilliseconds = Math.abs(
    endDate.getTime() - startDate.getTime()
  );
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  const daysOfWeek = [];

  let i = 0;

  while (i < differenceInDays && startDate < endDate) {
    daysOfWeek.push(startDate.getDay());
    startDate.setDate(startDate.getDate() + 1);
    i++;
  }

  return daysOfWeek;
};

export const getDaysOfWeekBetweenDatesFull = (
  sDate = "2021-07-18",
  eDate = "2021-07-20"
) => {
  const startDate = new Date(sDate);
  const endDate = new Date(eDate);

  endDate.setDate(endDate.getDate() + 1);

  const differenceInMilliseconds = Math.abs(
    endDate.getTime() - startDate.getTime()
  );
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  const daysOfWeek = [];

  let i = 0;

  while (i < differenceInDays && startDate < endDate) {
    const currentDate = new Date(startDate);
    daysOfWeek.push({
      day: currentDate.getUTCDay(),
      date: currentDate,
    });
    startDate.setDate(startDate.getDate() + 1);
    i++;
  }

  return daysOfWeek;
};

export function dayOfWeekAsString(dayIndex) {
  return (
    ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"][
      dayIndex
    ] || ""
  );
}

export const daySorter = {
  lundi: 1,
  mardi: 2,
  mercredi: 3,
  jeudi: 4,
  vendredi: 5,
  samedi: 6,
  dimanche: 7,
};

export function usDateFormatter(date){
  const formattedDate = date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  
  const [datePart, timePart] = formattedDate.split(', ');
  const [month, day, year] = datePart.split('/');
  const [hour, minute, second] = timePart.split(':');
  
  const finalFormattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  return finalFormattedDate;
}

export function differentBetweenDate (startDate, endDate, interval) {
  var second = 1000,
    minute = second * 60,
    hour = minute * 60,
    day = hour * 24,
    week = day * 7;
  startDate = new Date(startDate);
  endDate = new Date(endDate);
  var timediff = endDate - startDate;
  if (isNaN(timediff)) return NaN;
  switch (interval) {
    case "years":
      return endDate.getFullYear() - startDate.getFullYear();
    case "months":
      return (
        endDate.getFullYear() * 12 +
        endDate.getMonth() -
        (startDate.getFullYear() * 12 + startDate.getMonth())
      );
    case "weeks":
      return Math.floor(timediff / week);
    case "days":
      return Math.floor(timediff / day);
    case "hours":
      return Math.floor(timediff / hour);
    case "minutes":
      return Math.floor(timediff / minute);
    case "seconds":
      return Math.floor(timediff / second);
    default:
      return undefined;
  }
};