import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { batch, useDispatch, useSelector } from "react-redux";
import { FileSVG, SearchSVG } from "../../utils/svgImages";
import AgentPropositionCard from "./components/AgentPropositionCard";
import { CustomButton, CustomConfirm } from "../../components";
import { COLORS } from "../../utils";
import {
  clientConfirmResponse,
  clientGetResponseLine,
  clientValidateRejectApprobation,
} from "../../redux/commandProcessing/commandProcessingActions";

function AgentProposed() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const agents = useSelector((state) => state.agents);
  const responseLines = useSelector(
    (state) => state.commandProcessing?.responseLines
  );

  const responseLine = responseLines?.comandLines?.filter(
    (val) => val.id === state.commandLine
  )[0]?.responseLine;

  const responseAgents = responseLines?.comandLines?.filter(
    (val) => val.id === state.commandLine
  )[0]?.responseLine?.responseLineCommandToAgents;

  const [selectedAgents, setSelectedAgents] = useState({});
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState("");
  const [modalSource, setModalSource] = useState("");
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");

  const onModalConfirm = () => {
    switch (modalSource) {
      case "CONFIRM":
        onConfirm();
        break;

      default:
        break;
    }
    onCloseModal();
  };

  const onModalOpen = (source) => {
    setModalSource(source);
    switch (source) {
      case "CONFIRM":
        setConfirmModalTitle("Etes-vous sûr de vouloir CONFIRMER ?");
        break;

      default:
        break;
    }
    setConfirmModal(true);
  };

  const onCloseModal = () => {
    setConfirmModal(false);
    setConfirmModalTitle("");
    setModalSource("");
  };

  const callback = () => {
    // navigate("/home/command-detail", { state: state.command });
  };

  const onError = () => {
    setLoading(false);
  };

  const onValidateReject = (status, agentId, responseLineCommandToagentId) => {
    setLoading(true);
    dispatch(
      clientValidateRejectApprobation(
        {
          responseLineCommandToagentId,
          agentId,
          clientId: agent?.id,
          validationStatus: status,
        },
        callback,
        onError
      )
    );
  };

  const onConfirm = () => {
    setLoading(true);
    dispatch(
      clientConfirmResponse(
        {
          commandLineId: state.commandLine,
          responseLineId: responseLine?.id,
          clientId: agent?.id,
        },
        callback,
        onError
      )
    );
  };

  useEffect(() => {
    batch(() => {
      dispatch(clientGetResponseLine(state.command));
    });
  }, [dispatch, state.command]);

  return (
    <Box
      p={4}
      px={{ xs: 2, md: 4, lg: 4 }}
      style={{
        backgroundColor: "#f4f3f7",
        minHeight: "100vh",
      }}
    >
      <Stack
        direction={{ xs: "column", md: "row", lg: "row" }}
        alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
        my={3}
        spacing={3}
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Avatar sx={{ bgcolor: "#161B38", width: 48, height: 48 }}>
            <FileSVG />
          </Avatar>
          <Typography color="primary" fontSize={20} fontWeight={600}>
            Agents proposés
          </Typography>
        </Stack>

        <TextField
          id="input-with-icon-textfield"
          type="search"
          size="small"
          color="secondary"
          placeholder="Rechercher"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchSVG />
              </InputAdornment>
            ),
            sx: { backgroundColor: "white" },
          }}
          variant="outlined"
          style={{
            borderRadius: "2%",
            width: 250,
          }}
          value={search}
          onChange={({ target }) => setSearch(target.value)}
        />
      </Stack>

      <Box
        mt={3}
        sx={{
          backgroundColor: "#64677A20",
          minHeight: "400px",
          width: { xs: "100%", md: "70%", lg: "70%" },
        }}
      >
        <Box>
          <Stack
            sx={{
              height: "50px",
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>Liste des agents</Typography>
          </Stack>
          <Box p={2} sx={{ height: "100%" }}>
            {responseAgents?.map((val, inx) => (
              <AgentPropositionCard
                key={inx}
                agent={agents[val.agentId]}
                showAdd={false}
                showInfo={false}
                alterTitle
                agentType={state.agentType}
                approvedByClient={val.approvedByClient}
                isProcessed={val.approvedByClient}
                commandState={state.commandState}
                processedTitle={
                  val.statusApprobationAgent === "ACCEPTED"
                    ? "Accepté"
                    : "Rejeté"
                }
                onValidate={() => ["NEW","PROCESSING","PROCESSED"].includes(state.commandState) ?
                  onValidateReject(
                    true,
                    val.agentId,
                    val.responseLineCommandToagentId
                  ):null
                }
                onReject={() => ["NEW","PROCESSING","PROCESSED"].includes(state.commandState) ?
                  onValidateReject(
                    false,
                    val.agentId,
                    val.responseLineCommandToagentId
                  ) : null
                }
              />
            ))}
            {!responseAgents?.filter(
              (val) =>
                !Object.keys(selectedAgents || {})?.includes(String(val.id))
            ).length ? (
              <Stack
                justifyContent={"center"}
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <img src="/paper.png" style={{ width: 100 }} alt="no item" />
                <Typography textAlign={"center"} fontSize={12}>
                  Liste vide
                </Typography>
              </Stack>
            ) : null}
          </Box>
        </Box>
      </Box>

      <Stack direction="row" alignItems="center" spacing={2} mt={2}>
        <CustomButton
          title="Sortir"
          onClick={() => navigate(-1)}
          titleStyle={{ color: COLORS.primary }}
          rootStyle={{
            padding: 1.5,
            backgroundColor: "transparent",
            border: `1px solid ${COLORS.primary}`,
            width: 250,
          }}
        />
        {!responseLine?._isConfirmedByClient &&
        responseAgents?.length && !["REJECTED","CANCELED","VALIDATED"].includes(state.commandState) &&
        agent?.authAccountType === "client" ? (
          <CustomButton
            title="Confirmer"
            onClick={() => onModalOpen("CONFIRM")}
            rootStyle={{ padding: 1.5, width: 250 }}
          />
        ) : null}
      </Stack>
      <CustomConfirm
        showIcon={false}
        visible={confirmModal}
        title={confirmModalTitle}
        onConfirm={onModalConfirm}
        onClose={onCloseModal}
      />
    </Box>
  );
}

AgentProposed.propTypes = {};

export default AgentProposed;
