import axios from "axios";
import { SERVER } from "../../utils/server";
import commandTypes from "./commandTypes";
import notificationTypes from "../notifications/notificationTypes";
import { listNotifications } from "../notifications/notificationActions";
import { getErrorMessage } from "../../utils/errorCodeMessage";

export const createCommand =
  (command, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}command`, command);
      dispatch({
        type: commandTypes.CREATE_COMMAND,
        payload: results.data.data,
      });
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: "Votre demande a bien été envoyé" },
      });
      callback?.();
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        // console.log(error.response.status);
        if (error.response.status === 400) {
          onError?.();
          dispatch({
            type: notificationTypes.SHOW_ERROR,
            payload: {
              message: getErrorMessage(error.response.data?.data[0]?.param),
            },
          });
        }
        return;
      }
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      onError?.();
      console.error("Command creation error", error);
    }
  };

export const listCommands = (id) => async (dispatch) => {
  try {
    const URL = id ? `${SERVER}command/client/${id}` : `${SERVER}commands`;
    const results = await axios.get(URL);
    dispatch({ type: commandTypes.LIST_COMMANDS, payload: results.data.data });
  } catch (error) {
    console.error("Command list error", error);
  }
};

export const listOperatorCommands = () => async (dispatch) => {
  try {
    const agent = JSON.parse(localStorage.getItem("agent") || "{}");
    const URL = `${SERVER}command/operator/${agent.id}`;
    const results = await axios.get(URL);
    dispatch({ type: commandTypes.LIST_COMMANDS, payload: results.data.data });
    dispatch(
      listNotifications(String(agent?.authAccountType).toUpperCase(), agent?.id)
    );
  } catch (error) {
    console.error("Operator Command list error", error);
  }
};

export const deleteCommand = (id) => async (dispatch) => {
  await axios.delete(`${SERVER}command/${id}`);

  dispatch({ type: commandTypes.REMOVE_COMMAND, payload: id });
};

export const downloadCommand = (id) => async () => {
  await axios.get(`${SERVER}command/download/${id}`);
};

export const downloadBonCommand =
  (commandId, commandLineId, agentId) => async () => {
    await axios.get(
      `${SERVER}command/downloadBonCommand/?commandId=${commandId}&commandLineId=${commandLineId}&agentId=${agentId}`
    );
  };

export const updateCommand =
  (command, callback, toastMessage = "Modifié avec succès") =>
  async (dispatch) => {
    try {
      const results = await axios.put(`${SERVER}command`, command);
      dispatch({
        type: commandTypes.UPDATE_COMMAND,
        payload: results.data.data,
      });
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: toastMessage },
      });
      callback?.();
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        // console.log(error.response.status);
        if (error.response.status === 400) {
          dispatch({
            type: notificationTypes.SHOW_ERROR,
            payload: {
              message: getErrorMessage(error.response.data?.data[0]?.param),
            },
          });
        }
        return;
      }
      console.log("update command error", error);
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
    }
  };
