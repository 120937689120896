import { CloudDone } from "@mui/icons-material";
import { Avatar, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { UploadSVG } from "../utils/svgImages";

export default function CustomDropzone({
  title = "une nouvelle image de profile",
  showAvatar = true,
  getRootProps,
  getInputProps,
  acceptedFiles,
}) {
  return (
    <div {...getRootProps}>
      <Box
        sx={{
          backgroundColor: "#D9DBDD25",
          borderRadius: "10px",
          border: "1px solid #D9DBDD",
          height: 172,
          position: "relative",
          mt: 5,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {showAvatar ? (
          <Avatar
            alt="user"
            style={{
              width: 82,
              height: 82,
              border: "3px solid #161B38",
              borderRadius: 100,
              position: "absolute",
              top: -20,
              left: -15,
            }}
          />
        ) : null}
        {!acceptedFiles?.length ? (
          <Box sx={{cursor: "pointer"}}>
            <Typography style={{ textAlign: "center" }}>
              <UploadSVG />
            </Typography>

            <Stack
              direction="row"
              spacing={1}
              sx={{ alignItems: "center", mt: 2 }}
            >
              <Typography
                fontWeight="700"
                color="#161B38"
                sx={{ textDecoration: "underline" }}
              >
                Télécharger
              </Typography>
              <Typography>{title}</Typography>
            </Stack>
          </Box>
        ) : (
          <Stack alignItems="center" sx={{cursor: "pointer"}}>
            <Stack alignItems="center" spacing={1}>
              <Stack
                spacing={3}
                sx={{
                  width: 60,
                  height: 60,
                  backgroundColor: "white",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 2,
                }}
              >
                <CloudDone fontSize="large" />
              </Stack>
              <div>
                <Typography fontSize={18} color="#5D5C5C">
                  {acceptedFiles[0]?.path}
                </Typography>
                {/* <Typography color="#909293">
                  {acceptedFiles[0]?.size} bytes
                </Typography> */}
              </div>
            </Stack>
            <Typography
              mt={2}
              color="#dd3d45"
              sx={{ textDecoration: "underline" }}
            >
              Remplacer
            </Typography>
          </Stack>
        )}
      </Box>
      <input {...getInputProps} style={{ borderStyle: "none", display: 'none' }} />
    </div>
  );
}
