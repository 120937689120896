import axios from "axios";
import { SERVER } from "../../utils/server";
import agentTypes from "./agentTypeTypes";
import notificationTypes from "../notifications/notificationTypes";

export const createAgentType =
  (agent, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}agentType`, agent);

      if (results.data.isSuccess) {
        dispatch({
          type: agentTypes.CREATE_AGENT_TYPE,
          payload: results.data.data,
        });
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Enregistré avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: { message: results.data.data.message },
        });
        onError?.();
      }
    } catch (error) {
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Agent creation error", error);
    }
  };

export const listAgentTypes = () => async (dispatch) => {
  try {
    const results = await axios.get(`${SERVER}agentTypes`);
    dispatch({ type: agentTypes.LIST_AGENT_TYPES, payload: results.data.data.filter(admin=>!admin.isDeleted) });
  } catch (error) {
    console.error("Agent list error", error);
  }
};

export const deleteAgentType = (id) => async (dispatch) => {
   await axios.delete(`${SERVER}agentType/${id}`);

  dispatch({ type: agentTypes.REMOVE_AGENT_TYPE, payload: id });
};

export const updateAgentType =
  (agent, callback, onError, toastMessage = "Modifié avec succès") =>
  async (dispatch) => {
    try {
      const results = await axios.put(`${SERVER}agentType`, agent);
      dispatch({
        type: agentTypes.UPDATE_AGENT_TYPE,
        payload: results.data.data,
      });
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: toastMessage },
      });

      callback?.();
    } catch (error) {
      console.log("update agent type error", error);
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
    }
  };
