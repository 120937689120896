import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Box,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { COLORS } from "../../utils";
import SigninTextInput from "./components/SigninTextInput";
import { AuthLayout } from "../../layouts";
import SigninHeader from "./components/SigninHeader";
import SigninButton from "./components/SigninButton";
import { signin } from "../../redux/auth/authActions";
import { Lock } from "@mui/icons-material";

const Signin = () => {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const [errorPassword, setErrorPassword] = useState(false);
  const [errorUsername, setErrorUsername] = useState(false);

  const agent = JSON.parse(localStorage.getItem("agent") || "{}");

  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const callback = (value) =>
    navigate(value === "agent" ? "/home/bon-commands" : "/home/dashboard");
  const onError = () => setIsLoading(false);

  const login = async (e) => {
    e.preventDefault();

    try {
      if (
        email.trim() &&
        password.trim() &&
        email.trim().split(" ").length === 1
      ) {
        try {
          setIsLoading(true);
          setError(null);
          dispatch(signin({ email, password }, callback, onError));
        } catch (error) {
          console.error("sign in error", error);
          console.log("Error Code", error.code);
          setError(error);
          setIsLoading(false);
        }
      } else {
        if (!email.trim()) {
          setErrorUsername(true);
        }
        if (!password.trim()) {
          setErrorPassword(true);
        }
        if (email.trim().split(" ").length > 1) {
          setError({ message: "remove_white_space" });
        }
      }
    } catch (error) {
      console.log(error);
      alert("Try Again");
    }
  };

  useEffect(() => {
    if (agent?.authAccountType) {
      navigate(
        agent?.authAccountType === "agent"
          ? "/home/bon-commands"
          : "/home/dashboard"
      );
    } else {
      setLoading(false);
    }
  }, [agent?.authAccountType, navigate]);

  if (loading) {
    return (
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <img
          src={"/logo_full.png"}
          alt={"logo_full"}
          style={{ width: 300, height: 300, objectFit: "contain" }}
        />
        <CircularProgress />
        <Typography mt={2}>Chargement...</Typography>
      </Stack>
    );
  }

  return (
    <AuthLayout
      onSubmit={login}
      leftComponent={
        <Card
          sx={{
            maxWidth: 430,
            boxShadow: "none",
            maxHeight: 650,
          }}
        >
          <CardContent>
            <SigninHeader
              title={"Bienvenue !"}
              subTitle={
                "Remplissez vos détails afin de vous connecter à votre compte."
              }
            />

            <div
              component="form"
              style={{
                width: "100%",
              }}
            >
              <SigninTextInput
                title={"Email ou numéro de téléphone"}
                onChange={(value) => setEmail(value.trim())}
                // errorMessage={errors.phoneNumber}
              />
              <SigninTextInput
                title={"Mot de passe"}
                type="password"
                image="signin_lock"
                onChange={(value) => setPassword(value)}
                showError={errorPassword}
                icon={<Lock />}
                // errorMessage={errors.phoneNumber}
              />

              <Link to="/forgot-password">
                <Typography
                  textAlign="right"
                  color="red"
                  style={{
                    fontSize: "14px",
                    textDecoration: "underline",
                    fontWeight: 700,
                  }}
                  mt={2}
                >
                  mot de passe oublié ?
                </Typography>
              </Link>

              <SigninButton
                title={"Connexion"}
                onClick={login}
                isLoading={isLoading}
              />

              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Link to="/signup" style={{ textDecoration: "none" }}>
                  <Typography
                    color="black3"
                    style={{
                      fontSize: 13,
                      display: "flex",
                    }}
                  >
                    Vous n’avez pas encore de compte ?
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 13,
                        textDecoration: "underline",
                      }}
                      color="primary"
                      ml={1}
                    >
                      S’inscrire
                    </Typography>
                  </Typography>
                </Link>
              </Box>

              {error ? (
                <Typography
                  style={{
                    color: COLORS.red,
                    marginTop: 10,
                  }}
                  align={"center"}
                >
                  {error.name === "InvalidParameterException"
                    ? "remove_white_space"
                    : error.message}
                </Typography>
              ) : null}
            </div>
          </CardContent>
        </Card>
      }
    />
  );
};

export default Signin;
