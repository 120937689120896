import React from "react";
import {
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { CustomKeyValue } from "../../../components";
import { COLORS } from "../../../utils";


function CommandDetailClient({ client }) {
  return (
    <Box mt={2}>
    <Typography
      fontSize={20}
      fontWeight="700"
      color={COLORS.primary}
      sx={{ borderBottom: "1px solid #c2c2c2", width: "80px" }}
    >
      Client
    </Typography>
    <Stack
      direction={"row"}
      spacing={2}
      alignItems={"center"}
      width={{ xs: "100%", md: "70%", lg: "70%" }}
      mt={2}
      justifyContent="space-between"
    >
      <CustomKeyValue
        title={"Raison sociale"}
        titleStyle={{
          color: "#57585A",
          fontSize: 13,
        }}
        valueStyle={{
          color: "#161718",
          fontWeight: "700",
          fontSize: 16,
        }}
        value={client?.raisonSociale}
        rootStyle={{ width: "100%" }}
      />
      <CustomKeyValue
        title={"SIRET"}
        titleStyle={{
          color: "#57585A",
          fontSize: 13,
        }}
        valueStyle={{
          color: "#161718",
          fontWeight: "700",
          fontSize: 16,
        }}
        rootStyle={{ width: "100%" }}
        value={client?.siret}
      />
    </Stack>
    <Stack
      direction={'row'}
      spacing={2}
      alignItems={"center"}
      width={{ xs: "100%", md: "70%", lg: "70%" }}
      mt={2}
      justifyContent="space-between"
    >
      <CustomKeyValue
        title={"Téléphone"}
        titleStyle={{
          color: "#57585A",
          fontSize: 13,
        }}
        valueStyle={{
          color: "#161718",
          fontWeight: "700",
          fontSize: 16,
        }}
        rootStyle={{ width: "100%" }}
        value={client?.phoneNumber}
      />
      <CustomKeyValue
        title={"Email"}
        titleStyle={{
          color: "#57585A",
          fontSize: 13,
        }}
        valueStyle={{
          color: "#161718",
          fontWeight: "700",
          fontSize: 16,
        }}
        rootStyle={{ width: "100%" }}
        value={client?.email}
      />
    </Stack>
  </Box>
  );
}

export default CommandDetailClient;
