import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
// import { fr, en } from "./assets/locales";
import { createTheme, CssBaseline, StyledEngineProvider } from "@mui/material";
import { persistor } from "./redux/configureStore";
import { SnackbarProvider } from "notistack";

import "./App.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import locale from "date-fns/locale/fr";
import moment from "moment";
import 'moment/locale/fr'

moment.locale('fr')

const font = "'Open Sans', sans-serif";
const theme = createTheme({
  typography: {
    fontFamily: font,
  },
  palette: {
    primary: {
      main: "#161B38"
    },
    black: "#161718",
    black2: "#363839",
    black3: "#57585A",
    red: "#CB1728",
    activeBlue: "#161928",
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  },
});

const rootElement = document.getElementById("root");

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Router>
                <SnackbarProvider>
                  <App />
                </SnackbarProvider>
              </Router>
            </ThemeProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
