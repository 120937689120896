import {
  ArrowBack,
  ArrowCircleDown,
  ArrowCircleUp,
  ArrowForward,
  Close,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { isBrowser } from "react-device-detect";
import { CustomButton, CustomKeyValue } from "../../../components";
import { COLORS } from "../../../utils";
import { SERVER } from "../../../utils/server";
import AgentProfileTable from "../../agentProfile/components/AgentProfileTable";
import ServiceHistoryTable from "../../myService/components/ServiceHistoryTable";

function AgentPropositionCard({
  agent,
  showAdd = true,
  onSelect,
  approbations,
  isSelected,
  showInfo = true,
  alterTitle,
  onValidate,
  onReject,
  isProcessed,
  processedTitle,
  approvedByClient,
  agentType,
  commandState
}) {
  const [showDetail, setShowDetail] = useState(false);
  const [histories, setHistories] = useState([]);
  const [agentProfile, setAgentProfile] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem("agent") || "{}");
  const accountType = currentUser?.authAccountType;
  const isClient = currentUser?.authAccountType === "client";
  const [detailModal, setDetailModal] = useState(false);

  const getProfiles = useCallback(async () => {
    const result = await axios.get(
      `${SERVER}securityAgent/get/agentProfiles?agentId=${agent?.id}`
    );
    setAgentProfile(result.data?.data);
  }, [agent?.id]);

  const getHistories = useCallback(async () => {
    const result = await axios.get(`${SERVER}histories?agentId=${agent?.id}`);
    setHistories(result.data?.data);
  }, [agent?.id]);

  const getStatusApprobation = (val) => {
    switch (val) {
      case "SENT":
        return { title: "Envoyé", color: COLORS.yellow };
      case "ACCEPTED":
        return { title: "Accepté", color: COLORS.green };
      case "REJECTED":
        return { title: "Refusé", color: COLORS.red };

      default:
        return { title: isBrowser ? "Info" : "", color: COLORS.primary };
    }
  };

  const showDetails = () => {
    if (isBrowser) {
      setShowDetail(!showDetail);
    } else {
      setDetailModal(!detailModal);
    }
  };

  const DetailTab = () => (
    <>
      <Grid
        container
        spacing={2}
        sx={{ mx: 2, mt: 1 }}
        // columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {agent.raisonSociale ? (
          <>
            <Grid item xs={4} sm={4} md={4}>
              <CustomKeyValue
                title={"Raison sociale"}
                value={agent.raisonSociale}
                titleStyle={{ color: "#57585A", fontSize: 12 }}
                valueStyle={{
                  fontWeight: 700,
                  color: COLORS.primary,
                  wordWrap: "break-word",
                  fontSize: 12,
                }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CustomKeyValue
                title={"SIRET"}
                value={agent.siret}
                titleStyle={{ color: "#57585A", fontSize: 12 }}
                valueStyle={{
                  fontWeight: 700,
                  color: COLORS.primary,
                  wordWrap: "break-word",
                  fontSize: 12,
                }}
              />
            </Grid>
          </>
        ) : null}
        {!isClient ? (
          <Grid item xs={4} sm={4} md={4}>
            <CustomKeyValue
              title={"Téléphone"}
              value={agent.phoneNumber}
              titleStyle={{ color: "#57585A", fontSize: 12 }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                wordWrap: "break-word",
                fontSize: 12,
              }}
            />
          </Grid>
        ) : null}
        {!isClient ? (
          <Grid item xs={4} sm={4} md={4}>
            <CustomKeyValue
              title={"Email"}
              value={agent.email}
              titleStyle={{ color: "#57585A", fontSize: 12 }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                wordWrap: "break-word",
                fontSize: 12,
              }}
            />
          </Grid>
        ) : null}
        {agent.nationalId && !isClient ? (
          <Grid item xs={4} sm={4} md={4}>
            <CustomKeyValue
              title={"Nº Pièce d’identité"}
              value={agent.nationalId}
              titleStyle={{ color: "#57585A", fontSize: 12 }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                wordWrap: "break-word",
                fontSize: 12,
              }}
            />
          </Grid>
        ) : null}
        {agentType ? (
          <Grid item xs={4} sm={4} md={4}>
            <CustomKeyValue
              title={"Type d'agent"}
              value={agentType}
              titleStyle={{ color: "#57585A", fontSize: 12 }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                wordWrap: "break-word",
                fontSize: 12,
              }}
            />
          </Grid>
        ) : null}
        {agent.whatSApp ? (
          <Grid item xs={4} sm={4} md={4}>
            <CustomKeyValue
              title={"Whatsapp"}
              value={agent.whatSApp}
              titleStyle={{ color: "#57585A", fontSize: 12 }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                wordWrap: "break-word",
                fontSize: 12,
              }}
            />
          </Grid>
        ) : null}
        <Grid item xs={4} sm={4} md={4}>
          <CustomKeyValue
            title={"Adresse"}
            value={`${agent.adressCompleted?.address} ${
              agent.adressCompleted?.complementAddress || ""
            }`}
            titleStyle={{ color: "#57585A", fontSize: 12 }}
            valueStyle={{
              fontWeight: 700,
              color: COLORS.primary,
              wordWrap: "break-word",
              fontSize: 12,
            }}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <CustomKeyValue
            title={"Code Postal"}
            value={agent.adressCompleted?.postalCode}
            titleStyle={{ color: "#57585A", fontSize: 12 }}
            valueStyle={{
              fontWeight: 700,
              color: COLORS.primary,
              wordWrap: "break-word",
              fontSize: 12,
            }}
          />
        </Grid>
        {!isClient ? (
          <Grid item xs={4} sm={4} md={4}>
            <CustomKeyValue
              title={"Pays"}
              value={agent.adressCompleted?.country}
              titleStyle={{ color: "#57585A", fontSize: 12 }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                wordWrap: "break-word",
                fontSize: 12,
              }}
            />
          </Grid>
        ) : null}
        <Grid item xs={4} sm={4} md={4}>
          <CustomKeyValue
            title={"Ville"}
            value={agent.adressCompleted?.city}
            titleStyle={{ color: "#57585A", fontSize: 12 }}
            valueStyle={{
              fontWeight: 700,
              color: COLORS.primary,
              wordWrap: "break-word",
              fontSize: 12,
            }}
          />
        </Grid>
      </Grid>

      {accountType === "admin" && agentProfile.length ? (
        <Box
          sx={{
            backgroundColor: "#fff",
            width: "100%",
            height: "100%",
            borderRadius: 2,
            p: 3,
            my: 2,
          }}
        >
          <Typography
            color="primary"
            fontSize={20}
            fontWeight={800}
            mb={4}
            sx={{ textDecoration: "underline" }}
          >
            Profil d'agent
          </Typography>
          <AgentProfileTable agentProfile={agentProfile} />
        </Box>
      ) : null}

      {histories.length ? (
        <Box
          sx={{
            backgroundColor: "#fff",
            width: "100%",
            height: "100%",
            borderRadius: 2,
            p: 3,
            my: 2,
          }}
        >
          <Typography
            color="primary"
            fontSize={20}
            fontWeight={800}
            mb={4}
            sx={{ textDecoration: "underline" }}
          >
            Historique de services
          </Typography>
          <ServiceHistoryTable histories={histories} />
        </Box>
      ) : null}
    </>
  );

  useEffect(() => {
    getProfiles();
    getHistories();
  }, [getHistories, getProfiles]);

  return (
    <Box mb={2}>
      <Stack
        direction={{ xs: "column", md: "row", lg: "row" }}
        alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
        spacing={3}
        justifyContent="space-between"
      >
        <Stack direction={"row"} alignItems="center" spacing={2}>
          <Avatar
            src={
              agent?.imageUrl
                ? `${SERVER.replace("/api/v1", "")}${agent?.imageUrl}`
                : null
            }
          />
          <Typography
            fontWeight={600}
            fontSize={13}
          >{`${agent?.firstname} ${agent?.lastname}`}</Typography>
        </Stack>
        <Stack direction={"row"} alignItems="center" spacing={2}>
          {isSelected && showAdd ? (
            <IconButton
              onClick={onSelect}
              sx={{ backgroundColor: COLORS.primary, borderRadius: 1 }}
            >
              <ArrowBack htmlColor="white" fontSize="small" />
            </IconButton>
          ) : null}
          {showInfo &&
            (isBrowser ? (
              <CustomButton
                rootStyle={{
                  backgroundColor: getStatusApprobation(
                    approbations[agent?.id]?.statusApprobationAgent
                  ).color,
                }}
                title={
                  getStatusApprobation(
                    approbations[agent?.id]?.statusApprobationAgent
                  ).title
                }
                titleStyle={{ fontSize: 13, marginRight: 1 }}
                rightComponent={
                  <InfoOutlined fontSize="small" color="inherit" />
                }
              />
            ) : (
              <IconButton
                sx={{
                  backgroundColor: getStatusApprobation(
                    approbations[agent?.id]?.statusApprobationAgent
                  ).color,
                  borderRadius: 1,
                }}
              >
                <InfoOutlined fontSize="small" htmlColor="white" />
              </IconButton>
            ))}
          {isBrowser ? (
            <CustomButton
              title={showDetail ? "Voir moins" : "Voir plus"}
              rootStyle={{
                backgroundColor: "white",
                border: "1px solid #161B38",
              }}
              titleStyle={{
                color: COLORS.primary,
                fontSize: 13,
                marginRight: 1,
              }}
              rightComponent={
                showDetail ? (
                  <ArrowCircleUp htmlColor={COLORS.primary} fontSize="small" />
                ) : (
                  <ArrowCircleDown
                    htmlColor={COLORS.primary}
                    fontSize="small"
                  />
                )
              }
              onClick={showDetails}
            />
          ) : (
            <IconButton
              sx={{ backgroundColor: "white", borderRadius: 1 }}
              onClick={showDetails}
            >
              {showDetail ? (
                <ArrowCircleUp htmlColor={COLORS.primary} fontSize="small" />
              ) : (
                <ArrowCircleDown htmlColor={COLORS.primary} fontSize="small" />
              )}
            </IconButton>
          )}
          {["client"].includes(accountType) ? (
            isProcessed===null ? (
               ["PROCESSED"]?.includes(commandState) ? <>
                {!showInfo && (
                  <CustomButton
                    title={"OK"}
                    rootStyle={{
                      backgroundColor: COLORS.green,
                      px: 4,
                    }}
                    titleStyle={{
                      fontSize: 13,
                    }}
                    onClick={onValidate}
                  />
                )}
                {!showInfo && (
                  <CustomButton
                    title={"KO"}
                    rootStyle={{
                      backgroundColor: COLORS.red,
                      px: 4,
                    }}
                    titleStyle={{
                      fontSize: 13,
                    }}
                    onClick={onReject}
                  />
                )}
              </> : null
            ) : (
              <CustomButton
                title={isProcessed ? "OK":"KO"}
                rootStyle={{
                  backgroundColor:
                  isProcessed ? COLORS.green : COLORS.red,
                  px: 4,
                }}
                titleStyle={{
                  fontSize: 13,
                  color: COLORS.white,
                }}
              />
            )
          ) : null}
          {["admin", "superadmin"].includes(accountType) ? (
            <>
              {isProcessed ? (
                <CustomButton
                  title={processedTitle}
                  rootStyle={{
                    backgroundColor:
                      processedTitle === "Accepté" ? COLORS.green : COLORS.red,
                    px: 4,
                  }}
                  titleStyle={{
                    fontSize: 13,
                    color: COLORS.white,
                  }}
                  onClick={onValidate}
                />
              ) : null}
            </>
          ) : null}
          {/* {isProcessed ? (
            <CustomButton
              title={processedTitle}
              rootStyle={{
                backgroundColor: "#c2c2c2",
                px: 4,
              }}
              titleStyle={{
                fontSize: 13,
                color: COLORS.black,
              }}
              onClick={onValidate}
            />
          ) : (
            <>
              {!showInfo && (
                <CustomButton
                  title={alterTitle ? "OK" : "Valider"}
                  rootStyle={{
                    backgroundColor: COLORS.green,
                    px: 4,
                  }}
                  titleStyle={{
                    fontSize: 13,
                  }}
                  onClick={onValidate}
                />
              )}
              {!showInfo && (
                <CustomButton
                  title={alterTitle ? "KO" : "Rejeter"}
                  rootStyle={{
                    backgroundColor: COLORS.red,
                    px: 4,
                  }}
                  titleStyle={{
                    fontSize: 13,
                  }}
                  onClick={onReject}
                />
              )}
            </>
          )} */}
          {!isSelected && showAdd ? (
            <IconButton
              onClick={onSelect}
              sx={{ backgroundColor: COLORS.primary, borderRadius: 1 }}
            >
              <ArrowForward htmlColor="white" fontSize="small" />
            </IconButton>
          ) : null}
          {/* {isAdmin ? (
            <CustomButton
              title={approvedByClient ? "OK" : "KO"}
              rootStyle={{
                px: 4,
              }}
              titleStyle={{
                fontSize: 13,
              }}
            />
          ) : null} */}
        </Stack>
      </Stack>

      {showDetail && <DetailTab />}
      <Dialog onClose={showDetails} open={detailModal}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
          sx={{ backgroundColor: "white", p: 2, py: 1 }}
        >
          <Typography
            fontWeight={600}
            fontSize={13}
          >{`${agent?.firstname} ${agent?.lastname}`}</Typography>
          <IconButton onClick={showDetails}>
            <Close />
          </IconButton>
        </Stack>
        <Divider />
        <DetailTab />
      </Dialog>
    </Box>
  );
}

export default AgentPropositionCard;
