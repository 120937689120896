import { getStatus } from "./commandUtils";
import { differentBetweenDate } from "./dateUtils";

const generate_command_html = (command, comandLines) => {
  return `
  <!DOCTYPE html>
<html>

<head>
  <meta charset="utf-8" />
  <style>
    .invoice-box {
      max-width: 800px;
      margin: auto;
      font-size: 16px;
      line-height: 24px;
      font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
      color: #555;
    }

    .invoice-box table {
      width: 100%;
      line-height: inherit;
      text-align: left;
    }

    .invoice-box table td {
      padding: 5px;
      vertical-align: top;
    }

    .invoice-box table tr td:nth-child(2) {
      text-align: right;
    }

    .invoice-box table tr.top table td {
      padding-bottom: 20px;
    }

    .invoice-box table tr.top table td.title {
      font-size: 45px;
      line-height: 45px;
      color: #333;
    }

    .invoice-box table tr.information table td {
      padding-bottom: 40px;
    }

    .invoice-box table tr.heading td {
      background: #000;
      border-bottom: 1px solid #ddd;
      font-weight: bold;
      color: #fff;
    }

     .invoice-box table tr.header td {
      background: #eee;
      border-bottom: 1px solid #ddd;
      font-weight: bold;
      
    }

    .invoice-box table tr.details td {
      padding-bottom: 20px;
    }

    .invoice-box table tr.item td {
      border-bottom: 1px solid #eee;
    }

    .invoice-box table tr.item.last td {
      border-bottom: none;
    }

    .invoice-box table tr.total td:nth-child(2) {
      border-top: 2px solid #eee;
      font-weight: bold;
    }

    @media only screen and (max-width: 600px) {
      .invoice-box table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
      }

      .invoice-box table tr.information table td {
        width: 100%;
        display: block;
        text-align: center;
      }
    }

    .invoice-box.rtl {
      direction: rtl;
      font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    }

    .invoice-box.rtl table {
      text-align: right;
    }

    .invoice-box.rtl table tr td:nth-child(2) {
      text-align: left;
    }
  </style>
</head>

<body>
  <div class="invoice-box">
    <table cellpadding="0" cellspacing="0">
      <tr class="top">
        <td colspan="2">
          <table>
            <tr>
              <td class="title">
                <img src="https://zipexpress.cybexai-server.com/logo_full.png" alt="logo" style="width: 120px; height: 80px" />
              </td>

              <td>
                Commande N°:  <b>${command.commandNumber}</b><br />
									Date:  <b>${new Date(command.createdAt).toLocaleDateString("fr")}</b><br />
									Statut: <b>${getStatus(command.state).name}</b>
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <tr class="information">
        <td colspan="2">
          <table>
            <tr>
              <td>
                Nom du site: <b>${command.siteIntervension.name}</b><br />
									Adresse:  <b>${`${command.siteIntervension.address.address}, ${command.siteIntervension.address.city}`}</b><br />
              </td>

            </tr>
          </table>
        </td>
      </tr>
  
      ${comandLines.map((cmd) =>{
         return `
         <table cellpadding="0" cellspacing="0">
         <tr style="background-color: #161B38;">
         <td
           align="center"
           style="width: 33%; color: white; font-weight: 600;"
         >${cmd.title}: ${new Date(
           cmd.date
         ).toLocaleDateString("fr")}</td>
         <td align="center" style="width: 33%;"></td>
         <td align="center" style="width: 33%;"></td>
        </tr>
         ${cmd.data.map(val => {
          return `
          <tr style="border: 1px solid #c2c2c2;">
            <td
              align="center"
              style="border: 1px solid #c2c2c2;"
            >${val.numberOfAgent} ${val.AgentType.name}</td>
            <td
              align="left"
              style="border: 1px solid #c2c2c2; width: 400px;"
            >
               ${new Date(val.serviceDay.startTime
                  ).getHours()}h:${new Date(val.serviceDay.startTime
                  ).getMinutes()} - ${new Date(val.serviceDay.endTime
                  ).getHours()}h:${new Date(val.serviceDay.endTime
                  ).getMinutes()}
            </td>
            <td
              align="center"
              style="border: 1px solid #c2c2c2; width: 400px;"
            >
                 ${`${
                    Math.abs(differentBetweenDate(
                      new Date(
                        val.startTime || val?.serviceDay?.startTime
                      ),
                      new Date(val.endTime || val?.serviceDay?.endTime),
                      "hours"
                    ) * val.numberOfAgent)
                  } Heures`}
            </td>
          </tr>
          `
         })}
          </table>
        `
        })}
    </table>
  </div>
</body>

</html>
`;
};

const getTime=(serviceDays,val)=>
    Object.values(serviceDays || {})?.filter(
        (item) => item.day === val
      ).length ? ` ${new Date(
        Object.values(serviceDays).filter(
          (item) => item.day === val
        )[0]?.startTime
      )?.getHours()}
      H:
      ${new Date(
        Object.values(serviceDays).filter(
          (item) => item.day === val
        )[0]?.endTime
      )?.getMinutes()} <br /> ${new Date(
        Object.values(serviceDays).filter(
          (item) => item.day === val
        )[0]?.endTime
      )?.getHours()}
      H:
      ${new Date(
        Object.values(serviceDays).filter(
          (item) => item.day === val
        )[0]?.endTime
      )?.getMinutes()}`: '--'


export const saveCommandPDF = async (command, commandLines) => {
  try {
    const html = generate_command_html(command,commandLines);

    let mywindow = window.open(
      "",
      "PRINT",
      "height=650,width=900,top=100,left=150"
    );

    mywindow.document.write(html);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    // mywindow.close();
  } catch (error) {
    console.error(error);
  }
};
