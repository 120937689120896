// export const SERVER = "https://zipexpress-server.cybexai-server.com/api/v1/"
export const SERVER = "http://51.77.140.203:41007/api/v1/"
// {
//     "clientId": "4",
//     "operationelId":"9",
//     "adminId": "1",
//     "idSiteIntervension": "8",
//     "comment": "Auccun commmentaire pour cette primière commande",
//     "startDate": "2023-08-18 12:00:00 AM",
//     "endDate": "2023-08-19 12:00:00 AM",
//     "commandLines": [
//         {
//             "numberOfAgent": "1",
//             "agentTypeId": 1,
//             "startTime": "2023-08-18 07:00:00 AM",
//             "endTime": "2023-08-18 08:00:00 AM",
//             "dayOfWeek": "VENDREDI",
//             "date": "18/08/2023"
//         },
//          {
//             "numberOfAgent": "1",
//             "agentTypeId": 1,
//             "startTime": "2023-08-18 07:00:00 AM",
//             "endTime": "2023-08-18 08:00:00 AM",
//             "dayOfWeek": "SAMEDI",
//             "date": "18/08/2023"
//         }
//     ]
// }


// {
//     "clientId": "1",
//     "operationelId":"1",
//     "adminId": "",
//     "idSiteIntervension": "1",
//     "comment": "Auccun commmentaire pour cette primière commande",
//     "startDate": "2022-08-20 19:30:00",
//     "endDate": "2022-08-20 7:30:00",
//     "commandLines": [
//         {
//             "numberOfAgent": 4,
//             "agentTypeId": 2,
//             "startTime": "2022-08-20 19:30:00",
//             "endTime": "2022-08-20 7:30:00",
//             "dayOfWeek": "LUNDI",
//             "date": "2022-08-20"
//         },
//         {
//             "agentTypeId": 1,
//             "numberOfAgent": 4,
//             "startTime": "2022-08-20 19:30:00",
//             "endTime": "2022-08-20 7:30:00",
//             "dayOfWeek": "MARDI",
//             "date": "2022-08-20"
//         },
//         {
//             "agentTypeId": 2,
//             "numberOfAgent": 3,
//             "startTime": "2022-08-20 19:30:00",
//             "endTime": "2022-08-20 7:30:00",
//             "dayOfWeek": "JEUDI",
//             "date": "2022-08-20"
//         }
//     ]
// }