import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CalendarMonth,
  FilterAlt,
  KeyboardArrowDown,
  Search,
  Sort,
} from "@mui/icons-material";
import { fr } from "date-fns/locale";
import { DateRangePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";

import { EmptySVG, FileSVG, SearchSVG } from "../../utils/svgImages";
import { COLORS } from "../../utils";
import { listBonCommands } from "../../redux/commandLine/commandLineActions";
import CommandBonRow from "./components/CommandBonRow";
import { CustomButton } from "../../components";
import usePagination from "../../hooks/usePagination";
import { saveCommandPDF } from "../../utils/pdfUtil";
import { isBrowser, MobileView } from "react-device-detect";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CommandBon() {
  const [filter, setFilter] = useState("list");
  const [search, setSearch] = useState("");
  const [orderBy, setOrderBy] = useState("desc");
  const [open, setOpen] = useState(false);
  const [selectDate, setSelectDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [page, setPage] = useState(1);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");
  const commands = useSelector((state) => state.commandLines.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onTopBtnClick = (command) => {
    navigate("/home/command-bon-detail", { state: JSON.stringify(command) });
  };

  const PER_PAGE = 10;

  const count = Math.ceil(commands.length / PER_PAGE);
  const _DATA = usePagination(commands, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const resetFilter = () => {
    setFilter("list");
    setSearch("");
    setOrderBy("desc");
    setSelectDate({
      startDate: null,
      endDate: null,
    });
  };

  const displayInit = () => {
    if (
      filter !== "list" ||
      search !== "" ||
      orderBy !== "desc" ||
      selectDate?.startDate ||
      selectDate?.endDate
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSelectDate = (date) => {
    setSelectDate(date);
    setOpen(false);
  };
  // console.log(_DATA
  //   .currentData())
  const onBottomBtnClick = async (val) => {
    const command = val.responseLine?.CommandLine;
    const toSave = {
      commandNumber: val.numBonCommandAgent,
      createdAt: command.createdAt,
      state: command.command?.state,
      siteIntervension: command.command?.siteIntervension,
      comandLines: [
        {
          serviceDays: command.serviceDays,
          AgentType: command.AgentType,
          numberOfAgent: command.numberOfAgent,
          serviceTypes: command.serviceTypes,
          startDate: command.startDate,
          endDate: command.endDate,
          comment: command.comment,
        },
      ],
    };
    await saveCommandPDF(toSave);
  };

  useEffect(() => {
    dispatch(listBonCommands(agent?.id));
  }, [agent?.id, dispatch]);

  return (
    <Box
      p={4}
      px={{ xs: 2, md: 4, lg: 4 }}
      style={{
        backgroundColor: "#f4f3f7",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: { xs: "block", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
        my={4}
        mb={2}
      >
        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
          <Avatar sx={{ bgcolor: "#161B38", width: 48, height: 48 }}>
            <FileSVG />
          </Avatar>
          <Typography color="primary" fontSize={20} fontWeight={600}>
            Bons de commande
          </Typography>
        </Stack>

        <MobileView>
          <Stack direction="row" justifyContent={"center"} my={2} spacing={3}>
            <IconButton
              onClick={() => setShowCalendar(!showCalendar)}
              style={{
                backgroundColor: showCalendar ? COLORS.primary : "transparent",
              }}
            >
              <CalendarMonth
                htmlColor={showCalendar ? COLORS.white : COLORS.primary}
              />
            </IconButton>
            <IconButton
              onClick={() => setShowSearch(!showSearch)}
              style={{
                backgroundColor: showSearch ? COLORS.primary : "transparent",
              }}
            >
              <Search htmlColor={showSearch ? COLORS.white : COLORS.primary} />
            </IconButton>
            <IconButton
              onClick={() => setShowFilter(!showFilter)}
              style={{
                backgroundColor: showFilter ? COLORS.primary : "transparent",
              }}
            >
              <FilterAlt
                htmlColor={showFilter ? COLORS.white : COLORS.primary}
              />
            </IconButton>
            <IconButton
              onClick={() => setShowSort(!showSort)}
              style={{
                backgroundColor: showSort ? COLORS.primary : "transparent",
              }}
            >
              <Sort htmlColor={showSort ? COLORS.white : COLORS.primary} />
            </IconButton>
          </Stack>
        </MobileView>

        <Stack
          direction={{ xs: "column", md: "row", lg: "row" }}
          alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
          spacing={3}
        >
          {isBrowser || showFilter ? (
            <FormControl>
              <InputLabel id="filter-select">Filtrer</InputLabel>
              <Select
                labelId="filter-select"
                id="filter-select"
                size="small"
                label="Filtrer"
                style={{
                  width: isBrowser ? 250 : "100%",
                  backgroundColor: "white",
                }}
                sx={{ mt: { xs: displayInit() ? 0 : 3, md: 0, lg: 0 } }}
                onChange={({ target }) => setFilter(target.value)}
                displayEmpty
                renderValue={
                  filter !== ""
                    ? undefined
                    : () => (
                        <Typography color="#c2c2c2">
                          Sélectionner un élément
                        </Typography>
                      )
                }
                IconComponent={(props) => (
                  <KeyboardArrowDown
                    {...props}
                    style={{
                      fontSize: "30px",
                    }}
                  />
                )}
                value={filter}
              >
                <MenuItem value="list" style={{ background: "white" }}>
                  Tous les Bons de commande
                </MenuItem>

                <MenuItem value="NEW" style={{ background: "white" }}>
                  Nouveaux bons de commande
                </MenuItem>
                {/* <MenuItem value="ACCEPTED" style={{ background: "white" }}>
                  Bons de commande acceptés
                </MenuItem>
                <MenuItem value="REJECTED" style={{ background: "white" }}>
                  Bons de commande refusés
                </MenuItem> */}
                <MenuItem value="VALIDATED" style={{ background: "white" }}>
                  Bons de commande validés
                </MenuItem>
                <MenuItem value="CANCELED" style={{ background: "white" }}>
                  Bons de commande annulés
                </MenuItem>
              </Select>
            </FormControl>
          ) : null}

          {isBrowser || showSearch ? (
            <TextField
              id="input-with-icon-textfield"
              type="search"
              size="small"
              color="secondary"
              placeholder="Rechercher"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchSVG />
                  </InputAdornment>
                ),
                sx: { backgroundColor: "white" },
              }}
              variant="outlined"
              style={{
                borderRadius: "2%",
                width: isBrowser ? 250 : "100%",
              }}
              sx={{ mt: { xs: displayInit() ? 0 : 3, md: 0, lg: 0 } }}
              value={search}
              onChange={({ target }) => setSearch(target.value)}
            />
          ) : null}
        </Stack>
      </Box>

      <Stack
        direction={{ xs: "column", md: "row", lg: "row" }}
        mb={!displayInit() && !isBrowser ? 0 : 3}
        spacing={2}
      >
        {/* <Button
          fullWidth
          onClick={() => setOpen(true)}
          sx={{ textAlign: "left", textTransform: "none", width: "250px" }}
        >
          <SigninTextInput
            value={`Du ${(
              selectDate?.startDate || new Date()
            )?.toLocaleDateString("fr")} au ${(
              selectDate?.endDate || new Date()
            )?.toLocaleDateString("fr")}`}
            icon={<CalendarDaySVG />}
            endIcon={<ClockSVG />}
            disabled
            size="small"
            rootStyle={{ marginTop: 0, height: "auto" }}
          />
        </Button> */}

        {isBrowser || showCalendar ? (
          <DateRangePicker
            startDate={selectDate.startDate || new Date()}
            endDate={selectDate.endDate || new Date()}
            onStartDateChange={(date) =>
              setSelectDate({ ...selectDate, startDate: date })
            }
            onEndDateChange={(date) =>
              setSelectDate({ ...selectDate, endDate: date })
            }
            minimumLength={1}
            format="dd MMM yyyy"
            locale={fr}
          >
            {({ startDateInputProps, endDateInputProps, focus }) =>
              isBrowser ? (
                <Stack
                  direction={"row"}
                  style={{
                    border: "1px solid #c2c2c2",
                    padding: 10,
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                  }}
                >
                  <CalendarMonth />
                  <TextField
                    id="start"
                    size="small"
                    variant="standard"
                    sx={{
                      "& .MuiInput-root": {
                        "&:before, :after, :hover:not(.Mui-disabled):before": {
                          borderBottom: 0,
                        },
                      },
                      ml: 1,
                      width: "100px",
                    }}
                    {...startDateInputProps}
                  />
                  <Typography>---</Typography>
                  <TextField
                    id="end"
                    size="small"
                    variant="standard"
                    sx={{
                      "& .MuiInput-root": {
                        "&:before, :after, :hover:not(.Mui-disabled):before": {
                          borderBottom: 0,
                        },
                      },
                      width: "100px",
                    }}
                    {...endDateInputProps}
                  />
                </Stack>
              ) : (
                <Stack
                  direction={"row"}
                  style={{
                    border: "1px solid #c2c2c2",
                    padding: 10,
                    borderRadius: "5px",
                    backgroundColor: "white",
                  }}
                >
                  <CalendarMonth />
                  <input
                    style={{ border: "none", width: "40%" }}
                    {...startDateInputProps}
                    placeholder="Start date"
                  />
                  <Typography>---</Typography>
                  <input
                    style={{ border: "none", width: "45%", textAlign: "right" }}
                    {...endDateInputProps}
                    placeholder="End date"
                  />
                </Stack>
              )
            }
          </DateRangePicker>
        ) : null}

        {isBrowser || showSort ? (
          <Select
            variant="standard"
            size="small"
            value={orderBy}
            onChange={({ target }) => setOrderBy(target.value)}
            input={<OutlinedInput />}
            style={{
              backgroundColor: "#fff",
            }}
            MenuProps={MenuProps}
          >
            <MenuItem value={"desc"}>Du plus récent au plus ancien</MenuItem>
            <MenuItem value={"asc"}>Du plus ancien au plus récent</MenuItem>
          </Select>
        ) : null}
        {displayInit() ? (
          <CustomButton title="Réinitialiser le filtre" onClick={resetFilter} />
        ) : null}
      </Stack>
      {/* <DateRangePicker
        open={open}
        locale={locale}
        toggle={toggle}
        onChange={(range) => onSelectDate(range)}
      /> */}
      {_DATA
        .currentData()
        .sort((a, b) =>
          orderBy === "desc"
            ? b.createdAt > a.createdAt
              ? 1
              : -1
            : a.createdAt < b.createdAt
            ? -1
            : 1
        )
        .filter((val) =>
          search !== ""
            ? [
                val.numBonCommandAgent?.toString(),
                val.responseLine?.CommandLine?.AgentType?.name?.toLowerCase(),
                val.responseLine?.CommandLine?.command?.commandNumber?.toLowerCase(),
                val.responseLine?.CommandLine?.command?.siteIntervension?.name?.toLowerCase(),
                val.responseLine?.CommandLine?.command?.siteIntervension?.address?.address?.toLowerCase(),
                val.responseLine?.CommandLine?.command?.siteIntervension?.address?.city?.toLowerCase(),
                val.responseLine?.CommandLine?.command?.siteIntervension?.address?.country?.toLowerCase(),
                val.responseLine?.CommandLine?.command?.siteIntervension?.address?.postalCode?.toLowerCase(),
              ].includes(search)
            : selectDate?.startDate
            ? new Date(val.startDate) >= new Date(selectDate?.startDate) &&
            new Date(val.endDate) <= new Date(selectDate?.endDate)
            : filter !== "list"
            ? val.state === filter
            : val
        )
        .map((command, index) => (
          <CommandBonRow
            key={index}
            elevation={0}
            bon={command}
            data={command.responseLine?.CommandLine}
            rootStyle={{ my: 2, mt: { xs: 3, md: 2, lg: 2 } }}
            onTopBtnClick={() => onTopBtnClick(command)}
            onBottomBtnClick={() => onBottomBtnClick(command)}
          />
        ))}

      {!_DATA
        .currentData()
        .filter((val) =>
          search !== ""
            ? [
                val.numBonCommandAgent?.toString(),
                val.responseLine?.CommandLine?.AgentType?.name?.toLowerCase(),
                val.responseLine?.CommandLine?.command?.commandNumber?.toLowerCase(),
                val.responseLine?.CommandLine?.command?.siteIntervension?.name?.toLowerCase(),
                val.responseLine?.CommandLine?.command?.siteIntervension?.address?.address?.toLowerCase(),
                val.responseLine?.CommandLine?.command?.siteIntervension?.address?.city?.toLowerCase(),
                val.responseLine?.CommandLine?.command?.siteIntervension?.address?.country?.toLowerCase(),
                val.responseLine?.CommandLine?.command?.siteIntervension?.address?.postalCode?.toLowerCase(),
              ].includes(search)
            : selectDate?.startDate
            ? new Date(val.startDate) >= new Date(selectDate?.startDate) &&
            new Date(val.endDate) <= new Date(selectDate?.endDate)
            : filter !== "list"
            ? val.state === filter
            : val
        ).length ? (
        <Typography textAlign="center" color={"#c2c2c2"} mt={10}>
          Aucun élément trouvé
        </Typography>
      ) : null}

      {!_DATA.currentData().length ? (
        <Stack alignItems={"center"} justifyContent="center" mt={10}>
          <Typography fontSize={20} fontWeight="600" color={COLORS.primary}>
            Vous n’avez pas encore de nouvelle commande
          </Typography>
          <EmptySVG />
        </Stack>
      ) : null}
      <Stack direction="row" justifyContent="center" alignItems="center" mt={1}>
        <Pagination
          count={count}
          size="medium"
          page={page}
          shape="rounded"
          color="primary"
          onChange={handleChange}
        />
      </Stack>
    </Box>
  );
}

CommandBon.propTypes = {};

export default CommandBon;
