import React, { useEffect, useState } from "react";
import { Typography, Card, CardContent, Box, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
// import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import SigninTextInput from "./components/SigninTextInput";
import { AuthLayout } from "../../layouts";
import SigninHeader from "./components/SigninHeader";
import SigninButton from "./components/SigninButton";
import { createAgent } from "../../redux/agents/agentActions";
import { agentYup } from "../../utils/yupValidation";
import SigninDropdown from "./components/SigninDropdown";
import { COUNTRIES } from "../../utils/countries";
import SigninPhoneInput from "./components/SigninPhoneInput";
import notificationTypes from "../../redux/notifications/notificationTypes";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import {
  Fingerprint,
  Height,
  Lock,
  Mail,
  Phone,
  Public,
  Scale,
} from "@mui/icons-material";

const Signup = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [address, setAddress] = useState(null);

  const dispatch = useDispatch();
  const callback = () => {
    localStorage.setItem("zipConfirmEmail", values.email);
    resetForm();
    setIsLoading(false);
    navigate("/confirm-account");
  };

  const onError = () => setIsLoading(false);

  const { values, handleSubmit, errors, resetForm, setFieldValue } = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      id: "",
      phoneNumber: "",
      complementAddress: "",
      email: "",
      password: "",
      address: "",
      postalCode: "0000",
      city: "",
      country: "France",
      nationalId: "",
      registrationNumber: "",
      confirmPassword: "",
      weight: null,
      height: null,
      dressSizeTop: null,
      dressSizeBottom: null,
      dressSizeFoot: null,
    },
    validationSchema: Yup.object().shape(agentYup),
    onSubmit(values) {
      try {
        setIsLoading(true);
        if (values.password !== values.confirmPassword) {
          setIsLoading(false);
          return dispatch({
            type: notificationTypes.SHOW_ERROR,
            payload: { message: "Les mot de passe ne correspondent pas" },
          });
        }
        const user = {
          ...values,
          gpsCoordinates: {
            long: longitude,
            lat: latitude,
          },
        };
        dispatch(createAgent(user, callback, onError));
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    },
  });

  const onAddressChange=(val)=>{
    setAddress(val);
    setFieldValue("address", val.label);
    setFieldValue("complementAddress", val.value.reference);
    geocodeByPlaceId(val.value.reference)
    .then(results => setFieldValue("city", results[0]?.address_components.filter(val=> val.types.includes("locality"))?.[0]?.long_name))
    .catch(error => console.error(error));
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  }, []);

  return (
    <AuthLayout
      onSubmit={handleSubmit}
      image="signup"
      leftComponent={
        <Card
          sx={{
            maxWidth: 470,
            boxShadow: "none",
          }}
        >
          <CardContent>
            <SigninHeader
              title={"Bienvenue !"}
              subTitle={"Remplissez vos détails afin de créer un compte."}
            />

            <div
              component="form"
              style={{
                width: "100%",
                // height: "100%",
                // overflow: "auto",
              }}
            >
              {/* <GooglePlacesAutocomplete
                apiKey="AIzaSyCCkYj8-bRf9zuAdPr9ZUQvPWil_VSurzM"
                selectProps={{
                  value,
                  onChange: setValue,
                }}
              /> */}

              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Nom"}
                  value={values.lastname}
                  id="lastname"
                  name="lastname"
                  onChange={(value) => setFieldValue("lastname", value)}
                  errorMessage={errors.lastname}
                />
                <SigninTextInput
                  title={"Prénom"}
                  value={values.firstname}
                  id="firstname"
                  name="firstname"
                  onChange={(value) => setFieldValue("firstname", value)}
                  // icon={<LockSVG />}
                  errorMessage={errors.firstname}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Poids"}
                  value={values.weight}
                  id="weight"
                  name="weight"
                  type="number"
                  onChange={(value) => setFieldValue("weight", Number(value))}
                  icon={<Scale />}
                  errorMessage={errors.weight}
                />
                <SigninTextInput
                  title={"Taille"}
                  value={values.height}
                  id="height"
                  name="height"
                  type="number"
                  onChange={(value) => setFieldValue("height", Number(value))}
                  icon={<Height />}
                  errorMessage={errors.height}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Taille tenue haute"}
                  value={values.dressSizeTop}
                  id="dressSizeTop"
                  name="dressSizeTop"
                  type="number"
                  onChange={(value) => setFieldValue("dressSizeTop", Number(value))}
                  icon={<Height />}
                  errorMessage={errors.dressSizeTop}
                />
                <SigninTextInput
                  title={"Taille tenue basse"}
                  value={values.dressSizeBottom}
                  id="dressSizeBottom"
                  name="dressSizeBottom"
                  type="number"
                  onChange={(value) => setFieldValue("dressSizeBottom", Number(value))}
                  icon={<Height />}
                  errorMessage={errors.dressSizeBottom}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Taille du pied"}
                  value={values.dressSizeFoot}
                  id="dressSizeFoot"
                  name="dressSizeFoot"
                  type="number"
                  onChange={(value) => setFieldValue("dressSizeFoot", Number(value))}
                  icon={<Height />}
                  errorMessage={errors.dressSizeFoot}
                />
                    <SigninPhoneInput
                  title={"Téléphone"}
                  value={values.phoneNumber}
                  id="phoneNumber"
                  name="phoneNumber"
                  onChange={(value) => setFieldValue("phoneNumber", value)}
                  icon={<Phone />}
                  errorMessage={errors.phoneNumber}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
            
                <SigninTextInput
                  title={"Email"}
                  value={values.email}
                  id="email"
                  name="email"
                  type="email"
                  onChange={(value) => setFieldValue("email", value.trim())}
                  icon={<Mail />}
                  errorMessage={errors.email}
                />
                  <SigninTextInput
                  title={"Nº Pièce d’identité"}
                  value={values.nationalId}
                  id="nationalId"
                  name="nationalId"
                  onChange={(value) => setFieldValue("nationalId", value)}
                  icon={<Fingerprint />}
                  errorMessage={errors.nationalId}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
              
                {/* <SigninTextInput
                  title={"Adresse"}
                  value={values.address}
                  id="address"
                  name="address"
                  onChange={(value) => setFieldValue("address", value)}
                  errorMessage={errors.address}
                  icon={<LocationOn />}
                /> */}
                 <Box width="100%">
              <Typography
                color="black"
                sx={{
                  fontSize: {
                    xs: 12,
                    md: 14,
                  },
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                Adresse *
              </Typography>
              <GooglePlacesAutocomplete
                selectProps={{
                  value: address,
                  onChange: onAddressChange,
                  placeholder: "Adresse",
                  styles: {
                    container: (provided) => ({
                      ...provided,
                      width: "100%",
                      height: "45px",
                      marginBottom: "15px",
                    }),
                    input: (provided) => ({
                      ...provided,
                      width: "100%",
                      height: "45px",
                    }),
                    option: (provided) => ({
                      ...provided,
                    }),
                  },
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                  country: ['fr'],
                  }
                }}
              />
            </Box>
              </Stack>
              {/* <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Complement Adresse"}
                  value={values.complementAddress}
                  id="complementAddress"
                  name="complementAddress"
                  icon={<LocationOn />}
                  onChange={(value) =>
                    setFieldValue("complementAddress", value)
                  }
                  errorMessage={errors.complementAddress}
                />
                <SigninTextInput
                  title={"Code Postal"}
                  value={values.postalCode}
                  id="postalCode"
                  name="postalCode"
                  onChange={(value) => setFieldValue("postalCode", value)}
                  icon={<QrCode />}
                  errorMessage={errors.postalCode}
                />
              </Stack> */}
              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninDropdown
                  title={"Pays"}
                  icon={<Public />}
                  value={values.country}
                  id="country"
                  name="country"
                  data={COUNTRIES}
                  disabled
                  onChange={(value) => setFieldValue("country", value)}
                  errorMessage={errors.country}
                />
                <SigninTextInput
                  title={"Ville"}
                  icon={<Public />}
                  value={values.city}
                  id="city"
                  name="city"
                  onChange={(value) => setFieldValue("city", value)}
                  errorMessage={errors.city}
                />
              </Stack>
              {/* <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Position GPS (Longitude)"}
                  icon={<LocationOn />}
                  value={longitude}
                  id="longitude"
                  name="longitude"
                  type="number"
                  onChange={(value) => setFieldValue("longitude", value)}
                />
                <SigninTextInput
                  title={"Position GPS (Latitude)"}
                  icon={<LocationOn />}
                  value={latitude}
                  id="latitude"
                  name="latitude"
                  type="number"
                  onChange={(value) => setFieldValue("latitude", value)}
                />
              </Stack> */}
              <Stack
                direction="row"
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Mot de passe"}
                  icon={<Lock />}
                  value={values.password}
                  id="password"
                  name="password"
                  type="password"
                  onChange={(value) => setFieldValue("password", value)}
                  errorMessage={errors.password}
                />

                <SigninTextInput
                  title={"Confirmer Mot de passe"}
                  icon={<Lock />}
                  value={values.confirmPassword}
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  onChange={(value) => setFieldValue("confirmPassword", value)}
                  errorMessage={errors.confirmPassword}
                />
              </Stack>

              <SigninButton
                title={"S’incrire"}
                onClick={handleSubmit}
                isLoading={isLoading}
              />

              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Link to="/signin" style={{ textDecoration: "none" }}>
                  <Typography
                    color="black3"
                    style={{
                      fontSize: 13,
                      display: "flex",
                    }}
                  >
                    Vous avez déjà un compte ?
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 13,
                        textDecoration: "underline",
                      }}
                      color="primary"
                      ml={1}
                    >
                      S’identifier
                    </Typography>
                  </Typography>
                </Link>
              </Box>
            </div>
          </CardContent>
        </Card>
      }
    />
  );
};

export default Signup;
