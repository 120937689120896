import React from "react";
import {
  Box,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { CustomKeyValue } from "../../../components";
import { COLORS } from "../../../utils";


function CommandDetailSite({ data }) {
  return (
    <Box mt={2}>
    <Typography
      fontSize={20}
      fontWeight="700"
      color={COLORS.primary}
      sx={{ borderBottom: "1px solid #c2c2c2", width: "80px" }}
    >
      Site
    </Typography>
    <Stack
      direction={"row"}
      spacing={2}
      alignItems={"center"}
      width={{ xs: "100%", md: "70%", lg: "70%" }}
      mt={2}
      justifyContent="space-between"
    >
      <CustomKeyValue
        title={"Nom du site"}
        titleStyle={{
          color: "#57585A",
          fontSize: 13,
        }}
        valueStyle={{
          color: "#161718",
          fontWeight: "700",
          fontSize: 16,
        }}
        value={data?.siteIntervension?.name}
        rootStyle={{ width: "100%" }}
      />
      <CustomKeyValue
        title={"Type"}
        titleStyle={{
          color: "#57585A",
          fontSize: 13,
        }}
        valueStyle={{
          color: "#161718",
          fontWeight: "700",
          fontSize: 16,
        }}
        rootStyle={{ width: "100%" }}
        value={data?.siteIntervension?.siteType?.name}
      />
    </Stack>
    <Stack
      direction={"row"}
      spacing={2}
      alignItems={"center"}
      width={{ xs: "100%", md: "70%", lg: "70%" }}
      my={2}
      justifyContent="space-between"
    >
      <CustomKeyValue
        title={"Ville de l’intervention"}
        titleStyle={{
          color: "#57585A",
          fontSize: 13,
        }}
        valueStyle={{
          color: "#161718",
          fontWeight: "700",
          fontSize: 16,
        }}
        rootStyle={{ width: "100%" }}
        value={data?.siteIntervension?.address?.city}
      />
      <CustomKeyValue
        title={"Adresse de l’intervention"}
        titleStyle={{
          color: "#57585A",
          fontSize: 13,
        }}
        valueStyle={{
          color: "#161718",
          fontWeight: "700",
          fontSize: 16,
        }}
        rootStyle={{ width: "100%" }}
        value={data?.siteIntervension?.address?.address}
      />
    </Stack>

        {data?.operationalCreator?.id ? (
          <>
            <Typography
              fontSize={20}
              fontWeight="700"
              color={COLORS.primary}
              sx={{ borderBottom: "1px solid #c2c2c2", width: "80px" }}
            >
              Opérationnel
            </Typography>

            <Stack
              direction="row"
              my={2}
              width={"70%"}
              justifyContent="space-between"
            >
              <CustomKeyValue
                title={"Nom"}
                titleStyle={{
                  color: "#57585A",
                  fontSize: 13,
                }}
                valueStyle={{
                  color: "#161718",
                  fontWeight: "700",
                  fontSize: 16,
                }}
                value={`${data?.operationalCreator?.firstname} ${data?.operationalCreator?.lastname}`}
                rootStyle={{ width: "100%" }}
              />
              <CustomKeyValue
                title={"Numéro de téléphone"}
                titleStyle={{
                  color: "#57585A",
                  fontSize: 13,
                }}
                valueStyle={{
                  color: "#161718",
                  fontWeight: "700",
                  fontSize: 16,
                }}
                value={data?.operationalCreator?.phoneNumber}
                rootStyle={{ width: "100%" }}
              />
            </Stack>
          </>
        ) : null}

        <Divider />

        <Stack
          direction="row"
          my={2}
          width={"70%"}
          justifyContent="space-between"
        >
          <CustomKeyValue
            title={"Commentaire"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
              height: 100
            }}
            value={data?.comment}
            rootStyle={{ width: "100%", overflow:"auto", marginRight: 9 }}
          />
          <CustomKeyValue
            title={"Date de services"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
            }}
            value={`Du ${new Date(
              data?.startDate
            ).toLocaleDateString("fr")} au ${new Date(
              data?.endDate
            ).toLocaleDateString("fr")}`}
            rootStyle={{ width: "100%" }}
          />
        </Stack>
  </Box>
  );
}

export default CommandDetailSite;
