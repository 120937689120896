import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import NotAuthorized from "../components/NotAuthorized";

export default function PrivateRoute({ children, name }) {
  const token = localStorage.getItem("accessToken");
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");

  let location = useLocation();
  const routeToShow = () => {
    switch (agent?.authAccountType) {
      case "superadmin":
        return [
          "home",
          "commands",
          "sites",
          "admins",
          "clients",
          "agents",
          "agent-types",
          "service-types",
        ];
      case "admin":
        return [
          "home",
          "commands",
          "sites",
          "clients",
          "agents",
          "agent-types",
          "service-types",
          "operationnels",
        ];
      case "agent":
        return ["boncommands"];
      case "client":
        return ["home", "commands", "sites", "agents", "operationnels"];
      case "sousCompteClient":
        return ["home", "commands", "sites"];

      default:
        break;
    }
  };
  if (!token) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return routeToShow().includes(name) ? children : <NotAuthorized />;
}
