import React from "react";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { v4 } from "uuid";
import { CustomButton } from "../../../components";
import { COLORS } from "../../../utils";
import { Add, Close, CopyAll } from "@mui/icons-material";
import { TimePicker } from "@mui/x-date-pickers";
import SigninTextInput from "../../auth/components/SigninTextInput";
import { UsersSVG } from "../../../utils/svgImages";
import { isDesktop } from "react-device-detect";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CrenauxCard({
  agentTypes,
  date,
  resources,
  setResources,
  serviceDays,
}) {
  const onDuplicate = (pKeyVal, index) => {
    const currentResource = {
      ...resources[pKeyVal][index],
    };

    const indexKey = v4();

    //copy one resource in all sections
    setResources((current) => {
      let copy = { ...current };
      const DAYS = Object.keys(copy).filter((val) => val !== pKeyVal);

      DAYS.forEach((day) => {
        const parts = day.split("_");
        const dayOfWeek = parts[0];
        // console.log(day, index, copy);
        copy = {
          ...copy,
          [day]: {
            ...copy[day],
            [indexKey]: {
              ...currentResource,
              dayOfWeek: dayOfWeek,
              date: Object.values(copy[day])[0]?.date,
              index: indexKey,
            },
          },
        };
        // console.log(copy)
      });
      return copy;
    });

    return;
    //copy one resource in same section
    setResources((current) => {
      let copy = { ...current };
      copy = {
        ...copy,
        [pKeyVal]: {
          ...copy[pKeyVal],
          [Object.values(resources[pKeyVal]).length + 1]: currentResource,
        },
      };
      return copy;
    });

    // setResources(
    //   getDaysOfWeekBetweenDatesFull(date.sDate, date.eDate)
    //     .map((val) => ({
    //       dayOfWeek: dayOfWeekAsString(val.day).toUpperCase(),
    //       date: val.date,
    //     }))
    //     .sort(function sortByDay(a, b) {
    //       let day1 = a.dayOfWeek.toLowerCase();
    //       let day2 = b.dayOfWeek.toLowerCase();
    //       return daySorter[day1] - daySorter[day2];
    //     })
    //     .reduce(
    //       (a, b) => ({
    //         ...a,
    //         [b.dayOfWeek]: {
    //           0: { ...currentResource, index: 0, dayOfWeek: b.dayOfWeek },
    //         },
    //       }),
    //       {}
    //     )
    // );
  };

  const addRessource = (pKeyVal, date) => {
    const parts = pKeyVal.split("_");
    const dayOfWeek = parts[0];

    const indexKey = v4();

    // console.log("@@@@", resources)

    // console.log("####",{
    //   ...resources,
    //   [pKeyVal]: {
    //     ...resources[pKeyVal],
    //     [Object.values(resources[pKeyVal]).length + 1]: {
    //       dayOfWeek: dayOfWeek,
    //       startTime: "",
    //       endTime: "",
    //       numberOfAgent: "",
    //       agentTypeId: "",
    //       index: Object.values(resources[pKeyVal]).length + 1,
    //     },
    //   },
    // })
    // return

    setResources({
      ...resources,
      [pKeyVal]: {
        ...resources[pKeyVal],
        [indexKey]: {
          dayOfWeek: dayOfWeek,
          startTime: "",
          endTime: "",
          numberOfAgent: "",
          agentTypeId: "",
          index: indexKey,
          date: date,
        },
      },
    });
  };

  const removeRessource = (pKeyVal, index) => {
    setResources((current) => {
      const copy = { ...current };
      delete copy[pKeyVal][index];
      return copy;
    });
  };

  const updateRessource = (pKeyVal, index, keyVal, value) => {
    setResources({
      ...resources,
      [pKeyVal]: {
        ...resources[pKeyVal],
        [index]: {
          ...resources[pKeyVal][index],
          [keyVal]: value,
        },
      },
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        border: "1px solid #c2c2c2",
        borderRadius: 2,
        p: 2,
        pl: 4,
      }}
      mt={4}
      mb={4}
    >
      {Object.values(serviceDays || {}).map((item, index, arr) => (
        <Stack
          key={index}
          direction={"row"}
          spacing={3}
          sx={{
            borderBottom:
              arr.length - 1 !== index ? "1px solid #c2c2c2" : "none",
            pb: 4,
          }}
          alignItems="flex-end"
          my={3}
        >
          <div style={{ width: 180 }}>
            <Typography fontWeight="700" textTransform="capitalize">
              {`${String(item.dayOfWeek).toLocaleLowerCase()}: ${new Date(
                item.date
              ).toLocaleDateString("fr")}`}
            </Typography>
          </div>

          <Box>
            {Object.values(
              resources[`${item.dayOfWeek}_${item.date}`] || {}
            ).map((value, index) => (
              // <Stack
              //   key={index}
              //   direction={"row"}
              //   alignItems={"flex-end"}
              //   spacing={2}
              // >
              <Grid
                container
                columnSpacing={3}
                sx={{ alignItems: "flex-end" }}
                key={index}
              >
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <Typography
                      color="black"
                      sx={{
                        fontSize: {
                          xs: 12,
                          md: 14,
                        },
                        fontWeight: 600,
                        mb: 1,
                      }}
                    >
                      Qualification
                    </Typography>
                    <Select
                      variant="standard"
                      fullWidth
                      value={value.agentTypeId}
                      onChange={({ target }) =>
                        updateRessource(
                          `${value.dayOfWeek}_${value.date}`,
                          value.index,
                          "agentTypeId",
                          target.value
                        )
                      }
                      input={<OutlinedInput />}
                      style={{
                        backgroundColor: "#fff",
                      }}
                      MenuProps={MenuProps}
                    >
                      {agentTypes.map((agent) => (
                        <MenuItem key={agent.id} value={agent.id}>
                          {agent.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                  <SigninTextInput
                    title={"Nombre"}
                    endIcon={<UsersSVG />}
                    icon={null}
                    type={"number"}
                    rootStyle={{ height: "auto" }}
                    onChange={(val) =>
                      updateRessource(
                        `${value.dayOfWeek}_${value.date}`,
                        value.index,
                        "numberOfAgent",
                        val
                      )
                    }
                    value={value.numberOfAgent}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box>
                    <Typography
                      color="black"
                      sx={{
                        fontSize: {
                          xs: 12,
                          md: 14,
                        },
                        fontWeight: 600,
                        mb: 1,
                      }}
                    >
                      Horaire
                    </Typography>

                    <Stack direction="row" alignItems="center" spacing={2}>
                      <TimePicker
                        value={value.startTime}
                        ampm={false}
                        label="Début"
                        onChange={(time) =>
                          updateRessource(
                            `${value.dayOfWeek}_${value.date}`,
                            value.index,
                            "startTime",
                            time
                          )
                        }
                        disableOpenPicker={isDesktop}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                      <TimePicker
                        value={value.endTime}
                        ampm={false}
                        label="Fin"
                        onChange={(time) =>
                          updateRessource(
                            `${value.dayOfWeek}_${value.date}`,
                            value.index,
                            "endTime",
                            time
                          )
                        }
                        disableOpenPicker={isDesktop}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={12} columnGap={2} sx={{display: "flex"}} md={4}>
                  <Tooltip
                    title="Ajouter une nouvelle ressource"
                    arrow
                    placement="top"
                  >
                    <Box>
                      <CustomButton
                        leftComponent={<Add htmlColor={COLORS.white} />}
                        rootStyle={{
                          height: 40,
                          width: 50,
                          backgroundColor: COLORS.primary,
                        }}
                        onClick={() =>
                          addRessource(
                            `${item.dayOfWeek}_${item.date}`,
                            item.date
                          )
                        }
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title="Appliquer aux autres dates"
                    arrow
                    placement="top"
                  >
                    <Box>
                      <CustomButton
                        leftComponent={<CopyAll htmlColor={COLORS.white} />}
                        rootStyle={{
                          height: 40,
                          width: 50,
                          backgroundColor: COLORS.orange,
                        }}
                        onClick={() =>
                          onDuplicate(
                            `${value.dayOfWeek}_${value.date}`,
                            value.index
                          )
                        }
                      />
                    </Box>
                  </Tooltip>

                  {Object.values(resources[`${value.dayOfWeek}_${value.date}`])
                    .length > 1 ? (
                    <Tooltip title="Supprimer" arrow placement="top">
                      <Box>
                        <CustomButton
                          leftComponent={<Close htmlColor={COLORS.primary} />}
                          rootStyle={{
                            height: 40,
                            width: 50,
                            backgroundColor: "#989A9B70",
                          }}
                          onClick={() =>
                            removeRessource(
                              `${value.dayOfWeek}_${value.date}`,
                              value.index
                            )
                          }
                        />
                      </Box>
                    </Tooltip>
                  ) : null}
                </Grid>
              </Grid>
              // {/* <Stack direction="row" alignItems={"flex-end"} spacing={2}>

              // </Stack> */}

              // </Stack>
            ))}
          </Box>
        </Stack>
      ))}
    </Box>
  );
}

export default CrenauxCard;
