const COLORS = {
  primary: "#161B38",
  red: "#CB1728",
  green: "#127D07",
  white: "#ffffff",
  black: "#000000",
  orange: "#f88122",
  grey: "#909293",
  blue: "#0fb9c5",
  light_red: "#FF7171",
  light_orange: "#FF9F40",
  brokenWhite: "#F4F3F7",
  yellow: "#F2C94C"
};
export default COLORS;
