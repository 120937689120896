import React, { useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { COLORS } from "../../utils";
import { useTheme } from "@mui/styles";
import Profile from "../profile/Profile";
import AgentType from "../agentType/AgentType";
import ServiceType from "../serviceType/ServiceType";
import SiteType from "../siteType/SiteType";
import Password from "../password/Password";
import ServiceHistory from "../myService/ServiceHistory";
import AgentProfile from "../agentProfile/AgentProfile";

function TabPanel(props) {
  const { children, value, theme, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function Settings() {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");

  const routeToShow = () => {
    switch (agent?.authAccountType) {
      case "superadmin":
        return [
          "Profil",
          "Type d'agents",
          "Type de site",
          "Mot de passe",
        ];
      case "admin":
        return [
          "Profil",
          "Type d'agents",
          "Type de site",
          "Mot de passe",
        ];
      case "agent":
        return [
          "Informations personnelles",
          "Profil d'agent",
          "Historique",
          "Mot de passe",
        ];
      case "client":
        return ["Profil", "Mot de passe"];
      case "sousCompteClient":
        return ["Profil", "Mot de passe", "Entreprise"];

      default:
        break;
    }
  };

  return (
    <Box
      p={4}
      px={{ xs: 2, md: 4, lg: 4 }}
      style={{
        backgroundColor: "#f4f3f7",
        minHeight: "100vh",
      }}
    >
      <Typography fontWeight="600" color="#161B38" fontSize={28} my={3}>
        Paramètres
      </Typography>

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: 5,
        }}
      >
        <Tabs
          value={value}
          onChange={(_, val) => setValue(val)}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          sx={{
            "& > :first-child": {
              display: "none",
            },
            backgroundColor: COLORS.primary + "50",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
          TabIndicatorProps={{
            sx: {
              bottom: 0,
            },
            style: {
              // height: '100%',
              backgroundColor: "transparent",
            },
          }}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {routeToShow().map((title, inx) => (
            <Tab
              key={title}
              value={inx}
              label={title}
              style={{
                textTransform: "none",
                color: inx === value ? COLORS.white : COLORS.primary,
                fontWeight: inx === value ? "bold" : "normal",
                padding: theme.spacing(0, 3),
                margin: 10,
                borderRadius: 5,
                backgroundColor: inx === value ? COLORS.primary : "transparent",
              }}
            />
          ))}
        </Tabs>

        <TabPanel theme={theme} value={value} index={0} dir={theme.direction}>
          <Profile />
        </TabPanel>
        {["superadmin", "admin"].includes(agent?.authAccountType) ? (
          <>
            <TabPanel
              theme={theme}
              value={value}
              index={1}
              dir={theme.direction}
            >
              <AgentType />
            </TabPanel>
            {/* <TabPanel
              theme={theme}
              value={value}
              index={2}
              dir={theme.direction}
            >
              <ServiceType />
            </TabPanel> */}
            <TabPanel
              theme={theme}
              value={value}
              index={2}
              dir={theme.direction}
            >
              <SiteType />
            </TabPanel>
            <TabPanel
              theme={theme}
              value={value}
              index={3}
              dir={theme.direction}
            >
              <Password />
            </TabPanel>
          </>
        ) : null}
        {agent.authAccountType === "agent" ? (
          <>
            <TabPanel
              theme={theme}
              index={1}
              value={value}
              dir={theme.direction}
            >
              <AgentProfile />
            </TabPanel>
            <TabPanel
              theme={theme}
              index={2}
              value={value}
              dir={theme.direction}
            >
              <ServiceHistory />
            </TabPanel>
            <TabPanel
              theme={theme}
              value={value}
              index={3}
              dir={theme.direction}
            >
              <Password />
            </TabPanel>
          </>
        ) : null}
        {["client","sousCompteClient"].includes(agent.authAccountType) ? (
          <TabPanel theme={theme} value={value} index={1} dir={theme.direction}>
            <Password />
          </TabPanel>
        ) : null}
        {["sousCompteClient"].includes(agent.authAccountType) ? (
          <TabPanel theme={theme} value={value} index={2} dir={theme.direction}>
            <Profile isOperator />
          </TabPanel>
        ) : null}
      </Box>
    </Box>
  );
}

Settings.propTypes = {};

export default Settings;
