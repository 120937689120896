import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import FormModalTitle from "../../../components/FormModalTitle";
import SigninTextInput from "../../auth/components/SigninTextInput";
import SigninDropdown from "../../auth/components/SigninDropdown";
import { COUNTRIES } from "../../../utils/countries";
import { createSite, updateSite } from "../../../redux/sites/sitesActions";
import { siteYup } from "../../../utils/yupValidation";
import { CustomDropzone } from "../../../components";
import { fileUploader } from "../../../utils/uploadUtils";
import { Public, SquareFoot } from "@mui/icons-material";
import CustomLoader from "../../../components/CustomLoader";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, md: 650, lg: 700 },
  bgcolor: "background.paper",
  borderRadius: 2,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SiteFormModal({ data, open, onClose }) {
  const dispatch = useDispatch();

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({ multiple: false });

  const agent = JSON.parse(localStorage.getItem("agent") || "{}");
  const siteTypes = useSelector((state) => Object.values(state.siteTypes));
  const clients = useSelector((state) => Object.values(state.clients));
  const operationnels = useSelector((state) => state.operationnels);

  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(null);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFieldValue(
      "operationelAffectes",
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleClose = () => {
    onClose();
    resetForm();
  };

  const callback = () => {
    handleClose();
    setLoading(false);
    window.location.reload(false);
  };

  const onError = () => {
    setLoading(false);
  };

  async function _handleSubmit(values) {
    setLoading(true);
    let imageUrl = undefined;

    if (acceptedFiles?.length) {
      imageUrl = await fileUploader(acceptedFiles[0]);
    }

    if (values.id !== "") {
      dispatch(
        updateSite(
          {
            ...values,
            imageUrl: data.imageUrl || imageUrl,
            superficy: Number(values.superficy),
          },
          callback
        )
      );
    } else {
      let toSave = {
        ...values,
        isActivated: "false",
        isActive: true,
        superSite: false,
        gpsCoordinates: {
          long: longitude,
          lat: latitude,
        },
        imageUrl,
        superficy: Number(values.superficy),
      };

      if (agent?.authAccountType === "admin") {
        toSave = {
          ...toSave,
          adminId: agent.id,
        };
      } else {
        toSave = {
          ...toSave,
          clientId: agent?.client?.id || agent.id,
        };
      }
      if (agent?.authAccountType === "sousCompteClient") {
        toSave.operationelAffectes = ["" + agent?.id];
      }
      delete toSave.id;
      dispatch(createSite(toSave, callback, onError));
    }
  }

  const {
    values,
    handleSubmit,
    setValues,
    errors,
    setFieldValue,
    resetForm,
    isValid,
  } = useFormik({
    initialValues: {
      name: "",
      superficy: "",
      id: "",
      siteTypeId: null,
      clientId: null,
      complementAddress: "",
      localisation: "",
      address: "",
      postalCode: "0000",
      city: "",
      country: "France",
      operationelAffectes: [],
    },
    validationSchema: Yup.object().shape(siteYup),
    onSubmit(values) {
      _handleSubmit(values);
    },
  });

  const onAddressChange=(val)=>{
    setAddress(val);
    setFieldValue("address", val.label);
    setFieldValue("complementAddress", val.value.reference);
    geocodeByPlaceId(val.value.reference)
    .then(results => setFieldValue("city", results[0]?.address_components.filter(val=> val.types.includes("locality"))?.[0]?.long_name))
    .catch(error => console.error(error));
  }

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(function (position) {
  //     setLatitude(position.coords.latitude);
  //     setLongitude(position.coords.longitude);
  //   });
  // }, []);

  const disabled =
    !values.address.trim() ||
    !values.city.trim() ||
    !values.name.trim() ||
    values.siteTypeId === null;

  useEffect(() => {
    if (data?.id) {
      setValues(data);
      const { address } = data;
      setFieldValue("complementAddress", address.complementAddress);
      setFieldValue("address", address.address);
      setFieldValue("postalCode", address.postalCode);
      setFieldValue("city", address.city);
      setFieldValue("country", address.country);
      setFieldValue("siteTypeId", data.siteType?.id);
      setFieldValue("clientId", data.client?.id);
    }
  }, [data, setFieldValue, setValues]);

  const filteredOperateurs = Object.values(operationnels).filter(
    (val) =>
      val?.client?.id ===
      (["client"].includes(agent?.authAccountType)
        ? agent?.id
        : values.clientId)
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit}>
        <Box sx={style}>
          <FormModalTitle
            title={`${values.id ? "Modifier" : "Créer"} un site`}
            closeModal={handleClose}
            disabled={disabled}
          />
          <Box p={4} pt={0} style={{ maxHeight: "85vh", overflow: "auto" }}>
            <Stack
              direction="row"
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <SigninTextInput
                title={"Nom *"}
                value={values.name}
                id="name"
                name="name"
                onChange={(value) => setFieldValue("name", value)}
                errorMessage={errors.name}
              />
              {/* <SigninTextInput
                title={"Type"}
                value={values.type}
                id="type"
                name="type"
                onChange={(value) => setFieldValue("type", value)}
                errorMessage={errors.type}
              /> */}
              <FormControl sx={{ my: 1 }} fullWidth>
                <Typography
                  color="black"
                  sx={{
                    fontSize: {
                      xs: 12,
                      md: 14,
                    },
                    fontWeight: 600,
                    mb: 1,
                  }}
                >
                  Type *
                </Typography>
                <Select
                  variant="standard"
                  value={values.siteTypeId}
                  onChange={({ target }) =>
                    setFieldValue("siteTypeId", target.value)
                  }
                  input={<OutlinedInput />}
                  style={{
                    backgroundColor: "#fff",
                  }}
                  MenuProps={MenuProps}
                >
                  {siteTypes.map((agent, index) => (
                    <MenuItem key={index} value={agent.id}>
                      {agent.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          
            <Stack
              direction="row"
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              {/* <SigninTextInput
                title={"Code Postal *"}
                value={values.postalCode}
                id="postalCode"
                name="postalCode"
                icon={<QrCode />}
                onChange={(value) => setFieldValue("postalCode", value)}
                errorMessage={errors.postalCode}
              /> */}
                <Stack width="100%">
              <Typography
                color="black"
                sx={{
                  fontSize: {
                    xs: 12,
                    md: 14,
                  },
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                Adresse *
              </Typography>
              <GooglePlacesAutocomplete
                selectProps={{
                  value: address,
                  onChange: onAddressChange,
                  placeholder: "Adresse",
                  styles: {
                    container: (provided) => ({
                      ...provided,
                      width: "100%",
                      height: "45px",
                      marginBottom: "10px",
                    }),
                    input: (provided) => ({
                      ...provided,
                      width: "100%",
                      height: "45px",
                    }),
                    option: (provided) => ({
                      ...provided,
                    }),
                  },
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                  country: ['fr'],
                  }
                }}
              />
              {/* <SigninTextInput
                title={"Adresse *"}
                value={values.address}
                id="address"
                name="address"
                onChange={(value) => setFieldValue("address", value)}
                errorMessage={errors.address}
                icon={<LocationOn />}
              /> */}

              {/* <SigninTextInput
                title={"Complement Adresse"}
                value={values.complementAddress}
                id="complementAddress"
                name="complementAddress"
                icon={<LocationOn />}
                onChange={(value) => setFieldValue("complementAddress", value)}
                errorMessage={errors.complementAddress}
              /> */}
            </Stack>
              <SigninDropdown
                title={"Pays"}
                icon={<Public />}
                value={values.country}
                id="country"
                name="country"
                data={COUNTRIES}
                disabled
                onChange={(value) => setFieldValue("country", value)}
                errorMessage={errors.country}
              />
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <SigninTextInput
                title={"Ville *"}
                icon={<Public />}
                value={values.city}
                id="city"
                name="city"
                onChange={(value) => setFieldValue("city", value)}
                errorMessage={errors.city}
              />

              <SigninTextInput
                title={"Superficie (m²)"}
                value={values.superficy}
                id="superficy"
                name="superficy"
                type="number"
                onChange={(value) => setFieldValue("superficy", value)}
                errorMessage={errors.superficy}
                icon={<SquareFoot />}
              />
            </Stack>

            {["admin"].includes(agent?.authAccountType) ? (
              <FormControl sx={{ mb: 3, mt: 2 }} fullWidth>
                <Typography
                  color="black"
                  sx={{
                    fontSize: {
                      xs: 12,
                      md: 14,
                    },
                    fontWeight: 600,
                    mb: 1,
                  }}
                >
                  Client
                </Typography>
                <Select
                  variant="standard"
                  value={values.clientId}
                  onChange={({ target }) =>
                    setFieldValue("clientId", target.value)
                  }
                  input={<OutlinedInput />}
                  style={{
                    backgroundColor: "#fff",
                  }}
                  MenuProps={MenuProps}
                >
                  {clients.map((client, index) => (
                    <MenuItem key={index} value={client.id}>
                      {client.raisonSociale}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}

            {agent?.authAccountType !== "sousCompteClient" ? (
              <FormControl sx={{ my: 1 }} fullWidth>
                <Typography
                  color="black"
                  sx={{
                    fontSize: {
                      xs: 12,
                      md: 14,
                    },
                    fontWeight: 600,
                    mb: 1,
                  }}
                >
                  Opérationnels
                </Typography>
                <Select
                  disableUnderline
                  variant="standard"
                  multiple
                  value={values.operationelAffectes}
                  onChange={handleChange}
                  input={<OutlinedInput />}
                  style={{
                    backgroundColor: "#fff",
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={`${operationnels[value]?.firstname} ${operationnels[value]?.lastname}`}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {filteredOperateurs.map((service) => (
                    <MenuItem
                      key={service.id}
                      value={service.id}
                      // style={getStyles(service.id, command.serviceTypeIds, theme)}
                    >
                      {service.firstname} {service.lastname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
            <CustomDropzone
              title="l'image du site"
              showAvatar={false}
              acceptedFiles={acceptedFiles}
              getRootProps={getRootProps({ className: "dropzone" })}
              getInputProps={getInputProps()}
            />
          </Box>
        </Box>
        <CustomLoader open={loading} />
      </form>
    </Modal>
  );
}

SiteFormModal.propTypes = {};

export default SiteFormModal;
