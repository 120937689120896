import axios from "axios";
import { SERVER } from "../../utils/server";
import sitesTypes from "./sitesTypes";
import notificationTypes from "../notifications/notificationTypes";
import { getErrorMessage } from "../../utils/errorCodeMessage";

export const createSite = (site, callback, onError) => async (dispatch) => {
  try {
    const results = await axios.post(`${SERVER}site`, site);
    dispatch({
      type: sitesTypes.CREATE_SITE,
      payload: results.data.data,
    });
    dispatch({
      type: notificationTypes.SHOW_SUCCESS,
      payload: { message: "Enregistré avec succès" },
    });
    callback?.();
  } catch (error) {
    if (error.response) {
      // console.log(error.response.data);
      // console.log(error.response.status);
      if (error.response.status === 400) {
        onError?.();
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: {
            message: getErrorMessage(error.response.data?.data[0]?.param),
          },
        });
      }
      return;
    }
    onError?.();
    dispatch({
      type: notificationTypes.SHOW_ERROR,
      payload: { message: "Une erreur est survenue" },
    });
    console.error("Site creation error", error);
  }
};

export const listSites = () => async (dispatch) => {
  try {
    const agent = JSON.parse(localStorage.getItem("agent") || "{}");
    const results = await axios.get(`${SERVER}sites`);
    const sites = results.data.data;

    const filteredSites = sites.filter((val) =>
      !["superadmin", "admin"].includes(agent?.authAccountType)
        ? (agent.rules?.includes("CLIENT_ADMIN") || agent?.authAccountType === "client")
          ? [agent?.client?.id, agent?.id].includes(val?.client?.id)
          : val?.operationelAffectes?.map((val) => val.id).includes(agent.id)
        : val
    );

    dispatch({ type: sitesTypes.LIST_SITES, payload: filteredSites });
  } catch (error) {
    console.error("Site list error", error);
  }
};

export const deleteSite = (id) => async (dispatch) => {
  await axios.delete(`${SERVER}site/${id}`);

  dispatch({ type: sitesTypes.REMOVE_SITE, payload: id });
};

export const updateSite =
  (site, callback, toastMessage = "Modifié avec succès") =>
  async (dispatch) => {
    try {
      const results = await axios.put(`${SERVER}site`, site);
      dispatch({
        type: sitesTypes.UPDATE_SITE,
        payload: results.data.data,
      });
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: toastMessage },
      });
      callback?.();
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        // console.log(error.response.status);
        if (error.response.status === 400) {
          dispatch({
            type: notificationTypes.SHOW_ERROR,
            payload: {
              message: getErrorMessage(error.response.data?.data[0]?.param),
            },
          });
        }
        return;
      }
      console.log("update site error", error);
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
    }
  };
