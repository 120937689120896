import React from "react";
import { Typography } from "@mui/material";

function CustomKeyValue({
  title,
  value,
  titleStyle,
  valueStyle,
  rootStyle,
}) {
  return (
    <div style={{ ...rootStyle }}>
      <Typography sx={{ color: "#757575", fontSize: 12, ...titleStyle }}>
        {title}
      </Typography>
      <Typography sx={{ ...valueStyle }}>{value}</Typography>
    </div>
  );
}

CustomKeyValue.propTypes = {};

export default CustomKeyValue;
