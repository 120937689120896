import React from "react";
import {
  FileSVG,
  HomeSVG,
  MapWhiteSVG,
  UsersWhiteSVG,
} from "../utils/svgImages";
import Admins from "../views/admins/Admins";
import Agents from "../views/agents/Agents";
import Clients from "../views/clients/Clients";
import CommandBon from "../views/commands/CommandBon";
import CommandList from "../views/commands/CommandList";
import Commands from "../views/commands/Commands";
import Operationnels from "../views/operationnels/Operationnels";
import Sites from "../views/sites/Sites";
import PrivateRoute from "./PrivateRoute";

const routes = [
  {
    icon: <HomeSVG style={{ width: 25, height: 25, color: "#fff" }} />,
    iconActive: <HomeSVG style={{ width: 25, height: 25, color: "#000" }} />,
    name: "Accueil",
    path: "/home/dashboard",
    key: "home",
    component: (
      <PrivateRoute name="home">
        <Commands />
      </PrivateRoute>
    ),
  },
  {
    icon: <FileSVG style={{ color: "#fff" }} />,
    iconActive: <FileSVG style={{ color: "#000" }} />,
    name: "Mes commandes",
    path: "/home/my-commands",
    key: "commands",
    component: (
      <PrivateRoute name="commands">
        <CommandList />
      </PrivateRoute>
    ),
  },
  {
    icon: <FileSVG style={{ color: "#fff" }} />,
    iconActive: <FileSVG style={{ color: "#000" }} />,
    name: "Commandes",
    path: "/home/my-commands",
    key: "commands-admin",
    component: (
      <PrivateRoute name="commands">
        <CommandList />
      </PrivateRoute>
    ),
  },
  {
    icon: <FileSVG style={{ color: "#fff" }} />,
    iconActive: <FileSVG style={{ color: "#000" }} />,
    name: "Bons de commande",
    path: "/home/bon-commands",
    key: "boncommands",
    component: (
      <PrivateRoute name="boncommands">
        <CommandBon />
      </PrivateRoute>
    ),
  },
  {
    icon: <MapWhiteSVG style={{ color: "#fff" }} />,
    iconActive: <MapWhiteSVG style={{ color: "#000" }} />,
    name: "Mes sites",
    path: "/home/my-sites",
    key: "sites",
    component: (
      <PrivateRoute name="sites">
        <Sites />
      </PrivateRoute>
    ),
  },
  {
    icon: <UsersWhiteSVG style={{ color: "#fff" }} />,
    iconActive: <UsersWhiteSVG style={{ color: "#000" }} />,
    name: "Admins",
    path: "/home/admins",
    key: "admins",
    component: (
      <PrivateRoute name="admins">
        <Admins />
      </PrivateRoute>
    ),
  },
  {
    icon: <UsersWhiteSVG style={{ color: "#fff" }} />,
    iconActive: <UsersWhiteSVG style={{ color: "#000" }} />,
    name: "Clients",
    path: "/home/clients",
    key: "clients",
    component: (
      <PrivateRoute name="clients">
        <Clients />
      </PrivateRoute>
    ),
  },
  {
    icon: <UsersWhiteSVG style={{ color: "#fff" }} />,
    iconActive: <UsersWhiteSVG style={{ color: "#000" }} />,
    name: "Agents",
    path: "/home/agents",
    key: "agents",
    component: (
      <PrivateRoute name="agents">
        <Agents />
      </PrivateRoute>
    ),
  },
  {
    icon: <UsersWhiteSVG style={{ color: "#fff" }} />,
    iconActive: <UsersWhiteSVG style={{ color: "#000" }} />,
    name: "Opérationnels",
    path: "/home/operationnels",
    key: "operationnels",
    component: (
      <PrivateRoute name="operationnels">
        <Operationnels />
      </PrivateRoute>
    ),
  },
  // {
  //   icon: <UsersWhiteSVG />,
  //   name: "Type d'Agents",
  //   path: "/home/agent-types",
  //   key: "agent-types",
  //   component: (
  //     <PrivateRoute name="agent-types">
  //       <AgentType />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   icon: <UsersWhiteSVG />,
  //   name: "Type de service",
  //   path: "/home/service-types",
  //   key: "service-types",
  //   component: (
  //     <PrivateRoute name="service-types">
  //       <ServiceType />
  //     </PrivateRoute>
  //   ),
  // },
];

export default routes;
