import React, { useState } from "react";
import {
  Box,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CommandStepperCard from "./CommandStepperCard";
import ExistingSiteCard from "./ExistingSiteCard";
import { SearchSVG } from "../../../utils/svgImages";
import { CustomButton } from "../../../components";
import { COLORS } from "../../../utils";

function StepOne({
  toggleSiteMode,
  isCreateSite,
  isSelectSite,
  sites,
  idSiteIntervension,
  NextStepper,
  setIdSiteIntervension,
  onCreateSise,
}) {
  const [search, setSearch] = useState("");
  const [tableIndicator, setTableIndicator] = useState(1);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(8);

  const onNext = () => {
    setTableIndicator((prev) => prev + 1);
    setFrom(to);
    setTo((prev) => prev * 2);
  };

  const onPrevious = () => {
    setTableIndicator((prev) => prev - 1);
    setFrom(from - from);
    setTo(from);
  };

  return (
    <div>
      <Stack direction="row" spacing={5} sx={{ alignItems: "center", overflow:'auto' }} my={4}>
        <CommandStepperCard
          title="Sélectionner un site existant"
          onClick={() => toggleSiteMode("select")}
          active={isSelectSite}
        />
        <CommandStepperCard
          title="Créer un site"
          onClick={onCreateSise}
          active={isCreateSite}
        />
      </Stack>

      <Box>
        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          spacing={3}
          mt={2}
          justifyContent="space-between"
        >
          <TextField
            id="input-with-icon-textfield"
            type="search"
            size="small"
            color="secondary"
            placeholder="Rechercher"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchSVG />
                </InputAdornment>
              ),
              sx: { backgroundColor: "white" },
            }}
            variant="outlined"
            sx={{
              borderRadius: "2%",
              width: { xs: 300, md: 350, lg: 350 },
            }}
            value={search}
            onChange={({ target }) => setSearch(target.value)}
          />
          <Stack direction="row" alignItems="center" spacing={1}>
            {tableIndicator > 1 ? (
              <CustomButton
                title="Précédent"
                rootStyle={{ backgroundColor: "transparent" }}
                titleStyle={{
                  color: COLORS.primary,
                  fontWeight: 550,
                  textDecoration: "underline",
                }}
                onClick={onPrevious}
              />
            ) : null}
            <Typography color="#B9BABC">
              {tableIndicator}-{Math.ceil(sites.length / 8)}
            </Typography>
            {Math.ceil(sites.length / 8) !== tableIndicator ? (
              <CustomButton
                title="Suivant"
                rootStyle={{ backgroundColor: "transparent" }}
                titleStyle={{
                  color: COLORS.primary,
                  fontWeight: 550,
                  textDecoration: "underline",
                }}
                onClick={onNext}
              />
            ) : null}
          </Stack>
        </Stack>

        <Stack
          direction="row"
          sx={{ alignItems: "center", flexWrap: "wrap" }}
          my={4}
        >
          {sites
            .filter((val) =>
              search !== ""
                ? [
                    val.superficy?.toString(),
                    val.type?.toLowerCase(),
                    val.name?.toLowerCase(),
                    val.address?.address?.toLowerCase(),
                    val.address?.city?.toLowerCase(),
                    val.address?.postalCode?.toLowerCase(),
                  ].includes(search)
                : val
            )
            .slice(from, to)
            .map((site, index) => (
              <ExistingSiteCard
                data={site}
                key={index}
                active={idSiteIntervension === site.id}
                onClick={() => setIdSiteIntervension(site.id)}
              />
            ))}
          {!sites.filter((val) =>
            search !== ""
              ? [
                  val.superficy?.toString(),
                  val.type?.toLowerCase(),
                  val.name?.toLowerCase(),
                  val.address?.address?.toLowerCase(),
                  val.address?.city?.toLowerCase(),
                  val.address?.postalCode?.toLowerCase(),
                ].includes(search)
              : val
          ).length ? (
            <Typography textAlign="center" color={"#c2c2c2"}>
              Aucun élément trouvé
            </Typography>
          ) : null}
          {!sites.length ? (
            <Typography textAlign="center">Aucun site disponible</Typography>
          ) : null}
        </Stack>
        <Divider />
      </Box>

      <NextStepper />
    </div>
  );
}

export default StepOne;
