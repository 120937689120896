import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { batch, useDispatch, useSelector } from "react-redux";
import { FileSVG, SearchSVG } from "../../utils/svgImages";
import AgentPropositionCard from "./components/AgentPropositionCard";
import { CustomButton, CustomConfirm } from "../../components";
import { createCommandLine } from "../../redux/commandLine/commandLineActions";
import { COLORS } from "../../utils";
import {
  adminConfirmSelection,
  listApprobations,
} from "../../redux/commandProcessing/commandProcessingActions";
import { listAllHistories } from "../../redux/history/historyActions";
import { isBrowser } from "react-device-detect";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

function AgentProposition() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const agents = useSelector((state) =>
    Object.values(state.agents).filter(
      (val) => !val.isDeleted && !val.isSuspended
    )
  );
  const approbations = useSelector(
    (state) => state.commandProcessing?.approbations
  );
  const responseLineCommandToAgents =
    approbations?.responseLine?.responseLineCommandToAgents;
  const serviceTypes = useSelector((state) =>
    Object.values(state.serviceTypes)
  );
  const agentTypes = useSelector((state) => Object.values(state.agentTypes).filter(admin=>!admin.isDeleted));
  const histories = useSelector((state) => state.histories);

  // const numberOfAgent = state.numberOfAgent;

  const [selectedAgents, setSelectedAgents] = useState({});
  const [search, setSearch] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [agentType, setAgentType] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState("");
  const [modalSource, setModalSource] = useState("");

  const canConfirmSelection = !responseLineCommandToAgents
    ?.map((val) => val.statusApprobationAgent)
    ?.includes("REJECTED");

  const onSelectAgent = (agent, action) => {
    // if (
    //   action === "add" &&
    //   Object.keys(selectedAgents || {}).length === Number(numberOfAgent)
    // ) {
    //   dispatch({
    //     type: notificationTypes.SHOW_ERROR,
    //     payload: { message: `Nombre d'agents requis: ${numberOfAgent}` },
    //   });
    //   return;
    // }
    if (Object.keys(selectedAgents || {}).includes(String(agent?.id))) {
      setSelectedAgents((prev) => {
        delete prev?.[agent?.id];
        return { ...prev };
      });
      
      if (
        responseLineCommandToAgents.filter((val) => val.agentId === agent?.id)
      ) {
        removeAgentOnline(agent);
      }

      return;
    }
    setSelectedAgents({
      ...selectedAgents,
      [agent?.id]: agent,
    });
  };

  const removeAgentOnline = (agent) => {
    dispatch(
      createCommandLine({
        commandLineId: state.commandLine,
        response: [{ agentId: agent?.id, selected: false }],
      })
    );
  };

  const onError = () => {
    setLoading(false);
  };

  const onModalConfirm = () => {
    switch (modalSource) {
      case "CONFIRM":
        onConfirm();
        break;
      case "SAVE":
        onSave();
        break;

      default:
        break;
    }
    onCloseModal();
  };

  const displayInit = () => {
    if (agentType !== "" || search !== "" || serviceType !== "") {
      return true;
    } else {
      return false;
    }
  };

  const resetFilter = () => {
    setAgentType("");
    setSearch("");
    setServiceType("");
  };

  const onModalOpen = (source) => {
    setModalSource(source);
    switch (source) {
      case "CONFIRM":
        setConfirmModalTitle("Etes-vous sûr de vouloir CONFIRMER ?");
        break;
      case "SAVE":
        setConfirmModalTitle("Etes-vous sûr de vouloir ENREGISTRER ?");
        break;

      default:
        break;
    }
    setConfirmModal(true);
  };

  const onCloseModal = () => {
    setConfirmModal(false);
    setConfirmModalTitle("");
    setModalSource("");
  };

  const callback = () => {
    setLoading(false);
    navigate("/home/command-detail", { state: state.command });
  };

  const onConfirm = () => {
    setLoading(true);
    dispatch(
      adminConfirmSelection(
        {
          commandLineId: approbations.id,
          responseLineId: approbations.responseLine.id,
        },
        callback,
        onError
      )
    );
  };

  const onSave = () => {
    setLoading(true);
    const values = Object.keys(selectedAgents || {}).reduce(
      (a, b) => ({ ...a, [b]: { agentId: b, selected: true } }),
      {}
    );

    dispatch(
      createCommandLine(
        {
          commandLineId: state.commandLine,
          response: Object.values(values),
        },
        callback,
        onError
      )
    );
  };

  useEffect(() => {
    batch(() => {
      dispatch(listApprobations(state.commandLine));
      dispatch(listAllHistories());
    });
  }, [dispatch, state.commandLine]);

  useEffect(() => {
    setSelectedAgents(
      responseLineCommandToAgents?.reduce(
        (a, b) => ({ ...a, [b.agentId]: b.agent }),
        {}
      )
    );
  }, [responseLineCommandToAgents]);

  return (
    <Box
      p={4}
      px={{ xs: 2, md: 4, lg: 4 }}
      style={{
        backgroundColor: "#f4f3f7",
        minHeight: "100vh",
      }}
    >
      <Stack
        direction={{ xs: "column", md: "row", lg: "row" }}
        alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
        my={3}
        spacing={2}
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Avatar sx={{ bgcolor: "#161B38", width: 48, height: 48 }}>
            <FileSVG />
          </Avatar>
          <Typography color="primary" fontSize={20} fontWeight={600}>
            Proposer des agents
          </Typography>
        </Stack>

        {/* <Typography>Nombre d'agents requis: {numberOfAgent}</Typography> */}

        <TextField
          id="input-with-icon-textfield"
          type="search"
          size="small"
          color="secondary"
          placeholder="Rechercher"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchSVG />
              </InputAdornment>
            ),
            sx: { backgroundColor: "white" },
          }}
          variant="outlined"
          style={{
            borderRadius: "2%",
            width: isBrowser ? 250 : "100%",
          }}
          value={search}
          onChange={({ target }) => setSearch(target.value)}
        />
      </Stack>

      <Stack direction={"row"} alignItems={"center"} spacing={2} my={3}>
        <div>
          <Typography color="primary" fontSize={14}>
            Type de service
          </Typography>
          <Select
            variant="standard"
            size="small"
            value={serviceType}
            onChange={({ target }) => setServiceType(target.value)}
            input={<OutlinedInput />}
            style={{
              backgroundColor: "#fff",
              minWidth: 170,
            }}
            MenuProps={MenuProps}
          >
            {serviceTypes.map((service) => (
              <MenuItem key={service.id} value={service.id}>
                {service.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div>
          <Typography color="primary" fontSize={14}>
            Type d’agent
          </Typography>
          <Select
            variant="standard"
            size="small"
            value={agentType}
            onChange={({ target }) => setAgentType(target.value)}
            input={<OutlinedInput />}
            style={{
              backgroundColor: "#fff",
              minWidth: 170,
            }}
            MenuProps={MenuProps}
          >
            {agentTypes.map((agent) => (
              <MenuItem key={agent.id} value={agent.id}>
                {agent.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        {/* <div>
          <Typography color="primary" fontSize={14}>
            Distance
          </Typography>
          <Select
            variant="standard"
            size="small"
            // value={orderBy}
            // onChange={({ target }) => setOrderBy(target.value)}
            input={<OutlinedInput />}
            style={{
              backgroundColor: "#fff",
              minWidth: 200,
            }}
            MenuProps={MenuProps}
          >
            <MenuItem value={"asc"}>Du plus proche</MenuItem>
            <MenuItem value={"desc"}>Du plus éloigner</MenuItem>
          </Select>
        </div> */}
        {displayInit() ? (
          <div>
            <CustomButton
              title="Réinitialiser le filtre"
              rootStyle={{ marginTop: 3 }}
              onClick={resetFilter}
            />
          </div>
        ) : null}
      </Stack>

      <Box mt={3} sx={{ backgroundColor: "#64677A20", minHeight: "400px" }}>
        <Grid container>
          <Grid item xs={6}>
            <Stack
              sx={{
                height: "50px",
                backgroundColor: "white",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>Liste des agents</Typography>
            </Stack>
            <Box p={2} sx={{ height: "100%" }}>
              {agents
                ?.filter(
                  (val) =>
                    !Object.keys(selectedAgents || {})?.includes(
                      String(val.id)
                    ) &&
                    (search !== ""
                      ? [
                          val.firstname.toLowerCase(),
                          val.lastname.toLowerCase(),
                          val.adressCompleted?.address.toLowerCase(),
                          val.adressCompleted?.postalCode.toLowerCase(),
                          val.adressCompleted?.country.toLowerCase(),
                          val.adressCompleted?.city.toLowerCase(),
                        ].includes(search.toLowerCase())
                      : val) &&
                    (serviceType !== ""
                      ? histories[serviceType]?.agent?.id === val.id
                      : val) &&
                    (agentType !== ""
                      ? val.agentCategories
                          .map((val) => val.id)
                          .includes(agentType)
                      : val)
                )
                ?.map((agent) => (
                  <AgentPropositionCard
                    agent={agent}
                    approbations={
                      responseLineCommandToAgents?.reduce(
                        (a, b) => ({ ...a, [b.agentId]: b }),
                        {}
                      ) || []
                    }
                    onSelect={() => onSelectAgent(agent, "add")}
                  />
                ))}
              {!agents?.filter(
                (val) =>
                  !Object.keys(selectedAgents || {})?.includes(String(val.id))
              ).length ? (
                <Stack
                  justifyContent={"center"}
                  alignItems="center"
                  sx={{ height: "100%" }}
                >
                  <img src="/paper.png" style={{ width: 100 }} alt="no item" />
                  <Typography textAlign={"center"} fontSize={12}>
                    Liste vide
                  </Typography>
                </Stack>
              ) : null}
            </Box>
          </Grid>

          <Grid
            item
            xs={6}
            sx={{ borderLeft: "1px solid #c2c2c2", minHeight: "400px" }}
          >
            <Stack
              sx={{
                height: "50px",
                backgroundColor: "white",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>Agents sélectionnés</Typography>
            </Stack>
            <Box p={2} sx={{ height: "100%" }}>
              {!Object.values(selectedAgents || {}).length ? (
                <Stack
                  justifyContent={"center"}
                  alignItems="center"
                  sx={{ height: "100%" }}
                >
                  <img src="/paper.png" style={{ width: 100 }} alt="no item" />
                  <Typography textAlign={"center"} fontSize={12}>
                    Aucun Agent sélectionné
                  </Typography>
                </Stack>
              ) : null}
              {Object.values(selectedAgents || {})?.map((agent) => (
                <AgentPropositionCard
                  agent={agent}
                  isSelected
                  showAdd={!approbations?.responseLine?._isConfirmed}
                  approbations={
                    responseLineCommandToAgents?.reduce(
                      (a, b) => ({ ...a, [b.agentId]: b }),
                      {}
                    ) || []
                  }
                  onSelect={() => onSelectAgent(agent)}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {loading && (
        <CustomButton
          isLoading={true}
          rootStyle={{
            padding: 1.5,
            width: "100%",
            mt: 2,
            backgroundColor: "#c2c2c280",
          }}
        />
      )}
      <div style={{ display: loading ? "none" : "block" }}>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          spacing={2}
          mt={2}
        >
          <CustomButton
            title="Sortir"
            onClick={() => navigate(-1)}
            titleStyle={{ color: COLORS.primary }}
            rootStyle={{
              padding: 1.5,
              backgroundColor: "transparent",
              border: `1px solid ${COLORS.primary}`,
              width: 150,
            }}
          />

          {Object.values(selectedAgents || {}).length ? (
            <Stack
              direction={{ xs: "column", md: "row", lg: "row" }}
              alignItems="center"
              spacing={2}
            >
              <CustomButton
                title="Enregistrer"
                onClick={() => onModalOpen("SAVE")}
                titleStyle={{ color: COLORS.primary }}
                rootStyle={{
                  padding: 1.5,
                  backgroundColor: "transparent",
                  border: `1px solid ${COLORS.primary}`,
                  width: 150,
                }}
              />
              {!approbations?.responseLine?._isConfirmed &&
              canConfirmSelection &&
              approbations?.responseLine?.responseLineCommandToAgents
                ?.length ? (
                <CustomButton
                  title="Confirmer la sélection"
                  onClick={() => onModalOpen("CONFIRM")}
                  rootStyle={{
                    padding: 1.5,
                    minWidth: 150,
                    paddingLeft: 4,
                    paddingRight: 4,
                  }}
                />
              ) : null}
            </Stack>
          ) : null}
        </Stack>
        <CustomConfirm
          showIcon={false}
          visible={confirmModal}
          title={confirmModalTitle}
          onConfirm={onModalConfirm}
          onClose={onCloseModal}
        />
      </div>
    </Box>
  );
}

AgentProposition.propTypes = {};

export default AgentProposition;
