import operationnelTypes from "./operationnelTypes";

const operationnelReducer = (state = {}, action) => {
  const payload = action.payload;
  if (action.type === operationnelTypes.CREATE_OPERATIONNEL) {
    return { [payload.id]: { ...payload }, ...state };
  }
  if (action.type === operationnelTypes.REMOVE_OPERATIONNEL) {
    const localState = { ...state };
    delete localState[payload];
    return { ...localState };
  }
  if (action.type === operationnelTypes.UPDATE_OPERATIONNEL) {
    return { ...state, [payload.id]: { ...payload } };
  }
  if (action.type === operationnelTypes.LIST_OPERATIONNELS) {
    let tempData = {};
    for (let obj of payload) {
      tempData[obj.id] = { ...obj };
    }
    return { ...tempData };
  }
  return state;
};

export default operationnelReducer;
