import React from "react";
import { Box, Typography } from "@mui/material";

function CommandEmpty() {
  return (
    <Box sx={{ marginTop: 10 }}>
      <Typography textAlign="center">
        {" "}
        <img
          src="/error.png"
          alt="error"
          style={{ width: "250px", height: "225px" }}
        />
      </Typography>
      <Typography fontSize={14} color="#4F4F4F" mt={1} textAlign="center">
        Vous n’avez pas encore fait de commande
      </Typography>
    </Box>
  );
}

export default CommandEmpty;
