import React from "react";
import { Stack, Typography } from "@mui/material";

function SiteCardRow({ title, value, rootStyle, active }) {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        my: 2,
        ...rootStyle,
      }}
    >
      <Typography fontSize={14} color={active ? "#ffffff" : "#161B38"}>
        {title}
      </Typography>
      <Typography
        fontSize={14}
        fontWeight={700}
        color={active ? "#ffffff" : "#161B38"}
      >
        {value}
      </Typography>
    </Stack>
  );
}

SiteCardRow.propTypes = {};

export default SiteCardRow;
