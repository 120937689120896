import React, { useState } from "react";
import Fuse from "fuse.js";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import SiteCard from "./components/SiteCard";
import { CustomConfirm } from "../../components";
import { updateSite } from "../../redux/sites/sitesActions";
import EmptyList from "../../components/EmptyList";
import SiteDetailModal from "./components/SiteDetailModal";
import { SearchSVG } from "../../utils/svgImages";
import usePagination from "../../hooks/usePagination";

function Sites() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const agent = JSON.parse(localStorage.getItem("agent") || "{}");
  const siteTypes = useSelector((state) =>
    Object.values(state.siteTypes).filter((val) => !val.isDeleted)
  );
  const sites = useSelector((state) =>
    Object.values(state.sites).filter((val) => !val.isDeleted)
  );

  const PER_PAGE = 10;

  const count = Math.ceil(sites.length / PER_PAGE);
  const _DATA = usePagination(sites, PER_PAGE);

  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [searchResults, setSearchResults] = useState(_DATA.currentData());
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);

  const options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["superficy", "type", "name", "address.city", "address.postalCode"],
  };

  const fuse = new Fuse(sites, options);

  const onCommandClick = (site) => {
    navigate("/home/command-new", { state: { siteID: site.id } });
  };

  const onCommandListClick = (site) => {
    navigate("/home/my-commands", { state: site.id });
  };
  // const toggleModal = () => {
  //   setOpen(!open);
  // };
  const toggleDeleteModal = (item) => {
    if (item) {
      setSelectedItem(item);
    }
    setDeleteModal(!deleteModal);
  };

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleSearch = (event) => {
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setSearchResults(_DATA.currentData());
      return;
    }

    const results = fuse.search(value);
    const items = results.map((result) => result.item);
    setSearchResults(items);
  };

  const toggleEdit = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const toggleDetail = (item) => {
    setSelectedItem(item);
    setDetailModal(true);
  };

  const closeDetailModal = () => {
    setSelectedItem({});
    setDetailModal(false);
  };

  const onDelete = () => {
    dispatch(
      updateSite(
        { ...selectedItem, isDeleted: true },
        setDeleteModal(!deleteModal),
        "Supprimé avec succès"
      )
    );
  };

  return (
    <Box
      p={4}
      px={{ xs: 1, md: 4, lg: 4 }}
      style={{
        backgroundColor: "#f4f3f7",
        minHeight: "100vh",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          borderRadius: 3,
          // height: "600px",
          backgroundColor: "#fff",
          padding: 2,
          marginTop: 5,
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row", lg: "row" }}
          alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
          justifyContent="space-between"
          spacing={2}
          sx={{
            mb: 1,
          }}
        >
          <Typography fontWeight="600" color="#161B38" fontSize={28}>
            Mes sites récents
          </Typography>

          <Stack
            direction={{ xs: "column", md: "row", lg: "row" }}
            alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
            spacing={3}
          >
            <FormControl>
              {/* <InputLabel id="filter-select">Filtrer</InputLabel> */}
              <Select
                labelId="filter-select"
                id="filter-select"
                size="small"
                label=""
                displayEmpty
                renderValue={
                  filter !== ""
                    ? undefined
                    : () => (
                        <Typography color="#c2c2c2">Types de site</Typography>
                      )
                }
                sx={{ minWidth: 250 }}
                onChange={({ target }) => setFilter(target.value)}
                IconComponent={(props) => (
                  <KeyboardArrowDown
                    {...props}
                    style={{
                      fontSize: "30px",
                    }}
                  />
                )}
                value={filter}
              >
                <MenuItem value={""}>Tout</MenuItem>
                {siteTypes.map((agent) => (
                  <MenuItem key={agent.id} value={agent.id}>
                    {agent.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="input-with-icon-textfield"
              type="search"
              size="small"
              color="secondary"
              placeholder="Rechercher"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchSVG />
                  </InputAdornment>
                ),
                sx: { backgroundColor: "white" },
              }}
              variant="outlined"
              style={{
                borderRadius: "2%",
                width: 250,
              }}
              onChange={handleSearch}
              // onChange={({ target }) => setSearch(target.value)}
            />

            {/* {agent?.authAccountType === "client" ? (
              <CustomButton
                title={"Ajouter"}
                titleStyle={{
                  fontSize: "13px",
                  textTransform: "none",
                  fontWeight: "600",
                }}
                rootStyle={{
                  backgroundColor: "#161B38",
                  height: 42,
                }}
                onClick={toggleModal}
              />
            ) : null} */}
          </Stack>
        </Stack>
        {searchResults.length ? (
          <Grid container spacing={2} mt={3}>
            {searchResults
              .filter((val) =>
                filter !== "" ? val?.siteType?.id === filter : val
              )
              .map((site) => (
                <SiteCard
                  key={site.id}
                  data={site}
                  onDetail={() => toggleDetail(site)}
                  onCommandList={() => onCommandListClick(site)}
                  onEdit={() => toggleEdit(site)}
                  onDelete={() => toggleDeleteModal(site)}
                  onCommand={() => onCommandClick(site)}
                  isAdmin={agent?.authAccountType === "admin"}
                />
              ))}
            {!searchResults
              .filter((val) =>
                filter !== "" ? val?.siteType?.id === filter : val
              ).length ? (
              <Grid item xs={12}>
                <Typography textAlign="center" color={"#c2c2c2"}>
                  Aucun élément trouvé
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EmptyList />
          </Box>
        )}

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          mt={5}
        >
          <Pagination
            count={count}
            size="medium"
            page={page}
            shape="rounded"
            color="primary"
            onChange={handleChange}
          />
        </Stack>
      </Paper>

      {/* {open && (
        <SiteFormModal data={selectedItem} open={open} onClose={toggleModal} />
      )} */}

      <SiteDetailModal
        data={selectedItem}
        open={detailModal}
        onClose={closeDetailModal}
      />

      <CustomConfirm
        visible={deleteModal}
        onClose={toggleDeleteModal}
        onConfirm={onDelete}
      />
    </Box>
  );
}

export default Sites;
