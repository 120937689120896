import axios from "axios";
import { SERVER } from "../../utils/server";
import historyTypes from "./historyTypes";
import notificationTypes from "../notifications/notificationTypes";

export const createHistory =
  (history, callback) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}history`, history);
      dispatch({
        type: historyTypes.CREATE_HISTORY,
        payload: results.data.data,
      });
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: "Enregistré avec succès" },
      });
      callback?.();
    } catch (error) {
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("History creation error", error);
    }
  };

export const listHistories = (id) => async (dispatch) => {
  try {
    const results = await axios.get(`${SERVER}histories?agentId=${id}`);
    dispatch({ type: historyTypes.LIST_HISTORIES, payload: results.data.data });
  } catch (error) {
    console.error("History list error", error);
  }
};

export const listAllHistories = () => async (dispatch) => {
  try {
    const results = await axios.get(`${SERVER}histories-all`);
    dispatch({ type: historyTypes.LIST_HISTORIES, payload: results.data.data });
  } catch (error) {
    console.error("History list error", error);
  }
};

export const deleteHistory = (id) => async (dispatch) => {
  await axios.delete(`${SERVER}history/${id}`);

  dispatch({ type: historyTypes.REMOVE_HISTORY, payload: id });
};

export const updateHistory =
  (history, callback, onError) =>
  async (dispatch) => {
    try {
      const results = await axios.put(`${SERVER}history`, history);
      dispatch({
        type: historyTypes.UPDATE_HISTORY,
        payload: results.data.data,
      });
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: {
          message: "Modifié avec succès",
        },
      });
      callback?.();
    } catch (error) {
      console.log("update history error", error);
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
    }
  };
