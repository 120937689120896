import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { BrowserView } from "react-device-detect";

function CommandStepper({ step, title, active }) {
  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
      <Box
        sx={{
          backgroundColor: active ? "#161B38" : "#989A9B",
          height: "40px",
          width: "40px",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
          display: "flex",
        }}
      >
        <Typography
          fontSize={20}
          fontWeight={600}
          color={active ? "#fff" : "#57585A"}
        >
          {step}
        </Typography>
      </Box>
      <BrowserView>
        <Typography fontSize={16} fontWeight={600}>
          {title}
        </Typography>
      </BrowserView>
    </Stack>
  );
}

CommandStepper.propTypes = {};

export default CommandStepper;
