import * as React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Typography } from "@mui/material";
import "./CustomDateInput.css";
import dayjs from "dayjs";


export default function CustomDateInput({
  title,
  onChange,
  titleStyle,
  containerStyle,
  component,
  inputStyle,
  minDate,
  maxDate,
  value
}) {

  return (
    <Box
      sx={{
        ...containerStyle,
      }}
    >
      <Typography
        className="text-14 font-500"
        sx={{
          color: "#344051",
          ...titleStyle,
        }}
      >
        {title}
      </Typography>

      {!component ? (
        <DatePicker
          format="DD-MM-YYYY"
          onChange={(ev) => onChange?.(new Date(ev))}
          className="datePicker w-100"
          sx={{ "&:hover": { border: "none" }, ...inputStyle }}
          value={dayjs(value)}
          minDate={dayjs(minDate)}
          slotProps={{
            textField: {
                readOnly: true,
            },
        }}
        />
      ) : (
        component
      )}
    </Box>
  );
}
