import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Box, Grid, Modal, Stack, Typography } from "@mui/material";
import { COLORS } from "../../utils";
import { FileSVG } from "../../utils/svgImages";
import {
  CustomButton,
  CustomKeyValue,
  DetailModalTitle,
} from "../../components";
import { listAgentProfiles } from "../../redux/agentProfile/agentProfileActions";
import { listHistories } from "../../redux/history/historyActions";
import ServiceHistoryTable from "../myService/components/ServiceHistoryTable";
import AgentProfileTable from "../agentProfile/components/AgentProfileTable";
import { SERVER } from "../../utils/server";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, md: 650, lg: 650 },
  bgcolor: "background.paper",
  borderRadius: 2,
  p: 3,
};
function AgentDetail() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const histories = useSelector((state) => Object.values(state.histories));
  const agentProfile = useSelector((state) =>
    Object.values(state.agentProfile)
  );
  const data = JSON.parse(state);

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const onOpen = (val) => {
    setSelectedItem(val);
    setOpen(true);
  };
  const onClose = () => {
    setSelectedItem(null);
    setOpen(false);
  };

  useEffect(() => {
    dispatch(listAgentProfiles(data?.id));
    dispatch(listHistories(data.id));
  }, [data.id, dispatch]);

  return (
    <Box
      p={4}
      px={{ xs: 2, md: 4, lg: 4 }}
      style={{
        backgroundColor: "#f4f3f7",
        minHeight: "100vh",
      }}
    >
      <Stack direction="row" alignItems={"center"} spacing={2} my={3}>
        <Avatar sx={{ bgcolor: "#161B38", width: 48, height: 48 }}>
          <FileSVG />
        </Avatar>
        <Typography color="primary" fontSize={20} fontWeight={600}>
          Détail de l'agent
        </Typography>
      </Stack>

      <Box
        sx={{
          backgroundColor: "#fff",
          width: "100%",
          height: "100%",
          borderRadius: 2,
          p: 3,
          mb: 4,
        }}
      >
        <Typography
          color="primary"
          fontSize={20}
          fontWeight={800}
          mb={4}
          sx={{ textDecoration: "underline" }}
        >
          Informations personnelles
        </Typography>
        <Grid
          container
          spacing={{ xs: 2, md: 5 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {data.lastname ? (
            <>
              <Grid item xs={2} sm={4} md={4}>
                <CustomKeyValue
                  title={"Nom"}
                  value={data.lastname}
                  titleStyle={{ color: "#57585A" }}
                  valueStyle={{
                    fontWeight: 700,
                    color: COLORS.primary,
                    wordWrap: "break-word",
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <CustomKeyValue
                  title={"Prénom"}
                  value={data.firstname}
                  titleStyle={{ color: "#57585A" }}
                  valueStyle={{
                    fontWeight: 700,
                    color: COLORS.primary,
                    wordWrap: "break-word",
                  }}
                />
              </Grid>
            </>
          ) : null}
          {data.raisonSociale ? (
            <>
              <Grid item xs={2} sm={4} md={4}>
                <CustomKeyValue
                  title={"Raison sociale"}
                  value={data.raisonSociale}
                  titleStyle={{ color: "#57585A" }}
                  valueStyle={{
                    fontWeight: 700,
                    color: COLORS.primary,
                    wordWrap: "break-word",
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <CustomKeyValue
                  title={"SIRET"}
                  value={data.siret}
                  titleStyle={{ color: "#57585A" }}
                  valueStyle={{
                    fontWeight: 700,
                    color: COLORS.primary,
                    wordWrap: "break-word",
                  }}
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs={2} sm={4} md={4}>
            <CustomKeyValue
              title={"Téléphone"}
              value={data.phoneNumber}
              titleStyle={{ color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                wordWrap: "break-word",
              }}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <CustomKeyValue
              title={"Email"}
              value={`${data.email}`}
              titleStyle={{ color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                wordWrap: "break-word",
              }}
            />
          </Grid>
          {data.nationalId ? (
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Nº Pièce d’identité"}
                value={data.nationalId}
                titleStyle={{ color: "#57585A" }}
                valueStyle={{
                  fontWeight: 700,
                  color: COLORS.primary,
                  wordWrap: "break-word",
                }}
              />
            </Grid>
          ) : null}
          {data.whatSApp ? (
            <Grid item xs={2} sm={4} md={4}>
              <CustomKeyValue
                title={"Whatsapp"}
                value={data.whatSApp}
                titleStyle={{ color: "#57585A" }}
                valueStyle={{
                  fontWeight: 700,
                  color: COLORS.primary,
                  wordWrap: "break-word",
                }}
              />
            </Grid>
          ) : null}
          <Grid item xs={2} sm={4} md={4}>
            <CustomKeyValue
              title={"Adresse"}
              value={data.adressCompleted?.address}
              titleStyle={{ color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                wordWrap: "break-word",
              }}
            />
          </Grid>
          {/* <Grid item xs={2} sm={4} md={4}>
            <CustomKeyValue
              title={"Complement Adresse"}
              value={data.adressCompleted?.complementAddress}
              titleStyle={{ color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                wordWrap: "break-word",
              }}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <CustomKeyValue
              title={"Code Postal"}
              value={data.adressCompleted?.postalCode}
              titleStyle={{ color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                wordWrap: "break-word",
              }}
            />
          </Grid> */}
          <Grid item xs={2} sm={4} md={4}>
            <CustomKeyValue
              title={"Pays"}
              value={data.adressCompleted?.country}
              titleStyle={{ color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                wordWrap: "break-word",
              }}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <CustomKeyValue
              title={"Ville"}
              value={data.adressCompleted?.city}
              titleStyle={{ color: "#57585A" }}
              valueStyle={{
                fontWeight: 700,
                color: COLORS.primary,
                wordWrap: "break-word",
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          backgroundColor: "#fff",
          width: "100%",
          height: "100%",
          borderRadius: 2,
          p: 3,
          mb: 4,
        }}
      >
        <Typography
          color="primary"
          fontSize={20}
          fontWeight={800}
          mb={4}
          sx={{ textDecoration: "underline" }}
        >
          Profil d'agent
        </Typography>
        <AgentProfileTable
          agentProfile={agentProfile}
          onOpenJust={(val) => onOpen(val)}
        />
      </Box>

      <Box
        sx={{
          backgroundColor: "#fff",
          width: "100%",
          height: "100%",
          borderRadius: 2,
          p: 3,
          mb: 4,
        }}
      >
        <Typography
          color="primary"
          fontSize={20}
          fontWeight={800}
          mb={4}
          sx={{ textDecoration: "underline" }}
        >
          Historique de services
        </Typography>
        <ServiceHistoryTable histories={histories} />
      </Box>
      <CustomButton
        title="Sortir"
        onClick={() => navigate(-1)}
        titleStyle={{ color: COLORS.primary }}
        rootStyle={{
          padding: 1.5,
          backgroundColor: "transparent",
          border: `1px solid ${COLORS.primary}`,
          width: 150,
        }}
      />

      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DetailModalTitle
            title={`Pièce justificative (${selectedItem?.qualification?.name})`}
            onClose={onClose}
          />

          <Box
            sx={{
              backgroundColor: "#D9DBDD1A",
              width: "100%",
              height: "100%",
              borderRadius: 2,
            }}
          >
            {selectedItem?.justificatifUrl?.substr(
              selectedItem?.justificatifUrl?.length - 3
            ) !== "pdf" ? (
              <img
                src={`${SERVER.replace("/api/v1", "")}${
                  selectedItem?.justificatifUrl
                }`}
                alt="justificatif"
                style={{ height: 450, width: "100%", borderRadius: 5 }}
              />
            ) : (
              <iframe
                title="Pièce justificative"
                src={`${SERVER.replace("/api/v1", "")}${
                  selectedItem?.justificatifUrl
                }`}
                style={{ height: 450, width: "100%", borderRadius: 5 }}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

AgentDetail.propTypes = {};

export default AgentDetail;
