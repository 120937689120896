import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import SigninTextInput from "../views/auth/components/SigninTextInput";
import CustomButton from "./CustomButton";

export default function CustomReasonReject({
  visible,
  title = "Voulez-vous vraiment supprimer ce site",
  onClose,
  onConfirm,
}) {
  const [reason, setReason] = useState("");

  const onSave = () => {
    onConfirm(reason);
    onClose();
  };

  return (
    <Dialog
      open={visible}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Box
          sx={{
            width: { xs: "100%", md: "500px", lg: "500px" },
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
          py={3}
        >
          <IconButton
            onClick={onClose}
            sx={{ position: "absolute", right: 10, top: 10 }}
          >
            <Close htmlColor="#989A9B" />
          </IconButton>
          <div>
            <Typography textAlign="center" my={2} mt={4} fontWeight="500">
              {title}
            </Typography>

            <SigninTextInput
              title={"Raison du rejet"}
              value={reason}
              id="reason"
              name="reason"
              icon={null}
              onChange={(value) => setReason(value)}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              {reason !== "" ? (
                <CustomButton
                  title={"Confirmer"}
                  rootStyle={{
                    backgroundColor: "#D9DBDD",
                    width: "45%",
                  }}
                  titleStyle={{ color: "black" }}
                  onClick={onSave}
                />
              ) : null}
              <CustomButton
                title={"Annuler"}
                rootStyle={{ width: "45%", backgroundColor: "#CB1728" }}
                onClick={onClose}
              />
            </div>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
