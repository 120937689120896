import React, { useState } from "react";
import { Card, CardContent } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import SigninTextInput from "./components/SigninTextInput";
import { AuthLayout } from "../../layouts";
import SigninHeader from "./components/SigninHeader";
import SigninButton from "./components/SigninButton";
import { LockSVG } from "../../utils/svgImages";
import { verifyOtp } from "../../redux/auth/authActions";
import notificationTypes from "../../redux/notifications/notificationTypes";
import { Lock } from "@mui/icons-material";

const NewPassword = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const email = localStorage.getItem("emailToReset");

  const [otp, setOtp] = useState("");
  const [newPassword, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const callback = () => {
    setIsLoading(false);
    navigate("/signin");
  };
  const onError = () => {
    setIsLoading(false);
  };

  const login = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Les mot de passe ne sont pas identiques" },
      });
    } else {
      setIsLoading(true);
      dispatch(verifyOtp({ email, otp, newPassword }, callback, onError));
    }
  };

  return (
    <AuthLayout
      onSubmit={login}
      leftComponent={
        <Card
          sx={{
            maxWidth: 430,
            boxShadow: "none",
          }}
        >
          <CardContent>
            <SigninHeader
              title={`Nouveau mot de passe`}
              subTitle={
                "Créez un nouveau mot de passe associé à ce compte afin d'y accéder en toute sécurité."
              }
            />

            <div
              component="form"
              style={{
                width: "100%",
              }}
            >
              <SigninTextInput
                title={"Code"}
                onChange={(value) => setOtp(value)}
                value={otp}
                icon={<Lock />}
                required
              />
              <SigninTextInput
                title={"Nouveau mot de passe"}
                onChange={(value) => setPassword(value)}
                value={newPassword}
                icon={<Lock />}
                type="password"
                required
              />
              <SigninTextInput
                title={"Confirmer le mot de passe"}
                onChange={(value) => setConfirmPassword(value)}
                value={confirmPassword}
                icon={<Lock />}
                type="password"
                required
              />

              <SigninButton
                title={"Réinitialiser mon mot de passe"}
                onClick={login}
                isLoading={isLoading}
              />
            </div>
          </CardContent>
        </Card>
      }
    />
  );
};

export default NewPassword;
