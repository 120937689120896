import React from "react";
import { Divider, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../utils";
import { isMobile } from "react-device-detect";

const SidebarMenu = ({
  item,
  parentTextStyle,
  dividerBottom = true,
  closeDrawer,
}) => {
  let location = useLocation();
  const navigate = useNavigate();

  const isActive = (path) => location.pathname === path;

  const onNavigate = (route) => {
    navigate(route);
    isMobile && closeDrawer();
  };

  return (
    <React.Fragment>
      <ListItem
        onClick={() => onNavigate(item.path)}
        sx={{
          height: isActive(item.path) ? 50 : 25,
          marginBottom: 2,
          padding: 0,
          backgroundColor: isActive(item.path) ? "white" : "transparent",
          cursor: "pointer",
        }}
      >
        <ListItemIcon sx={{ minWidth: 24, marginRight: 1, ml: 4 }}>
          {isActive(item.path) ? item.iconActive : item.icon}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            color: isActive(item.path) ? "black" : "white",
            fontWeight: "600",
            ...(!isActive(item.path) && parentTextStyle),
          }}
          primary={capitalizeFirstLetter(item.name)}
        />
      </ListItem>
      {dividerBottom && (
        <Divider sx={{ backgroundColor: "#D9DBDD", mx: 6, ml: 4, mb: 2 }} />
      )}
    </React.Fragment>
  );
};

export default SidebarMenu;
