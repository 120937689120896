import React, { useEffect, useState } from "react";
import { Avatar, Box, Paper, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import SigninTextInput from "../auth/components/SigninTextInput";
import { GlobeSVG } from "../../utils/svgImages";
import { CustomButton, CustomDropzone } from "../../components";
import { updateAdmin } from "../../redux/admins/adminActions";
import { updateAgent } from "../../redux/agents/agentActions";
import { updateClient } from "../../redux/clients/clientActions";
import SigninDropdown from "../auth/components/SigninDropdown";
import { COUNTRIES } from "../../utils/countries";
import { fileUploader } from "../../utils/uploadUtils";
import { SERVER } from "../../utils/server";
import {
  AppRegistration,
  Fingerprint,
  Height,
  LocationOn,
  Mail,
  Phone,
  Public,
  QrCode,
  Scale,
  WhatsApp,
} from "@mui/icons-material";

function Profile({ isOperator }) {
  const [isLoading, setIsLoading] = useState(false);
  const localAgent = JSON.parse(localStorage.getItem("agent") || "{}");
  const agent = isOperator ? localAgent?.client : localAgent;

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({
      multiple: false,
      accept: {
        "image/jpeg": [],
        "image/png": [],
        "image/jpg": [],
      },
    });

  const dispatch = useDispatch();

  const callback = () => {
    setIsLoading(false);
  };
  const onError = () => {
    setIsLoading(false);
  };

  async function _handleSubmit(values) {
    try {
      setIsLoading(true);

      let imageUrl = undefined;

      if (acceptedFiles?.length) {
        imageUrl = await fileUploader(acceptedFiles[0]);
      }

      // if (values.password !== values.confirmPassword) {
      //   setIsLoading(false);
      //   return dispatch({
      //     type: notificationTypes.SHOW_ERROR,
      //     payload: { message: "Les mot de passe ne correspondent pas" },
      //   });
      // }
      let user = {
        ...values,
        imageUrl,
      };
      if (agent.authAccountType === "admin") {
        [
          "siret",
          "raisonSociale",
          "whatSApp",
          "nationalId",
          "registrationNumber",
        ].forEach((e) => delete user[e]);
        dispatch(
          updateAdmin(user, callback, "Modifié avec succès", onError, true)
        );
      } else if (agent.authAccountType === "agent") {
        ["siret", "raisonSociale", "whatSApp", "email"].forEach(
          (e) => delete user[e]
        );
        dispatch(
          updateAgent(
            { ...user, categoriesId: [] },
            callback,
            "Modifié avec succès",
            onError,
            true
          )
        );
      } else if (agent.authAccountType === "client") {
        [
          "nationalId",
          "registrationNumber",
          "firstname",
          "lastname",
          "email",
        ].forEach((e) => delete user[e]);
        dispatch(
          updateClient({
            client: user,
            callback,
            toastMessage: "Modifié avec succès",
            onError,
            isProfile: true,
          })
        );
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  const { values, handleSubmit, setValues, errors, setFieldValue } = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      id: "",
      phoneNumber: "",
      complementAddress: "",
      email: "",
      address: "",
      postalCode: "",
      city: "",
      country: "",
      nationalId: "",
      // registrationNumber: "",
      siret: "",
      raisonSociale: "",
      whatSApp: "",
    },
    // validationSchema: Yup.object().shape(clientYup),
    onSubmit(values) {
      _handleSubmit(values);
    },
  });

  const initialValues = () => {
    setValues(agent);
    // const { adressCompleted } = agent;
    let adressCompleted;
    if (agent.adressCompleted) {
      adressCompleted = agent.adressCompleted;
    } else {
      adressCompleted = agent.address;
    }
    setFieldValue("complementAddress", adressCompleted.complementAddress);
    setFieldValue("address", adressCompleted.address);
    setFieldValue("postalCode", adressCompleted.postalCode);
    setFieldValue("city", adressCompleted.city);
    setFieldValue("country", adressCompleted.country);
  };

  useEffect(() => {
    setTimeout(() => {
      initialValues();
    }, 1000);
  }, []);

  return (
    <Box
      style={{
        minHeight: "100vh",
      }}
    >
      <form onSubmit={handleSubmit}>
        <Paper
          elevation={0}
          sx={{
            borderRadius: "10px",
            minHeight: "600px",
            backgroundColor: "#fff",
            marginTop: 5,
            position: "relative",
          }}
        >
          <Box sx={{ ml: 5, position: "relative", pt: 1, pr: 5 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                alt="user"
                src={
                  agent.imageUrl
                    ? `${SERVER}download?fileName=${agent.imageUrl}`
                    : "/site.png"
                }
                style={{
                  width: 112,
                  height: 112,
                  border: "3px solid #161B38",
                  borderRadius: 100,
                }}
              />
              <div>
                <Typography fontWeight="700" color="#161B38" my={1}>
                  {agent?.firstname
                    ? `${agent.firstname} ${agent.lastname}`
                    : agent?.raisonSociale}
                </Typography>
                <Typography fontWeight="400" color="#161B38" mb={3}>
                  {agent?.adressCompleted?.city}
                </Typography>
              </div>
            </Stack>

            <>
              {agent.authAccountType !== "client" ? (
                <Stack
                  direction={{ xs: "column", md: "row", lg: "row" }}
                  spacing={2}
                  // sx={{ alignItems: "center", justifyContent: "space-between" }}
                >
                  <SigninTextInput
                    title={"Nom"}
                    value={values.lastname}
                    id="lastname"
                    name="lastname"
                    disabled={isOperator}
                    onChange={(value) => setFieldValue("lastname", value)}
                    // showError={errorUsername}
                    // errorMessage={I18n.get("username_required")}
                  />
                  <SigninTextInput
                    title={"Prénom"}
                    value={values.firstname}
                    id="firstname"
                    name="firstname"
                    disabled={isOperator}
                    onChange={(value) => setFieldValue("firstname", value)}
                    // showError={errorUsername}
                    // icon={<LockSVG />}
                    // errorMessage={I18n.get("username_required")}
                  />
                </Stack>
              ) : null}
              {agent.authAccountType === "client" ? (
                <Stack
                  direction={{ xs: "column", md: "row", lg: "row" }}
                  spacing={2}
                  // sx={{ alignItems: "center", justifyContent: "space-between" }}
                >
                  <SigninTextInput
                    title={"Raison sociale"}
                    value={values.raisonSociale}
                    id="raisonSociale"
                    name="raisonSociale"
                    disabled={isOperator}
                    onChange={(value) => setFieldValue("raisonSociale", value)}
                    errorMessage={errors.raisonSociale}
                  />
                  <SigninTextInput
                    title={"SIRET"}
                    value={values.siret}
                    id="siret"
                    name="siret"
                    disabled={isOperator}
                    onChange={(value) => setFieldValue("siret", value)}
                    errorMessage={errors.siret}
                    icon={<AppRegistration />}
                  />
                </Stack>
              ) : null}
              {agent.authAccountType === "agent" ? (
                <Stack>
                  <Stack
                    direction={{ xs: "column", md: "row", lg: "row" }}
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <SigninTextInput
                      title={"Poids"}
                      value={values.weight}
                      id="weight"
                      name="weight"
                      type="number"
                      onChange={(value) =>
                        setFieldValue("weight", Number(value))
                      }
                      icon={<Scale />}
                      errorMessage={errors.weight}
                    />
                    <SigninTextInput
                      title={"Taille"}
                      value={values.height}
                      id="height"
                      name="height"
                      type="number"
                      onChange={(value) =>
                        setFieldValue("height", Number(value))
                      }
                      icon={<Height />}
                      errorMessage={errors.height}
                    />
                  </Stack>
                  <Stack
                    direction={{ xs: "column", md: "row", lg: "row" }}
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <SigninTextInput
                      title={"Taille tenue haute"}
                      value={values.dressSizeTop}
                      id="dressSizeTop"
                      name="dressSizeTop"
                      type="number"
                      onChange={(value) =>
                        setFieldValue("dressSizeTop", Number(value))
                      }
                      icon={<Height />}
                      errorMessage={errors.dressSizeTop}
                    />
                    <SigninTextInput
                      title={"Taille tenue basse"}
                      value={values.dressSizeBottom}
                      id="dressSizeBottom"
                      name="dressSizeBottom"
                      type="number"
                      onChange={(value) =>
                        setFieldValue("dressSizeBottom", Number(value))
                      }
                      icon={<Height />}
                      errorMessage={errors.dressSizeBottom}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <SigninTextInput
                      title={"Taille du pied"}
                      value={values.dressSizeFoot}
                      id="dressSizeFoot"
                      name="dressSizeFoot"
                      type="number"
                      onChange={(value) =>
                        setFieldValue("dressSizeFoot", Number(value))
                      }
                      icon={<Height />}
                      errorMessage={errors.dressSizeFoot}
                    />
                  </Stack>
                </Stack>
              ) : null}

              <Stack
                direction={{ xs: "column", md: "row", lg: "row" }}
                spacing={2}
                // sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <SigninTextInput
                  title={"Téléphone"}
                  value={values.phoneNumber}
                  id="phoneNumber"
                  name="phoneNumber"
                  disabled={isOperator}
                  onChange={(value) => setFieldValue("phoneNumber", value)}
                  // showError={errorUsername}
                  icon={<Phone />}
                  // errorMessage={I18n.get("username_required")}
                />
                <SigninTextInput
                  title={"Email"}
                  value={values.email}
                  id="email"
                  name="email"
                  type="email"
                  disabled={true}
                  onChange={(value) => setFieldValue("email", value)}
                  // showError={errorUsername}
                  icon={<Mail />}
                  // errorMessage={I18n.get("username_required")}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row", lg: "row" }}
                spacing={2}
                // sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                {agent.authAccountType === "agent" ? (
                  <SigninTextInput
                    title={"Nº Pièce d’identité"}
                    value={values.nationalId}
                    id="nationalId"
                    name="nationalId"
                    onChange={(value) => setFieldValue("nationalId", value)}
                    // showError={errorUsername}
                    icon={<Fingerprint />}
                    // errorMessage={I18n.get("username_required")}
                  />
                ) : null}
                {agent.authAccountType === "client" ? (
                  <SigninTextInput
                    title={"Whatsapp"}
                    value={values.whatSApp}
                    id="whatSApp"
                    name="whatSApp"
                    disabled={isOperator}
                    onChange={(value) => setFieldValue("whatSApp", value)}
                    // showError={errorUsername}
                    icon={<WhatsApp />}
                    // errorMessage={I18n.get("username_required")}
                  />
                ) : null}
                {agent.authAccountType !== "admin" ? (
                  <SigninTextInput
                    title={"Adresse"}
                    value={values.address}
                    id="address"
                    name="address"
                    disabled={isOperator}
                    onChange={(value) => setFieldValue("address", value)}
                    icon={<LocationOn />}
                    // errorMessage={I18n.get("username_required")}
                  />
                ) : null}
              </Stack>
            
              {agent.authAccountType !== "admin" ? (
                <Stack
                  direction={{ xs: "column", md: "row", lg: "row" }}
                  spacing={2}
                  // sx={{ alignItems: "center", justifyContent: "space-between" }}
                >
                  <SigninDropdown
                    title={"Pays"}
                    icon={<Public />}
                    value={values.country}
                    id="country"
                    name="country"
                    data={COUNTRIES}
                    disabled
                    onChange={(value) => setFieldValue("country", value)}
                    // showError={errorUsername}
                    // errorMessage={I18n.get("username_required")}
                  />
                  <SigninTextInput
                    title={"Ville"}
                    icon={<Public />}
                    value={values.city}
                    id="city"
                    name="city"
                    disabled={isOperator}
                    onChange={(value) => setFieldValue("city", value)}
                    // showError={errorUsername}
                    // errorMessage={I18n.get("username_required")}
                  />
                </Stack>
              ) : null}
            </>

            {!isOperator ? (
              <CustomDropzone
                acceptedFiles={acceptedFiles}
                getRootProps={getRootProps({ className: "dropzone" })}
                getInputProps={getInputProps()}
              />
            ) : null}

            {!isOperator ? (
              <CustomButton
                title={"Enregistrer mes informations"}
                titleStyle={{
                  fontSize: "13px",
                  textTransform: "none",
                  fontWeight: "600",
                }}
                rootStyle={{
                  height: 52,
                  width: { xs: "100%", md: "30%", lg: "30%" },
                  my: 4,
                }}
                type="submit"
                isLoading={isLoading}
              />
            ) : null}
            <div style={{ width: "100%" }} />
          </Box>
        </Paper>
      </form>
    </Box>
  );
}

Profile.propTypes = {};

export default Profile;
