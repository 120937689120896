import axios from "axios";
import { SERVER } from "../../utils/server";
import clientTypes from "./clientTypes";
import notificationTypes from "../notifications/notificationTypes";
import { CODES } from "../../utils/errorCodes";
import { getErrorMessage } from "../../utils/errorCodeMessage";

export const createClient =
  ({client, callback, onError}) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}client`, client);
      dispatch({
        type: clientTypes.CREATE_CLIENT,
        payload: results.data.data,
      });
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: "Enregistré avec succès" },
      });
      callback?.();
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        // console.log(error.response.status);
        if (error.response.status === 400) {
          onError?.();
          dispatch({
            type: notificationTypes.SHOW_ERROR,
            payload: {
              message: getErrorMessage(error.response.data?.message),
            },
          });
        }
        return;
      }
      onError?.();
        dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Client creation error", error);
    }
  };

export const listClients = () => async (dispatch) => {
  try {
    const results = await axios.get(`${SERVER}clients`);
    dispatch({ type: clientTypes.LIST_CLIENTS, payload: results.data.data });
  } catch (error) {
    console.error("Client list error", error);
  }
};

export const deleteClient = (id) => async (dispatch) => {
   await axios.delete(`${SERVER}client/${id}`);

  dispatch({ type: clientTypes.REMOVE_CLIENT, payload: id });
};

export const updateClient = ({client, callback, toastMessage="Modifié avec succès",onError,isProfile}) => async (dispatch) => {
  try {
    const results = await axios.put(`${SERVER}client`, client);
    dispatch({
      type: clientTypes.UPDATE_CLIENT,
      payload: results.data.data,
    });
    dispatch({
      type: notificationTypes.SHOW_SUCCESS,
      payload: {
        message: toastMessage,
      },
    });
    if(isProfile){
      localStorage.setItem("agent",JSON.stringify(results.data.data))
    }
    callback?.();
  } catch (error) {
    if (error.response) {
      // console.log(error.response.data);
      // console.log(error.response.status);
      if (error.response.status === 400) {
        onError?.();
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: {
            message: getErrorMessage(error.response.data?.data[0]?.param),
          },
        });
      }
      return;
    }
    console.log("update client error", error);
    onError?.();
    dispatch({
      type: notificationTypes.SHOW_ERROR,
      payload: { message: "Une erreur est survenue" },
    });
  }
};

export const resetClientPassword = (client, callback,onError) => async (dispatch, getState) => {
  try {
    const results = await axios.post(`${SERVER}client/resetPassword`, client);
    if (results.data.isSuccess) {
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: "Modifié avec succès" },
      });
      callback?.();
    } else {
      const { errorCode } = results.data;
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: CODES[errorCode] },
      });
      onError?.();
    }
  } catch (error) {
    dispatch({
      type: notificationTypes.SHOW_ERROR,
      payload: { message: "Une erreur est survenue" },
    });
    console.error("Admin creation error", error);
  }
};

export const resetClientOperatorPassword = (client, callback,onError) => async (dispatch, getState) => {
  try {
    const results = await axios.post(`${SERVER}clientSubAccount/resetPassword`, client);
    if (results.data.isSuccess) {
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: "Modifié avec succès" },
      });
      callback?.();
    } else {
      const { errorCode } = results.data;
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: CODES[errorCode] },
      });
      onError?.();
    }
  } catch (error) {
    dispatch({
      type: notificationTypes.SHOW_ERROR,
      payload: { message: "Une erreur est survenue" },
    });
    console.error("Admin creation error", error);
  }
};