import { combineReducers } from "redux";
import adminReducer from "./admins/adminReducer";
import agentProfileReducer from "./agentProfile/agentProfileReducer";
import agentReducer from "./agents/agentReducer";
import agentTypeReducer from "./agentType/agentTypeReducer";
import authReducer from "./auth/authReducer";
import clientReducer from "./clients/clientReducer";
import commandLineReducer from "./commandLine/commandLineReducer";
import commandProcessingReducer from "./commandProcessing/commandProcessingReducer";
import commandReducer from "./commands/commandReducer";
import devisReducer from "./devis/devisReducer";
import historyReducer from "./history/historyReducer";
import notificationReducer from "./notifications/notificationReducer";
import operationnelReducer from "./operationnels/operationnelReducer";
import serviceTypeReducer from "./serviceType/serviceTypeReducer";
import siteReducer from "./sites/sitesReducer";
import siteTypeReducer from "./siteType/siteTypeReducer";

const appReducer = combineReducers({
  agents: agentReducer,
  admins: adminReducer,
  clients: clientReducer,
  notifications: notificationReducer,
  sites: siteReducer,
  auth: authReducer,
  agentTypes: agentTypeReducer,
  serviceTypes: serviceTypeReducer,
  commands: commandReducer,
  siteTypes: siteTypeReducer,
  histories: historyReducer,
  agentProfile: agentProfileReducer,
  commandLines: commandLineReducer,
  commandProcessing: commandProcessingReducer,
  operationnels: operationnelReducer,
  devis: devisReducer,
});

export default appReducer;
