import commandProcessingTypes from "./commandProcessingTypes";

const initialState = {
    loading: false,
    approbations: {},
    responseLines: []
};

const commandProcessingReducer = (state = initialState, action) => {
    let nextState;

    switch (action.type) {

        case commandProcessingTypes.LIST_APPROBATIONS:
            nextState = {
                ...state,
                approbations: action.payload,
                loading: false,
            };
            return nextState || state;

        case commandProcessingTypes.LIST_RESPONSE_LINE:
            nextState = {
                ...state,
                responseLines: action.payload,
                loading: false,
            };
            return nextState || state;

        default:
            return state;
    }
};

export default commandProcessingReducer;
