import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  CustomButton,
  CustomConfirm,
  TablePaginationActions,
} from "../../components";
import SigninTextInput from "../auth/components/SigninTextInput";
import FormModalTitle from "../../components/FormModalTitle";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  createHistory,
  listHistories,
  updateHistory,
} from "../../redux/history/historyActions";
import ServiceHistoryTable from "./components/ServiceHistoryTable";

const MenuProps = {
  PaperProps: {
    style: {
      width: 150,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, md: 650, lg: 650 },
  bgcolor: "background.paper",
  borderRadius: 2,
};

function ServiceHistory() {
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");
  const histories = useSelector((state) => Object.values(state.histories));
  const serviceTypes = useSelector((state) =>
    Object.values(state.serviceTypes)
  );
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [confirmModalTitle, setConfirmModalTitle] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [experienceHours, setExperienceHours] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const toggleModal = () => {
    setOpen(!open);
  };
  const toggleDeleteModal = (item) => {
    if (item) {
      setSelectedItem(item);
    }
    setDeleteModal(!deleteModal);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleEdit = (item) => {
    setId(item.id);
    setName(item.name);
    setExperienceHours(item.experienceHours);
    setOpen(true);
  };

  const deleteCallback=()=>{
    setDeleteModal(!deleteModal)
    dispatch(listHistories(agent?.client?.id || agent.id));
  }

  const openDelete = (item) => {
    setSelectedItem(item);
    setConfirmModalTitle("Voulez-vous vraiment supprimer cet historique?");
    setDeleteModal(true);
    setIsDelete(true);
  };

  const deleteConfirm = () => {
    if (isDelete) {
      onDelete();
    }
  };

  const onDelete = () => {
    let user = { ...selectedItem, isDeleted: true };
    dispatch(
      updateHistory(user, deleteCallback, "Supprimé avec succès")
    );
  };

  const onCloseModal = () => {
    setId("");
    setName("");
    setExperienceHours(0);
    toggleModal();
  };

  const onError = () => {
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const history = {
      name,
      experienceHours,
      agentId: agent?.client?.id || agent.id,
    };
    if(id){
      dispatch(updateHistory({...history,id}, callback, onError));
    }else{
      dispatch(createHistory(history, callback, onError));
    }
    
  };

  const dispatch = useDispatch();
  const callback = () => {
    setOpen(false);
    setLoading(false);
    setName("");
    setExperienceHours(0);
  };

  useEffect(() => {
    dispatch(listHistories(agent?.client?.id || agent.id));
  }, [dispatch, agent?.client?.id, agent.id]);

  return (
    <Box
      style={{
        minHeight: "100vh",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          borderRadius: 3,
          // height: "600px",
          backgroundColor: "#fff",
          padding: 2,
          marginTop: 3,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{
            mb: 1,
          }}
        >
          <Typography fontWeight="600" color="#161B38" fontSize={28}>
            Historique de mes services
          </Typography>

          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <CustomButton
              title={"Ajouter"}
              titleStyle={{
                fontSize: "13px",
                textTransform: "none",
                fontWeight: "600",
              }}
              rootStyle={{
                backgroundColor: "#161B38",
                height: 42,
              }}
              onClick={toggleModal}
            />
          </Stack>
        </Stack>

        <ServiceHistoryTable
          histories={histories}
          menuComponent
          toggleEdit={(val) => toggleEdit(val)}
          openDelete={(val) => openDelete(val)}
          tableFooter={
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    10,
                    25,
                    50,
                    100,
                    { label: "All", value: -1 },
                  ]}
                  labelRowsPerPage="Rangées par page"
                  count={histories.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rangées par page",
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          }
        />

      </Paper>

      <Modal
        open={open}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <FormModalTitle
              title={`${id ? "Modifier" : "Ajouter"} un service`}
              closeModal={onCloseModal}
              isLoading={loading}
            />
            <Box p={4} pt={0} style={{ maxHeight: "85vh", overflow: "auto" }}>
              <Stack
                direction={{ xs: "column", md: "row", lg: "row" }}
                spacing={2}
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                {/* <SigninTextInput
                  title={"Type de service"}
                  value={name}
                  id="name"
                  name="name"
                  onChange={(value) => setName(value)}
                  required
                  // errorMessage={errors.name}
                /> */}
                <FormControl sx={{ my: 1 }} fullWidth>
                  <Typography
                    color="black"
                    sx={{
                      fontSize: {
                        xs: 12,
                        md: 14,
                      },
                      fontWeight: 600,
                      mb: 1,
                    }}
                  >
                    Type(s) de service
                  </Typography>
                  <Select
                    variant="standard"
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                    input={<OutlinedInput />}
                    style={{
                      backgroundColor: "#fff",
                    }}
                    MenuProps={MenuProps}
                  >
                    {serviceTypes.map((service) => (
                      <MenuItem key={service.id} value={service.name}>
                        {service.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <SigninTextInput
                  title={"Durée de l’expérience (en heures)"}
                  value={experienceHours}
                  id="experienceHours"
                  name="experienceHours"
                  type="number"
                  onChange={(value) => setExperienceHours(value)}
                  // errorMessage={errors.description}
                  // icon={<LockSVG />}
                />
              </Stack>
            </Box>
          </Box>
        </form>
      </Modal>
      <CustomConfirm
        visible={deleteModal}
        onClose={toggleDeleteModal}
        title={confirmModalTitle}
        onConfirm={deleteConfirm}
      />
    </Box>
  );
}

ServiceHistory.propTypes = {};

export default ServiceHistory;
