import React from "react";
import {
  Box,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CustomKeyValue } from "../../../components";
import { LineSVG } from "../../../utils/svgImages";
import { COLORS } from "../../../utils";

const DAYS = [
  "LUNDI",
  "MARDI",
  "MERCREDI",
  "JEUDI",
  "VENDREDI",
  "SAMEDI",
  "DIMANCHE",
];

const style = {
  fontSize: 12,
  color: "#57585A",
  fontWeight: 550,
  textTransform: "capitalize",
  textAlign: "center",
  border: "1px solid #c2c2c2",
};

function CommandDetailCard({ command, index, children, isBon }) {
  return (
    <div style={{ width: "100%", marginTop: 10 }}>
      {!isBon ? (
        <Typography
          fontSize={20}
          fontWeight={700}
          sx={{ borderBottom: "1px solid #c2c2c2", width: "120px" }}
          color={COLORS.primary}
        >
          Besoin {index + 1}
        </Typography>
      ) : null}

      <Box mt={2}>
        <Stack
          direction={"row"}
          alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
          spacing={2}
          width={{ xs: "100%", md: "70%", lg: "70%" }}
          mt={2}
          justifyContent="space-between"
        >
          <CustomKeyValue
            title={"Type d’agent"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
            }}
            value={command?.AgentType?.name}
          />
          {!isBon ? <CustomKeyValue
            title={"Quantité"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
            }}
            value={`${command?.numberOfAgent} Agents`}
          /> : null}
          <CustomKeyValue
            title={"Type de service"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
            }}
            value={command.serviceTypes?.map((val) => val?.name)?.toString()}
          />
        </Stack>
      </Box>

      <Typography mt={3} mb={1} color="#57585A" fontSize={13} fontWeight="600">
        Jour(s) et heure(s) de service
      </Typography>
      <Box
        style={{
          border: "1px solid #c2c2c270",
          borderRadius: 5,
          // width: "70%",
        }}
        sx={{ width: { xs: "100%", md: "70%", lg: "70%" } }}
      >
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow sx={{ bgcolor: "#B9BABC90" }}>
                {DAYS.map((val, index) => (
                  <TableCell key={index} sx={style}>
                    {val}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow sx={{ bgcolor: "#fff" }}>
                {DAYS?.map((val, index) => (
                  <TableCell key={index} sx={style}>
                    {Object.values(command.serviceDays || {})?.filter(
                      (item) => item.day === val
                    ).length ? (
                      <Stack>
                        <Typography>
                          {new Date(
                            Object.values(command.serviceDays).filter(
                              (item) => item.day === val
                            )[0]?.startTime
                          )?.getHours()}
                          H:
                          {new Date(
                            Object.values(command.serviceDays).filter(
                              (item) => item.day === val
                            )[0]?.endTime
                          )?.getMinutes()}
                        </Typography>
                        <Typography>
                          {new Date(
                            Object.values(command.serviceDays).filter(
                              (item) => item.day === val
                            )[0]?.endTime
                          )?.getHours()}
                          H:
                          {new Date(
                            Object.values(command.serviceDays).filter(
                              (item) => item.day === val
                            )[0]?.endTime
                          )?.getMinutes()}
                        </Typography>
                      </Stack>
                    ) : (
                      <LineSVG />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box my={3} style={{ width: "100%" }}>
        <Stack
          direction={{ xs: "column", md: "row", lg: "row" }}
          alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
          mt={2}
          spacing={2}
          width={"70%"}
          justifyContent="space-between"
        >
          <CustomKeyValue
            title={"Date de services"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
            }}
            value={`Du ${new Date(
              command.startDate
            ).toLocaleDateString("fr")} au ${new Date(
              command.endDate
            ).toLocaleDateString("fr")}`}
          />
          <CustomKeyValue
            title={"Commentaire"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
            }}
            value={command.comment}
            // rootStyle={{ width: "450px" }}
          />
        </Stack>
      </Box>
      {children}
      <Divider />
    </div>
  );
}

export default CommandDetailCard;
