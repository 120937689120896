import React from "react";
import {
  Box,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import { MoreVert } from "@mui/icons-material";

const MenuProps = {
  PaperProps: {
    style: {
      width: 150,
    },
  },
};

function NotificationCard({ data, onRead, onArchive }) {
  return (
    <Stack
      direction={"row"}
      spacing={2}
      p={3}
      sx={{ borderBottom: "1px solid #fff" }}
    >
         {!data.itIsAlreadyRead && !data.itIsArchive ? (
        <Box
          sx={{
            width: 10,
            height: 10,
            borderRadius: 200,
            backgroundColor: "#4caa53",
            mt: 0.5
          }}
        />
      ) : null}
      <Box sx={{ width: "90%" }}>
        <Typography sx={{ color: "#fff", fontSize: 14 }}>
          {data.frMessage}
        </Typography>

        <Stack direction={"row"} alignItems="center" spacing={2} mt={1}>
          <Typography sx={{ color: "#8e9093", fontSize: 12 }}>
            {moment(data.createdAt).fromNow()}
          </Typography>
          {/* <Typography sx={{ color: "#57585A", fontSize: 12 }}>
            • Grenoble
          </Typography> */}
        </Stack>
      </Box>
     <Select
        MenuProps={MenuProps}
        variant="standard"
        disableUnderline
        sx={{ "& .MuiSelect-icon": { transform: "none" }, }}
        IconComponent={(props) => (
          <MoreVert fontSize="medium" {...props} style={{ color: "#fff" }} />
        )}
      >
        <MenuItem
          style={{
            background: "white",
            color: "#161928",
            fontSize: 14,
            fontWeight: 700,
          }}
          onClick={() => onArchive(data.ownerId, data.id)}
        >
          Archiver
        </MenuItem>
        <Divider sx={{ mx: 2 }} />
        <MenuItem
          style={{
            background: "white",
            color: "#161928",
            fontSize: 14,
            fontWeight: 700,
          }}
          onClick={() => onRead(data.ownerId, data.id)}
        >
          Marquer comme lu
        </MenuItem>
      </Select>
   
    </Stack>
  );
}

export default NotificationCard;
