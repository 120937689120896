import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import {
  CustomButton,
  CustomConfirm,
  CustomReasonReject,
} from "../../components";
import { COLORS } from "../../utils";
import { FileSVG } from "../../utils/svgImages";
import {
  adminSaveSendCommand,
  clientCancelCommand,
  clientValidateRejectCommand,
} from "../../redux/commandProcessing/commandProcessingActions";
import { listCommands } from "../../redux/commands/commandActions";
import CommandStatus from "./components/CommandStatus";
import { Print } from "@mui/icons-material";
import { saveCommandPDF } from "../../utils/pdfUtil";
import CommandDetailSite from "./components/CommandDetailSite";
import CommandDetailClient from "./components/CommandDetailClient";
import CommandRecapTable from "./components/CommandRecapTable";
import CommandDevisModal from "./components/CommandDevisModal";
import {
  listBonDeCommand,
  listCommandDevis,
} from "../../redux/devis/devisActions";
import CommandDevisCard from "./components/CommandDevisCard";
import DevisPreviewModal from "./components/DevisPreviewModal";
import CommandBonModal from "./components/CommandBonModal";

function CommandDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const agent = JSON.parse(localStorage.getItem("agent") || "{}");

  const commandStore = useSelector((store) => store.commands[id]);
  const agentTypes = useSelector((state) => state.agentTypes);

  const client = commandStore?.client;
  
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState("");
  const [modalSource, setModalSource] = useState("");
  const [rejectDetailModal, setRejectDetailModal] = useState(false);
  const [devisModal, setDevisModal] = useState(false);
  const [bonCommandModal, setBonCommandModal] = useState(false);
  const [devisPreview, setDevisPreview] = useState(false);
  const [filename, setFilename] = useState("");
  const [devis, setDevis] = useState(null);
  const [selectedDevis, setSelectedDevis] = useState(null);
  const [bonCommand, setBonCommand] = useState(null);
  const [isEditDevis, setIsEditDevis] = useState(false);

  function groupByDate() {
    const results = [];
    let commandLineTab = commandStore?.comandLines;

    if (commandLineTab) {
      // commandLineTab?.sort((a, b) => b.serviceDay.day - a.serviceDay.day);
      while (commandLineTab.length !== 0) {
        let dayOfWeek = commandLineTab[0].serviceDay.day;
        let group = commandLineTab.filter(
          (item) => item.serviceDay.day === dayOfWeek
        );
        results.push({
          title: dayOfWeek,
          data: group,
          date: group[0]?.date,
        });
        commandLineTab = commandLineTab.slice(group.length);
      }

      return [...results];
    }
    return [];
  }

  const groupedCommands = [...groupByDate()].sort((a, b) => b.date < a.date);;

  const onPrint = async () => {
    const results = [];
    let commandLineTab = commandStore?.comandLines;

    commandLineTab?.sort((a, b) => b.serviceDay.day - a.serviceDay.day);
    while (commandLineTab.length !== 0) {
      let dayOfWeek = commandLineTab[0].serviceDay.day;
      let group = commandLineTab.filter(
        (item) => item.serviceDay.day === dayOfWeek
      );
      results.push({
        title: dayOfWeek,
        data: group,
        date: group[0]?.date,
      });
      commandLineTab = commandLineTab.slice(group.length);
    }

    await saveCommandPDF(commandStore, [...results]);
  };

  const toggleDevisModal = (item, isEditDevis) => {
    if (item) {
      setSelectedDevis(item);
      setIsEditDevis(isEditDevis);
    } else {
      setSelectedDevis(null);
      setIsEditDevis(false);
    }
    setDevisModal(!devisModal);
  };

  const toggleBonCommandModal = () => {
    setBonCommandModal(!bonCommandModal);
  };

  const onClone = () => {
    navigate("/home/command-new", { state: { command: commandStore } });
  };

  const closeDevis = (name) => {
    setFilename("");
    setDevisPreview(false);
  };

  const onError = () => {
    setLoading(false);
  };

  const callback = () => {
    setLoading(false);
    dispatch(
      listCommands(agent?.authAccountType === "admin" ? null : agent?.id)
    );
    navigate("/home/my-commands");
  };

  const onCancelCommand = () => {
    setLoading(true);
    dispatch(clientCancelCommand(commandStore.id, callback, onError));
  };

  const onValidateRejectCommand = (status, rejectedReason) => {
    setLoading(true);
    dispatch(
      clientValidateRejectCommand(
        {
          commandId: commandStore.id,
          clientId: agent?.id,
          status,
          rejectedReason,
        },
        callback,
        onError
      )
    );
  };

  const onSave = () => {
    setLoading(true);
    dispatch(
      adminSaveSendCommand(
        {
          commandId: commandStore.id,
          adminId: agent?.client?.id || agent.id,
        },
        callback,
        onError
      )
    );
  };

  const onModalConfirm = () => {
    switch (modalSource) {
      case "VALIDATE":
        onValidateRejectCommand("VALIDATED");
        break;
      case "REJECT":
        onValidateRejectCommand("REJECTED");
        break;
      case "CANCEL":
        onCancelCommand();
        break;
      case "SAVE_SEND":
        onSave();
        break;

      default:
        break;
    }
    onCloseModal();
  };

  const getDevis = useCallback(async () => {
    const res = await listCommandDevis(commandStore?.id);
    setDevis(res);
  }, [commandStore?.id]);

  const getBonCommand = useCallback(async () => {
    const res = await listBonDeCommand(commandStore?.id);
    setBonCommand(res);
  }, [commandStore?.id]);

  const onCloseModal = () => {
    setConfirmModal(false);
    setRejectDetailModal(false);
    setConfirmModalTitle("");
    setModalSource("");
  };

  useEffect(() => {
    getDevis();
  }, [getDevis]);

  useEffect(() => {
    getBonCommand();
  }, [getBonCommand]);

  return (
    <Box
      p={4}
      px={{ xs: 2, md: 4, lg: 4 }}
      style={{
        backgroundColor: "#f4f3f7",
        minHeight: "100vh",
      }}
    >
      <Stack
        direction={{ xs: "column", md: "row", lg: "row" }}
        spacing={2}
        alignItems={{ xs: "flex-start" }}
        my={3}
        sx={{ justifyContent: "space-between" }}
      >
        <Stack direction="row" alignItems={"center"} spacing={2}>
          <Avatar sx={{ bgcolor: "#161B38", width: 48, height: 48 }}>
            <FileSVG />
          </Avatar>
          <Typography color="primary" fontSize={20} fontWeight={600}>
            Commande N° {commandStore?.commandNumber}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <CommandStatus status={commandStore?.state} />
          {agent?.authAccountType === "client" ? (
            <CustomButton
              title={"Cloner"}
              onClick={onClone}
              rootStyle={{ margin: 2, padding: 1.5 }}
            />
          ) : null}
          {agent?.authAccountType === "admin" &&
          commandStore?.state !== "VALIDATED" ? (
            <CustomButton
              title={"Envoyer Devis"}
              onClick={toggleDevisModal}
              rootStyle={{
                margin: 2,
                padding: 1.5,
                backgroundColor: "#25634c",
              }}
            />
          ) : null}
          <CustomButton
            title={"Imprimer"}
            leftComponent={<Print />}
            onClick={onPrint}
            titleStyle={{ marginLeft: 1 }}
            rootStyle={{ margin: 2, padding: 1.5 }}
          />
        </Stack>
      </Stack>

      {/* {["admin", "superadmin"]?.includes(agent?.authAccountType) ? ( */}
        <CommandDetailClient client={client} />
      {/* ) : null} */}
      <CommandDetailSite data={commandStore} />

      {["admin", "superadmin", "client"]?.includes(agent?.authAccountType) &&
      commandStore?.state === "REJECTED" ? (
        <Stack mt={3}>
          <Typography color={COLORS.red} fontWeight={700}>
            Raison du rejet
          </Typography>
          <Typography>{commandStore?.rejectedReason}</Typography>
        </Stack>
      ) : null}

      <CommandRecapTable
        isDetail
        data={groupedCommands}
        agentTypes={agentTypes}
        rootStyle={{ marginTop: 5, marginBottom: 3 }}
      />

      {devis?.map((dev, index) => (
        <CommandDevisCard
          key={index}
          devis={dev}
          index={index + 1}
          command={commandStore}
          bonCommand={bonCommand}
          onEdit={toggleDevisModal}
          onBonCommand={toggleBonCommandModal}
        />
      ))}

      {loading && (
        <CustomButton
          isLoading={true}
          rootStyle={{
            padding: 1.5,
            width: "100%",
            mt: 2,
            backgroundColor: "#c2c2c280",
          }}
        />
      )}
      <div style={{ display: loading ? "none" : "block" }}>
        <Stack
          direction={{ xs: "column", md: "row", lg: "row" }}
          spacing={2}
          alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
          justifyContent={"space-between"}
          mt={2}
        >
          <CustomButton
            title="Sortir"
            onClick={() => navigate("/home/my-commands")}
            titleStyle={{ color: COLORS.primary }}
            rootStyle={{
              padding: 1.5,
              backgroundColor: "transparent",
              border: `1px solid ${COLORS.primary}`,
              width: 100,
            }}
          />
        </Stack>
      </div>
      <CustomConfirm
        showIcon={false}
        visible={confirmModal}
        title={confirmModalTitle}
        onConfirm={onModalConfirm}
        onClose={onCloseModal}
      />
      {rejectDetailModal && (
        <CustomReasonReject
          visible={rejectDetailModal}
          title={confirmModalTitle}
          onConfirm={(reason) => onValidateRejectCommand("REJECTED", reason)}
          onClose={onCloseModal}
        />
      )}
      {devisModal ? (
        <CommandDevisModal
          commandId={commandStore.id}
          devis={selectedDevis}
          open={devisModal}
          isEditDevis={isEditDevis}
          onClose={toggleDevisModal}
        />
      ) : null}
      {bonCommandModal ? (
        <CommandBonModal
          commandId={commandStore.id}
          open={bonCommandModal}
          onClose={toggleBonCommandModal}
        />
      ) : null}
      {devisPreview ? (
        <DevisPreviewModal
          open={devisPreview}
          filename={filename}
          onClose={closeDevis}
        />
      ) : null}
    </Box>
  );
}

CommandDetail.propTypes = {};

export default CommandDetail;
