import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { COLORS } from "../utils";
import { Close } from "@mui/icons-material";

function DetailModalTitle({ title, onClose }) {
  return (
    <Box
      id="modal-modal-title"
      style={{
        alignItems: "center",
        width: "100%",
        justifyContent:'center',
        display:'flex'
      }}
      p={4}
      pb={2}
      pt={2}
    >
    <div style={{alignItems: 'center'}}>
    <Typography
        variant="h6"
        component="h2"
        style={{
          color: COLORS.primary,
          fontWeight: "550",
        }}
        textAlign="center"
      >
        {title}
      </Typography>

        <hr
          style={{
            width: 120,
            borderWidth: 1.5,
            borderColor: COLORS.primary,
            borderRadius: 50,
          }}
        />
    </div>
      <IconButton onClick={onClose} style={{position: 'absolute', right: 15,top: 15}}>
        <Close />
      </IconButton>
    </Box>
  );
}

DetailModalTitle.propTypes = {};

export default DetailModalTitle;
