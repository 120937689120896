import React from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";
import { CustomKeyValue, DetailModalTitle } from "../../components";
import { COLORS } from "../../utils";
import { SERVER } from "../../utils/server";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, md: 650, lg: 650 },
  bgcolor: "background.paper",
  borderRadius: 2,
  p: 3,
};

function AgentProfileDetailModal({ data, open, onClose }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <DetailModalTitle title={`Détails de profil`} onClose={onClose} />

        <Box
          sx={{
            backgroundColor: "#D9DBDD1A",
            width: "100%",
            height: "100%",
            borderRadius: 2,
            p: 3,
            mb: 4,
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 5 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <>
              <Grid item xs={2} sm={4} md={4}>
                <CustomKeyValue
                  title={"Qualification"}
                  value={data.qualification?.name}
                  titleStyle={{ color: "#57585A" }}
                  valueStyle={{
                    fontWeight: 700,
                    color: COLORS.primary,
                    wordWrap: "break-word",
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <CustomKeyValue
                  title={"Commentaire"}
                  value={data.comment}
                  titleStyle={{ color: "#57585A" }}
                  valueStyle={{
                    fontWeight: 700,
                    color: COLORS.primary,
                    wordWrap: "break-word",
                  }}
                />
              </Grid>
            </>
          </Grid>

          <Typography sx={{ color: "#757575", fontSize: 12 }} mt={3} mb={1}>
            Pièce justificative
          </Typography>

          {data.justificatifUrl?.substr(data.justificatifUrl?.length - 3) !==
          "pdf" ? (
            <img
              src={`${SERVER.replace("/api/v1", "")}${data.justificatifUrl}`}
              alt="justificatif"
              style={{ height: 350, width: "100%", borderRadius: 5 }}
            />
          ) : (
            <iframe
              title="Pièce justificative"
              src={`${SERVER.replace("/api/v1", "")}${data.justificatifUrl}`}
              style={{ height: 350, width: "100%", borderRadius: 5 }}
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default AgentProfileDetailModal;
