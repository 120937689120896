import React, { useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import "react-nice-dates/build/style.css";
import CrenauxCard from "./CrenauxCard";
import { useSelector } from "react-redux";
import CustomDateInput from "../../../components/dateInput/CustomDateInput";

const MenuProps = {
  PaperProps: {
    style: {
      width: 150,
    },
  },
};

function CommandForm({
  agentTypes,
  formFields,
  setFormFields,
  resources,
  serviceDays,
  setResources,
}) {
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");
  const clients = useSelector((state) =>
    Object.values(state.clients).filter((val) => !val.isDeleted)
  );
  const operationnels = useSelector((state) =>
    Object.values(state.operationnels)
  );

  const handleFormFieldChange = ({ value, key }) => {
    setFormFields({ ...formFields, [key]: value });
  };

  return (
    <Box sx={{ my: 4 }}>
      <Stack style={{ marginTop: 15 }}>
        {["admin"].includes(agent?.authAccountType) ? (
          <FormControl sx={{ mb: 3 }} fullWidth>
            <Typography
              color="black"
              sx={{
                fontSize: {
                  xs: 12,
                  md: 14,
                },
                fontWeight: 600,
                mb: 1,
              }}
            >
              Client
            </Typography>
            <Select
              variant="standard"
              value={formFields.clientId}
              onChange={({ target }) =>
                handleFormFieldChange({ value: target.value, key: "clientId" })
              }
              input={<OutlinedInput />}
              style={{
                backgroundColor: "#fff",
              }}
              MenuProps={MenuProps}
            >
              {clients.map((client, index) => (
                <MenuItem key={index} value={client.id}>
                  {client.raisonSociale}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}

        {(["client"].includes(agent?.authAccountType) ||
          formFields?.clientId) &&
        !["sousCompteClient"].includes(agent?.authAccountType) ? (
          <FormControl sx={{ mb: 3 }} fullWidth>
            <Typography
              color="black"
              sx={{
                fontSize: {
                  xs: 12,
                  md: 14,
                },
                fontWeight: 600,
                mb: 1,
              }}
            >
              Opérationnels
            </Typography>
            <Select
              variant="standard"
              value={formFields.operationelId}
              onChange={({ target }) =>
                handleFormFieldChange({
                  value: target.value,
                  key: "operationelId",
                })
              }
              input={<OutlinedInput />}
              style={{
                backgroundColor: "#fff",
              }}
              MenuProps={MenuProps}
            >
              {operationnels
                .filter((val) => val.client?.id === formFields?.clientId)
                .map((client, index) => (
                  <MenuItem key={index} value={client.id}>
                    {`${client.firstname} ${client.lastname}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        ) : null}

        <Typography
          color="black"
          sx={{
            fontSize: {
              xs: 12,
              md: 14,
            },
            fontWeight: 600,
            mb: 1,
          }}
        >
          {"Période (Date de début et de fin)"}
        </Typography>
        {/* <DateRangePicker
          startDate={formFields.startDate}
          endDate={formFields.endDate}
          onStartDateChange={
            (date) => handleFormFieldChange({ value: date, key: "startDate" })
            // onSelectDate({ ...command, startDate: date })
          }
          onEndDateChange={
            (date) => handleFormFieldChange({ value: date, key: "endDate" })
            // onSelectDate({ ...command, endDate: date })
          }
          minimumLength={1}
          format="dd MMM yyyy"
          locale={fr}
        >
          {({ startDateInputProps, endDateInputProps, focus }) =>
            isBrowser ? (
              <Stack
                direction={"row"}
                style={{
                  border: "1px solid #c2c2c2",
                  padding: 10,
                  borderRadius: "5px",
                  backgroundColor: "white",
                }}
              >
                <CalendarMonth />
                <TextField
                  id="start"
                  size="small"
                  variant="standard"
                  sx={{
                    "& .MuiInput-root": {
                      "&:before, :after, :hover:not(.Mui-disabled):before": {
                        borderBottom: 0,
                      },
                    },
                    ml: 1,
                    width: "100px",
                  }}
                  {...startDateInputProps}
                />
                <Typography mr={1}>---</Typography>
                <TextField
                  id="end"
                  size="small"
                  variant="standard"
                  sx={{
                    "& .MuiInput-root": {
                      "&:before, :after, :hover:not(.Mui-disabled):before": {
                        borderBottom: 0,
                      },
                    },
                    width: "100px",
                  }}
                  {...endDateInputProps}
                />
              </Stack>
            ) : (
              <Stack
                direction={"row"}
                style={{
                  border: "1px solid #c2c2c2",
                  padding: 10,
                  borderRadius: "5px",
                  backgroundColor: "white",
                }}
              >
                <CalendarMonth />
                <input
                  style={{ border: "none", width: "40%" }}
                  {...startDateInputProps}
                  placeholder="Start date"
                />
                <Typography>---</Typography>
                <input
                  style={{ border: "none", width: "45%", textAlign: "right" }}
                  {...endDateInputProps}
                  placeholder="End date"
                />
              </Stack>
            )
          }
        </DateRangePicker> */}

        <Stack direction="row" spacing={3} alignItems="flex-end">
          <CustomDateInput
            title={"Date de début"}
            onChange={(date) =>
              handleFormFieldChange({ value: date, key: "startDate" })
            }
            value={formFields.startDate}
            containerStyle={{ marginTop: "6px" }}
          />
          <CustomDateInput
            title={"Date de fin"}
            onChange={(date) =>
              handleFormFieldChange({ value: date, key: "endDate" })
            }
            minDate={formFields.startDate}
            value={formFields.endDate}
            containerStyle={{ marginTop: "6px" }}
          />
        </Stack>
      </Stack>

      {formFields.startDate && formFields.endDate ? (
        <CrenauxCard
          agentTypes={agentTypes}
          resources={resources}
          serviceDays={serviceDays}
          setResources={setResources}
          date={{ sDate: formFields.startDate, eDate: formFields.endDate }}
        />
      ) : null}

      <div style={{ marginTop: 15, width: "100%" }}>
        <Typography
          color="black"
          sx={{
            fontSize: {
              xs: 12,
              md: 14,
            },
            fontWeight: 600,
            mb: 1,
          }}
        >
          Ajouter un Commentaire
        </Typography>
        <TextareaAutosize
          aria-label="minimum height"
          minRows={6}
          value={formFields.comment}
          onChange={({ target }) =>
            handleFormFieldChange({ value: target.value, key: "comment" })
          }
          placeholder="Rédiger un commentaire"
          style={{
            width: "100%",
            borderRadius: 8,
            borderColor: "#bcbbbe",
            padding: 15,
          }}
        />
      </div>
    </Box>
  );
}

export default CommandForm;
