import React from "react";
import { Stack, Typography } from "@mui/material";
import { getStatus } from "../../../utils/commandUtils";
import { COLORS } from "../../../utils";

function CommandStatus({ status }) {
  return (
    <Stack
      sx={{
        backgroundColor: getStatus(status).color,
        borderRadius: 10,
        border: `1px solid ${COLORS.primary}`,
        px: 3,
        justifyContent:'center',
      }}
    >
      <Typography color={status === "CANCELED" ? "white":COLORS.primary}>{getStatus(status).name}</Typography>
    </Stack>
  );
}

export default CommandStatus;
