import React, { useState } from "react";
import {
  Box,
  Divider,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { TablePaginationActions } from "../../../components";
import { useSelector } from "react-redux";
import EmptyList from "../../../components/EmptyList";
import { COLORS } from "../../../utils";
import { isBrowser, isMobile } from "react-device-detect";

const MenuProps = {
  PaperProps: {
    style: {
      width: 150,
    },
  },
};

function ClientTable({
  openDelete,
  openSuspend,
  toggleDetail,
  toggleEdit,
  filter,
}) {
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");
  const clients = useSelector((state) => Object.values(state.clients));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      {clients.length ? (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {isMobile ? (
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "600" }}
                  ></TableCell>
                ) : null}
                <TableCell align="left" sx={{ fontWeight: "600" }}>
                  #
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: "600" }}>
                  Nom
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  Ville
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  Adresse
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  Téléphone
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  Email
                </TableCell>
                {isBrowser ? (
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "600" }}
                  ></TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? clients.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : clients
              )
                .filter((admin) =>
                  filter === "suspended"
                    ? admin.isSuspended
                    : filter === "deleted"
                    ? admin.isDeleted
                    : !admin.isSuspended && !admin.isDeleted
                )
                .map((admin, index) => (
                  <TableRow
                    key={admin.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {isMobile ? (
                      <TableCell align="center">
                        <Select
                          MenuProps={MenuProps}
                          variant="standard"
                          disableUnderline
                          sx={{ "& .MuiSelect-icon": { transform: "none" } }}
                          IconComponent={(props) => (
                            <MoreVert
                              fontSize="large"
                              {...props}
                              style={{ color: "#161B38" }}
                            />
                          )}
                        >
                          <MenuItem
                            style={{
                              background: "white",
                              color: "#161928",
                              fontSize: 14,
                              fontWeight: 700,
                            }}
                            onClick={() => toggleDetail(admin)}
                          >
                            Détails
                          </MenuItem>
                          <Divider sx={{ mx: 2 }} />
                          <MenuItem
                            style={{
                              background: "white",
                              color: "#161928",
                              fontSize: 14,
                              fontWeight: 700,
                            }}
                            onClick={() => toggleEdit(admin)}
                          >
                            Modifier
                          </MenuItem>
                          <Divider sx={{ mx: 2 }} />
                          <MenuItem
                            style={{
                              background: "white",
                              color: !admin.isSuspended
                                ? COLORS.red
                                : COLORS.green,
                              fontSize: 14,
                              fontWeight: 700,
                            }}
                            onClick={() => openSuspend(admin)}
                          >
                            {!admin.isSuspended ? "Suspendre" : "Retablir"}
                          </MenuItem>
                          <Divider sx={{ mx: 2 }} />
                          {filter === "suspended" &&
                          ["superadmin"].includes(agent?.authAccountType) ? (
                            <MenuItem
                              style={{
                                background: "white",
                                color: COLORS.red,
                                fontSize: 14,
                                fontWeight: 700,
                              }}
                              onClick={() => openDelete(admin)}
                            >
                              {"Supprimer"}
                            </MenuItem>
                          ) : null}
                        </Select>
                      </TableCell>
                    ) : null}
                    <TableCell component="th" scope="row" align="left">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      {admin.raisonSociale}
                    </TableCell>
                    <TableCell align="center">
                      {admin.adressCompleted?.city}
                    </TableCell>
                    <TableCell align="center">
                      {admin.adressCompleted?.address}
                    </TableCell>
                    <TableCell align="center">{admin.phoneNumber}</TableCell>
                    <TableCell align="center">{admin.email}</TableCell>
                    {isBrowser ? (
                      <TableCell align="center">
                        <Select
                          MenuProps={MenuProps}
                          disableUnderline
                          variant="standard"
                          sx={{ "& .MuiSelect-icon": { transform: "none" } }}
                          IconComponent={(props) => (
                            <MoreVert
                              fontSize="large"
                              {...props}
                              style={{ color: "#161B38" }}
                            />
                          )}
                        >
                          <MenuItem
                            style={{
                              background: "white",
                              color: "#161928",
                              fontSize: 14,
                              fontWeight: 700,
                            }}
                            onClick={() => toggleDetail(admin)}
                          >
                            Détails
                          </MenuItem>
                          <Divider sx={{ mx: 2 }} />
                          <MenuItem
                            style={{
                              background: "white",
                              color: "#161928",
                              fontSize: 14,
                              fontWeight: 700,
                            }}
                            onClick={() => toggleEdit(admin)}
                          >
                            Modifier
                          </MenuItem>
                          <Divider sx={{ mx: 2 }} />
                          <MenuItem
                            style={{
                              background: "white",
                              color: !admin.isSuspended
                                ? COLORS.red
                                : COLORS.green,
                              fontSize: 14,
                              fontWeight: 700,
                            }}
                            onClick={() => openSuspend(admin)}
                          >
                            {!admin.isSuspended ? "Suspendre" : "Retablir"}
                          </MenuItem>
                          <Divider sx={{ mx: 2 }} />
                          {filter === "suspended" &&
                          ["superadmin"].includes(agent?.authAccountType) ? (
                            <MenuItem
                              style={{
                                background: "white",
                                color: COLORS.red,
                                fontSize: 14,
                                fontWeight: 700,
                              }}
                              onClick={() => openDelete(admin)}
                            >
                              {"Supprimer"}
                            </MenuItem>
                          ) : null}
                        </Select>
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    10,
                    25,
                    50,
                    100,
                    { label: "All", value: -1 },
                  ]}
                  labelRowsPerPage="Rangées par page"
                  count={clients.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rangées par page",
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {!clients.filter((admin) =>
            filter === "suspended"
              ? admin.isSuspended
              : filter === "deleted"
              ? admin.isDeleted
              : !admin.isSuspended && !admin.isDeleted
          ).length ? (
            <Typography my={4} textAlign="center" color={"#9b9999"}>
              Aucun élément trouvé
            </Typography>
          ) : null}
        </TableContainer>
      ) : (
        <EmptyList />
      )}
    </Box>
  );
}

ClientTable.propTypes = {};

export default ClientTable;
