import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { CustomKeyValue } from "../../../components";
import { COLORS } from "../../../utils";
import { useSelector } from "react-redux";
import CommandRecapTable from "./CommandRecapTable";

function StepThree({
  commandLines,
  agentTypes,
  NextStepper,
  idSiteIntervension,
  formFields,
}) {
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");
  const site = useSelector((state) => state.sites[idSiteIntervension]);
  const selectedClient = useSelector(
    (state) => state.clients[formFields?.clientId || agent?.client?.id || agent?.id]
  );
  const selectedOperationnel = useSelector(
    (state) => state.operationnels[formFields?.operationelId]
  );

  function groupByDate() {
    const results = [];
    let commandLineTab = [...commandLines];

    commandLineTab.sort((a, b) => b.dayOfWeek - a.dayOfWeek);
    while (commandLineTab.length !== 0) {
      let dayOfWeek = commandLineTab[0].dayOfWeek;
      let group = commandLineTab.filter((item) => item.dayOfWeek === dayOfWeek);
      results.push({
        title: dayOfWeek,
        data: group,
        date: group[0]?.date,
      });
      commandLineTab = commandLineTab.slice(group.length);
    }

    return [...results];
  }

  const groupedCommands = groupByDate();

  // console.log("command", commandLines, groupByDate());
  return (
    <>
      <Box my={2} ml={2}>
      {selectedClient?.id ? (
          <>
            <Typography
              fontSize={20}
              fontWeight="700"
              color={COLORS.primary}
              sx={{ borderBottom: "1px solid #c2c2c2", width: "80px" }}
            >
              Client
            </Typography>

            <Stack
              direction="row"
              my={2}
              width={"70%"}
              justifyContent="space-between"
            >
              <CustomKeyValue
                title={"Nom"}
                titleStyle={{
                  color: "#57585A",
                  fontSize: 13,
                }}
                valueStyle={{
                  color: "#161718",
                  fontWeight: "700",
                  fontSize: 16,
                }}
                value={selectedClient?.raisonSociale}
                rootStyle={{ width: "100%" }}
              />
              <CustomKeyValue
                title={"Numéro de téléphone"}
                titleStyle={{
                  color: "#57585A",
                  fontSize: 13,
                }}
                valueStyle={{
                  color: "#161718",
                  fontWeight: "700",
                  fontSize: 16,
                }}
                value={selectedClient?.phoneNumber}
                rootStyle={{ width: "100%" }}
              />
            </Stack>
          </>
        ) : null}
        
        <Typography
          fontSize={20}
          fontWeight="700"
          color={COLORS.primary}
          sx={{ borderBottom: "1px solid #c2c2c2", width: "80px" }}
        >
          Site
        </Typography>
        <Stack
          direction="row"
          width={"70%"}
          mt={2}
          justifyContent="space-between"
        >
          <CustomKeyValue
            title={"Nom du site"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
            }}
            value={site.name}
            rootStyle={{ width: "100%" }}
          />
          <CustomKeyValue
            title={"Type"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
            }}
            rootStyle={{ width: "100%" }}
            value={site.siteType?.name}
          />
        </Stack>
        <Stack
          direction="row"
          width={"70%"}
          my={2}
          justifyContent="space-between"
        >
          <CustomKeyValue
            title={"Ville de l’intervention"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
            }}
            rootStyle={{ width: "100%" }}
            value={site.address?.city}
          />
          <CustomKeyValue
            title={"Adresse de l’intervention"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
            }}
            rootStyle={{ width: "100%" }}
            value={site.address?.address}
          />
        </Stack>

        {selectedOperationnel?.id ? (
          <>
            <Typography
              fontSize={20}
              fontWeight="700"
              color={COLORS.primary}
              sx={{ borderBottom: "1px solid #c2c2c2", width: "80px" }}
            >
              Opérationnel
            </Typography>

            <Stack
              direction="row"
              my={2}
              width={"70%"}
              justifyContent="space-between"
            >
              <CustomKeyValue
                title={"Nom"}
                titleStyle={{
                  color: "#57585A",
                  fontSize: 13,
                }}
                valueStyle={{
                  color: "#161718",
                  fontWeight: "700",
                  fontSize: 16,
                }}
                value={`${selectedOperationnel?.firstname} ${selectedOperationnel?.lastname}`}
                rootStyle={{ width: "100%" }}
              />
              <CustomKeyValue
                title={"Numéro de téléphone"}
                titleStyle={{
                  color: "#57585A",
                  fontSize: 13,
                }}
                valueStyle={{
                  color: "#161718",
                  fontWeight: "700",
                  fontSize: 16,
                }}
                value={selectedOperationnel?.phoneNumber}
                rootStyle={{ width: "100%" }}
              />
            </Stack>
          </>
        ) : null}

        <Divider />

        <Stack
          direction="row"
          my={2}
          width={"70%"}
          justifyContent="space-between"
        >
          <CustomKeyValue
            title={"Commentaire"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
            }}
            value={formFields.comment}
            rootStyle={{ width: "100%" }}
          />
          <CustomKeyValue
            title={"Date de services"}
            titleStyle={{
              color: "#57585A",
              fontSize: 13,
            }}
            valueStyle={{
              color: "#161718",
              fontWeight: "700",
              fontSize: 16,
            }}
            value={`Du ${new Date(
              formFields.startDate
            ).toLocaleDateString("fr")} au ${new Date(
              formFields.endDate
            ).toLocaleDateString("fr")}`}
            rootStyle={{ width: "100%" }}
          />
        </Stack>
      </Box>
      <CommandRecapTable isDetail={false} data={groupedCommands} agentTypes={agentTypes} />

      <NextStepper showLeft />
    </>
  );
}

export default StepThree;
