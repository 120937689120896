import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  CustomButton,
  CustomConfirm,
} from "../../components";
import { useDispatch } from "react-redux";
import { updateClient } from "../../redux/clients/clientActions";
import AgentDetailModal from "../agents/components/AgentDetailModal";
import ClientFormModal from "./components/ClientFormModal";
import ClientTable from "./components/ClientTable";

function Clients() {
  const agent = JSON.parse(localStorage.getItem("agent") || "{}");
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [detailModal, setDetailModal] = useState(false);
  const [filter, setFilter] = useState("list");
  const [confirmModalTitle, setConfirmModalTitle] = useState("");
  const [isDelete, setIsDelete] = useState(false);

  const toggleModal = () => {
    setSelectedItem(null);
    setOpen(!open);
  };

  const toggleDeleteModal = (item) => {
    if (item) {
      setSelectedItem(item);
    }
    setDeleteModal(!deleteModal);
  };

  const toggleDetail = (item) => {
    setSelectedItem(item);
    setDetailModal(true);
  };

  const toggleEdit = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const onDelete = () => {
    let user = { ...selectedItem, isDeleted: true };
    delete user.email;
    dispatch(
      updateClient({
        client: user,
        callback: setDeleteModal(!deleteModal),
        toastMessage: "Supprimé avec succès",
      })
    );
    setSelectedItem(null);
  };

  const onSuspend = () => {
    let user = { ...selectedItem, isSuspended: !selectedItem.isSuspended };
    delete user.email;
    dispatch(
      updateClient({
        client: user,
        callback: setDeleteModal(!deleteModal),
        toastMessage: "Suspendu avec succès",
      })
    );
    setSelectedItem(null);
  };

  const dispatch = useDispatch();

  const openSuspend = (item) => {
    setSelectedItem(item);
    setConfirmModalTitle(
      `Voulez-vous vraiment ${
        item.isSuspended ? "retablir" : "suspendre"
      } ce Client?`
    );
    setDeleteModal(true);
    setIsDelete(false);
  };

  const openDelete = (item) => {
    setSelectedItem(item);
    setConfirmModalTitle("Voulez-vous vraiment supprimer ce Client?");
    setDeleteModal(true);
    setIsDelete(true);
  };

  const deleteConfirm = () => {
    if (isDelete) {
      onDelete();
    } else {
      onSuspend();
    }
  };

  const closeDetailModal = () => {
    setSelectedItem({});
    setDetailModal(false);
  };

  return (
    <Box
      p={4}
      style={{
        backgroundColor: "#f4f3f7",
        minHeight: "100vh",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          borderRadius: 3,
          // height: "600px",
          backgroundColor: "#fff",
          padding: 2,
          marginTop: 3,
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row", lg: "row" }}
          alignItems={{ xs: "flex-start", md: "center", lg: "center" }}
          justifyContent="space-between"
          spacing={2}
          sx={{
            mb: 1,
          }}
        >
          <Typography fontWeight="600" color="#161B38" fontSize={28}>
            Liste des clients
          </Typography>

          {["superadmin", "admin"].includes(agent?.authAccountType) ? (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <FormControl>
                <InputLabel id="filter-select">Filtrer</InputLabel>
                <Select
                  labelId="filter-select"
                  id="filter-select"
                  size="small"
                  label="Filtrer"
                  sx={{ width: { xs: 200, md: 250, lg: 250 } }}
                  onChange={({ target }) => setFilter(target.value)}
                  displayEmpty
                  renderValue={
                    filter !== ""
                      ? undefined
                      : () => (
                          <Typography color="#c2c2c2">
                            Sélectionner un élément
                          </Typography>
                        )
                  }
                  IconComponent={(props) => (
                    <KeyboardArrowDown
                      {...props}
                      style={{
                        fontSize: "30px",
                      }}
                    />
                  )}
                  value={filter}
                >
                  <MenuItem value="list" style={{ background: "white" }}>
                    Liste des Clients
                  </MenuItem>
                  <MenuItem value="suspended" style={{ background: "white" }}>
                    Clients Suspendu
                  </MenuItem>
                  {/* <MenuItem value="deleted" style={{ background: "white" }}>
                    Clients Supprimé
                  </MenuItem> */}
                </Select>
              </FormControl>

              <CustomButton
                title={"Ajouter"}
                titleStyle={{
                  fontSize: "13px",
                  textTransform: "none",
                  fontWeight: "600",
                }}
                rootStyle={{
                  backgroundColor: "#161B38",
                  height: 42,
                }}
                onClick={toggleModal}
              />
            </Stack>
          ) : null}
        </Stack>

        <ClientTable
          openDelete={openDelete}
          openSuspend={openSuspend}
          toggleDetail={toggleDetail}
          toggleEdit={toggleEdit}
          filter={filter}
        />
      </Paper>

      {open && (
        <ClientFormModal
          open={open}
          item={selectedItem}
          toggleModal={toggleModal}
        />
      )}

      {deleteModal && (
        <CustomConfirm
          visible={deleteModal}
          title={confirmModalTitle}
          onConfirm={deleteConfirm}
          onClose={toggleDeleteModal}
        />
      )}
      {detailModal && (
        <AgentDetailModal
          data={selectedItem}
          open={detailModal}
          onClose={closeDetailModal}
        />
      )}
    </Box>
  );
}

export default Clients;
