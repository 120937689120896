import clientTypes from "./clientTypes";

const clientReducer = (state = {}, action) => {
  const payload = action.payload;
  if (action.type === clientTypes.CREATE_CLIENT) {
    return { [payload.id]: { ...payload }, ...state };
  }
  if (action.type === clientTypes.REMOVE_CLIENT) {
    const localState = { ...state };
    delete localState[payload];
    return { ...localState };
  }
  if (action.type === clientTypes.UPDATE_CLIENT) {
    return { ...state, [payload.id]: { ...payload } };
  }
  if (action.type === clientTypes.LIST_CLIENTS) {
    let tempData = {};
    for (let obj of payload) {
      tempData[obj.id] = { ...obj };
    }
    return { ...tempData };
  }
  return state;
};

export default clientReducer;
