import React from "react";
import { Box, Typography } from "@mui/material";
import { COLORS } from "../utils";

function NotAuthorized() {
  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        m: 10,
      }}
    >
      <div>
        <Typography textAlign="center" fontWeight="800" fontSize={150} color={COLORS.primary}>
          401
        </Typography>
        <Typography textAlign="center" fontWeight="600" color={COLORS.primary}>
          Vous n'êtes pas autorisé à accéder à cette page.
        </Typography>
      </div>
    </Box>
  );
}

export default NotAuthorized;
