import React, { useState } from "react";
import { Card, CardContent } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import SigninTextInput from "./components/SigninTextInput";
import { AuthLayout } from "../../layouts";
import SigninHeader from "./components/SigninHeader";
import SigninButton from "./components/SigninButton";
import { sendOtp } from "../../redux/auth/authActions";
import { Mail } from "@mui/icons-material";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const callback = () => {
    setIsLoading(false);
    navigate("/new-password");
  };
  const onError = () => {
    setIsLoading(false);
  };

  const login = (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(sendOtp({ email }, callback, onError));
  };

  return (
    <AuthLayout
      onSubmit={login}
      leftComponent={
        <Card
          sx={{
            maxWidth: 430,
            boxShadow: "none",
          }}
        >
          <CardContent>
            <SigninHeader
              title={"Réinitialisation du mot de passe"}
              subTitle={
                "Rentrer votre adresse mail et recevez le mail de réinitialisation de votre mot de passe."
              }
            />

            <div
              component="form"
              style={{
                width: "100%",
              }}
            >
              <SigninTextInput
                title={"Email"}
                onChange={(value) => setEmail(value)}
                icon={<Mail />}
                required
                value={email}
              />

              <SigninButton
                title={"Envoyer le code"}
                onClick={login}
                isLoading={isLoading}
              />
            </div>
          </CardContent>
        </Card>
      }
    />
  );
};

export default ForgotPassword;
