import axios from "axios";
import { SERVER } from "../../utils/server";
import agentProfileTypes from "./agentProfileTypes";
import notificationTypes from "../notifications/notificationTypes";

  export const createAgentProfile =
  (agent, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.post(`${SERVER}securityAgent/agentProfile`, agent);
      if (results.data.isSuccess) {
        dispatch({
          type: agentProfileTypes.CREATE_AGENT_PROFILE,
          payload: results.data.data,
        });
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Enregistré avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: { message: results.data.message },
        });
        onError?.();
      }
    } catch (error) {
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Agent profile creation error", error);
    }
  };

export const listAgentProfiles = (id) => async (dispatch) => {
  try {
    const results = await axios.get(`${SERVER}securityAgent/get/agentProfiles?agentId=${id}`);
    dispatch({ type: agentProfileTypes.LIST_AGENT_PROFILE, payload: results.data.data });
  } catch (error) {
    console.error("Agent profile list error", error);
  }
};

export const deleteAgentProfile = (id) => async (dispatch) => {
   await axios.delete(`${SERVER}securityAgent/agentProfile/${id}`);

  dispatch({ type: agentProfileTypes.REMOVE_AGENT_PROFILE, payload: id });
};

export const updateAgentProfile =
  (agent, callback, onError) =>
  async (dispatch) => {
    try {
      const results = await axios.put(`${SERVER}securityAgent/agentProfile`, agent);
      dispatch({
        type: agentProfileTypes.UPDATE_AGENT_PROFILE,
        payload: results.data.data,
      });
      dispatch({
        type: notificationTypes.SHOW_SUCCESS,
        payload: { message: "Modifié avec succès" },
      });
      
      callback?.();
    } catch (error) {
      console.log("update agent profile error", error);
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
    }
  };
