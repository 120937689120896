import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { DeleteSVG } from "../utils/svgImages";
import CustomButton from "./CustomButton";

export default function CustomConfirm({
  visible,
  title = "Voulez-vous vraiment supprimer ce site",
  onClose,
  onConfirm,
  showIcon = true,
  icon = <DeleteSVG />,
}) {
  return (
    <Dialog
      open={visible}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Box
          sx={{
            width: {xs: "100%", md: "500px", lg: "500px"},
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
          py={3}
        >
          <IconButton
            onClick={onClose}
            sx={{ position: "absolute", right: 10, top: 10 }}
          >
            <Close htmlColor="#989A9B" />
          </IconButton>
          <div style={{ width: "250px" }}>
            {showIcon && <Typography textAlign="center">{icon}</Typography>}
            <Typography textAlign="center" my={2} fontWeight="500">
              {title}
            </Typography>
            {onConfirm ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <CustomButton
                  title={"Confirmer"}
                  rootStyle={{
                    backgroundColor: "#D9DBDD",
                    width: "45%",
                  }}
                  titleStyle={{ color: "black" }}
                  onClick={onConfirm}
                />
                <CustomButton
                  title={"Annuler"}
                  rootStyle={{ width: "45%", backgroundColor: "#CB1728" }}
                  onClick={onClose}
                />
              </div>
            ) : null}
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
