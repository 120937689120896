import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, Stack, Divider } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import CommandStepper from "./components/CommandStepper";
import { CustomButton, CustomConfirm } from "../../components";
import StepOne from "./components/StepOne";
import StepThree from "./components/StepThree";
import SiteFormModal from "../sites/components/SiteFormModal";
import { LoadingSVG } from "../../utils/svgImages";
import notificationTypes from "../../redux/notifications/notificationTypes";
import CommandForm from "./components/CommandForm";
import { createCommand } from "../../redux/commands/commandActions";
import {
  dayOfWeekAsString,
  getDaysOfWeekBetweenDatesFull,
  usDateFormatter,
} from "../../utils/dateUtils";
import CustomLoader from "../../components/CustomLoader";
import { listNotifications } from "../../redux/notifications/notificationActions";

function NewCommand() {
  const { state } = useLocation();

  const agent = JSON.parse(localStorage.getItem("agent") || "{}");
  const agentTypes = useSelector((state) => state.agentTypes);

  const allSites = useSelector((state) =>
    Object.values(state.sites).filter((val) => !val.isDeleted)
  );
  const sites = allSites.filter((val) =>
    !["superadmin", "admin"].includes(agent?.authAccountType)
      ? val?.client?.id === (agent?.client?.id || agent.id)
      : !val.isDeleted
  );

  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCreateSite, setIsCreateSite] = useState(false);
  const [isSelectSite, setIsSelectSite] = useState(true);
  const [resultModal, setResultModal] = useState(false);
  const [idSiteIntervension, setIdSiteIntervension] = useState("");
  const [formFields, setFormFields] = useState({
    startDate: null,
    endDate: null,
    comment: "",
    clientId: ["sousCompteClient", "client"].includes(agent?.authAccountType)
      ? agent?.id || agent?.client?.id
      : null,
    operationelId: null,
  });

  const [resources, setResources] = useState({});
  const [serviceDays, setServiceDays] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const callback = () => {
    // setResultModal(true);
    setLoading(false);
    navigate("/home/my-commands");
    dispatch(
      listNotifications(String(agent?.authAccountType).toUpperCase(), agent?.id)
    );
  };

  const onError = () => {
    setLoading(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    const item = Object.values(resources)
      .map((val) => {
        return Object.values(val);
      })
      .flat();
    const toSave = {
      ...formFields,
      startDate: usDateFormatter(formFields.startDate),
      endDate: usDateFormatter(formFields.endDate),
      idSiteIntervension: idSiteIntervension,
      commandLines: item.map((val) => ({
        ...val,
        date: new Date(val.date).toLocaleDateString("fr"),
        startTime: usDateFormatter(new Date(val.startTime)),
        endTime: usDateFormatter(new Date(val.endTime)),
      })),
    };

    if (agent?.authAccountType === "admin") {
      toSave.adminId = agent?.id;
    }

    if (agent?.authAccountType === "client") {
      toSave.clientId = agent?.id;
    }

    if (agent?.authAccountType === "sousCompteClient") {
      toSave.clientId = agent?.client?.id;
      toSave.operationelId = agent?.id;
    }

    // console.log(toSave);
    dispatch(createCommand(toSave, callback, onError));
  };

  const toggleSiteMode = (source) => {
    if (source === "create") {
      setIsCreateSite(true);
      setIsSelectSite(false);
    } else {
      setIsCreateSite(false);
      setIsSelectSite(true);
    }
  };

  const navigator = (type) => {
    switch (type) {
      case "prev":
        if (step2) {
          setStep2(false);
          setStep1(true);
        } else if (step3) {
          setStep3(false);
          setStep2(true);
        }
        break;
      case "next":
        if (step1) {
          if (idSiteIntervension) {
            setStep1(false);
            setStep2(true);
          } else {
            dispatch({
              type: notificationTypes.SHOW_ERROR,
              payload: { message: "Veuillez selectionner un Site" },
            });
          }
        } else if (step2) {
          let errors = false;
          // Object.values(commandLines).forEach((val) => {
          //   if (!val.startDate || !val.endDate) {
          //     errors = true;
          //   }
          // });
          if (errors) {
            dispatch({
              type: notificationTypes.SHOW_ERROR,
              payload: { message: "Veuillez ajouter toutes les périodes" },
            });
          } else {
            setStep2(false);
            setStep3(true);
          }
        } else if (step3) {
          handleSubmit();
        }
        break;

      default:
        break;
    }
    window.scrollTo(0, 0);
  };

  const NextStepper = ({ showLeft = false }) => (
    <Stack
      direction="row"
      spacing={5}
      sx={{
        alignItems: "center",
        justifyContent: showLeft ? "space-between" : "flex-end",
      }}
      my={3}
    >
      {showLeft && (
        <CustomButton
          title={"Modifier"}
          titleStyle={{
            fontSize: "13px",
            textTransform: "none",
            fontWeight: "600",
            color: "#161B38",
          }}
          rootStyle={{
            border: "2px solid #161B38",
            height: 42,
            width: "172px",
            backgroundColor: "#fff",
          }}
          onClick={() => navigator("prev")}
        />
      )}
      <CustomButton
        title={step3 ? "Envoyer la commande" : "Etape suivante"}
        titleStyle={{
          fontSize: "13px",
          textTransform: "none",
          fontWeight: "600",
        }}
        rootStyle={{
          backgroundColor: "#161B38",
          height: 42,
          width: "172px",
        }}
        onClick={() => navigator("next")}
      />
    </Stack>
  );

  const initResources = useCallback(() => {
    const indexKey = v4();
    setResources(
      getDaysOfWeekBetweenDatesFull(formFields.startDate, formFields.endDate)
        .map((val) => ({
          dayOfWeek: dayOfWeekAsString(val.day).toUpperCase(),
          date: val.date,
        }))
        .reduce(
          (a, b) => ({
            ...a,
            [`${b.dayOfWeek}_${b.date}`]: {
              [indexKey]: {
                dayOfWeek: b.dayOfWeek,
                startTime: "",
                endTime: "",
                numberOfAgent: "",
                agentTypeId: "",
                index: indexKey,
                date: b.date,
              },
            },
          }),
          {}
        )
    );
    setServiceDays(
      getDaysOfWeekBetweenDatesFull(formFields.startDate, formFields.endDate)
        .map((val) => ({
          dayOfWeek: dayOfWeekAsString(val.day).toUpperCase(),
          startTime: "",
          endTime: "",
          state: true,
          date: val.date,
        }))
        .reduce((a, b) => ({ ...a, [`${b.dayOfWeek}_${b.date}`]: b }), {})
    );
  }, [formFields.startDate, formFields.endDate]);

  useEffect(() => {
    initResources();
  }, [initResources]);

  useEffect(() => {
    if (state?.siteID) {
      setIdSiteIntervension(state?.siteID);
      setStep1(false);
      setStep2(true);
    } else if (state?.command) {
      setFormFields({
        operationelId: state.command?.operationalCreator?.id,
        clientId: state.command?.client?.id,
        comment: state.command?.comment,
        startDate: state.command?.startDate,
        endDate: state.command?.endDate,
      });

      setIdSiteIntervension(state?.command?.siteIntervension?.id);
      setStep1(false);
      setStep2(true);
    }
  }, [state?.command, state?.siteID]);

  return (
    <div style={{ backgroundColor: "#f4f3f7" }}>
      <Box
        p={4}
        px={{ xs: 2, md: 4, lg: 4 }}
        style={{
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "80%", lg: "80%" },
          }}
        >
          <Typography color="#161928" fontWeight="600" fontSize={28} my={3}>
            Nouvelle commande
          </Typography>

          <Stack
            direction="row"
            spacing={5}
            sx={{ alignItems: "center" }}
            my={4}
          >
            <CommandStepper
              step="1"
              title="Création du site"
              active={step1 || step2 || step3}
            />
            <CommandStepper
              step="2"
              title="Type d’agent"
              active={step2 || step3}
            />
            <CommandStepper
              step="3"
              title="Vérification des informations"
              active={step3}
            />
          </Stack>
          <Divider />
        </Box>

        {step1 && (
          <StepOne
            sites={sites}
            isSelectSite={isSelectSite}
            toggleSiteMode={toggleSiteMode}
            idSiteIntervension={idSiteIntervension}
            NextStepper={NextStepper}
            setIdSiteIntervension={setIdSiteIntervension}
            onCreateSise={() => setIsCreateSite(true)}
          />
        )}
        <Box
          sx={{
            width: { xs: "100%", md: "80%", lg: "80%" },
          }}
        >
          {step2 && (
            <div>
              <CommandForm
                formFields={formFields}
                setFormFields={setFormFields}
                resources={resources}
                serviceDays={serviceDays}
                setResources={setResources}
                agentTypes={Object.values(agentTypes).filter(
                  (admin) => !admin.isDeleted
                )}
              />
              <Divider />
              {formFields.startDate && formFields.endDate ? (
                <NextStepper showLeft />
              ) : null}
            </div>
          )}
          {step3 && (
            <StepThree
              commandLines={Object.values(resources)
                .map((val) => {
                  return Object.values(val);
                })
                .flat()}
              formFields={formFields}
              agentTypes={agentTypes}
              NextStepper={NextStepper}
              idSiteIntervension={idSiteIntervension}
            />
          )}
        </Box>
        {isCreateSite && (
          <SiteFormModal
            open={isCreateSite}
            onClose={() => setIsCreateSite(false)}
          />
        )}
        <CustomConfirm
          visible={resultModal}
          icon={<LoadingSVG />}
          onClose={() => navigate("/home/my-commands")}
          title="Votre demande a bien été envoyé Actuellement en cours de traitement..."
        />
      </Box>
      <CustomLoader open={loading} />
    </div>
  );
}

NewCommand.propTypes = {};

export default NewCommand;
