import axios from "axios";
import { SERVER } from "../../utils/server";
import commandProcessingTypes from "./commandProcessingTypes";
import notificationTypes from "../notifications/notificationTypes";
import { CODES } from "../../utils/errorCodes";

export const listApprobations = (id) => async (dispatch) => {
  try {
    const results = await axios.get(
      `${SERVER}commandLineResponse/getLigneResponse?commandLigneId=${id}`
    );
    dispatch({
      type: commandProcessingTypes.LIST_APPROBATIONS,
      payload: results.data.data,
    });
  } catch (error) {
    console.error("List Approbations error", error);
  }
};

export const adminConfirmSelection =
  (data, callback, onError) => async (dispatch) => {
    try {
      const result = await axios.post(
        `${SERVER}commandLineResponse/confirmResponseLineSelection`,
        data
      );
      const { errorCode } = result.data;
      if (errorCode === 39004) {
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Enregistré avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: {
            message: CODES[errorCode],
          },
        });
        onError?.();
      }
    } catch (error) {
      onError?.();
      console.error("adminConfirmSelection error", error);
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: {
          message:
            error?.response?.data?.data?.message || "Une erreur est survenue",
        },
      });
    }
  };

export const adminSaveSendCommand =
  (data, callback, onError) => async (dispatch) => {
    try {
      const result = await axios.post(
        `${SERVER}command/sendCommandResponse`,
        data
      );

      const { errorCode } = result.data;
      if (errorCode === 39004) {
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Enregistré avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: {
            message: CODES[errorCode],
          },
        });
        onError?.();
      }
    } catch (error) {
      onError?.();
      console.error("adminConfirmSelection error", error);
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: {
          message:
            error?.response?.data?.data?.message || "Une erreur est survenue",
        },
      });
    }
  };

export const clientValidateRejectApprobation =
  (data, callback, onError) => async (dispatch) => {
    try {
      const result = await axios.post(
        `${SERVER}commandLineResponse/clientApprobation`,
        data
      );

      const { errorCode } = result.data;
      if (errorCode === 39004) {
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Enregistré avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: {
            message: CODES[errorCode],
          },
        });
        onError?.();
      }
    } catch (error) {
      onError?.();
      console.error("clientValidateRejectApprobation error", error);
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: {
          message:
            error?.response?.data?.data?.message || "Une erreur est survenue",
        },
      });
    }
  };

export const clientConfirmResponse =
  (data, callback, onError) => async (dispatch) => {
    try {
      const result = await axios.post(
        `${SERVER}commandLineResponse/clientConfirmResponseLineSelection`,
        data
      );

      const { errorCode } = result.data;
      if (errorCode === 39004) {
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Enregistré avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: {
            message: CODES[errorCode],
          },
        });
        onError?.();
      }
    } catch (error) {
      onError?.();
      console.error("clientConfirmResponse error", JSON.stringify(error));
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: {
          message:
            error?.response?.data?.data?.message || "Une erreur est survenue",
        },
      });
    }
  };

export const clientValidateRejectCommand =
  (data, callback, onError) => async (dispatch) => {
    try {
      const result = await axios.post(
        `${SERVER}command/valideOrReject/?commandId=${data.commandId}&clientId=${data.clientId}&status=${data.status}&rejectedReason=${data?.rejectedReason}`
      );

      const { errorCode } = result.data;
      if (errorCode === 39004) {
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Enregistré avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: {
            message: CODES[errorCode],
          },
        });
        onError?.();
      }
    } catch (error) {
      onError?.();
      console.error("clientValidateRejectCommand error", error);
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: {
          message:
            error?.response?.data?.data?.message || "Une erreur est survenue",
        },
      });
    }
  };

export const clientCancelCommand =
  (data, callback, onError) => async (dispatch) => {
    try {
      const result = await axios.post(
        `${SERVER}command/cancel/?commandId=${data}`
      );

      const { errorCode } = result.data;
      if (errorCode === 39004) {
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Enregistré avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: {
            message: CODES[errorCode],
          },
        });
        onError?.();
      }
    } catch (error) {
      onError?.();
      console.error("clientValidateRejectCommand error", error);
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: {
          message:
            error?.response?.data?.data?.message || "Une erreur est survenue",
        },
      });
    }
  };

export const clientGetResponseLine = (data) => async (dispatch) => {
  try {
    const results = await axios.get(
      `${SERVER}command/getResponseLigne/${data}`
    );
    dispatch({
      type: commandProcessingTypes.LIST_RESPONSE_LINE,
      payload: results.data.data,
    });
  } catch (error) {
    console.error("clientGetResponseLine error", error);
  }
};
