import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Grid, Paper, Stack, Typography } from "@mui/material";

import { FileSVG } from "../../utils/svgImages";
import { CustomButton } from "../../components";
import CommandEmpty from "./components/CommandEmpty";
import { listCommands } from "../../redux/commands/commandActions";
import CommandCard from "./components/CommandCard";

function Commands() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const agent = JSON.parse(localStorage.getItem("agent") || "{}");
  const commands = useSelector((state) => Object.values(state.commands));

  useEffect(() => {
    dispatch(
      listCommands(
        ["superadmin", "admin"].includes(agent?.authAccountType)
          ? null
          : agent?.id
      )
    );
  }, [agent?.authAccountType, agent?.id, dispatch]);

  return (
    <Box
      p={4}
      style={{
        backgroundColor: "#f4f3f7",
        minHeight: "100vh",
      }}
    >
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={7.7} lg={7.7}>
          <Paper
            elevation={0}
            sx={{
              borderRadius: 3,
              height: "600px",
              backgroundColor: "#fff",
              padding: 2,
            }}
          >
            <Carousel sx={{ width: "100%", height: "70%", borderRadius: 3 }}>
              <img
                src="/agent.webp"
                alt="agent"
                style={{ width: "100%", height: "100%" }}
              />
            </Carousel>
            <Stack
              sx={{
                height: "30%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                textAlign="center"
                color="primary"
                fontSize={20}
                fontWeight={600}
              >
                Agent Cynophile
              </Typography>
              {/* <Typography
                textAlign="center"
                mt={3}
                color="primary"
                fontSize={16}
                fontWeight={400}
              >
                Animi laborum aut praesentium. Architecto fugiat reprehenderit
                qui. Doloremque vel harum voluptatem consequatur ipsa.
              </Typography> */}
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4.3} lg={4.3}>
          <Paper
            elevation={0}
            sx={{
              borderRadius: 3,
              height: "600px",
              backgroundColor: "#fff",
              padding: 2,
            }}
          >
            <Box
              sx={{
                backgroundColor: "#D9DBDD",
                borderRadius: 3,
                padding: 2,
                height: "100%",
              }}
            >
              <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                <Avatar sx={{ bgcolor: "#161B38", width: 48, height: 48 }}>
                  {" "}
                  <FileSVG />
                </Avatar>
                <Typography color="primary" fontSize={20} fontWeight={600}>
                  Commandes
                </Typography>
              </Stack>
              <Box>
                {commands.slice(0, 3).map((command, index) => (
                  <CommandCard
                    key={index}
                    status="En cours"
                    color={"#127D07"}
                    data={command}
                  />
                ))}

                {commands.length ? (
                  <CustomButton
                    title={"Voir toutes les commandes"}
                    titleStyle={{
                      fontSize: "13px",
                      textTransform: "none",
                      fontWeight: "600",
                    }}
                    rootStyle={{
                      backgroundColor: "#161B38",
                      height: 42,
                    }}
                    onClick={() => navigate("/home/my-commands")}
                  />
                ) : null}
              </Box>

              {!commands.length ? <CommandEmpty /> : null}

              {/* <Box>
                <CommandCard status="En cours" color={"#127D07"} />
                <CommandCard status="Terminé" color={"#64677A"} />
                <CommandCard status="Annulé" color={"#D00000"} />

                <CustomButton
                  title={"Voir toutes les commandes"}
                  titleStyle={{
                    fontSize: "13px",
                    textTransform: "none",
                    fontWeight: "600",
                  }}
                  rootStyle={{
                    backgroundColor: "#161B38",
                    height: 42,
                  }}
                  onClick={() => navigate("/home/my-commands-list")}
                />
              </Box> */}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

Commands.propTypes = {};

export default Commands;
