import axios from "axios";
import { SERVER } from "../../utils/server";
import commandLineTypes from "./commandLineTypes";
import notificationTypes from "../notifications/notificationTypes";
import { CODES } from "../../utils/errorCodes";

export const createCommandLine =
  (command, callback, onError) => async (dispatch, getState) => {
    try {
      const results = await axios.post(
        `${SERVER}commandLineResponse/setLigneResponse`,
        command
      );
      // dispatch({
      //   type: commandLineTypes.CREATE_AGENT_COMMANDLINE,
      //   payload: results.data.data,
      // });
      if (onError) {
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "La proposition a bien été envoyé" },
        });
      }
      callback?.();
    } catch (error) {
      onError?.();
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      console.error("Command line creation error", error);
    }
  };

export const agentApprobation =
  (command, callback, onError) => async (dispatch, getState) => {
    try {
      const result = await axios.post(
        `${SERVER}commandLineResponse/agentApprobation`,
        command
      );

      const { errorCode } = result.data;

      if (errorCode === 39004) {
        dispatch({
          type: notificationTypes.SHOW_SUCCESS,
          payload: { message: "Enregistré avec succès" },
        });
        callback?.();
      } else {
        dispatch({
          type: notificationTypes.SHOW_ERROR,
          payload: {
            message: CODES[errorCode],
          },
        });
        onError?.();
      }
    } catch (error) {
      dispatch({
        type: notificationTypes.SHOW_ERROR,
        payload: { message: "Une erreur est survenue" },
      });
      onError?.();
      console.error("Command approbation creation error", error);
    }
  };

export const listBonCommands = (id) => async (dispatch) => {
  try {
    const URL = `${SERVER}commandLineResponse/purchaseOrder?agentId=${id}`;
    const results = await axios.get(URL);
    dispatch({
      type: commandLineTypes.LIST_BON_COMMAND,
      payload: results.data.data,
    });
  } catch (error) {
    console.error("Bon Command list error", error);
  }
};

// export const deleteCommand = (id) => async (dispatch) => {
//    await axios.delete(`${SERVER}command/${id}`);

//   dispatch({ type: commandLineTypes.REMOVE_COMMAND, payload: id });
// };

// export const updateCommand =
//   (command, callback, toastMessage = "Modifié avec succès") =>
//   async (dispatch) => {
//     try {
//       const results = await axios.put(`${SERVER}command`, command);
//       dispatch({
//         type: commandLineTypes.UPDATE_COMMAND,
//         payload: results.data.data,
//       });
//       dispatch({
//         type: notificationTypes.SHOW_SUCCESS,
//         payload: { message: toastMessage },
//       });
//       callback?.();
//     } catch (error) {
//       console.log("update command error", error);
//       dispatch({
//         type: notificationTypes.SHOW_ERROR,
//         payload: { message: "Une erreur est survenue" },
//       });
//     }
//   };
